import React from "react";
import { IoClose } from "react-icons/io5";
function Popup({ type, onClose }) {
  return (
    <div className="popup-container">
      <div className="p-3 rounded-2xl  bg-white w-full md:max-w-[470px] pop-up-animation mx-2 relative">
        <h2 className="text-[15px] font-semibold border-b flex items-center justify-between pb-2 px-3">
          {type === "edit" ? <>Edit Challan</> : <>Upload Challan</>}

          <span
            onClick={() => onClose()}
            className="bg-[#ffffff] hover:bg-red-300 border-[1px] border-zinc-400 hover:border-red-500 shadow-sm hover:shadow p-1 rounded-full text-black hover:scale-95 duration-300 cursor-pointer absolute -right-2 -top-2 z-10"
          >
            <IoClose className="text-xl" />
          </span>
        </h2>

        <div className="flex flex-col border rounded-lg font-semibold m-2.5">
          <div className="flex justify-between px-3 py-1 pt-3">
            <p>Total Purchase Value:</p>
            <span className="text-sm">₹ 10,00,000/-</span>
          </div>
          <div className="flex justify-between px-3 py-2">
            <p>Already Uploaded:</p>
            <span className="text-sm ">-₹ 8,00,000/-</span>
          </div>
          <div className="flex justify-between bg-[#F1F7F9] px-3 py-2 rounded-b-lg">
            <p>Balance Amount:</p>
            <span className="text-sm text-red-600">₹ 2,00,000/-</span>
          </div>
        </div>
        <div className="flex flex-col gap-5 px-3 items-start justify-between my-5 ">
          {/* Purchase and date */}
          <div className="flex justify-between gap-3  w-full">
            <div className="font-semibold flex flex-col">
              Purchase id :
              <select
                name=""
                id=""
                className="mt-1 px-2 py-1 text-gray-700 bg-white  border-gray-300 rounded-md focus:outline-none shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] transition-all font-medium min-w-[178px] text-[13px]"
              >
                <option value="">BUWCH/26/001</option>
              </select>
            </div>
            <div className="">
              <h2 className="font-semibold">Date:</h2>
              <div>
                <input
                  type="date"
                  className="mt-1 px-2 py-1 text-gray-700 bg-white  border-gray-300 rounded-md focus:outline-none shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] transition-all font-medium min-w-[178px]"
                />
              </div>
            </div>
          </div>
          {/*  */}
          <div className="flex justify-between gap-3  w-full">
            <div className="font-semibold flex flex-col ">
              Supplier A/C No :
              <input
                type="text"
                placeholder="Enter Account Number"
                className="mt-1 px-2 py-1 text-gray-700 bg-white  border-gray-300 rounded-md focus:outline-none shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] transition-all font-medium min-w-[160px]  placeholder:text-xs"
              />
            </div>
            <div className="">
              <h2 className="font-semibold">IFSC:</h2>
              <input
                type="text"
                placeholder="Enter IFSC"
                className="mt-1 px-2 py-1 text-gray-700 bg-white  border-gray-300 rounded-md focus:outline-none shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] transition-all font-medium min-w-[160px]  placeholder:text-xs"
              />
            </div>
          </div>

          <div className="flex justify-between gap-3  w-full">
            <div className="font-semibold flex flex-col ">
              UTR/Transaction ID: :
              <input
                type="text"
                placeholder="UTR"
                className="mt-1 px-2 py-1 text-gray-700 bg-white  border-gray-300 rounded-md focus:outline-none shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] transition-all font-medium min-w-[160px]  placeholder:text-xs"
              />
            </div>
            <div className="">
              <h2 className="font-semibold">Amount:</h2>
              <input
                type="text"
                placeholder="Amount"
                className="mt-1 px-2 py-1 text-gray-700 bg-white  border-gray-300 rounded-md focus:outline-none shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] transition-all font-medium min-w-[160px]  placeholder:text-xs"
              />
            </div>
          </div>
        </div>

        {/* Change Button */}
        {type === "edit" ? (
          <div className="flex justify-between items-center w-full mt-2.5 px-3 mb-2">
            <button
              onClick={() => onClose()}
              className="bg-[#FFD61F] hover:bg-[#FEC23D] duration-300 px-5 py-1.5 rounded-full text-sm font-semibold text-black flex items-center justify-center gap-2 w-fit disabled:opacity-50 disabled:cursor-not-allowed"
            >
              Change
              <span className="text-xl">
                <svg
                  version="1.1"
                  className="w-3.5 h-3.5"
                  id="fi_545661"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsHlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 512 512"
                  xmlSpace="preserve"
                >
                  <g>
                    <g>
                      <path
                        d="M493.815,70.629c-11.001-1.003-20.73,7.102-21.733,18.102l-2.65,29.069C424.473,47.194,346.429,0,256,0
              C158.719,0,72.988,55.522,30.43,138.854c-5.024,9.837-1.122,21.884,8.715,26.908c9.839,5.024,21.884,1.123,26.908-8.715
              C102.07,86.523,174.397,40,256,40c74.377,0,141.499,38.731,179.953,99.408l-28.517-20.367c-8.989-6.419-21.48-4.337-27.899,4.651
              c-6.419,8.989-4.337,21.479,4.651,27.899l86.475,61.761c12.674,9.035,30.155,0.764,31.541-14.459l9.711-106.53
              C512.919,81.362,504.815,71.632,493.815,70.629z"
                      ></path>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M472.855,346.238c-9.838-5.023-21.884-1.122-26.908,8.715C409.93,425.477,337.603,472,256,472
              c-74.377,0-141.499-38.731-179.953-99.408l28.517,20.367c8.989,6.419,21.479,4.337,27.899-4.651
              c6.419-8.989,4.337-21.479-4.651-27.899l-86.475-61.761c-12.519-8.944-30.141-0.921-31.541,14.459l-9.711,106.53
              c-1.003,11,7.102,20.73,18.101,21.733c11.014,1.001,20.731-7.112,21.733-18.102l2.65-29.069C87.527,464.806,165.571,512,256,512
              c97.281,0,183.012-55.522,225.57-138.854C486.594,363.309,482.692,351.262,472.855,346.238z"
                      ></path>
                    </g>
                  </g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                </svg>
              </span>
            </button>
            <button
              onClick={() => onClose()}
              className=" border duration-300 px-5 py-1.5 rounded-full text-sm font-semibold text-black flex items-center justify-center gap-2 w-fit disabled:opacity-50 disabled:cursor-not-allowed shadow-sm"
            >
              Delete
            </button>
          </div>
        ) : (
          <button
            onClick={() => onClose()}
            className="bg-[#FFD61F] hover:bg-[#FEC23D] duration-300 px-5 py-1.5 rounded-full text-sm font-semibold text-black mx-auto flex items-center justify-center gap-2 w-fit disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Submit
            <span className="text-xl">
              <svg
                id="fi_5467630"
                className="w-2.5 h-2.5"
                viewBox="0 0 512 512"
                xmlns="http://www.w3.org/2000/svg"
                data-name="Layer 1"
              >
                <path d="m512 40v432a40 40 0 0 1 -80 0v-335.432l-363.716 363.717a40 40 0 0 1 -56.569-56.569l363.717-363.716h-335.432a40 40 0 0 1 0-80h432a40 40 0 0 1 40 40z"></path>
              </svg>
            </span>
          </button>
        )}
      </div>
    </div>
  );
}

export default Popup;
