import React, { useRef, useEffect, useState } from "react";

const ReviewsMarquee = ({ reviews }) => {
  const [isOverflowing, setIsOverflowing] = useState(false);
  const containerRef = useRef(null);
  const marqueeRef = useRef(null);
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    if (marqueeRef.current) {
      const totalWidth = marqueeRef.current.scrollWidth;
      const containerWidth = marqueeRef.current.offsetWidth;
      const calculatedDuration = totalWidth / 100;
      setDuration(calculatedDuration);
    }
  }, [reviews]);

  useEffect(() => {
    const checkOverflow = () => {
      if (containerRef.current && marqueeRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        const contentWidth = marqueeRef.current.scrollWidth;

        setIsOverflowing(contentWidth > containerWidth);
      }
    };

    checkOverflow();
    window.addEventListener("resize", checkOverflow);

    return () => {
      window.removeEventListener("resize", checkOverflow);
    };
  }, []);

  return (
    <div
      className="flex  justify-center items-center gap-2.5 mt-5 overflow-hidden relative"
      ref={containerRef}
    >
      <div
        className={`flex gap-5 ${isOverflowing ? "animate-marquee" : ""}`}
        ref={marqueeRef}
        style={{ animationDuration: `${duration}s` }}
      >
        {reviews.map((review, index) => (
          <div
            key={index}
            className="flex flex-col gap-2 w-[250px] md:w-[300px] h-max py-3 px-3 rounded-md mt-8 border hover:scale-[102%] hover:duration-150 duration-150 text-[#313131]"
          >
            <div className="flex flex-row justify-between items-center w-full mb-2">
              <div className="flex flex-row justify-start items-center gap-2 w-full">
                {review?.pic ? (
                  <img
                    src={review.pic}
                    alt=""
                    className="md:w-7 w-6 h-6 md:h-7 rounded-full"
                  />
                ) : (
                  <img
                    src="/images/user.svg"
                    alt=""
                    className="md:w-7 w-6 h-6 md:h-7 rounded-full bg-slate-600 animate-pulse"
                  />
                )}
                <div className="flex flex-col justify-start items-start">
                  <div className="font-semibold text-xs md:text-sm">
                    {review.username}
                  </div>
                  <div className="text-xs">
                    <div className="rating">
                      {Array.from({ length: 5 }, (_, index) => {
                        const rate = parseInt(review.rating);
                        const starValue = 5 - index;
                        const isFullStar = starValue <= Math.floor(rate);
                        const isHalfStar =
                          starValue === Math.ceil(rate) && rate % 1 !== 0;
                        const isEmptyStar = starValue > rate;
                        return (
                          <React.Fragment key={starValue}>
                            <input
                              value={starValue}
                              name="rating"
                              type="radio"
                              className="hidden"
                              defaultChecked={Math.round(rate) === starValue}
                            />
                            <label>
                              {isFullStar ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="#FFD700"
                                  viewBox="0 0 24 24"
                                  className="w-2 h-2 md:w-3 md:h-3"
                                >
                                  <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                                </svg>
                              ) : isHalfStar ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  className="w-2 h-2 md:w-3 md:h-3"
                                >
                                  <defs>
                                    <linearGradient
                                      id={`half-star-${starValue}`}
                                      x1="0%"
                                      y1="0%"
                                      x2="100%"
                                      y2="0%"
                                    >
                                      <stop offset="50%" stopColor="#FFD700" />{" "}
                                      {/* Gold for left half */}
                                      <stop
                                        offset="50%"
                                        stopColor="lightgray"
                                      />{" "}
                                      {/* Gray for right half */}
                                    </linearGradient>
                                  </defs>
                                  <polygon
                                    points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"
                                    fill={`url(#half-star-${starValue})`}
                                  />
                                </svg>
                              ) : (
                                isEmptyStar && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="lightgray" // Gray color for empty stars
                                    viewBox="0 0 24 24"
                                    className="w-2 h-2 md:w-3 md:h-3"
                                  >
                                    <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                                  </svg>
                                )
                              )}
                            </label>
                          </React.Fragment>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-row justify-between w-full z-10">
              <div className="w-full font-semibold md:font-medium text-xs md:text-sm">
                {review.title}
              </div>
            </div>
            <div className="w-full h-16 text-xs">{review.description}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReviewsMarquee;
