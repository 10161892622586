import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { IoEyeSharp } from "react-icons/io5";
import { HiMiniEyeSlash } from "react-icons/hi2";
import CheckAuth from "../ServiceFunctions/CheckAuth";
import { FaGoogle, FaLinkedin } from "react-icons/fa";
import secureLocalStorage from "react-secure-storage";
import Loader1 from "../Loaders/Loader1";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";

function Login() {
  const { pan } = CheckAuth();
  const [username, setUsername] = useState("");
  const [loader, setLoader] = useState(false);
  const [pass, setPass] = useState("");
  const [kycPop, setKycPop] = useState(false);
  const [verifyLogin, setVerifyLogin] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const storedData = secureLocalStorage.getItem("kycData");
  const storedData2 = secureLocalStorage.getItem("Material_cost");
  const storedData3 = secureLocalStorage.getItem("Customize");
  const storedData4 = secureLocalStorage.getItem("Catalogue");
  const storedData5 = secureLocalStorage.getItem("user_order");
  const navigate = useNavigate();
  const [registerPopUp, setRegisterPopUp] = useState(false);

  if (pan) {
    navigate("/");
  }

  const popupRef = useRef(null);
  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setRegisterPopUp(false);
    }
  };

  useEffect(() => {
    if (registerPopUp) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [registerPopUp]);

  function getDeviceInfo() {
    const deviceInfo = {
      platform: navigator.platform,
      userAgent: navigator.userAgent,
      browser: navigator.appName,
      browserVersion: navigator.appVersion,
      language: navigator.language,
      screenWidth: window.screen.width,
      screenHeight: window.screen.height,
    };
    return deviceInfo;
  }

  const handleLogin = (e) => {
    setLoader(true);
    e.preventDefault();
    const deviceInfo = getDeviceInfo();
    fetch(`https://buwchlifestyles.com/api/login_check/`, {
      method: "POST",
      body: JSON.stringify({
        username: username,
        password: pass,
        device_info: deviceInfo,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to login");
        }
        return response.json();
      })
      .then((response) => {
        if (response.data === "Dual login detected") {
          setLoader(false);
          setErrorMessage(true);
          setLoader(false);
        } else {
          if (response.data === "user not registered") {
            setVerifyLogin(1);
            setLoader(false);
          } else {
            if (response.data === "wrong password, try again") {
              setVerifyLogin(2);
              setErrorMessage(false);
              setLoader(false);
            } else {
              setTokens(
                response.data.access_token,
                response.data.refresh_token
              );
              setVerifyLogin(0);
              if (storedData === null) {
                secureLocalStorage.setItem(
                  "kycData",
                  JSON.stringify(response.data.data5)
                );
              }
              if (storedData3 === null) {
                secureLocalStorage.setItem(
                  "Customize",
                  JSON.stringify(response.data.customize)
                );
              }
              if (storedData2 === null) {
                secureLocalStorage.setItem(
                  "Material_cost",
                  JSON.stringify(response.data.material_cost)
                );
              }
              if (storedData4 === null) {
                secureLocalStorage.setItem(
                  "Catalogue",
                  JSON.stringify(response.data.catalogue)
                );
              }
              if (storedData5 === null) {
                secureLocalStorage.setItem(
                  "user_order",
                  JSON.stringify(response.data.user_order)
                );
              }
              alert(response.data.message);
              setLoader(false);
              if (
                window.location.origin === "https://buwch.com" &&
                (window.location.pathname === "/" ||
                  window.location.pathname === "")
              )
                navigate(`/${response.data.user_type.toLowerCase()}/dashboard`);
              else window.history.back();
              window.location.reload();
            }
          }
        }
      })
      .catch((error) => {
        // console.error("Error: ", error);
      });
  };

  const handleGoogleLoginSuccess = (response) => {
    setLoader(true);
    const deviceInfo = getDeviceInfo();
    // Send response token to the backend for verification
    fetch("https://buwchlifestyles.com/google/callback/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: response.credential,
        device_info: deviceInfo,
      }),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .then((response) => {
        if (response.data === "Dual login detected") {
          setLoader(false);
          setErrorMessage(true);
          setLoader(false);
        } else {
          if (response.data === "user not registered") {
            setVerifyLogin(1);
            setLoader(false);
            setUsername(response.name);
            navigate("/register", {
              state: {
                email: response.email,
                name: response.name,
                alert:
                  "Gmail ID provided does not match our records. Please register.",
              },
            });
          } else {
            if (response.data === "wrong password, try again") {
              setVerifyLogin(2);
              setErrorMessage(false);
              setLoader(false);
            } else {
              setTokens(
                response.data.access_token,
                response.data.refresh_token
              );
              const cookies = document.cookie.split("; ");
              setVerifyLogin(0);
              if (
                storedData === null ||
                storedData2 === null ||
                storedData3 === null ||
                storedData4 === null ||
                storedData5 === null
              ) {
                secureLocalStorage.setItem(
                  "kycData",
                  JSON.stringify(response.data5)
                );
                secureLocalStorage.setItem(
                  "Customize",
                  JSON.stringify(response.data1)
                );
                secureLocalStorage.setItem(
                  "Material_cost",
                  JSON.stringify(response.data2)
                );
                secureLocalStorage.setItem(
                  "Catalogue",
                  JSON.stringify(response.data3)
                );
                secureLocalStorage.setItem(
                  "user_order",
                  JSON.stringify(response.data4)
                );
              }
              // setMessage(response.data.message);
              // setPan2(response.pan);
              // setStatus(response.data.status);
            }
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleGoogleLoginFailure = (error) => {
    console.error("Google SignIn Failure", error);
  };

  const setTokens = (accessToken, refreshToken) => {
    const access_token_expires = new Date();
    const refresh_token_expires = new Date();

    // Set access token to expire after 1 minute
    access_token_expires.setTime(
      access_token_expires.getTime() + 60 * 60 * 1000
    );

    // Set refresh token to expire after 12 hours
    refresh_token_expires.setTime(
      refresh_token_expires.getTime() + 12 * 60 * 60 * 1000
    );

    document.cookie = `access_token=${accessToken};expires=${access_token_expires.toUTCString()};path=/`;
    document.cookie = `refresh_token=${refreshToken};expires=${refresh_token_expires.toUTCString()};path=/`;
    secureLocalStorage.setItem("access_token_time", access_token_expires);
  };

  return (
    <>
      {loader && <Loader1 />}
      <div className="w-full mx-auto flex flex-col justify-center items-center bg-white">
        <a href="/">
          <img
            className="mx-auto h-16 w-auto"
            src="/images/BuwchLogo.svg"
            alt="Your Company"
          />
        </a>
        <h2 className="md:mt-2  text-center text-xl md:text-2xl font-bold leading-9 tracking-tight text-[#212121] w-[350px] md:w-[450px] max-w-md">
          Sign in to your dashboard
        </h2>
      </div>
      <div className="mt-8 md:mt-10 sm:mx-auto sm:w-full sm:max-w-sm bg-white mb-4">
        <form className="space-y-6" action="#" method="POST">
          <div className="group">
            {verifyLogin === 1 && (
              <>
                <p className="text-danger mb-2 mt-0 font-size1 text-center">
                  User is not Registered.
                </p>
              </>
            )}
            <label
              htmlFor="email"
              className="block text-sm font-semibold  leading-4 text-[#212121]"
            >
              Enter ID
            </label>
            <div className="mt-2 ">
              <input
                value={username}
                onChange={(e) => {
                  setUsername(e.target.value);
                  secureLocalStorage.clear();
                }}
                id="email"
                name="email"
                type="email"
                placeholder="Email/Phone"
                autoComplete="email"
                className={`px-2 block w-full  py-1.5 text-[#212121] ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 group-hover:ring-2 group-hover:ring-[#eebd2d] group-hover:outline-none group-hover:ring-inset focus:ring-2 focus:ring-inset focus:ring-[#eebd2d] focus:outline-none  sm:text-sm sm:leading-6 ${
                  verifyLogin === 1 ? "border-danger border-2" : "border-dark"
                }`}
                required
              />
            </div>
          </div>

          <div className="group">
            {verifyLogin === 2 && (
              <>
                <p className="text-danger my-2 font-size1 text-center">
                  Password is incorrect. Try again!
                </p>
              </>
            )}
            <div className="flex items-center justify-between group">
              <label
                htmlFor="password"
                className="block text-sm font-semibold  leading-4 text-[#212121] "
              >
                Enter Password
              </label>

              <div className="text-sm">
                <a
                  href="/forgetpassword"
                  className="font-semibold text-[#FFC94A] hover:text-[#e2a731] duration-300 ease-in-out"
                >
                  Forgot password?
                </a>
              </div>
            </div>

            <div className="mt-2">
              <div
                className={`relative flex ${
                  verifyLogin === 2 ? "border-danger border-2" : "border-dark"
                }`}
              >
                <input
                  value={pass}
                  onChange={(e) => {
                    setPass(e.target.value);
                    secureLocalStorage.clear();
                  }}
                  type={showPassword ? "text" : "password"}
                  id="password"
                  name="password"
                  placeholder="Password"
                  autoComplete="current-password"
                  required
                  className="px-2 py-1.5 block w-full border-0 text-[#212121] ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 group-hover:ring-2 group-hover:ring-[#eebd2d] group-hover:outline-none group-hover:ring-inset focus:ring-2 focus:ring-inset focus:ring-[#eebd2d] focus:outline-none sm:text-sm sm:leading-6"
                />
                <div
                  className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <IoEyeSharp size={22} color="#FFC94A" />
                  ) : (
                    <HiMiniEyeSlash size={22} />
                  )}
                </div>
              </div>
            </div>
          </div>

          <div>
            <button
              type="submit"
              onClick={handleLogin}
              className="flex w-full justify-center bg-[#FFC94A] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[#e2a731] duration-300 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#dc9119]"
            >
              Sign in
            </button>
          </div>
        </form>

        <p className="mt-10 text-center text-sm text-gray-500">
          Don't have an account?{" "}
          <a
            href="/register"
            className="font-semibold leading-6 text-[#FFC94A] hover:text-[#e2a731] duration-300 ease-in-out"
          >
            Register Here
          </a>
        </p>
        <div className="mt-3  text-center text-sm font-semibold text-gray-500 flex-column">
          Or Sign-up with:{" "}
          <div className="signup-icons mt-1 flex items-center justify-center gap-2">
            <GoogleOAuthProvider clientId="666963340992-cigf9d6on3gtajkrdg8qoj1i9stkv3i0.apps.googleusercontent.com">
              <div className="">
                <GoogleLogin
                  onSuccess={handleGoogleLoginSuccess}
                  onError={handleGoogleLoginFailure}
                  scope="https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/user.phonenumbers.read"
                  size="large"
                  width="300px"
                />
              </div>
            </GoogleOAuthProvider>

            <button
              data-mdb-ripple-init
              type="button"
              className=" btn-floating mx-1 scale-100 hover:scale-90 duration-300 ease-in-out text-[#FFC94A] hover:text-[#e2a731]"
            >
              <FaLinkedin size={30} />
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
