// Import necessary dependencies
import { useState, useEffect, useRef } from "react";
import Confetti from "react-confetti";
import secureLocalStorage from "react-secure-storage";
import Header from "../../components/Header/Header";
import { QRCodeSVG } from "qrcode.react";
import Tesseract from "tesseract.js";
import CheckAuth from "../../components/ServiceFunctions/CheckAuth";

const EcommerceComponent = () => {
  const { email, type, currency } = CheckAuth();
  let userData = JSON.parse(secureLocalStorage.getItem("kycData"));
  let MaterialCost = JSON.parse(secureLocalStorage.getItem("Material_cost"));
  let Customize = JSON.parse(secureLocalStorage.getItem("Customize"));
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
  const [orderStatus, setOrderStatus] = useState(null); // 'success' or 'failed'
  const [showPopup, setShowPopup] = useState(false);
  const [cartItem, setCartItem] = useState(null);
  const [loyalty, setLoyalty] = useState(userData?.Loyalty_points || 0);
  const [bankOpen, setBankOpen] = useState(false);
  const [selectedBank, setSelectedBank] = useState(null);
  const [isRedirected, setIsRedirected] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState("bankPayment");
  const [file, setFile] = useState(null);
  const [submitNeft, setSubmitNeft] = useState(false);
  const [vpa, setVpa] = useState("");
  const [paymentStarted, setPaymentStarted] = useState(false);
  const [timer, setTimer] = useState(1000);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (email && email !== "") {
      fetch(`https://buwchlifestyles.com/api/get-cart-data/`, {
        method: "POST",
        body: JSON.stringify({
          email,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to fetch data");
          }
          return response.json();
        })
        .then((data) => {
          if (data.message === "success") {
            setCartItem(data.data);
          } else {
            alert(data.message);
          }
        })
        .catch((error) => {
          console.error("Error: ", error);
        });

      const selectedAddressKey = Object.keys(userData || {}).find(
        (key) => key.startsWith("Shippingaddress") && userData[key]?.isDefault
      );

      const selectedAddress = selectedAddressKey
        ? {
            name: userData[selectedAddressKey]?.receiverName || "",
            postalCode: userData[selectedAddressKey]?.pin || "",
            state: userData[selectedAddressKey]?.state || "",
            city: userData[selectedAddressKey]?.district || "",
            address: `${userData[selectedAddressKey]?.addressline1 || ""} ${
              userData[selectedAddressKey]?.addressline2 || ""
            }`,
          }
        : null;
      setSelectedAddress(selectedAddress);
    }
  }, [email]);

  const handleBuyNow = () => {
    setShowPopup(true);
  };

  const handlePayment = () => {
    // Simulate payment process
    const isSuccess = Math.random() > 0.5; // Randomly decide success or failure
    setOrderStatus(isSuccess ? "success" : "failed");
    setShowPopup(false);
  };

  const handleQuantity = (data, action) => {
    fetch(`https://buwchlifestyles.com/api/update-cart-item/`, {
      method: "POST",
      body: JSON.stringify({
        data,
        action,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        return response.json();
      })
      .then((data) => {
        if (data.message === "success") {
          setCartItem(data.data);
        } else {
          alert(data.message);
        }
      })
      .catch((error) => {
        console.error("Error: ", error);
      });
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const [isEditing, setIsEditing] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);

  const savedAddresses = Object?.keys(userData || {})
    .filter((key) => key.startsWith("Shippingaddress"))
    .map((key) => ({
      name: userData?.[key]?.receiverName || "",
      postalCode: userData?.[key]?.pin || "",
      state: userData?.[key]?.state || "",
      city: userData?.[key]?.district || "",
      address: `${userData?.[key]?.addressline1 || ""} ${
        userData?.[key]?.addressline2
      }`,
      default: userData?.[key]?.isDefault || false,
    }));

  const bankLinks = [
    { name: "State Bank of India", url: "https://www.onlinesbi.sbi/" },
    { name: "Bank of Baroda", url: "https://www.bankofbaroda.in/" },
    { name: "Bank of India", url: "https://bankofindia.co.in/" },
    { name: "Bank of Maharashtra", url: "https://bankofmaharashtra.in/" },
    { name: "Canara Bank", url: "https://www.canarabank.com/" },
    {
      name: "Central Bank of India",
      url: "https://www.centralbankofindia.co.in/en",
    },
    { name: "Indian Bank", url: "https://www.indianbank.in/" },
    { name: "Indian Overseas Bank", url: "https://www.iob.in/" },
    { name: "Punjab & Sind Bank", url: "https://punjabandsindbank.co.in/" },
    { name: "Punjab National Bank", url: "https://www.pnbindia.in/" },
    { name: "UCO Bank", url: "https://www.ucobank.com/hi/" },
    { name: "Union Bank of India", url: "https://www.unionbankonline.co.in/" },

    // Private Sector Banks
    { name: "Axis Bank", url: "https://www.axisbank.com/" },
    { name: "Bandhan Bank", url: "https://www.bandhanbank.com/" },
    { name: "Catholic Syrian Bank", url: "https://www.csb.co.in/" },
    { name: "City Union Bank", url: "https://www.cityunionbank.com/" },
    { name: "DCB Bank", url: "https://www.dcbbank.com/" },
    { name: "Dhanlaxmi Bank", url: "https://www.dhanbank.com/" },
    { name: "Federal Bank", url: "https://www.federalbank.co.in/" },
    { name: "HDFC Bank", url: "https://www.hdfcbank.com/" },
    { name: "ICICI Bank", url: "https://www.icicibank.com/" },
    { name: "IDBI Bank", url: "https://www.idbibank.in/" },
    { name: "IDFC FIRST Bank", url: "https://www.idfcfirstbank.com/" },
    { name: "IndusInd Bank", url: "https://www.indusind.com/" },
    { name: "Jammu & Kashmir Bank", url: "https://www.jkbank.com/" },
    { name: "Karnataka Bank", url: "https://karnatakabank.com/" },
    { name: "Karur Vysya Bank", url: "https://www.kvb.co.in/" },
    { name: "Kotak Mahindra Bank", url: "https://www.kotak.com/" },
    { name: "RBL Bank", url: "https://www.rblbank.com/" },
    { name: "South Indian Bank", url: "https://www.southindianbank.com/" },
    { name: "YES Bank", url: "https://www.yesbank.in/" },
    {
      name: "Nainital bank Ltd.",
      url: "https://www.nainitalbank.co.in/english/home.aspx",
    },
    { name: "Tamilnad Mercantile Bank Ltd.", url: "https://tmb.in/" },
  ];

  const handlePay = () => {
    if (selectedBank) {
      //   window.open(selectedBank.url, "_blank");
      setTimeout(() => setIsRedirected(true), 500); // Simulate redirection delay
      setCurrentPage("uploadVerification");
    } else {
      alert("Please select a bank first.");
    }
  };
  const handleNeft = async () => {
    const formData = new FormData();
    formData.append("email", email);
    formData.append("loyaltyPoint", loyalty);
    formData.append("bank", selectedBank.name);
    formData.append("method", selectedPaymentMethod);
    formData.append("file", file);
    formData.append("order_id", "Buwch/200218786");
    formData.append("orders", JSON.stringify(cartItem));

    try {
      const response = await fetch(
        "https://buwchlifestyles.com/api/product-checkout/",
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();
      console.log(data);

      // Assuming `data` contains extracted information
      const extractedData = data; // Adjust according to backend response structure

      if (data.message === "success") {
        console.log("File uploaded successfully");
      }

      // Validation for extracted data
      if (
        !extractedData.senderBank ||
        !extractedData.utrNo ||
        !extractedData.amount
      ) {
        setError("Validation failed. Ensure all required fields are present.");
        return;
      }

      alert("Payment verification submitted successfully!");
    } catch (error) {
      console.error("Error: ", error);
      setError("An error occurred during file upload or validation.");
    }
  };

  const filteredBanks = bankLinks.filter((bank) =>
    bank.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const dropdownRef = useRef(null);
  const popupRef = useRef(null);
  // Close dropdown on outside click
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setBankOpen(false);
      }
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowPopup(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Update the timer every second if paymentStarted is true
  useEffect(() => {
    let interval;
    if (paymentStarted && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      setPaymentSuccess(true);
    }
    return () => clearInterval(interval);
  }, [paymentStarted, timer]);

  const [isVerified, setIsVerified] = useState(false);
  const [countdown, setCountdown] = useState(5);

  const handleVerify = () => {
    if (vpa) {
      setIsVerified(true); // Mark as verified
    }
  };

  useEffect(() => {
    let timer;
    if (isVerified && countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prev) => prev - 1); // Countdown logic
      }, 1000);
    } else if (isVerified && countdown === 0) {
      handlePayNow(); // Trigger payment logic when countdown ends
    }
    return () => clearInterval(timer); // Cleanup timer
  }, [isVerified, countdown]);

  const handlePayNow = () => {
    setPaymentStarted(true); // Replace with your payment logic
  };

  const [extractedData, setExtractedData] = useState({});
  const [error, setError] = useState("");

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setFile(file);
    setError("");

    const fileExtension = file?.name.split(".").pop().toLowerCase();

    if (fileExtension === "pdf") {
      await parsePDF(file);
    } else if (["jpeg", "jpg", "png"].includes(fileExtension)) {
      await parseImage(file);
    } else {
      setError("Unsupported file type. Please upload a PDF or image.");
    }
  };

  const calculatePrice = (item) => {
    let price = 0;
    let consumption = 0;
    const index = Customize?.findIndex(
      (data) => data?.Article_no === item?.Article_no
    );
    if (index !== -1) {
      consumption = Customize[index].Leather.Consumption;
    }
    MaterialCost?.forEach((mat) => {
      if (
        mat.item === "Leathers" &&
        mat.Leathers[item.Leather?.replaceAll(" ", "_")]
      ) {
        const leather = mat.Leathers[item.Leather?.replaceAll(" ", "_")];
        const leatherKey = Object.keys(leather).find(
          (key) =>
            leather[key].Color === item.color_leather?.replaceAll(" ", "_")
        );
        if (leatherKey) {
          price += leather[leatherKey].Price_per_dm * consumption;
        }
      }

      if (mat.item === "Soles" && mat.Soles[item.Sole?.replaceAll(" ", "_")]) {
        // Calculate sole price
        price += mat.Soles[item.Sole?.replaceAll(" ", "_")].Price || 0;
      }

      if (
        mat.item === "Finishings" &&
        mat.Finishings[item.Finish?.replaceAll(" ", "_")]
      ) {
        // Calculate finishing price
        price += mat.Finishings[item.Finish?.replaceAll(" ", "_")].Price || 0;
      }

      if (
        mat.item === "Constructions" &&
        mat.Constructions[item.Construction?.replaceAll(" ", "_")]
      ) {
        // Calculate construction price
        price +=
          mat.Constructions[item.Construction?.replaceAll(" ", "_")].Price || 0;
      }

      if (mat.item === "Welts" && mat.Welts[item.Welt?.replaceAll(" ", "_")]) {
        // Calculate welt price
        price += mat.Welts[item.Welt?.replaceAll(" ", "_")].Price || 0;
      }

      if (mat.item === "Edges" && mat.Edges[item.Edge?.replaceAll(" ", "_")]) {
        // Calculate edge price
        price += mat.Edges[item.Edge?.replaceAll(" ", "_")].Price || 0;
      }

      if (
        mat.item === "Thickness" &&
        mat.Thickness[item.Sole_thickness?.replaceAll(" ", "_")]
      ) {
        // Calculate thickness price
        price +=
          mat.Thickness[item.Sole_thickness?.replaceAll(" ", "_")].Price || 0;
      }
    });

    return price;
  };

  const parsePDF = async (file) => {
    try {
      const arrayBuffer = await file.arrayBuffer();
      // const text = await extractTextFromPDF(arrayBuffer);
      // validateData(text);
    } catch (err) {
      setError("Failed to extract data from the PDF file.");
    }
  };

  const parseImage = async (file) => {
    try {
      const reader = new FileReader();
      reader.onload = async () => {
        try {
          const result = await Tesseract.recognize(reader.result, "eng");
          const text = result.data.text;
          // validateData(text);
        } catch (err) {
          setError("Failed to extract data from the image file.");
        }
      };
      reader.readAsDataURL(file);
    } catch (err) {
      setError("Failed to process the image file.");
    }
  };

  const validateData = (text) => {
    const senderBank = text.match(/(SBI|Axis|HDFC|PNB)/i)?.[0];
    const utrNo = text.match(/\b[0-9A-Z]{8,}\b/)?.[0];
    const amount = text.match(/\b\d+(\.\d{1,2})?\b/)?.[0];

    if (!senderBank || !utrNo || !amount) {
      setError(
        "Some information is missing or incorrect. Please recheck the file."
      );
      return;
    }

    setExtractedData({ senderBank, utrNo, amount });
    setError("");
  };

  const DeleteCartItem = (data) => {
    fetch(`https://buwchlifestyles.com/api/delete-from-cart/`, {
      method: "POST",
      body: JSON.stringify({
        data,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        return response.json();
      })
      .then((data) => {
        if (data.message === "success") {
          setCartItem(data.data);
        } else {
          alert(data.message);
        }
      })
      .catch((error) => {
        console.error("Error: ", error);
      });
  };

  const calculateTotal_GST = (type, cartItem, stateCode) => {
    let total = 0;
    let percent = 0;
    let igst = 0;
    let cgst = 0;
    let sgst = 0;

    cartItem?.forEach((data) => {
      const price = calculatePrice(data);
      if (price > 1000) percent = 0.18;
      else percent = 0.12;

      if (type === "individual") {
        cgst += price * (percent / 2) * data?.Quantity;
        sgst += price * (percent / 2) * data?.Quantity;
      } else {
        if (stateCode) {
          if (stateCode === "07") {
            cgst += price * (percent / 2) * data?.Quantity;
            sgst += price * (percent / 2) * data?.Quantity;
          } else {
            igst += price * percent * data?.Quantity;
          }
        }
      }
      total += price * data?.Quantity;
    });

    return { "Base Price": total, IGST: igst, CGST: cgst, SGST: sgst };
  };

  // Usage
  const prices = calculateTotal_GST(
    type,
    cartItem,
    userData?.contact_details?.gst.slice(0, 2)
  );

  const totalPrice = Object.values(prices)?.reduce((sum, val) => sum + val, 0);

  return (
    <>
      <Header cartCount={cartItem} />
      <div className="min-h-[96vh]">
        <div className="after-header  flex flex-col items-center justify-center ">
          <p className="text-2xl font-semibold py-4">Your Cart</p>
          <div className="flex lg:flex-row max-lg:flex-col max-lg:items-center lg:items-start max-lg:justify-start lg:justify-center gap-3 w-full">
            <div className="flex flex-col justify-start items-start w-[90%] lg:lg:w-[65%] gap-3">
              <div className="w-full border  rounded-lg flex flex-col items-start justify-start py-2 px-3">
                <div className="text-sm xl:text-base font-semibold text-[#212121] ">
                  {" "}
                  {isEditing ? "Your addresses" : "Delivery address"}
                </div>

                <hr className="w-[100%] opacity-20 flex justify-center mt-2" />
                <div className="w-full  rounded-lg flex flex-col items-start justify-start py-2 ">
                  {isEditing ? (
                    <div className="w-full">
                      <div className="mt-2 w-full">
                        {savedAddresses.map((address, index) => (
                          <div
                            key={index}
                            className="mb-2 border rounded-lg p-2 flex items-start justify-between w-full cursor-pointer hover:bg-gray-100 duration-300 ease-in-out"
                            onClick={() => {
                              setSelectedAddress(address);
                              setIsEditing(false);
                            }}
                          >
                            <div>
                              <p className="text-sm font-semibold text-[#212121]">
                                {address?.name}
                              </p>
                              <p className="text-xs xl:text-sm font-medium">
                                {address?.address}, {address?.city},{" "}
                                {address?.state}, {address?.postalCode}
                              </p>
                            </div>
                            {selectedAddress?.address !== address?.address && (
                              <button className="text-xs xl:text-sm font-medium text-yellow-500 underline ml-2">
                                Use this address
                              </button>
                            )}
                          </div>
                        ))}
                        <button className="text-xs xl:text-sm font-medium text-yellow-500 underline mt-3">
                          + Add a new address
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="w-full flex justify-between items-start">
                      <div>
                        <p className="text-sm font-semibold text-[#212121]">
                          {selectedAddress?.name}
                        </p>
                        <p className="text-xs xl:text-sm font-medium">
                          {selectedAddress?.address}, {selectedAddress?.city},{" "}
                          {selectedAddress?.state},{" "}
                          {selectedAddress?.postalCode}
                        </p>
                      </div>
                      <button
                        className="text-xs font-medium underline text-yellow-500"
                        onClick={() => setIsEditing(true)}
                      >
                        Change
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex flex-col items-center justify-center w-full pb-4 ">
                {/* Sample Article */}

                {cartItem?.map((data) => (
                  <>
                    <div className="border max-md:px-2 md:px-4 py-2  flex sm:flex-row max-sm:flex-col max-sm:items-stretch sm:items-center max-sm:justify-center  sm:justify-between w-full rounded-lg  z-10 bg-white max-sm:gap-y-2 ">
                      <div className="flex items-center justify-between md:gap-x-7 xl:gap-x-10 2xl:gap-x-14 max-sm:gap-x-2">
                        <img
                          src={data?.images?.Main}
                          alt={data?.Article_no}
                          className="md:w-28 md:h-28  w-24 h-24 object-cover "
                        />
                        <div className="text-xs font-medium text-[#313131] flex flex-col items-start justify-center max-md:gap-y-1 md:gap-y-1.5 uppercase">
                          <p className="font-semibold">
                            SKU-
                            {data?.Article_no?.split("_")?.slice(-1)[0] ||
                              "N/A"}
                          </p>
                          <p className="text-xs 2xl:text-sm ">
                            {data?.color_leather?.replaceAll("_", " ")}&nbsp;
                            {data?.Article_no?.split("_")
                              .slice(0, -1)
                              .join(" ")}
                          </p>
                          <p>
                            Customized:{" "}
                            <span className="font-semibold text-xs ">
                              {data?.Type === "retail" ? "No" : "Yes"}
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="flex items-center justify-between md:gap-x-7 xl:gap-x-10 2xl:gap-x-14">
                        <div className=" font-medium text-[#313131] flex flex-col items-center justify-start gap-y-1 uppercase md:h-32">
                          <p className="float-start font-semibold md:mb-4 text-xs xl:text-sm">
                            SIZE
                          </p>
                          <div className=" flex flex-wrap px-1 justify-center gap-1">
                            <div
                              className={` w-8 h-8 text-sm flex items-center justify-center  font-semibold  `}
                            >
                              {data.Size}
                            </div>
                          </div>
                          {/* <div className="w-40 flex flex-wrap px-1 justify-center gap-1">
                <div
                  className={`border-[1px] w-8 h-8  text-xs flex items-center justify-center cursor-pointer hover:bg-[#FFF4B5] hover:border-[#FFD61F] bg-white border-[#9CA3AF]`}
                >
                  7
                </div>
                <div
                  className={`border-[1px] w-8 h-8  text-xs flex items-center justify-center cursor-pointer hover:bg-[#FFF4B5] hover:border-[#FFD61F] bg-white border-[#9CA3AF]`}
                >
                  8.5
                </div>
                <div
                  className={`border-[1px] w-8 h-8  text-xs flex items-center justify-center cursor-pointer hover:bg-[#FFF4B5] hover:border-[#FFD61F] bg-white border-[#9CA3AF]`}
                >
                  9.5
                </div>
                <div
                  className={`border-[1px] w-8 h-8  text-xs flex items-center justify-center cursor-pointer hover:bg-[#FFF4B5] hover:border-[#FFD61F] bg-white border-[#9CA3AF]`}
                >
                  11
                </div>
                <div
                  className={`border-[1px] w-8 h-8  text-xs flex items-center justify-center cursor-pointer hover:bg-[#FFF4B5] hover:border-[#FFD61F] bg-white border-[#9CA3AF]`}
                >
                  11.5
                </div>
                <div
                  className={`border-[1px] w-8 h-8  text-xs flex items-center justify-center cursor-pointer hover:bg-[#FFF4B5] hover:border-[#FFD61F] bg-white border-[#9CA3AF]`}
                >
                  12
                </div>
              </div> */}
                        </div>
                        <div className="font-medium text-[#313131] flex flex-col items-center justify-start gap-y-1 uppercase  md:h-32">
                          <p className="float-start font-semibold md:mb-4 text-xs xl:text-sm">
                            QUANTITY
                          </p>
                          <div className=" flex  justify-center gap-1">
                            <div className="   h-9 flex items-center justify-center  px-2">
                              <button
                                class="group cursor-pointer outline-none hover:rotate-0 duration-300 focus:outline-none ring-0   "
                                title="Add New"
                                onClick={() => handleQuantity(data, "decrease")}
                              >
                                <svg
                                  class="stroke-yellow-400 fill-none group-hover:fill-yellow-800 group-active:stroke-yellow-200 group-active:fill-yellow-600 group-active:duration-0 duration-300 w-6 h-6"
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    stroke-width="1.5"
                                    d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                                  ></path>
                                  <path stroke-width="1.5" d="M8 12H16"></path>
                                </svg>
                              </button>

                              <span className="flex items-center justify-center w-7 2xl:w-9 cursor-default">
                                {data?.Quantity}
                              </span>
                              <button
                                class="group cursor-pointer outline-none hover:rotate-0 duration-300 focus:outline-none ring-0"
                                title="Add New"
                                onClick={() => handleQuantity(data, "increase")}
                              >
                                <svg
                                  class="stroke-yellow-400 fill-none group-hover:fill-yellow-800 group-active:stroke-yellow-200 group-active:fill-yellow-600 group-active:duration-0 duration-300 w-6 h-6"
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    stroke-width="1.5"
                                    d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                                  ></path>
                                  <path stroke-width="1.5" d="M8 12H16"></path>
                                  <path stroke-width="1.5" d="M12 16V8"></path>
                                </svg>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className=" font-medium text-[#313131] flex flex-col items-center justify-start gap-y-1 uppercase  md:h-32">
                          <p className="float-start font-semibold md:mb-4 text-xs xl:text-sm">
                            PRICE
                          </p>
                          <div className=" h-9 flex items-center justify-center  px-2 text-sm font-semibold">
                            Rs.{" "}
                            {(
                              data?.Quantity * calculatePrice(data)
                            ).toLocaleString("en-IN")}
                          </div>
                        </div>
                      </div>
                      {/* <button
            onClick={handleBuyNow}
            className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
          >
            Buy Now
          </button> */}
                    </div>
                    <div className="py-1 w-full bg-gray-100 border rounded-lg pt-3 relative -top-3 text-xs px-2 font-medium flex justify-between items-center">
                      <div>
                        {/* <span>Non-returnable</span> | <span>Cancel within 24hrs</span> */}
                        You can cancel & return within{" "}
                        <span className="font-semibold">15 days</span>
                      </div>
                      <button
                        className="text-white bg-red-500 rounded-md px-2 my-1"
                        onClick={() => {
                          DeleteCartItem(data);
                        }}
                      >
                        Delete
                      </button>
                    </div>
                  </>
                ))}
              </div>
            </div>
            <div className="border rounded-lg w-[90%] lg:w-[25%]  mb-10 flex flex-col items-center   justify-start p-2">
              <div className="font-semibold text-base">Overview</div>
              <div className="w-full mt-3 p-3 text-sm font-medium flex flex-col justify-between h-full">
                {/* Subtotal Header */}
                <div className="h-32">
                  <div
                    className={`p-2  flex flex-col items-start cursor-pointer hover:border-[#cecece] duration-300 ease-in-out border-[1px] border-[#fff] ${
                      isOpen ? "border-[#cecece] border-[1px]" : ""
                    }`}
                    onClick={toggleDropdown}
                  >
                    <div className="w-full flex justify-between cursor-pointer text-xs xl:text-sm">
                      <div className="flex items-center gap-x-2">
                        Subtotal
                        <span>
                          <svg
                            id="fi_2985150"
                            enable-background="new 0 0 128 128"
                            viewBox="0 0 128 128"
                            className={`w-4 h-4 transition-all duration-300 ease-in-out ${
                              isOpen ? "rotate-180" : ""
                            } `}
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              id="Down_Arrow_3_"
                              d="m64 88c-1.023 0-2.047-.391-2.828-1.172l-40-40c-1.563-1.563-1.563-4.094 0-5.656s4.094-1.563 5.656 0l37.172 37.172 37.172-37.172c1.563-1.563 4.094-1.563 5.656 0s1.563 4.094 0 5.656l-40 40c-.781.781-1.805 1.172-2.828 1.172z"
                            ></path>
                          </svg>
                        </span>
                      </div>
                      <div className="font-semibold">
                        Rs.{" "}
                        {totalPrice?.toLocaleString("en-IN", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </div>
                    </div>
                    {/* Dropdown Content */}
                    <div
                      className={` text-xs transition-all duration-500 ease-in-out w-full overflow-hidden fadein-animation ${
                        isOpen ? "h-16 opacity-1 mt-2 " : "h-0 opacity-0 mt-0"
                      }`}
                    >
                      {Object.entries(prices)?.map(([key, val]) => (
                        <div className="flex justify-between">
                          <div>{key.replace("_", " ")}</div>
                          <div>
                            Rs.{" "}
                            {val?.toLocaleString("en-IN", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col items-center w-full">
                  <div className="flex  justify-center pb-1 text-xs w-full">
                    <div>
                      Expected Delivery:
                      <span className="font-semibold"> 3 weeks </span>
                    </div>
                  </div>
                  <div className="rounded-md flex items-center justify-between mb-4 text-xs font-medium  bg-gray-50 border w-full cursor-pointer">
                    <button
                      className={` rounded-md flex items-center justify-start gap-2  font-medium p-1.5 w-full  cursor-pointer`}
                    >
                      <div className="checkbox-wrapper-31 scale-[90%]">
                        <input
                          type="checkbox"
                          id="auto-rotate"
                          readOnly
                          checked={loyalty > 0}
                          onClick={() => {
                            setLoyalty((prev) =>
                              prev > 0 ? 0 : userData?.Loyalty_points || 0
                            );
                          }}
                        />
                        <svg
                          viewBox="0 0 35.6 35.6"
                          className={`${loyalty > 0 ? "checked-altered" : ""}`}
                        >
                          <circle
                            class="background"
                            cx="17.8"
                            cy="17.8"
                            r="17.8"
                          ></circle>
                          <circle
                            class="stroke"
                            cx="17.8"
                            cy="17.8"
                            r="14.37"
                          ></circle>
                          <polyline
                            class="check"
                            points="11.78 18.12 15.55 22.23 25.17 12.87"
                          ></polyline>
                        </svg>
                      </div>
                      <label
                        for="auto-rotate"
                        className=" cursor-pointer w-full flex justify-start"
                      >
                        <span className="font-semibold pr-1">
                          {userData?.Loyalty_points?.toLocaleString("en-IN")}
                        </span>{" "}
                        Loyalty Points
                      </label>
                    </button>
                  </div>

                  <hr className="w-[80%] opacity-20 flex justify-center mb-3" />
                  <div className="flex  justify-between pb-2 font-normal w-full">
                    <div>Shipping Cost</div>
                    <div>
                      {type === "individual" ? (
                        "Rs. 0.00"
                      ) : (
                        <>
                          <a href={`type/dashboard/shipping/request-for-quote`}>
                            Request-for-Quote
                          </a>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="flex  justify-between pb-2 font-semibold w-full">
                    <div>Total</div>
                    <div>
                      Rs.{" "}
                      {(totalPrice - loyalty)?.toLocaleString("en-IN", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </div>
                  </div>

                  <button
                    onClick={handleBuyNow}
                    className="border-2  text-sm flex items-center justify-center overflow-hidden group border-black/80  w-full py-2   font-medium hover:bg-black/90 duration-300 ease-in-out hover:text-white hover:font-normal "
                  >
                    Checkout
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* Confirmation Popup */}
          {showPopup && (
            <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
              <div
                className="bg-white p-4 rounded-lg shadow-lg  relative pop-up-animation"
                ref={popupRef}
              >
                <div>
                  <label className="block mb-4 font-bold text-center">
                    Select a Payment Method
                  </label>
                  <div className="flex flex-col gap-2">
                    <div
                      className={`p-2 relative  rounded cursor-pointer flex flex-col items-center justify-center gap-2 ${
                        selectedPaymentMethod === "neft" ? "bg-gray-200" : ""
                      }`}
                      onClick={() => {
                        setSelectedPaymentMethod("neft");
                        setFile(null);
                        setError("");
                        setSelectedBank(null);
                        setCurrentPage("bankPayment");
                      }}
                    >
                      <div className="border-2 border-gray-300 hover:border-gray-500 duration-300 ease-in-out hover:bg-gray-50 rounded-lg w-60 h-24 flex flex-col items-center justify-center py-2 ">
                        <img
                          src="/images/neft.svg"
                          className="w-36 h-16  object-cover "
                          alt=""
                        />
                      </div>
                      {/* <div className="px-2 py-0.5 bg-yellow-300 font-semibold text-sm border-2 border-yellow-200 absolute -right-2 -top-2 rounded-lg">
                      5% Off
                    </div> */}
                    </div>
                    <div
                      className={`p-2  relative rounded cursor-pointer flex flex-col items-center justify-center gap-2 ${
                        selectedPaymentMethod === "upi" ? "bg-gray-200" : ""
                      }`}
                      onClick={() => setSelectedPaymentMethod("upi")}
                    >
                      <div className="border-2 border-gray-300 hover:border-gray-500 duration-300 ease-in-out hover:bg-gray-50 rounded-lg w-60 h-24 flex flex-col items-center justify-start py-2">
                        <div className="flex items-start text-[10px] tracking-tight font-medium font-sans gap-1">
                          <svg
                            id="fi_3064155"
                            enable-background="new 0 0 24 24"
                            className="w-3 h-3 fill-slate-700"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="m18.75 9h-.75v-3c0-3.309-2.691-6-6-6s-6 2.691-6 6v3h-.75c-1.24 0-2.25 1.009-2.25 2.25v10.5c0 1.241 1.01 2.25 2.25 2.25h13.5c1.24 0 2.25-1.009 2.25-2.25v-10.5c0-1.241-1.01-2.25-2.25-2.25zm-10.75-3c0-2.206 1.794-4 4-4s4 1.794 4 4v3h-8zm5 10.722v2.278c0 .552-.447 1-1 1s-1-.448-1-1v-2.278c-.595-.347-1-.985-1-1.722 0-1.103.897-2 2-2s2 .897 2 2c0 .737-.405 1.375-1 1.722z"></path>
                          </svg>
                          <span>Payments powered by</span>
                        </div>
                        <img
                          src="/images/upi_logo.svg"
                          className="w-36 h-16 p-1  object-cover"
                          alt=""
                        />
                      </div>
                      {/* <div className="px-2 py-0.5 bg-yellow-300 font-semibold text-sm border-2 border-yellow-200 absolute -right-2 -top-2 rounded-lg">
                      3% Off
                    </div> */}
                    </div>
                    <div
                      className={`p-2  relative rounded cursor-pointer flex flex-col items-center justify-center gap-2 ${
                        selectedPaymentMethod === "gateway" ? "bg-gray-200" : ""
                      }`}
                      onClick={() => setSelectedPaymentMethod("gateway")}
                    >
                      <div className="border-2 border-gray-300 hover:border-gray-500 duration-300 ease-in-out hover:bg-gray-50 rounded-lg w-60 h-24 flex flex-col items-center justify-start  py-2">
                        <div className="flex items-start text-[10px] tracking-tight font-medium font-sans gap-1">
                          <svg
                            id="fi_3064155"
                            enable-background="new 0 0 24 24"
                            className="w-3 h-3 fill-slate-700"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="m18.75 9h-.75v-3c0-3.309-2.691-6-6-6s-6 2.691-6 6v3h-.75c-1.24 0-2.25 1.009-2.25 2.25v10.5c0 1.241 1.01 2.25 2.25 2.25h13.5c1.24 0 2.25-1.009 2.25-2.25v-10.5c0-1.241-1.01-2.25-2.25-2.25zm-10.75-3c0-2.206 1.794-4 4-4s4 1.794 4 4v3h-8zm5 10.722v2.278c0 .552-.447 1-1 1s-1-.448-1-1v-2.278c-.595-.347-1-.985-1-1.722 0-1.103.897-2 2-2s2 .897 2 2c0 .737-.405 1.375-1 1.722z"></path>
                          </svg>
                          <span>Payments powered by</span>
                        </div>
                        <img
                          src="/images/razorpay-icon.svg"
                          className="w-36 h-16 "
                          alt=""
                        />
                      </div>
                      <div className="text-[10px] text-red-500 absolute right-2 -bottom-2 ">
                        *Extra charges applicable
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="mt-4 flex justify-between">
                <button
                  onClick={() => setShowPopup(false)}
                  className="bg-gray-500 text-white py-2 px-4 rounded hover:bg-gray-600"
                >
                  Cancel
                </button>
                <button
                  onClick={handlePayment}
                  className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600"
                >
                  Pay
                </button>
              </div> */}
                {selectedPaymentMethod && (
                  <div className="absolute bg-white w-full min-h-full top-0 left-0 rounded-lg shadow-lg p-3">
                    <div
                      class="group relative inline-block bg-slate-100 p-2 rounded-full hover:bg-slate-200 duration-200 ease-in-out cursor-pointer"
                      onClick={() => setSelectedPaymentMethod(null)}
                    >
                      <button class="focus:outline-none items-center flex justify-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 1024 1024"
                          className="w-6 h-6 stroke-2 stroke-black"
                        >
                          <path
                            d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z"
                            fill="#000000"
                          ></path>
                          <path
                            d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z"
                            fill="#000000"
                          ></path>
                        </svg>
                      </button>
                    </div>
                    {selectedPaymentMethod === "neft" ? (
                      <div className="w-full flex flex-col items-center">
                        {currentPage === "bankPayment" && (
                          <div className="flex flex-col items-center justify-start gap-2 w-full mt-3">
                            <div className="flex flex-col items-start justify-center leading-3 w-full">
                              <p className="text-xs font-semibold">Bank</p>
                              <p className="text-sm font-semibold  border w-full py-1 px-2">
                                Axis Bank
                              </p>
                            </div>
                            <div className="flex flex-col items-start justify-center leading-3 w-full">
                              <p className="text-xs font-semibold">
                                Account Number
                              </p>
                              <p className="text-sm font-semibold  border w-full py-1 px-2 flex items-center justify-between">
                                610244725373
                                <button
                                  className="ml-2 active:bg-yellow-300 p-1 rounded-full"
                                  onClick={() =>
                                    navigator.clipboard.writeText(
                                      "610244725373"
                                    )
                                  }
                                >
                                  <svg
                                    id="fi_9070501"
                                    viewBox="0 0 24 24"
                                    className="w-4 h-4"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="m5.4521 22h9.0957c1.7485 0 3.1822-1.3118 3.4064-3h.5936c1.9034 0 3.4522-1.5488 3.4522-3.4521v-10.0958c0-1.9033-1.5488-3.4521-3.4521-3.4521h-9.0958c-1.9033 0-3.4521 1.5488-3.4521 3.4521v.5479h-.5479c-1.9033 0-3.4521 1.5488-3.4521 3.4521v9.0957c0 1.9034 1.5488 3.4522 3.4521 3.4522zm2.5479-16.5479c0-.8007.6514-1.4521 1.4521-1.4521h9.0957c.8008 0 1.4522.6514 1.4522 1.4521v10.0957c0 .8008-.6514 1.4522-1.4521 1.4522h-.5479v-7.5479c0-1.9033-1.5488-3.4521-3.4521-3.4521h-6.5479zm-4 4c0-.8007.6514-1.4521 1.4521-1.4521h9.0957c.8008 0 1.4522.6514 1.4522 1.4521v9.0957c0 .8008-.6514 1.4522-1.4521 1.4522h-9.0958c-.8007 0-1.4521-.6514-1.4521-1.4521z"
                                      fill="rgb(0,0,0)"
                                    ></path>
                                  </svg>
                                </button>
                              </p>
                            </div>
                            <div className="flex flex-col items-start justify-center leading-3 w-full">
                              <p className="text-xs font-semibold">IFSC Code</p>
                              <p className="text-sm font-semibold  border w-full py-1 px-2 flex items-center justify-between">
                                SBIN0031360
                                <button
                                  className="ml-2 active:bg-yellow-300 p-1 rounded-full"
                                  onClick={() =>
                                    navigator.clipboard.writeText("SBIN0031360")
                                  }
                                >
                                  <svg
                                    id="fi_9070501"
                                    viewBox="0 0 24 24"
                                    className="w-4 h-4"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="m5.4521 22h9.0957c1.7485 0 3.1822-1.3118 3.4064-3h.5936c1.9034 0 3.4522-1.5488 3.4522-3.4521v-10.0958c0-1.9033-1.5488-3.4521-3.4521-3.4521h-9.0958c-1.9033 0-3.4521 1.5488-3.4521 3.4521v.5479h-.5479c-1.9033 0-3.4521 1.5488-3.4521 3.4521v9.0957c0 1.9034 1.5488 3.4522 3.4521 3.4522zm2.5479-16.5479c0-.8007.6514-1.4521 1.4521-1.4521h9.0957c.8008 0 1.4522.6514 1.4522 1.4521v10.0957c0 .8008-.6514 1.4522-1.4521 1.4522h-.5479v-7.5479c0-1.9033-1.5488-3.4521-3.4521-3.4521h-6.5479zm-4 4c0-.8007.6514-1.4521 1.4521-1.4521h9.0957c.8008 0 1.4522.6514 1.4522 1.4521v9.0957c0 .8008-.6514 1.4522-1.4521 1.4522h-9.0958c-.8007 0-1.4521-.6514-1.4521-1.4521z"
                                      fill="rgb(0,0,0)"
                                    ></path>
                                  </svg>
                                </button>
                              </p>
                            </div>
                            {/* Pay Button */}
                            {/* <button
                            onClick={handleNeft}
                            className="w-[90%]  text-sm font-semibold px-4 py-2 absolute bottom-20 text-white bg-yellow-400 rounded-md hover:bg-yellow-500"
                          >
                            Continue
                          </button> */}
                            <p className="text-xs absolute bottom-4  text-center px-3 text-red-500">
                              *Please open your Bank's App to make the NEFT/RTGS
                              payment to this Bank A/C No. and IFSC.
                            </p>
                          </div>
                        )}
                        {currentPage === "standby" && (
                          <div className="flex flex-col items-center justify-center mt-2">
                            <p className="text-lg font-semibold text-gray-700 text-center">
                              Processing Your Request
                            </p>
                            <p className="text-sm text-gray-500 mt-2 text-center">
                              Please wait while we verify your payment...
                            </p>
                            <div className="mt-4 animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-yellow-500"></div>
                          </div>
                        )}
                        {currentPage === "bankPayment" && (
                          <hr className="w-[80%] opacity-20 flex justify-center my-3 max-md:hidden" />
                        )}
                        <div className="max-w-lg mx-auto  w-full max-md:hidden">
                          <div>
                            {currentPage === "bankPayment" && (
                              <div>
                                <h1 className="text-base font-semibold mb-2">
                                  Bank Payment
                                </h1>
                                {/* Dropdown */}
                                <div
                                  className="relative mb-3"
                                  ref={dropdownRef}
                                >
                                  <button
                                    onClick={() => setBankOpen(!bankOpen)}
                                    className="w-full px-4 py-1 border   text-sm font-semibold"
                                  >
                                    {selectedBank
                                      ? selectedBank.name
                                      : "Select Bank"}
                                  </button>
                                  <div
                                    className={` absolute left-0 top-0 w-full bg-white rounded-b-md shadow-lg z-10 overflow-hidden duration-1000 ease-in-out transition-all  ${
                                      bankOpen ? " max-h-44" : " h-0"
                                    }`}
                                  >
                                    {/* Search Bar */}
                                    <input
                                      type="text"
                                      placeholder="Search for a bank..."
                                      value={searchTerm}
                                      onChange={(e) =>
                                        setSearchTerm(e.target.value)
                                      }
                                      className="w-full px-4 py-1.5 text-sm border-b border-gray-200 focus:outline-none"
                                    />
                                    <ul className=" max-h-32 overflow-y-auto no-scrollbar">
                                      {filteredBanks
                                        .slice(0, 4)
                                        .map((bank, index) => (
                                          <li key={index}>
                                            <button
                                              onClick={() => {
                                                setSelectedBank(bank);
                                                setBankOpen(false);
                                                setSearchTerm("");
                                              }}
                                              className="block w-full text-left px-4 py-1.5 text-sm text-gray-700 hover:bg-blue-100 border-b"
                                            >
                                              {bank.name}
                                            </button>
                                          </li>
                                        ))}
                                      {filteredBanks.length > 4 && (
                                        <div className="max-h-32 overflow-y-auto">
                                          {filteredBanks
                                            .slice(4)
                                            .map((bank, index) => (
                                              <li key={index}>
                                                <button
                                                  onClick={() => {
                                                    setSelectedBank(bank);
                                                    setBankOpen(false);
                                                    setSearchTerm("");
                                                  }}
                                                  className="block w-full text-left px-4 py-1.5 text-sm text-gray-700 hover:bg-blue-100 border-b"
                                                >
                                                  {bank.name}
                                                </button>
                                              </li>
                                            ))}
                                        </div>
                                      )}
                                    </ul>
                                  </div>
                                </div>
                                {/* Pay Button */}
                                <button
                                  onClick={handlePay}
                                  className="w-full text-sm font-semibold px-4 py-2 text-white bg-yellow-400 rounded-md hover:bg-yellow-500"
                                >
                                  Proceed
                                </button>
                              </div>
                            )}

                            {currentPage === "uploadVerification" && (
                              <div>
                                <h2 className="text-base font-semibold my-2">
                                  Upload Payment Verification
                                </h2>
                                {/* Pay Button */}
                                <div class="rounded-lg overflow-hidden mb-3">
                                  <div class="">
                                    <div class="w-full">
                                      <div class="relative w-full h-32 rounded-lg border-2 border-yellow-200 hover:border-yellow-400  hover:bg-gray-50 flex justify-center items-center  transition-all duration-300 ease-in-out overflow-hidden">
                                        <div class="absolute flex flex-col items-center justify-center">
                                          <svg
                                            id="fi_4726010"
                                            enable-background="new 0 0 791.454 791.454"
                                            viewBox="0 0 791.454 791.454"
                                            className="h-8 w-8 mb-2"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <g>
                                              <g id="Vrstva_x0020_1_15_">
                                                <path
                                                  clip-rule="evenodd"
                                                  d="m202.808 0h264.609l224.265 233.758v454.661c0 56.956-46.079 103.035-102.838 103.035h-386.036c-56.956 0-103.035-46.079-103.035-103.035v-585.384c-.001-56.956 46.078-103.035 103.035-103.035z"
                                                  fill={
                                                    file?.name
                                                      ? "#33ab00"
                                                      : "#e5252a"
                                                  }
                                                  fill-rule="evenodd"
                                                ></path>
                                                <g fill="#fff">
                                                  <path
                                                    clip-rule="evenodd"
                                                    d="m467.219 0v231.978h224.463z"
                                                    fill-rule="evenodd"
                                                    opacity=".302"
                                                  ></path>
                                                  <path d="m214.278 590.525v-144.566h61.505c15.228 0 27.292 4.153 36.389 12.657 9.097 8.306 13.646 19.579 13.646 33.62s-4.549 25.314-13.646 33.62c-9.097 8.504-21.161 12.657-36.389 12.657h-24.523v52.012zm36.982-83.456h20.37c5.537 0 9.888-1.187 12.855-3.955 2.966-2.571 4.549-6.131 4.549-10.877s-1.582-8.306-4.549-10.877c-2.966-2.769-7.317-3.955-12.855-3.955h-20.37zm89.785 83.456v-144.566h51.221c10.086 0 19.579 1.384 28.478 4.351 8.899 2.966 17.008 7.12 24.127 12.855 7.12 5.537 12.855 13.052 17.008 22.545 3.955 9.493 6.131 20.37 6.131 32.631 0 12.064-2.175 22.941-6.131 32.433-4.153 9.493-9.888 17.008-17.008 22.545-7.12 5.735-15.228 9.888-24.127 12.855-8.899 2.966-18.392 4.351-28.478 4.351zm36.191-31.444h10.679c5.735 0 11.075-.593 16.019-1.978 4.746-1.384 9.295-3.56 13.646-6.526 4.153-2.966 7.515-7.12 9.888-12.657s3.56-12.064 3.56-19.579c0-7.713-1.187-14.239-3.56-19.776s-5.735-9.69-9.888-12.657c-4.351-2.966-8.899-5.142-13.646-6.526-4.944-1.384-10.284-1.978-16.019-1.978h-10.679zm109.364 31.444v-144.566h102.838v31.445h-65.856v23.138h52.605v31.247h-52.605v58.736z"></path>
                                                </g>
                                              </g>
                                            </g>
                                          </svg>
                                          <span class="block text-gray-500 font-semibold text-sm">
                                            {file?.name
                                              ? ""
                                              : "Drag & drop your invoice"}
                                          </span>
                                          <span class="block text-gray-400 font-normal mt-1 text-xs px-4 ">
                                            {file?.name
                                              ? file?.name
                                              : " or click to upload"}
                                          </span>
                                        </div>

                                        <input
                                          name=""
                                          class="h-full w-full opacity-0 cursor-pointer"
                                          type="file"
                                          accept=".pdf,.jpeg,.jpg,.png"
                                          onChange={handleFileUpload}
                                        />

                                        {error && (
                                          <p
                                            className="error"
                                            style={{ color: "red" }}
                                          >
                                            {error}
                                          </p>
                                        )}
                                      </div>
                                      <div className="extracted-data">
                                        {extractedData.senderBank && (
                                          <p>
                                            Sender Bank:{" "}
                                            {extractedData.senderBank}
                                          </p>
                                        )}
                                        {extractedData.utrNo && (
                                          <p>
                                            UTR Number: {extractedData.utrNo}
                                          </p>
                                        )}
                                        {extractedData.amount && (
                                          <p>Amount: {extractedData.amount}</p>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <button
                                  onClick={handleNeft}
                                  disabled={!file}
                                  className="w-full text-sm font-semibold px-4 py-2 text-white bg-yellow-400 rounded-md hover:bg-yellow-500"
                                >
                                  Submit
                                </button>
                              </div>
                            )}

                            {currentPage === "standby" && (
                              <div className="flex flex-col items-center justify-center mt-2">
                                <p className="text-lg font-semibold text-gray-700 text-center">
                                  Processing Your Request
                                </p>
                                <p className="text-sm text-gray-500 mt-2 text-center">
                                  Please wait while we verify your payment...
                                </p>
                                <div className="mt-4 animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-yellow-500"></div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    ) : selectedPaymentMethod === "upi" ? (
                      <div className=" flex flex-col items-center justify-center ">
                        {!paymentSuccess ? (
                          <>
                            {!paymentStarted ? (
                              <div className="w-full">
                                <div className="relative max-md:hidden flex flex-col items-center">
                                  <div className="w-full flex items-center justify-center mt-2">
                                    <div className="flex flex-col items-start justify-center leading-3 w-44">
                                      <p className="text-xs font-semibold">
                                        UPI ID
                                      </p>
                                      <p className="text-sm font-semibold  border w-full py-1 px-2 flex items-center justify-between">
                                        buwch@upi
                                        <button
                                          className="ml-2 active:bg-yellow-300 p-1 rounded-full"
                                          onClick={() =>
                                            navigator.clipboard.writeText(
                                              "buwch@upi"
                                            )
                                          }
                                        >
                                          <svg
                                            id="fi_9070501"
                                            viewBox="0 0 24 24"
                                            className="w-4 h-4"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="m5.4521 22h9.0957c1.7485 0 3.1822-1.3118 3.4064-3h.5936c1.9034 0 3.4522-1.5488 3.4522-3.4521v-10.0958c0-1.9033-1.5488-3.4521-3.4521-3.4521h-9.0958c-1.9033 0-3.4521 1.5488-3.4521 3.4521v.5479h-.5479c-1.9033 0-3.4521 1.5488-3.4521 3.4521v9.0957c0 1.9034 1.5488 3.4522 3.4521 3.4522zm2.5479-16.5479c0-.8007.6514-1.4521 1.4521-1.4521h9.0957c.8008 0 1.4522.6514 1.4522 1.4521v10.0957c0 .8008-.6514 1.4522-1.4521 1.4522h-.5479v-7.5479c0-1.9033-1.5488-3.4521-3.4521-3.4521h-6.5479zm-4 4c0-.8007.6514-1.4521 1.4521-1.4521h9.0957c.8008 0 1.4522.6514 1.4522 1.4521v9.0957c0 .8008-.6514 1.4522-1.4521 1.4522h-9.0958c-.8007 0-1.4521-.6514-1.4521-1.4521z"
                                              fill="rgb(0,0,0)"
                                            ></path>
                                          </svg>
                                        </button>
                                      </p>
                                    </div>
                                  </div>

                                  <hr className="w-[80%] opacity-20 flex justify-center my-3" />
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                    className=""
                                  >
                                    <QRCodeSVG
                                      value={`upi://pay?pa=buwch@upi&am=${1.03}&cu=${"INR"}`}
                                      bgColor="#ffffff"
                                      fgColor="#000000"
                                      level="L"
                                      style={{ border: "1px solid #000" }}
                                      className=" w-44 h-44 p-1 rounded-lg"
                                    />
                                  </div>
                                  <p className="text-[10px] font-semibold text-center w-[80%] mx-auto text-semibold text-gray-500 leading-[12px] mt-2">
                                    Scan this code to pay
                                  </p>
                                  <p className="text-[11px] absolute top-72  text-red-500 mt-2 text-center">
                                    *Kindly attach your payment receipt or
                                    screenshot from your UPI app or payment
                                    service provider on WhatsApp as a reply to
                                    the Order_ID generation message sent by us
                                    (BUWCH).
                                  </p>
                                </div>
                                <div className="md:hidden p-2 pt-4 rounded-lg  w-full max-w-sm">
                                  <div className="group mb-3">
                                    <label
                                      htmlFor="vpa"
                                      className="block text-sm font-semibold leading-4 text-[#212121]"
                                    >
                                      Enter VPA
                                    </label>
                                    <div className="mt-2">
                                      <input
                                        value={vpa}
                                        name="vpa"
                                        className="px-2 block w-full py-1.5 text-[#212121] ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 group-hover:ring-2 group-hover:ring-[#eebd2d] group-hover:outline-none group-hover:ring-inset focus:ring-2 focus:ring-[#eebd2d] focus:outline-none focus:ring-inset text-sm sm:leading-6"
                                        onChange={(e) => setVpa(e.target.value)}
                                        id="vpa"
                                        placeholder="Enter your UPI ID"
                                        required
                                      />
                                    </div>
                                    {!isVerified ? (
                                      <button
                                        className="w-full text-sm font-semibold px-4 py-2 text-white bg-yellow-400 rounded-md hover:bg-yellow-500 mt-3"
                                        onClick={handleVerify}
                                      >
                                        Verify
                                      </button>
                                    ) : (
                                      <div className="mt-3">
                                        <p className="text-sm font-semibold text-[#212121]">
                                          Name: Nitish Kumar Pradhan{" "}
                                        </p>
                                        <p className="text-xs text-gray-600 mt-1 font-semibold">
                                          Redirecting in {countdown} seconds...
                                        </p>

                                        <div className="w-full h-1 bg-gray-500 rounded-full overflow-hidden">
                                          <div
                                            className={`h-full bg-yellow-400 transition-all duration-[5000ms] ease-in-out ${
                                              countdown > 0 ? "loading-animation" : ""
                                            }`}
                                          ></div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className="w-full">
                                <div className="flex flex-col items-center justify-center mt-2 max-md:hidden">
                                  <p className="text-lg font-semibold text-gray-700 text-center">
                                    Processing Your Request
                                  </p>
                                  <p className="text-sm text-gray-500 mt-2 text-center">
                                    Please wait while we verify your payment...
                                  </p>
                                  <div className="mt-4 animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-yellow-500"></div>
                                </div>
                                <div className="md:hidden bg-white p-6 rounded-lg  w-full max-w-sm">
                                  <div className="flex flex-col items-center justify-center">
                                    <h2 className="text-base font-medium  mb-2 text-center">
                                      Dear, <br />
                                      <span className="font-semibold">
                                        Nitish Pradhan
                                      </span>
                                    </h2>
                                    <img
                                      src="/images/sand-clock.gif"
                                      className="w-20 h-20"
                                      alt=""
                                    />

                                    <p className="text-center text-xs font-semibold mb-4">
                                      {Math.floor(timer / 60)}:{timer % 60} Mins
                                    </p>
                                    <p className="text-center text-sm font-semibold mb-2">
                                      Go to your UPI to Pay
                                    </p>
                                    <p className="text-center text-xs font-medium">
                                      You have 5 minutes to complete the
                                      payment.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            )}
                          </>
                        ) : (
                          <div className="flex flex-col items-center justify-center bg-white p-6 rounded-lg w-full max-w-sm text-center">
                            <img
                              src="/images/verified.gif"
                              className="w-20 h-20"
                              alt=""
                            />
                            <h2
                              className={`text-lg font-semibold mb-2 text-center  ${
                                paymentSuccess
                                  ? "text-green-500"
                                  : "text-red-500"
                              }`}
                            >
                              {paymentSuccess
                                ? "Payment Successful!"
                                : "Payment Failed!"}
                            </h2>
                            <p className="text-center text-xs font-medium">
                              {paymentSuccess
                                ? "Thank you for your payment. Your transaction was successful."
                                : "Your transaction has been failed. Please, try again!"}

                              {/*  */}
                            </p>
                          </div>
                        )}
                      </div>
                    ) : (
                      selectedPaymentMethod === "gateway" && (
                        <p className="mt-8 p-4 text-red-500 text-sm">
                          Razorpay charges will apply separately.
                        </p>
                      )
                    )}
                  </div>
                )}
              </div>
            </div>
          )}

          {/* Order Status Page */}
          {orderStatus && (
            <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex flex-col items-center justify-center">
              {orderStatus === "success" ? (
                <>
                  <div className="text-green-500 text-4xl mb-4">✓</div>
                  <h1 className="text-white text-2xl">Order Successful!</h1>
                  <Confetti />
                </>
              ) : (
                <>
                  <div className="text-red-500 text-4xl mb-4">✗</div>
                  <h1 className="text-white text-2xl">Payment Failed</h1>
                  <p className="text-gray-400 mt-2">
                    Please check your payment details and try again.
                  </p>
                </>
              )}
              <button
                onClick={() => setOrderStatus(null)}
                className="mt-4 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
              >
                Go Back
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default EcommerceComponent;
