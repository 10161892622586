import React, { useState } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import "./ImageUpload.css";

const ImageUploadProduct = ({
  data,
  currentImageSlot,
  handlePreviousSlot,
  handleNextSlot,
  handleImageDelete,
  getImg,
  handleFileChange,
}) => {
  const handleCrop = (val) => {
    const canvas = document
      .getElementById("cropper")
      ?.cropper?.getCroppedCanvas();
    const croppedImg = canvas?.toDataURL() || "";
    getImg(croppedImg, val);
  };

  return (
    <div>
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white rounded-md p-3 w-[90%] max-w-max   ">
          <h3 className="text-base font-semibold mb-2 text-center uppercase text-[#313131]">
            {currentImageSlot} Image
          </h3>

          <div className="flex items-center justify-between mb-2 relative ">
            <button
              onClick={() => {
                handlePreviousSlot();
                handleCrop("");
              }}
              className="pr-3 py-1"
            >
              <img
                src="/images/right-arrow.svg"
                className="rotate-180 w-6 h-6"
                alt=""
              />
            </button>
            <div className="w-72 h-72 border border-gray-300 flex items-center justify-center  cursor-pointer relative">
              {data?.images?.[currentImageSlot] ? (
                <>
                  <Cropper
                    id="cropper"
                    src={data?.images?.[currentImageSlot]}
                    className="absolute top-0 left-0 w-full h-full "
                    aspectRatio={1}
                    guides={false}
                    cropBoxMovable={true}
                    viewMode={2}
                    background={false} // Remove background grid
                    autoCropArea={1} // Set auto-crop to cover the entire image
                  />
                </>
              ) : (
                <span className="pencil-icon flex flex-col items-center justify-center text-[#313131] opacity-[65%] group-hover:opacity-[100%] duration-200 ease-in-out">
                  <span>
                    <img
                      src="/images/addimage.svg"
                      alt=""
                      className="w-10 h-10 "
                    />
                  </span>
                  <span className="text-xs md:text-xs font-medium mt-2">
                    {currentImageSlot} Image
                  </span>
                  <input
                    type="file"
                    accept="image/*"
                    className=" w-72 h-72 opacity-0 absolute border bg-slate-400 cursor-pointer  overflow-hidden"
                    onChange={handleFileChange}
                  />
                </span>
              )}
              {/* delete btn */}
              {data.images[currentImageSlot] && (
                <button
                  className="absolute -top-3 -right-3  px-1 py-1 flex items-center border-[2px]  border-gray-100 bg-gray-50 hover:border-red-200  hover:bg-gray-100   justify-center  overflow-hidden text-black duration-300 group ease-in-out text-xs md:text-sm font-semibold cursor-pointer rounded-full"
                  onClick={(e) => handleImageDelete()}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    id="fi_3405244"
                    data-name="Layer 2"
                    className="w-5 h-5 lg:w-6 lg:h-6 fill-[#000] group-hover:fill-[#ff4444] duration-300 ease-in-out"
                    viewBox="0 0 24 24"
                  >
                    <path d="M19,7a1,1,0,0,0-1,1V19.191A1.92,1.92,0,0,1,15.99,21H8.01A1.92,1.92,0,0,1,6,19.191V8A1,1,0,0,0,4,8V19.191A3.918,3.918,0,0,0,8.01,23h7.98A3.918,3.918,0,0,0,20,19.191V8A1,1,0,0,0,19,7Z"></path>
                    <path d="M20,4H16V2a1,1,0,0,0-1-1H9A1,1,0,0,0,8,2V4H4A1,1,0,0,0,4,6H20a1,1,0,0,0,0-2ZM10,4V3h4V4Z"></path>
                    <path d="M11,17V10a1,1,0,0,0-2,0v7a1,1,0,0,0,2,0Z"></path>
                    <path d="M15,17V10a1,1,0,0,0-2,0v7a1,1,0,0,0,2,0Z"></path>
                  </svg>
                </button>
              )}
            </div>

            <button
              onClick={() => {
                handleNextSlot();
                handleCrop("");
              }}
              className="pl-3 py-1 "
            >
              <img src="/images/right-arrow.svg" className=" w-6 h-6" alt="" />
            </button>
          </div>
          <div className="w-full flex justify-center gap-2 px-6">
            <button
              onClick={() => {
                handleCrop("save");
              }}
              className="rounded-lg   w-1/2 px-4 py-1.5 cursor-pointer flex items-center border-[3px]  border-yellow-200 bg-yellow-400 hover:border-yellow-300  hover:bg-yellow-500    justify-center overflow-hidden text-white duration-300 ease-in-out text-xs md:text-sm font-semibold"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageUploadProduct;
