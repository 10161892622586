import React, { useState } from "react";

function ReadytoShip() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="flex items-start justify-start gap-3">
      <div className="border rounded-lg w-[90%] lg:w-[25%] min-w-[400px]  mb-10 flex flex-col items-center   justify-start p-2">
        <div className="font-semibold text-base">Overview</div>
        <div className="w-full mt-3 p-3 text-sm font-medium flex flex-col justify-between h-full">
          {/* Subtotal Header */}
          <div className="h-32">
            <div
              className={`p-2  flex flex-col items-start cursor-pointer hover:border-[#cecece] duration-300 ease-in-out border-[1px] border-[#fff] ${
                isOpen ? "border-[#cecece] border-[1px]" : ""
              }`}
              onClick={toggleDropdown}
            >
              <div className="w-full flex justify-between cursor-pointer text-xs xl:text-sm">
                <div className="flex items-center gap-x-2">
                  Total Amount
                  <span>
                    <svg
                      id="fi_2985150"
                      enable-background="new 0 0 128 128"
                      viewBox="0 0 128 128"
                      className={`w-4 h-4 transition-all duration-300 ease-in-out ${
                        isOpen ? "rotate-180" : ""
                      } `}
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        id="Down_Arrow_3_"
                        d="m64 88c-1.023 0-2.047-.391-2.828-1.172l-40-40c-1.563-1.563-1.563-4.094 0-5.656s4.094-1.563 5.656 0l37.172 37.172 37.172-37.172c1.563-1.563 4.094-1.563 5.656 0s1.563 4.094 0 5.656l-40 40c-.781.781-1.805 1.172-2.828 1.172z"
                      ></path>
                    </svg>
                  </span>
                </div>
                <div className="font-semibold">Rs. 4,34,000.00</div>
              </div>
              {/* Dropdown Content */}
              <div
                className={` text-xs transition-all duration-500 ease-in-out w-full overflow-hidden fadein-animation ${
                  isOpen ? "h-16 opacity-1 mt-2 " : "h-0 opacity-0 mt-0"
                }`}
              >
                <div className="flex justify-between">
                  <div>Rs.</div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center w-full">
            <div className="rounded-md flex items-center justify-start   mb-4 text-sm px-2 py-1 font-medium  bg-gray-50 border w-full cursor-pointer">
              <span className="font-semibold">Rs. 1,00,000.00</span>- Advance
              Paid
            </div>

            <hr className="w-[80%] opacity-20 flex justify-center mb-3" />

            <div className="flex  justify-between pb-2 font-semibold w-full">
              <div>Balance Amount</div>
              <div>Rs. 3,34,000.00</div>
            </div>

            <button className="border-2  text-sm flex items-center justify-center overflow-hidden group border-black/80  w-full py-2   font-medium hover:bg-black/90 duration-300 ease-in-out hover:text-white hover:font-normal ">
              Checkout
            </button>
          </div>
        </div>
      </div>
      <div className="border rounded-lg w-[90%] lg:w-[25%] min-w-[400px] mb-10 flex flex-col items-center   justify-start p-2">
      <div className="font-semibold text-base">Details</div>
        <div className="flex justify-around items-center mb-6 mt-3 p-3 gap-x-5">
          {/* Item 1 */}
          <div className="text-center text-sm">
            <img
              src="/images/test-chestnut.webp" 
              alt="ART-001"
              className="w-24 h-24 object-contain mx-auto mb-2"
            />
            <p className="font-medium">ART-001</p>
            <p className="text-xs font-semibold text-gray-500">200 pairs</p>
          </div>
          {/* Item 2 */}
          {/* <div className="text-center text-sm">
            <img
              src="/images/test-rosewood.webp" 
              alt="ART-005"
              className="w-24 h-24 object-contain mx-auto mb-2"
            />
            <p className="font-medium">ART-005</p>
            <p className="text-xs font-semibold text-gray-500">12 pairs</p>
          </div> */}
          {/* Item 3 */}
          {/* <div className="text-center text-sm">
            <img
              src="/images/test-chestnut.webp"
              alt="ART-007"
              className="w-24 h-24 object-contain mx-auto mb-2"
            />
            <p className="font-medium">ART-007</p>
            <p className="text-xs font-semibold text-gray-500">30 pairs</p>
          </div> */}
        </div>
        <button className="w-full bg-yellow-500 text-white font-semibold py-2 rounded hover:bg-yellow-600 transition">
          Shipment Details
        </button>
      </div>
    </div>
  );
}

export default ReadytoShip;
