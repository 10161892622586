import React, { useState, useRef, useEffect } from "react";

function Sourcing() {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const data = [
    {
      image: "/images/test-tan.webp", // Replace with actual image URL
      title: "ART-001",
      items: [
        {
          name: "Boxcalf Leather",
          status: "Received",
          action: "view",
          image: "/images/shoe-1-lt.jpg",
        },
        {
          name: "Italina Leather Sole",
          status: "Pending",
          action: "",
          image: "",
        },
        {
          name: "Box",
          status: "Received",
          action: "view",
          image: "/images/dye-box.jpeg",
        },
        {
          name: "Wrapping Paper",
          status: "Received",
          action: "view",
          image: "/images/shoe-1-wp.jpeg",
        },
      ],
    },
    // {
    //   image: "/images/test-tan.webp", // Replace with actual image URL
    //   title: "ART-005",
    //   items: [
    //     {
    //       name: "Boxcalf Leather",
    //       status: "Pending",
    //       action: "",
    //       image: "",
    //     },
    //     {
    //       name: "TPR Sole",
    //       status: "Not Available",
    //       action: "Re-customize",
    //       image: "/images/test-tan.webp",
    //     },
    //   ],
    // },
  ];

  const handleViewClick = (item) => {
    setSelectedItem(item);
    setModalOpen(true);
  };

  // State for filtering
  const [selectStatus, setSelectStatus] = useState("Select");
  const [optionsVisible, setOptionsVisible] = useState(false);

  // Ref for dropdown
  const statusRef = useRef(null);

  // Close dropdowns when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (statusRef.current && !statusRef.current.contains(event.target)) {
        setOptionsVisible(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [selectedStatus, setSelectedStatus] = useState("Pending");
  const statuses = ["Received", "Pending", "Not Available"];

  const handleChange = (event) => {
    setSelectedStatus(event.target.value);
  };
  return (
    <div>
      <div className="   flex flex-wrap gap-3">
        {data.map((card, index) => (
          <div
            key={index}
            className="flex space-x-4 p-3 bg-white shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] rounded-lg h-max w-[550px]"
          >
            {/* Image Section */}
            <div className="flex flex-col items-start justify-center w-28 h-28">
              <h3 className="font-semibold text-gray-800 text-sm w-full h-4">
                {card.title}
              </h3>
              <img
                src={card.image}
                alt={card.title}
                className="w-full h-24 object-cover rounded"
              />
            </div>
            {/* Details Section */}
            <div className="flex-grow overflow-y-auto ">
              <ul className="mt-2 space-y-1">
                {card.items.map((item, idx) => (
                  <li
                    key={idx}
                    className="flex justify-between items-center w-full"
                  >
                    <div className="text-xs text-gray-700 w-5/12 font-semibold">
                      {item.name}
                    </div>
                    <div
                      className={`text-xs font-medium w-3/12 ${
                        item.status === "Received"
                          ? "text-green-500"
                          : item.status === "Pending"
                          ? "text-yellow-500"
                          : "text-red-500"
                      }`}
                    >
                      {item.status}
                    </div>
                    <div
                      className={`text-xs font-semibold w-4/12 text-end underline cursor-pointer  duration-300 ease-in-out ${
                        item.status === "Received"
                          ? "hover:text-yellow-400"
                          : "hover:text-red-400"
                      }`}
                      onClick={() =>
                        item.action === "view" && handleViewClick(item)
                      }
                    >
                      {item.action}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
        <div className="  hidden flex space-x-4 p-3 bg-white shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] rounded-lg  h-max w-[550px]">
          {/* Image Section */}
          <div className="flex flex-col items-start justify-center w-28 h-28">
            <h3 className="font-semibold text-gray-800 text-sm w-full h-4">
              BUW-005
            </h3>
            <img
              src="/images/test-rosewood.webp"
              alt="{card.title}"
              className="w-full h-24 object-cover rounded"
            />
          </div>
          {/* Details Section */}
          <div className="flex-grow overflow-y-auto">
            <ul className="mt-2 space-y-1">
              <li className="flex justify-between items-center w-full">
                <div className="text-xs text-gray-700 w-5/12 font-semibold">
                  Boxcalf Leather
                </div>
                <div className={`text-xs font-medium w-3/12 relative`}>
                  <div className="relative  w-28">
                    <select
                      value={selectedStatus}
                      onChange={handleChange}
                      className={`w-full py-0.5  border rounded-md text-xs font-medium  ring-1  focus:outline-none appearance-none ${
                        selectedStatus === "Received"
                          ? "ring-green-500 "
                          : selectedStatus === "Not Available"
                          ? "ring-red-500 "
                          : selectedStatus === "Pending"
                          ? "ring-yellow-500 "
                          : ""
                      }`}
                    >
                      {statuses.map((status, index) => (
                        <option key={index} value={status}>
                          {status}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div
                  className={`text-xs font-semibold w-4/12 text-end underline cursor-pointer  duration-300 ease-in-out `}
                >
                  {selectedStatus === "Received"
                    ? "view"
                    : selectedStatus === "Not Available"
                    ? "Re-customize"
                    : ""}
                </div>
              </li>
              <li className="flex justify-between items-center w-full">
                <div className="text-xs text-gray-700 w-5/12 font-semibold">
                  TPR Sole
                </div>
                <div className={`text-xs font-medium w-3/12 relative`}>
                  {/* <div className=" flex items-center">
                    <button
                      className="flex w-40 items-center justify-between text-[#313131] font-semibold text-xs"
                      onClick={() => setOptionsVisible((prev) => !prev)}
                    >
                      {selectStatus === "Select" ? "Select" : `${selectStatus}`}
                      <svg
                        className="ml-1 w-4 h-4"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M19 9l-7 7-7-7"
                        ></path>
                      </svg>
                    </button>

                    
                  </div>
                  {optionsVisible && (
                      <div
                        ref={statusRef}
                        className="absolute top-[11px] right-0 border-[1px]  bg-white overflow-hidden mt-2 w-44 px-2 z-50 transition-opacity duration-500 ease-in-out fadein-animation"
                      >
                        <ul className="py-1 text-xs text-gray-600">
                          {[
                            "All",
                            "Under Production",
                            "Delivered",
                            "Out for Delivery",
                          ].map((status) => (
                            <li
                              key={status}
                              onClick={() => {
                                setSelectStatus(status);
                                setOptionsVisible(false);
                              }}
                              className={`px-2 py-2 hover:text-[#212121] font-medium duration-200 ease-in-out cursor-pointer ${
                                selectStatus === status
                                  ? "text-[#212121]"
                                  : "text-[#616161]"
                              }`}
                            >
                              {status}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )} */}
                  <div className="relative w-28">
                    <select
                      value={selectedStatus}
                      onChange={handleChange}
                      className={`w-full py-0.5  border rounded-md text-xs font-medium  ring-1  focus:outline-none appearance-none ${
                        selectedStatus === "Received"
                          ? "ring-green-500 "
                          : selectedStatus === "Not Available"
                          ? "ring-red-500 "
                          : selectedStatus === "Pending"
                          ? "ring-yellow-500 "
                          : ""
                      }`}
                    >
                      {statuses.map((status, index) => (
                        <option key={index} value={status}>
                          {status}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div
                  className={`text-xs font-semibold w-4/12 text-end underline cursor-pointer  duration-300 ease-in-out `}
                >
                  {selectedStatus === "Received"
                    ? "view"
                    : selectedStatus === "Not Available"
                    ? "Re-customize"
                    : ""}
                </div>
              </li>
              <li className="flex justify-between items-center w-full">
                <div className="text-xs text-gray-700 w-5/12 font-semibold">
                  Wrapping Paper
                </div>
                <div className={`text-xs font-medium w-3/12 relative`}>
                  <div className="relative w-28">
                    <select
                      value={selectedStatus}
                      onChange={handleChange}
                      className={`w-full py-0.5  border rounded-md text-xs font-medium  ring-1  focus:outline-none appearance-none ${
                        selectedStatus === "Received"
                          ? "ring-green-500 "
                          : selectedStatus === "Not Available"
                          ? "ring-red-500 "
                          : selectedStatus === "Pending"
                          ? "ring-yellow-500 "
                          : ""
                      }`}
                    >
                      {statuses.map((status, index) => (
                        <option key={index} value={status}>
                          {status}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div
                  className={`text-xs font-semibold w-4/12 text-end underline cursor-pointer  duration-300 ease-in-out `}
                >
                  {selectedStatus === "Received"
                    ? "view"
                    : selectedStatus === "Not Available"
                    ? "Re-customize"
                    : ""}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Modal for viewing images */}
      {modalOpen && selectedItem && (
        <div className="demo1-container ">
          <div className="bg-white p-6 rounded-lg w-[600px] pop-up-animation">
            <div className="text-lg font-semibold text-center mb-3">
              {selectedItem.name}

              <button
                className="float-right"
                onClick={() => setModalOpen(false)}
                aria-label="Close"
              >
                <svg
                  viewBox="0 0 512 512"
                  xmlns="http://www.w3.org/2000/svg"
                  id="fi_1828945"
                  className=" hover:fill-red-600 hover:bg-red-300 rounded-full duration-300 ease-in-out hover:scale-90  w-4 lg:w-6 h-4 lg:h-6"
                >
                  <path d="m256 512c-141.164062 0-256-114.835938-256-256s114.835938-256 256-256 256 114.835938 256 256-114.835938 256-256 256zm0-480c-123.519531 0-224 100.480469-224 224s100.480469 224 224 224 224-100.480469 224-224-100.480469-224-224-224zm0 0"></path>
                  <path d="m176.8125 351.1875c-4.097656 0-8.195312-1.554688-11.308594-4.691406-6.25-6.25-6.25-16.382813 0-22.632813l158.398438-158.402343c6.253906-6.25 16.386718-6.25 22.636718 0s6.25 16.382812 0 22.636718l-158.402343 158.398438c-3.15625 3.136718-7.25 4.691406-11.324219 4.691406zm0 0"></path>
                  <path d="m335.1875 351.1875c-4.09375 0-8.191406-1.554688-11.304688-4.691406l-158.398437-158.378906c-6.253906-6.25-6.253906-16.382813 0-22.632813 6.25-6.253906 16.382813-6.253906 22.632813 0l158.398437 158.398437c6.253906 6.25 6.253906 16.382813 0 22.632813-3.132813 3.117187-7.230469 4.671875-11.328125 4.671875zm0 0"></path>
                </svg>
              </button>
            </div>
            <div className=" space-y-4">
              {selectedItem.image && (
                <img
                  src={selectedItem.image}
                  alt={selectedItem.name}
                  className="w-full  object-cover rounded"
                />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Sourcing;
