import { useState, useEffect } from "react";

const CheckAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [currency, setCurrency] = useState();
  const [type, setType] = useState("");
  const [loaderCheckAuth, setLoaderCheckAuth] = useState(true);

  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  }

  useEffect(() => {
    setLoaderCheckAuth(true);
    fetch(`https://buwchlifestyles.com/api/check/`, {
      method: "POST",
      body: JSON.stringify({
        access_token: "access_token=" + getCookie("access_token"),
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        if (response.status === 401) {
          setIsAuthenticated(false);
        }
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        return response.json();
      })
      .then((data) => {
        if (data["authenticated"] === true) {
          setEmail(data["user_email"]);
          setPhone(data["user_phone"]);
          setType(data["user_type"].toLowerCase());
          setCurrency(data["currency"]);
        }
        setLoaderCheckAuth(false);
        setIsAuthenticated(data["authenticated"]);
      })
      .catch((error) => {
        console.warn("jhgjkhkj: ", error);
      });
  }, [isAuthenticated, email, phone, type, currency]);

  return {
    loaderCheckAuth,
    isAuthenticated,
    email,
    phone,
    type,
    currency,
  };
};

export default CheckAuth;
