import React, { useState, useRef, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "./Products.css";
import ReviewsMarquee from "./ReviewsMarquee";
import Header from "../../components/Header/Header";
import CheckAuth from "../../components/ServiceFunctions/CheckAuth";
import secureLocalStorage from "react-secure-storage";

function ProductPage2() {
  const { isAuthenticated, email } = CheckAuth();
  let MaterialCost = JSON.parse(secureLocalStorage.getItem("Material_cost"));
  let Customize = JSON.parse(secureLocalStorage.getItem("Customize"));
  let Catalogue = JSON.parse(secureLocalStorage.getItem("Catalogue"));
  const [searchParams, setSearchParams] = useSearchParams();
  const [material, setMaterial] = useState([]);
  const [customize, setCustomize] = useState([]);
  const [catalogue, setCatalogue] = useState([]);
  const [rating, setRating] = useState([]);
  const [product, setProduct] = useState({});
  const [activeTab, setActiveTab] = useState("UPPER");
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [fadeClass, setFadeClass] = useState("fade-in");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { article } = useParams();
  const [selectedColor, setSelectedColor] = useState(
    searchParams.get("color") || ""
  );
  const [currency, setCurrency] = useState("INR");
  const [popOpen, setPopOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [sizeSystem, setSizeSystem] = useState("US");
  const [quantity, setQuantity] = useState(1);
  const [count, setCount] = useState(0);
  const [isChecked, setIsChecked] = useState(false);
  const [popUp, setPopUp] = useState("");
  const tabs = ["UPPER", "CONSTRUCTION", "SOLE", "FINISHING"];
  const [cartPosition, setCartPosition] = useState(null); // Store Hello button's position
  const [addToCartAnimation, setAddToCartAnimation] = useState(false);
  const productRef = useRef(null); // Reference to the Support button

  const animateAddToCartButton = () => {
    if (cartPosition && productRef.current) {
      const supportRect = productRef.current.getBoundingClientRect();

      // Calculate the difference
      const deltaX = cartPosition.left - supportRect.left;
      const deltaY = cartPosition.top - supportRect.top;

      // Set the style for animation
      setAddToCartAnimation(true);

      // Reset position after animation
      setTimeout(() => {
        setAddToCartAnimation(false);
        setCount(count + 1);
      }, 1500);
    }
  };

  // Update URL query parameters when filter values change
  useEffect(() => {
    const params = {};
    if (selectedColor) params.color = selectedColor;
    setSearchParams(params);
    catalogue?.findIndex((item) => {
      if (item.color_leather.toLowerCase() === selectedColor) {
        setProduct(item);
      }
    });
  }, [selectedColor]);

  useEffect(() => {
    if (searchParams.get("color")) {
      setSelectedColor(searchParams.get("color"));
    }
  }, [searchParams]);

  useEffect(() => {
    if (
      !isAuthenticated &&
      Catalogue === null &&
      Customize === null &&
      MaterialCost === null
    ) {
      fetch(`http://127.0.0.1:8000/api/get-all-products-data/`, {
        method: "POST",
        body: JSON.stringify({}),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to fetch data");
          }
          return response.json();
        })
        .then((data) => {
          const index = data.customize?.findIndex(
            (item) => item.Article_no.toLowerCase() === article
          );
          if (index !== -1) {
            setCustomize(data.customize[index]);
          }
          const matchingEntries = data.catalogue?.filter(
            (item) => item.Article_no.toLowerCase() === article
          );
          if (matchingEntries.length > 0) {
            setCatalogue(matchingEntries);
          }
          setMaterial(data.materials);
          matchingEntries?.findIndex((item) => {
            if (item.color_leather.toLowerCase() === selectedColor) {
              setProduct(item);
            }
          });
        })
        .catch((error) => {
          console.error("Error: ", error);
        });
    } else {
      setMaterial(MaterialCost);
      const index = Customize?.findIndex(
        (item) => item?.Article_no?.toLowerCase() === article
      );
      if (index !== -1) {
        setCustomize(Customize?.[index]);
      }
      const matchingEntries = Catalogue?.filter(
        (item) => item.Article_no.toLowerCase() === article
      );
      if (matchingEntries?.length > 0) {
        setCatalogue(matchingEntries);
      }
      matchingEntries?.findIndex((item) => {
        if (item.color_leather.toLowerCase() === selectedColor) {
          setProduct(item);
        }
      });
    }
  }, [isAuthenticated]);

  const Accessories = ["Shoeboxes", "Shoetrees", "Brushes", "Creams", "Horns"];

  // Create an array to store the highest-priced items
  const highestPricedAccessories = Accessories?.map((accessory) => {
    // Find the corresponding item in the material array
    const accessoryData = material?.find((item) => item.item === accessory);
    if (!accessoryData) return null; // If no data found for this accessory

    const category = accessoryData[accessory];
    let highestPriceItem = { price: -Infinity, name: "", img: "" };

    // Iterate through the items in the category
    for (let key in category) {
      const item = category[key];
      if (item.Price > highestPriceItem.price) {
        highestPriceItem = {
          category: accessory,
          price: item.Price,
          name: key,
          img: item.Img,
        };
      }
    }

    return highestPriceItem;
  }).filter((item) => item !== null);

  const calculatePrice = (item) => {
    let price = 0;
    let consumption = customize?.Leather?.Consumption;
    material?.forEach((mat) => {
      if (
        mat.item === "Leathers" &&
        mat.Leathers[item.Leather?.replaceAll(" ", "_")]
      ) {
        const leather = mat.Leathers[item.Leather?.replaceAll(" ", "_")];
        const leatherKey = Object.keys(leather).find(
          (key) =>
            leather[key].Color === item.color_leather?.replaceAll(" ", "_")
        );
        if (leatherKey) {
          price += leather[leatherKey].Price_per_dm * consumption;
        }
      }

      if (mat.item === "Soles" && mat.Soles[item.Sole?.replaceAll(" ", "_")]) {
        // Calculate sole price
        price += mat.Soles[item.Sole?.replaceAll(" ", "_")].Price || 0;
      }

      if (
        mat.item === "Finishings" &&
        mat.Finishings[item.Finish?.replaceAll(" ", "_")]
      ) {
        // Calculate finishing price
        price += mat.Finishings[item.Finish?.replaceAll(" ", "_")].Price || 0;
      }

      if (
        mat.item === "Constructions" &&
        mat.Constructions[item.Construction?.replaceAll(" ", "_")]
      ) {
        // Calculate construction price
        price +=
          mat.Constructions[item.Construction?.replaceAll(" ", "_")].Price || 0;
      }

      if (mat.item === "Welts" && mat.Welts[item.Welt?.replaceAll(" ", "_")]) {
        // Calculate welt price
        price += mat.Welts[item.Welt?.replaceAll(" ", "_")].Price || 0;
      }

      if (mat.item === "Edges" && mat.Edges[item.Edge?.replaceAll(" ", "_")]) {
        // Calculate edge price
        price += mat.Edges[item.Edge?.replaceAll(" ", "_")].Price || 0;
      }

      if (
        mat.item === "Thickness" &&
        mat.Thickness[item.Sole_thickness?.replaceAll(" ", "_")]
      ) {
        // Calculate thickness price
        price +=
          mat.Thickness[item.Sole_thickness?.replaceAll(" ", "_")].Price || 0;
      }
    });

    return price;
  };

  const reviews = Object.keys(product?.Reviews || {}).map((email) => {
    const review = product.Reviews[email];
    return {
      pic: review.pic,
      username: review.name,
      rating: review.rating,
      title: review.heading,
      description: review.description,
    };
  });

  let averageReviews = 0;

  const totalReviews = Object.keys(product?.Reviews || {}).length;

  if (totalReviews > 0) {
    averageReviews = Object.keys(product.Reviews).reduce((sum, email) => {
      const review = product.Reviews[email];
      return sum + parseFloat(review.rating);
    }, 0);

    averageReviews = averageReviews / totalReviews;
  }

  const handleRating = () => {
    if (isChecked) {
      fetch(`http://127.0.0.1:8000/api/set-catalogue-rating/`, {
        method: "POST",
        body: JSON.stringify({ email, rating, product }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to fetch data");
          }
          return response.json();
        })
        .then((data) => {
          if (data.message === "success") {
            alert("Thankyou for giving your valuable feedback.");
            secureLocalStorage.setItem(
              "Catalogue",
              JSON.stringify(data.product)
            );
            let Catalogue = JSON.parse(secureLocalStorage.getItem("Catalogue"));
            const matchingEntries = Catalogue?.filter(
              (item) => item.Article_no.toLowerCase() === article
            );
            if (matchingEntries?.length > 0) {
              setCatalogue(matchingEntries);
            }
            matchingEntries?.findIndex((item) => {
              if (item.color_leather.toLowerCase() === selectedColor) {
                setProduct(item);
              }
            });
            setPopOpen(false);
            setRating([]);
          }
        })
        .catch((error) => {
          console.error("Error: ", error);
        });
    } else {
      alert("checkbox is compulsory.");
    }
  };

  const images = catalogue
    ?.filter((item) => item.color_leather.toLowerCase() === selectedColor)
    .flatMap((item) => {
      return [
        item.images.Main,
        item.images.Front,
        item.images.Side1,
        item.images.Side2,
        item.images.Back,
      ]?.filter((url) => url);
    });

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleImageClick = (index) => {
    setFadeClass("fade-out"); // Trigger fade-out animation
    setTimeout(() => {
      setCurrentImageIndex(index); // Change the current image
      setFadeClass("fade-in"); // Trigger fade-in animation after the image change
    }, 10); // Wait for the fade-out animation duration (1s) before changing the image
  };

  const togglePopup = () => {
    if (isAuthenticated) {
      setPopOpen(!popOpen);
    } else {
      setPopUp("login");
    }
  };

  const colors = catalogue.map((item) => ({
    name: item.color_leather,
    image: item.images.Main,
  }));

  const toggleDropdown = () => setIsOpen(!isOpen);

  const selectOption = (label) => {
    setSelectedOption(label);
    setIsOpen(false);
  };

  const toggleSizeSystem = (system) => {
    setSizeSystem(system);
    setSelectedOption(""); // Reset the selection when switching systems
  };

  const sizes = {
    US: [
      6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13, 13.5,
      14, 14.5, 15, 15.5, 16, 16.5, 17, 17.5,
    ],
    UK: [
      5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13,
      13.5, 14, 14.5, 15, 15.5, 16, 16.5,
    ],
    EU: [
      39, 39.5, 40, 40.5, 41, 41.5, 42, 42.5, 43, 43.5, 44, 44.5, 45, 45.5, 46,
      46.5, 47, 47.5, 48, 48.5, 49, 49.5, 50, 50.5,
    ],
  };

  const options = sizes[sizeSystem];

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const increase = () => setQuantity(quantity + 1);
  const decrease = () => {
    if (quantity > 1) setQuantity(quantity - 1); // Prevent decreasing below 1
  };

  const advantages = [
    { title: "Handmade in India", image: "/images/indian-flag.svg" },
    {
      title: "15 days Replacement",
      image: "/images/return-box.svg",
    },
    { title: "Free Horn & Shiner", image: "/images/shoehorn.svg" },
    { title: "Secure Transaction", image: "/images/credit-card.svg" },
    { title: "Free Shipping", image: "/images/free-shipping.svg" },
    { title: "Quality Guarantee", image: "/images/best-product.svg" },
  ];

  const highlights = Object.values(product?.Miscellaneous || {}).map((val) => ({
    title: val.title,
    image: val.img,
    description: val.desc,
  }));

  const canvas = [
    {
      image: "/images/testcanvas1.jpg",
    },
    {
      image: "/images/testcanvas2.jpg",
    },
    {
      image: "/images/testcanvas3.jpg",
    },
  ];

  const zoomRef = useRef(null);

  const handleMouseMove = (e) => {
    if (!zoomRef.current) return;

    const { left, top, width, height } = e.target.getBoundingClientRect();
    const x = ((e.clientX - left) / width) * 100;
    const y = ((e.clientY - top) / height) * 100;

    zoomRef.current.style.backgroundPosition = `${x}% ${y}%`;
    zoomRef.current.style.display = "block"; // Show zoom viewer on mouse move
  };

  const handleMouseLeave = () => {
    if (zoomRef.current) {
      zoomRef.current.style.display = "none"; // Hide zoom viewer on mouse leave
    }
  };

  const content = {
    UPPER: {
      title: product?.Leather,
      description: (() => {
        const matchingMaterial = material?.find((mat) => {
          if (
            mat.item === "Leathers" &&
            mat.Leathers[product?.Leather?.replaceAll(" ", "_")]
          ) {
            const leather =
              mat.Leathers[product?.Leather?.replaceAll(" ", "_")];
            const leatherKey = Object.keys(leather).find(
              (key) =>
                leather[key].Color ===
                product?.color_leather?.replaceAll(" ", "_")
            );
            if (leatherKey) {
              return true;
            }
          }
          return false;
        });

        if (matchingMaterial) {
          const leather =
            matchingMaterial.Leathers[product?.Leather?.replaceAll(" ", "_")];
          const leatherKey = Object.keys(leather).find(
            (key) =>
              leather[key].Color ===
              product?.color_leather?.replaceAll(" ", "_")
          );
          return leather[leatherKey]?.desc || "";
        }
        return "";
      })(),
      image: (() => {
        const matchingMaterial = material?.find((mat) => {
          if (
            mat.item === "Leathers" &&
            mat.Leathers[product?.Leather?.replaceAll(" ", "_")]
          ) {
            const leather =
              mat.Leathers[product?.Leather?.replaceAll(" ", "_")];
            const leatherKey = Object.keys(leather).find(
              (key) =>
                leather[key].Color ===
                product?.color_leather?.replaceAll(" ", "_")
            );
            if (leatherKey) {
              return true;
            }
          }
          return false;
        });

        if (matchingMaterial) {
          const leather =
            matchingMaterial.Leathers[product?.Leather?.replaceAll(" ", "_")];
          const leatherKey = Object.keys(leather).find(
            (key) =>
              leather[key].Color ===
              product?.color_leather?.replaceAll(" ", "_")
          );
          return leather[leatherKey]?.Img || "";
        }
        return "";
      })(),
    },
    CONSTRUCTION: {
      title: product?.Construction,
      description: (() => {
        const matchingMaterial = material?.find(
          (mat) =>
            mat.item === "Constructions" &&
            mat.Constructions[product?.Construction?.replaceAll(" ", "_")]
        );
        return matchingMaterial
          ? matchingMaterial.Constructions[
              product?.Construction?.replaceAll(" ", "_")
            ].desc
          : "";
      })(),
      image: (() => {
        const matchingMaterial = material?.find(
          (mat) =>
            mat.item === "Constructions" &&
            mat.Constructions[product?.Construction?.replaceAll(" ", "_")]
        );
        return matchingMaterial
          ? matchingMaterial.Constructions[
              product?.Construction?.replaceAll(" ", "_")
            ].Img
          : "";
      })(),
    },
    SOLE: {
      title: product?.Sole,
      description: (() => {
        const matchingMaterial = material?.find(
          (mat) =>
            mat.item === "Soles" &&
            mat.Soles[product?.Sole?.replaceAll(" ", "_")]
        );
        return matchingMaterial
          ? matchingMaterial.Soles[product?.Sole?.replaceAll(" ", "_")].desc
          : "";
      })(),
      image: (() => {
        const matchingMaterial = material?.find(
          (mat) =>
            mat.item === "Soles" &&
            mat.Soles[product?.Sole?.replaceAll(" ", "_")]
        );
        return matchingMaterial
          ? matchingMaterial.Soles[product?.Sole?.replaceAll(" ", "_")].Img
          : "";
      })(),
    },
    FINISHING: {
      title: product?.Finish,
      description: (() => {
        const matchingMaterial = material?.find(
          (mat) =>
            mat.item === "Finishings" &&
            mat.Finishings[product?.Finish?.replaceAll(" ", "_")]
        );
        return matchingMaterial
          ? matchingMaterial.Finishings[product?.Finish?.replaceAll(" ", "_")]
              .desc
          : "";
      })(),
      image: (() => {
        const matchingMaterial = material?.find(
          (mat) =>
            mat.item === "Finishings" &&
            mat.Finishings[product?.Finish?.replaceAll(" ", "_")]
        );
        return matchingMaterial
          ? matchingMaterial.Finishings[product?.Finish?.replaceAll(" ", "_")]
              .Img
          : "";
      })(),
    },
  };

  const activeContent = content[activeTab];

  const handleAddTOBag = () => {
    if (isAuthenticated) {
      if (selectedOption) {
        fetch(`http://127.0.0.1:8000/api/add-to-cart/`, {
          method: "POST",
          body: JSON.stringify({
            email: email,
            product: product,
            type: "retail",
            unit: sizeSystem,
            size: selectedOption,
            quantity: quantity,
          }),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("Failed to fetch data");
            }
            return response.json();
          })
          .then((data) => {
            if (data.message === "success") {
              animateAddToCartButton();
            } else {
              alert(data.message);
            }
          })
          .catch((error) => {
            console.error("Error: ", error);
          });
      } else {
        alert("enter size.");
      }
    } else {
      setPopUp("login");
    }
  };

  return (
    <>
      <Header
        onCartPosition={(position) => setCartPosition(position)}
        curr={(e) => {
          setCurrency(e);
        }}
        popupState={popUp}
        closePopup={() => setPopUp("")}
        cartCount={count}
      />
      <div className="after-header flex justify-center min-h-screen ">
        {/* Main Container */}
        <div className="  w-full xl:w-[95%] mb-60">
          {/* Section 1 */}

          <div className="flex max-lg:flex-col lg:flex-row gap-2 w-full max-lg:justify-start lg:justify-center max-lg:items-center lg:items-start  py-4 ">
            {/* Images Section */}
            <div className="flex xl:flex-row max-xl:flex-col-reverse w-full lg:w-7/12 xl:gap-10 items-center justify-center">
              {/* Indicator Section */}
              <div className=" w-max h-full   flex max-xl:flex-row xl:flex-col max-xl:justify-center max-xl:items-center xl:justify-start gap-y-2 ">
                {images.map((image, index) => (
                  <div
                    key={index}
                    onClick={() => handleImageClick(index)}
                    className={`cursor-pointer h-12 w-12 md:h-16 md:w-16 2xl:h-24 xl:h-16 xl:w-36 2xl:w-44  rounded-lg ${
                      currentImageIndex === index
                        ? "border-[2px] border-gray-900/25"
                        : "opacity-50 "
                    }`}
                  >
                    <img
                      src={image}
                      alt={`Thumbnail ${index + 1}`}
                      className="w-full h-full object-cover rounded-md"
                    />
                  </div>
                ))}
              </div>
              {/* Image Section */}
              <div className=" bg-white w-2/3 md:w-2/4 lg:w-full  xl:p-3">
                <div
                  className="relative"
                  onMouseMove={handleMouseMove}
                  onMouseLeave={handleMouseLeave}
                  onClick={toggleModal} // Open modal on click
                >
                  <img
                    ref={productRef}
                    src={images[currentImageIndex]}
                    alt={`Image ${currentImageIndex + 1}`}
                    className={`${
                      addToCartAnimation ? "addToCart" : ""
                    } object-cover w-full h-full max-w-[40rem]`}
                  />
                  {addToCartAnimation && (
                    <img
                      src={images[currentImageIndex]}
                      alt={`Image ${currentImageIndex + 1}`}
                      className="slideImg opacity-0 object-cover w-full h-full max-w-[40rem]"
                    />
                  )}
                  <style jsx>
                    {`
                      .addToCart {
                        position: absolute;
                        transform: translate(
                          ${cartPosition?.left -
                          productRef?.current?.getBoundingClientRect()?.left}px,
                          ${cartPosition?.top -
                          productRef?.current?.getBoundingClientRect()?.top}px
                        );
                        width: 640px;
                        height: 640px;
                        animation: add-to-cart 1.5s ease-in-out;
                        z-index: 1000;
                        box-shadow: 2px 2px 10px 1px #00000063;
                      }

                      @keyframes add-to-cart {
                        0% {
                          transform: translate(0, 0);
                          border-radius: 0%;
                          width: 640px;
                          height: 640px;
                          box-shadow: 2px 2px 10px 1px #00000063;
                        }
                        100% {
                          transform: translate(
                            ${cartPosition?.left -
                            productRef?.current?.getBoundingClientRect()
                              ?.left}px,
                            ${cartPosition?.top -
                            productRef?.current?.getBoundingClientRect()?.top}px
                          );
                          border-radius: 50%;
                          width: 45px; /* You can change this value to the final size */
                          height: 45px; /* You can change this value to the final size */
                          box-shadow: 2px 2px 10px 1px #00000063;
                        }
                      }
                    `}
                  </style>

                  <div
                    className="zoom-viewer w-full h-full absolute top-0 hidden lg:block"
                    ref={zoomRef}
                    style={{
                      backgroundImage: `url(${images[currentImageIndex]})`,
                      backgroundSize: "300%",
                      display: "none", // Initially hidden
                    }}
                  />
                </div>
                {/* Modal Section */}
                {isModalOpen && images[currentImageIndex] && (
                  <div
                    className="fixed inset-0 bg-black/70 flex items-center justify-center z-50"
                    onClick={toggleModal}
                  >
                    <div className="relative image-animation ">
                      <img
                        src={images[currentImageIndex]}
                        alt={`Enlarged Image ${currentImageIndex + 1}`}
                        className="max-w-full max-h-[90vh] object-contain rounded-lg"
                      />
                      <button
                        className="absolute top-4 right-4"
                        onClick={toggleModal}
                        aria-label="Close"
                      >
                        <svg
                          viewBox="0 0 512 512"
                          xmlns="http://www.w3.org/2000/svg"
                          id="fi_1828945"
                          className="  hover:fill-red-600 hover:bg-red-300 rounded-full duration-300 ease-in-out hover:scale-90  w-4 lg:w-10 h-4 lg:h-10"
                        >
                          <path d="m256 512c-141.164062 0-256-114.835938-256-256s114.835938-256 256-256 256 114.835938 256 256-114.835938 256-256 256zm0-480c-123.519531 0-224 100.480469-224 224s100.480469 224 224 224 224-100.480469 224-224-100.480469-224-224-224zm0 0"></path>
                          <path d="m176.8125 351.1875c-4.097656 0-8.195312-1.554688-11.308594-4.691406-6.25-6.25-6.25-16.382813 0-22.632813l158.398438-158.402343c6.253906-6.25 16.386718-6.25 22.636718 0s6.25 16.382812 0 22.636718l-158.402343 158.398438c-3.15625 3.136718-7.25 4.691406-11.324219 4.691406zm0 0"></path>
                          <path d="m335.1875 351.1875c-4.09375 0-8.191406-1.554688-11.304688-4.691406l-158.398437-158.378906c-6.253906-6.25-6.253906-16.382813 0-22.632813 6.25-6.253906 16.382813-6.253906 22.632813 0l158.398437 158.398437c6.253906 6.25 6.253906 16.382813 0 22.632813-3.132813 3.117187-7.230469 4.671875-11.328125 4.671875zm0 0"></path>
                        </svg>
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* Information Section */}
            <div className="bg-white w-full lg:w-5/12   xl:px-4 rounded  ">
              {/* Product Information */}
              <div className=" w-full  lg:pl-4 xl:pl-8   max-lg:mt-8 flex flex-col justify-between gap-y-2">
                <div className="flex items-center justify-between gap-6 max-xl:mb-2 2xl:mb-4">
                  <div className="text">
                    <h2 className=" font-medium text-lg xl:text-xl leading-6 text-[#313131] mb-2 uppercase">
                      {product?.Article_no?.replaceAll("_", " ")}
                    </h2>
                    <p className="font-medium text-sm xl:text-base text-gray-500">
                      {product?.Leather?.replaceAll("_", " ")} |&nbsp;
                      {product?.Sole?.replaceAll("_", " ")}
                    </p>
                  </div>
                </div>

                <div className="flex   max-xl:mb-2 2xl:mb-4   ">
                  <div className="flex items-center">
                    <p className=" font-medium text-lg xl:text-xl  leading-9 text-[#313131] ">
                      Rs. {calculatePrice(product).toLocaleString("en-IN")}
                    </p>
                  </div>
                  {/* <svg
                    className="mx-5 max-[400px]:hidden"
                    xmlns="http://www.w3.org/2000/svg"
                    width="2"
                    height="36"
                    viewBox="0 0 2 36"
                    fill="none"
                  >
                    <path d="M1 0V36" stroke="#E5E7EB" />
                  </svg>
                  <button className="flex items-center gap-1 rounded-lg bg-amber-400 py-1.5 px-2.5 w-max">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_12657_16865)">
                        <path
                          d="M8.10326 2.26718C8.47008 1.52393 9.52992 1.52394 9.89674 2.26718L11.4124 5.33818C11.558 5.63332 11.8396 5.83789 12.1653 5.88522L15.5543 6.37768C16.3746 6.49686 16.7021 7.50483 16.1086 8.08337L13.6562 10.4738C13.4205 10.7035 13.313 11.0345 13.3686 11.3589L13.9475 14.7343C14.0877 15.5512 13.2302 16.1742 12.4966 15.7885L9.46534 14.1948C9.17402 14.0417 8.82598 14.0417 8.53466 14.1948L5.5034 15.7885C4.76978 16.1742 3.91235 15.5512 4.05246 14.7343L4.63137 11.3589C4.68701 11.0345 4.57946 10.7035 4.34378 10.4738L1.89144 8.08337C1.29792 7.50483 1.62543 6.49686 2.44565 6.37768L5.8347 5.88522C6.16041 5.83789 6.44197 5.63332 6.58764 5.33818L8.10326 2.26718Z"
                          fill="white"
                        />
                        <g clip-path="url(#clip1_12657_16865)">
                          <path
                            d="M8.10326 2.26718C8.47008 1.52393 9.52992 1.52394 9.89674 2.26718L11.4124 5.33818C11.558 5.63332 11.8396 5.83789 12.1653 5.88522L15.5543 6.37768C16.3746 6.49686 16.7021 7.50483 16.1086 8.08337L13.6562 10.4738C13.4205 10.7035 13.313 11.0345 13.3686 11.3589L13.9475 14.7343C14.0877 15.5512 13.2302 16.1742 12.4966 15.7885L9.46534 14.1948C9.17402 14.0417 8.82598 14.0417 8.53466 14.1948L5.5034 15.7885C4.76978 16.1742 3.91235 15.5512 4.05246 14.7343L4.63137 11.3589C4.68701 11.0345 4.57946 10.7035 4.34378 10.4738L1.89144 8.08337C1.29792 7.50483 1.62543 6.49686 2.44565 6.37768L5.8347 5.88522C6.16041 5.83789 6.44197 5.63332 6.58764 5.33818L8.10326 2.26718Z"
                            fill="white"
                          />
                        </g>
                      </g>
                      <defs>
                        <clipPath id="clip0_12657_16865">
                          <rect width="18" height="18" fill="white" />
                        </clipPath>
                        <clipPath id="clip1_12657_16865">
                          <rect width="18" height="18" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <span className="text-base font-medium text-white">4.8</span>
                  </button> */}
                </div>

                <hr className="text-slate-400  " />
                <div className="flex items-start justify-start gap-3 max-xl:my-2 2xl:my-4 max-w-sm overflow-x-auto no-scrollbar">
                  {colors.map((color, index) => (
                    <div
                      key={index}
                      className="color-box group cursor-pointer"
                      onClick={() => {
                        setSelectedColor(color.name.toLowerCase());
                        setCurrentImageIndex(0);
                      }}
                    >
                      <div className="h-14 w-24  xl:w-24 2xl:w-28 xl:h-12 2xl:h-16">
                        <img
                          src={color.image}
                          alt={`${color.name} shoe`}
                          className={`w-full h-full object-cover group-hover:border-[#616161] duration-300 ease-in-out ${
                            color.name.toLowerCase() === selectedColor
                              ? "border-[#000] border-[4px]"
                              : "border-[2px] border-gray-300 "
                          }`}
                        />
                      </div>
                      <div className="text-center mt-2">
                        <p
                          className={`group-hover:text-[#313131] duration-300 ease-in-out text-xs xl:text-sm leading-4 ${
                            color.name.toLowerCase() === selectedColor
                              ? "text-[#000] font-bold"
                              : "text-gray-500 font-medium"
                          }`}
                        >
                          {color.name}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>

                <hr className="text-slate-400 " />
                <div className="w-full max-xl:my-2 2xl:my-4 flex xl:flex-row lg:flex-col items-center   max-xl:gap-1 xl:gap-3">
                  <div
                    className="relative w-1/2 lg:w-full xl:w-1/2 "
                    ref={dropdownRef}
                  >
                    {/* Selected Option */}
                    <div
                      onClick={toggleDropdown}
                      className="bg-black/90 px-3 h-12 flex items-center justify-between text-white text-xs 2xl:text-sm cursor-pointer"
                    >
                      <span>
                        {`Select Size: ${selectedOption}(${sizeSystem})` ||
                          `Select Size (${sizeSystem})`}
                      </span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className={`w-4 h-4 ml-2 transform transition-transform ${
                          isOpen ? "rotate-0" : "-rotate-90"
                        }`}
                        viewBox="0 0 512 512"
                      >
                        <path
                          d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"
                          fill="white"
                        ></path>
                      </svg>
                    </div>

                    {/* Options */}
                    {isOpen && (
                      <div className="absolute z-10 mt-1 bg-gray-200 w-full flex justify-center items-center">
                        <div className="p-2 w-full max-w-[500px] flex flex-col justify-center items-center">
                          {/* Size System Toggle */}
                          <div className="flex justify-end w-full mb-2">
                            {["US", "UK", "EU"].map((system) => (
                              <button
                                key={system}
                                onClick={() => toggleSizeSystem(system)}
                                className={`px-2 py-1 text-xs font-semibold ${
                                  sizeSystem === system
                                    ? "bg-black/90 text-white"
                                    : "bg-gray-200 text-black/90"
                                }`}
                              >
                                {system}
                              </button>
                            ))}
                          </div>
                          <div className="flex flex-wrap justify-center gap-[5px]">
                            {options.map((option, index) => (
                              <div
                                key={index}
                                onClick={() => selectOption(option)}
                                className={`border-[1px] w-10 h-10 xl:w-[40px] xl:h-[40px] text-sm flex items-center justify-center cursor-pointer hover:bg-[#FFF4B5] hover:border-[#FFD61F] bg-white border-[#9CA3AF] ${
                                  selectedOption === option
                                    ? "bg-[#FFF4B5] border-[#FFD61F]"
                                    : ""
                                }`}
                              >
                                {option}
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="w-1/2 lg:w-full xl:w-1/2">
                    <div className=" bg-black/90 px-3 h-12 flex items-center justify-between cursor-pointer">
                      <p className="text-white text-xs 2xl:text-sm">
                        Quantity:
                      </p>
                      <button
                        className="group cursor-pointer outline-none hover:rotate-0 duration-300 focus:outline-none ring-0"
                        title="Add New"
                        onClick={decrease}
                      >
                        <svg
                          className="stroke-yellow-400 fill-none group-hover:fill-yellow-800 group-active:stroke-yellow-200 group-active:fill-yellow-600 group-active:duration-0 duration-300 w-6 h-6"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeWidth="1.5"
                            d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                          ></path>
                          <path strokeWidth="1.5" d="M8 12H16"></path>
                        </svg>
                      </button>
                      <input
                        type="number"
                        value={quantity}
                        readOnly
                        className="w-6 2xl:w-12 text-white text-sm bg-black/0  text-center border-none ring-0 outline-none"
                      />
                      <button
                        className="group cursor-pointer outline-none hover:rotate-0 duration-300 focus:outline-none ring-0"
                        title="Add New"
                        onClick={increase}
                      >
                        <svg
                          className="stroke-yellow-400 fill-none group-hover:fill-yellow-800 group-active:stroke-yellow-200 group-active:fill-yellow-600 group-active:duration-0 duration-300 w-6 h-6"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeWidth="1.5"
                            d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                          ></path>
                          <path strokeWidth="1.5" d="M8 12H16"></path>
                          <path strokeWidth="1.5" d="M12 16V8"></path>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>

                <div>
                  <button
                    className="border-2  text-sm flex items-center justify-center overflow-hidden group border-black/80  w-full h-12   font-medium hover:bg-black/90 duration-300 ease-in-out hover:text-white hover:font-normal max-xl:mb-2 2xl:mb-4"
                    onClick={() => {
                      handleAddTOBag();
                    }}
                  >
                    Add to Bag
                    <span className="pl-2">
                      <svg
                        className="w-6 h-6  group-hover:stroke-yellow-400 stroke-black stroke-2 fill-black/80 group-hover:fill-yellow-400 duration-300"
                        id="Layer_1"
                        enableBackground="new 0 0 128 128"
                        viewBox="0 0 128 128"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g>
                          <path d="m102.78 78.84-4.48-56.05c-.08-1.04-.95-1.84-1.99-1.84h-16.59c-1.03-11.45-10.67-20.45-22.38-20.45s-21.35 9-22.37 20.45h-16.59c-1.04 0-1.91.8-1.99 1.84l-6.36 79.64c-.25 3.16.83 6.31 2.98 8.64s5.21 3.67 8.38 3.67h49.2c4.53 7.9 12.85 12.76 22.02 12.76 14 0 25.4-11.39 25.4-25.4 0-10.07-6.08-19.25-15.23-23.26zm-45.44-74.34c9.5 0 17.34 7.21 18.35 16.45h-36.7c1.01-9.24 8.85-16.45 18.35-16.45zm-41.39 103.86c-1.42-1.53-2.1-3.53-1.94-5.61l6.21-77.8h14.65v9.22c0 1.1.9 2 2 2s2-.9 2-2v-9.22h36.94v9.22c0 1.1.9 2 2 2s2-.9 2-2v-9.22h14.65l4.19 52.5c-1.96-.48-3.98-.75-6.04-.75-14.01 0-25.4 11.39-25.4 25.4 0 2.95.52 5.89 1.52 8.64h-47.34c-2.09 0-4.02-.85-5.44-2.38zm76.66 15.14c-8.08 0-15.38-4.47-19.06-11.67-1.55-3.02-2.34-6.29-2.34-9.73 0-11.8 9.6-21.4 21.4-21.4 2.62 0 5.16.46 7.56 1.38 8.28 3.13 13.84 11.17 13.84 20.02 0 11.8-9.6 21.4-21.4 21.4z" />
                          <path d="m104.43 100.1h-9.82v-9.82c0-1.1-.9-2-2-2s-2 .9-2 2v9.82h-9.82c-1.1 0-2 .9-2 2s.9 2 2 2h9.82v9.82c0 1.1.9 2 2 2s2-.9 2-2v-9.82h9.82c1.1 0 2-.9 2-2s-.9-2-2-2z" />
                        </g>
                      </svg>
                    </span>
                  </button>
                </div>

                <div className="flex justify-center items-center w-full max-xl:mb-2 2xl:mb-4">
                  <button
                    onClick={() => {
                      if (isAuthenticated) {
                      } else {
                        setPopUp("login");
                      }
                    }}
                    className="flex overflow-hidden items-center text-xs 2xl:text-sm font-medium focus-visible:outline-none focus-visible:ring-1  text-gray-50  bg-black/90 h-12 px-4 py-1.5 w-full whitespace-pre md:flex group relative  justify-center gap-2  transition-all duration-300 ease-out ring-2 ring-yellow-400/70 hover:ring-yellow-400 ring-offset-2 "
                  >
                    <span className="absolute right-0 -mt-12 h-32 w-8 translate-x-12 rotate-12 bg-[#ffffff] opacity-20 transition-all duration-1000 ease-out group-hover:-translate-x-[680px]"></span>
                    <div className="flex items-center">
                      <span className="ml-1 text-white ">CREATE YOUR OWN</span>
                    </div>
                    <div className="ml-2 flex items-center gap-1 text-sm md:flex">
                      {/* <img className="w-6 h-6 stroke-white fill-white" src="/images/adminImages/Customize 3D.svg" alt="" /> */}
                      <svg
                        id="fi_3360739"
                        enableBackground="new 0 0 48 48"
                        className="w-6 h-6 fill-gray-200 group-hover:fill-yellow-400 duration-500 ease-in-out animate-pulse group-hover:animate-none"
                        viewBox="0 0 48 48"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g>
                          <path d="m36.2 45.2 2.1 2.1c.4.4 1 .7 1.7.7.6 0 1.2-.2 1.7-.7l5.6-5.6c.4-.4.7-1 .7-1.7 0-.6-.2-1.2-.7-1.7l-12.6-12.5-9 9 3.6 3.6 3.6-3.6c.4-.4 1.1-.4 1.5 0s.4 1.1 0 1.5l-3.6 3.6 3.8 3.8 5.5-5.5c.4-.4 1.1-.4 1.5 0s.4 1.1 0 1.5z"></path>
                          <path d="m9.6 18.6 3.6 3.6 9-9-12.5-12.5c-.5-.5-1.1-.7-1.7-.7s-1.2.2-1.7.7l-5.6 5.6c-.5.5-.7 1.1-.7 1.7s.2 1.2.7 1.7l2.1 2.1 5.5-5.5c.4-.4 1.1-.4 1.5 0s.4 1.1 0 1.5l-5.5 5.5 3.8 3.8 3.6-3.6c.4-.4 1.1-.4 1.5 0s.4 1.1 0 1.5z"></path>
                          <path d="m40.3.4c-.6-.6-1.6-.6-2.2 0l-3.1 3.1 9.4 9.4 3.1-3.1c.3-.2.5-.6.5-1s-.2-.8-.4-1.1z"></path>
                          <path
                            d="m2.3 18h42.1v13.3h-42.1z"
                            transform="matrix(.707 -.707 .707 .707 -10.591 23.728)"
                          ></path>
                          <path d="m1.9 47.9 9.2-2.7-8.4-8.4-2.7 9.2c-.2.5 0 1.1.4 1.5.4.5 1 .6 1.5.4z"></path>
                        </g>
                      </svg>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* Section 2 */}
          <div className="flex flex-wrap  w-full justify-center items-start  pb-4  gap-2">
            {advantages.map((advantage, index) => (
              <div
                key={index}
                className="w-[48%] sm:w-[32%] md:w-[15.5%]  flex items-start justify-center gap-2 pt-2 max-sm:px-4"
              >
                <div className="flex flex-col items-center  gap-y-2">
                  <img
                    className="w-8 xl:w-10 2xl:w-12"
                    src={advantage.image}
                    alt=""
                  />
                  <p className="font-semibold text-center text-xs ">
                    {advantage.title}
                  </p>
                </div>
              </div>
            ))}
          </div>

          {/* Section 3 */}
          <div className="block md:hidden">
            {/* Swiper for small screens */}
            <Swiper
              modules={[Pagination]}
              spaceBetween={16}
              slidesPerView="auto"
              pagination={{ clickable: true }}
              className="my-4"
            >
              {highlights.map((highlight, index) => (
                <SwiperSlide key={index} className="w-[70%] flex-shrink-0 mb-5">
                  <div className="flex flex-col items-center">
                    <img
                      className="w-full p-4 max-w-72 sm:max-w-96"
                      src={highlight.image}
                      alt={highlight.title}
                    />
                    <div className="text-center px-4 w-full mt-3">
                      <p className="font-semibold text-sm uppercase">
                        {highlight.title}
                      </p>
                      <p className="font-medium text-xs">
                        {highlight.description}
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>

          <div className="hidden md:flex w-full justify-between items-center my-10 py-4 gap-2">
            {/* Grid for larger screens */}
            {highlights.map((highlight, index) => (
              <div
                key={index}
                className="w-[30%] flex items-start justify-center"
              >
                <div className="flex flex-col items-center gap-x-3">
                  <img
                    className="w-full"
                    src={highlight.image}
                    alt={highlight.title}
                  />
                  <div className="text-center px-4 w-full mt-3">
                    <p className="font-semibold h-max text-[#313131] text-xs lg:text-sm uppercase">
                      {highlight.title}
                    </p>
                    <p className="font-medium text-[10px] lg:text-xs">
                      {highlight.description}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <hr className="text-slate-400 my-4 " />
          {/* Section 4 */}

          <div className="relative w-full overflow-hidden my-10 py-4  ">
            <div className="container  p-2 ">
              {/* Content Section */}
              <div className=" flex flex-col justify-center items-center">
                <div className="font-semibold text-[#212121] text-lg text-center   w-full mb-7 ">
                  PRODUCT SPECIFICATION
                </div>
                <div className="flex max-xl:flex-col xl:flex-row justify-center items-center md:items-start max-w-7xl gap-5">
                  {/* Image */}
                  <div className="w-full xl:w-2/5 flex justify-center">
                    <div className="relative shadow-[0px_0px_0px_5px_#facc1570] border-2 border-black/50 w-72 sm:w-96 2xl:w-[450px]  h-48 sm:h-60 2xl:h-[290px] overflow-hidden rounded-full flex items-center  ">
                      <img
                        src={activeContent.image}
                        alt={activeContent.title}
                        className=" w-full  object-cover"
                      />
                    </div>
                  </div>

                  {/* Text Content */}
                  <div className="w-full flex max-xl:flex-col xl:flex-row max-xl:items-center xl:items-start xl:w-3/5 gap-x-10">
                    {/* Tabs */}
                    <div className="flex max-xl:flex-row xl:flex-col items-start justify-center max-xl:gap-x-3 max-xl:pb-5 gap-y-5 pt-1">
                      {tabs.map((tab) => (
                        <button
                          key={tab}
                          onClick={() => setActiveTab(tab)}
                          className={`text-xs sm:text-sm xl:text-base text-start font-semibold xl:font-medium   ${
                            activeTab === tab
                              ? "border-b-2 border-yellow-400 text-[#313131]"
                              : "text-[#616161] underline-animation-light"
                          }`}
                        >
                          {tab}
                        </button>
                      ))}
                    </div>
                    <div className="w-full max-md:px-2">
                      <h2 className="text-sm md:text-base xl:text-xl font-semibold  max-xl:mb-2 xl:mb-3 text-[#313131]">
                        {activeContent.title}
                      </h2>
                      <p className="text-[#616161] mb-3 text-xs md:text-sm xl:text-base break-words">
                        {activeContent.description}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <hr className="text-slate-400 my-4 " />
          {/* Section 5 */}
          <div className="relative w-full overflow-hidden my-10 py-4">
            {/* Image Scroll Container */}
            <div className="flex justify-start w-full h-40 md:h-60 lg:h-80 overflow-x-scroll no-scrollbar scroll-smooth">
              <a href="https://www.instagram.com/buwch_lifestyles/">
                <div className="min-w-[160px] md:min-w-[240px] lg:min-w-[320px] h-full flex items-center justify-center overflow-hidden px-2">
                  <img
                    className="object-cover w-full h-full  "
                    src="/images/follow-insta.svg"
                    alt="Instagram: @buwch_lifestyle"
                  />
                </div>
              </a>
              {canvas.map((canvas, index) => (
                <div
                  key={index}
                  className="min-w-[160px] md:min-w-[240px] lg:min-w-[320px] h-full flex items-center justify-center overflow-hidden px-2"
                >
                  <img
                    className="object-cover w-full h-full max-lg:border-2 lg:border-4 border-black/20  rounded-xl"
                    src={canvas.image}
                    alt={`Image ${index + 1}`}
                  />
                </div>
              ))}
            </div>
          </div>

          <hr className="text-slate-400 my-4 " />
          {/* Section 6 */}
          <div className="relative w-full overflow-hidden my-10  py-4">
            <div className=" mx-auto  ">
              {/* Review Section */}
              <div className=" flex justify-center w-full">
                <div className="flex flex-col  justify-center items-center   ">
                  <p className="font-semibold text-[#212121] text-base lg:text-lg">
                    REVIEWS
                  </p>
                  <p className=" text-[#616161] text-xs lg:text-sm">
                    Leave a comment of this product.
                  </p>
                  <div className="w-full  flex justify-center mt-4">
                    <div className="rating mb-4">
                      {Array.from({ length: 5 }, (_, index) => {
                        const starValue = 5 - index;
                        const isFullStar =
                          starValue <= Math.floor(averageReviews);
                        const isHalfStar =
                          starValue === Math.ceil(averageReviews) &&
                          averageReviews % 1 !== 0;
                        const isEmptyStar = starValue > averageReviews;
                        return (
                          <React.Fragment key={starValue}>
                            <input
                              value={starValue}
                              name="rating"
                              type="radio"
                              className="hidden"
                              defaultChecked={
                                Math.round(averageReviews) === starValue
                              }
                            />
                            <label>
                              {isFullStar ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="#FFD700"
                                  viewBox="0 0 24 24"
                                  className="w-5 h-5 md:w-6 md:h-6"
                                >
                                  <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                                </svg>
                              ) : isHalfStar ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  className="w-5 h-5 md:w-6 md:h-6"
                                >
                                  <defs>
                                    <linearGradient
                                      id={`half-star-${starValue}`}
                                      x1="0%"
                                      y1="0%"
                                      x2="100%"
                                      y2="0%"
                                    >
                                      <stop offset="50%" stopColor="#FFD700" />{" "}
                                      {/* Gold for left half */}
                                      <stop
                                        offset="50%"
                                        stopColor="lightgray"
                                      />{" "}
                                      {/* Gray for right half */}
                                    </linearGradient>
                                  </defs>
                                  <polygon
                                    points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"
                                    fill={`url(#half-star-${starValue})`}
                                  />
                                </svg>
                              ) : (
                                isEmptyStar && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="lightgray" // Gray color for empty stars
                                    viewBox="0 0 24 24"
                                    className="w-5 h-5 md:w-6 md:h-6"
                                  >
                                    <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                                  </svg>
                                )
                              )}
                            </label>
                          </React.Fragment>
                        );
                      })}
                    </div>
                  </div>
                  <div className="w-full flex  justify-center">
                    <button
                      onClick={togglePopup}
                      className="border-2 flex items-center justify-center overflow-hidden group border-black/80 hover:border-[#212121] max-md:px-3 md:px-4 h-10 md:h-12   font-medium hover:bg-[#212121] duration-300 ease-in-out hover:text-white  uppercase text-xs md:text-sm"
                    >
                      Write a REVIEW
                      <span className="pl-2">
                        <svg
                          version="1.1"
                          id="fi_1001371"
                          className="w-5 h-5 md:w-6 md:h-6  group-hover:stroke-yellow-400 stroke-black stroke-2 fill-black/80 group-hover:fill-yellow-400 duration-300"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <g>
                            <g>
                              <g>
                                <path
                                  d="M352.459,220c0-11.046-8.954-20-20-20h-206c-11.046,0-20,8.954-20,20s8.954,20,20,20h206
				C343.505,240,352.459,231.046,352.459,220z"
                                ></path>
                                <path
                                  d="M126.459,280c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20H251.57c11.046,0,20-8.954,20-20c0-11.046-8.954-20-20-20
				H126.459z"
                                ></path>
                                <path
                                  d="M173.459,472H106.57c-22.056,0-40-17.944-40-40V80c0-22.056,17.944-40,40-40h245.889c22.056,0,40,17.944,40,40v123
				c0,11.046,8.954,20,20,20c11.046,0,20-8.954,20-20V80c0-44.112-35.888-80-80-80H106.57c-44.112,0-80,35.888-80,80v352
				c0,44.112,35.888,80,80,80h66.889c11.046,0,20-8.954,20-20C193.459,480.954,184.505,472,173.459,472z"
                                ></path>
                                <path
                                  d="M467.884,289.572c-23.394-23.394-61.458-23.395-84.837-0.016l-109.803,109.56c-2.332,2.327-4.052,5.193-5.01,8.345
				l-23.913,78.725c-2.12,6.98-0.273,14.559,4.821,19.78c3.816,3.911,9,6.034,14.317,6.034c1.779,0,3.575-0.238,5.338-0.727
				l80.725-22.361c3.322-0.92,6.35-2.683,8.79-5.119l109.573-109.367C491.279,351.032,491.279,312.968,467.884,289.572z
				 M333.776,451.768l-40.612,11.25l11.885-39.129l74.089-73.925l28.29,28.29L333.776,451.768z M439.615,346.13l-3.875,3.867
				l-28.285-28.285l3.862-3.854c7.798-7.798,20.486-7.798,28.284,0C447.399,325.656,447.399,338.344,439.615,346.13z"
                                ></path>
                                <path d="M332.459,120h-206c-11.046,0-20,8.954-20,20s8.954,20,20,20h206c11.046,0,20-8.954,20-20S343.505,120,332.459,120z"></path>
                              </g>
                            </g>
                          </g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                        </svg>
                      </span>
                    </button>

                    {/* Pop-up Modal */}
                    {popOpen && (
                      <div className="fixed inset-0 flex items-center justify-center bg-black/50 z-50">
                        <div className="pop-up-animation bg-white max-w-80 md:max-w-md max-md:p-4 md:p-6  relative ">
                          <div>
                            {/* Rating Section */}
                            <label className=" mt-4 flex items-center justify-start gap-3 mb-3">
                              <div className="font-semibold text-sm md:text-base">
                                Your rating
                              </div>
                              <div className="  flex justify-start items-center pt-2">
                                <div className="rating z-50">
                                  {Array.from({ length: 5 }, (_, index) => {
                                    const value = 5 - index; // Create ratings from 5 to 1
                                    return (
                                      <React.Fragment key={value}>
                                        <input
                                          value={value}
                                          name="rating"
                                          id={`star${value}`}
                                          type="radio"
                                          onChange={(e) => {
                                            setRating((prevState) => {
                                              return {
                                                ...prevState,
                                                rating: e.target.value,
                                              };
                                            });
                                          }}
                                          className="hidden"
                                        />
                                        <label
                                          title={`${value} stars`}
                                          htmlFor={`star${value}`}
                                        >
                                          <svg
                                            strokeLinejoin="round"
                                            strokeLinecap="round"
                                            strokeWidth="2"
                                            stroke="#000000"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="svgOne w-5 h-5 md:w-6 md:h-6"
                                          >
                                            <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                                          </svg>
                                          <svg
                                            strokeLinejoin="round"
                                            strokeLinecap="round"
                                            strokeWidth="2"
                                            stroke="#000000"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="svgTwo "
                                          >
                                            <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                                          </svg>
                                          <div className="ombre w-6 md:w-7 "></div>
                                        </label>
                                      </React.Fragment>
                                    );
                                  })}
                                </div>
                              </div>
                            </label>

                            {/* Full Name */}
                            <div className="mb-3">
                              <label className="block text-xs md:text-sm font-semibold leading-4 text-[#212121] mb-1">
                                Heading
                              </label>
                              <input
                                type="text"
                                onChange={(e) => {
                                  setRating((prevState) => {
                                    return {
                                      ...prevState,
                                      heading: e.target.value,
                                    };
                                  });
                                }}
                                className="px-2 block w-full  py-1.5 text-[#212121] ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 hover:ring-2 hover:ring-[#eebd2d] hover:outline-none hover:ring-inset focus:ring-2 focus:ring-[#eebd2d] focus:outline-none focus:ring-inset text-xs md:text-sm sm:leading-6  "
                                placeholder="Enter heading"
                              />
                            </div>

                            {/* Desciption */}
                            <div className="mb-3">
                              <label className="block text-xs md:text-sm font-semibold leading-4 text-[#212121] mb-1">
                                Description
                              </label>
                              <textarea
                                onChange={(e) => {
                                  setRating((prevState) => {
                                    return {
                                      ...prevState,
                                      description: e.target.value,
                                    };
                                  });
                                }}
                                className="px-2 block w-full  py-1.5 text-[#212121] ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 hover:ring-2 hover:ring-[#eebd2d] hover:outline-none hover:ring-inset focus:ring-2 focus:ring-[#eebd2d] focus:outline-none focus:ring-inset text-xs md:text-sm sm:leading-6  "
                                placeholder="Write your review"
                                rows="4"
                                required
                                maxlength="130"
                              ></textarea>
                            </div>

                            {/* Accept Terms */}
                            <div className="mt-4">
                              <label className="flex items-start">
                                <div className="relative flex items-center border rounded-md mt-0.5">
                                  <input
                                    type="checkbox"
                                    id="cbx"
                                    checked={isChecked}
                                    onClick={(e) => {
                                      setIsChecked(!isChecked);
                                    }}
                                    className="peer hidden w-4 md:w-5 h-4 md:h-5"
                                  />
                                  <label
                                    htmlFor="cbx"
                                    className="check cursor-pointer relative"
                                  >
                                    <svg
                                      viewBox="0 0 18 18"
                                      className="stroke-gray-400 transition-all peer-hover:stroke-sky-500 peer-checked:stroke-sky-500"
                                    >
                                      <path
                                        d="M1,9 L1,3.5 C1,2 2,1 3.5,1 L14.5,1 C16,1 17,2 17,3.5 L17,14.5 C17,16 16,17 14.5,17 L3.5,17 C2,17 1,16 1,14.5 L1,9 Z"
                                        className="transition-all peer-checked:stroke-dashoffset-60"
                                      />
                                      <polyline
                                        points="1 9 7 14 15 4"
                                        className="transition-all peer-checked:stroke-dashoffset-42 delay-150"
                                      />
                                    </svg>
                                  </label>
                                </div>
                                <span className="pl-2 tracking-wide font-medium text-xs md:text-sm">
                                  I have read and accept the{" "}
                                  <a
                                    href=""
                                    className=" underline hover:no-underline text-[#313131] hover:text-[#808080] duration-100 ease-in-out"
                                  >
                                    Privacy Policy
                                  </a>{" "}
                                  and the{" "}
                                  <a
                                    href=""
                                    className=" underline hover:no-underline text-[#313131] hover:text-[#808080] duration-100 ease-in-out"
                                  >
                                    Terms of Use
                                  </a>
                                  .
                                </span>
                              </label>
                            </div>

                            {/* Submit Button */}
                            <button
                              onClick={() => handleRating(product)}
                              className="w-full text-sm mt-6 bg-black text-white py-2  hover:bg-gray-800"
                            >
                              Send Comment
                            </button>
                          </div>

                          {/* Close Button */}
                          <button
                            className="absolute top-6 right-6"
                            onClick={togglePopup}
                            aria-label="Close"
                          >
                            <svg
                              viewBox="0 0 512 512"
                              xmlns="http://www.w3.org/2000/svg"
                              id="fi_1828945"
                              className=" hover:fill-red-600 hover:bg-red-300 rounded-full duration-300 ease-in-out hover:scale-90  w-4 lg:w-5 h-4 lg:h-5"
                            >
                              <path d="m256 512c-141.164062 0-256-114.835938-256-256s114.835938-256 256-256 256 114.835938 256 256-114.835938 256-256 256zm0-480c-123.519531 0-224 100.480469-224 224s100.480469 224 224 224 224-100.480469 224-224-100.480469-224-224-224zm0 0"></path>
                              <path d="m176.8125 351.1875c-4.097656 0-8.195312-1.554688-11.308594-4.691406-6.25-6.25-6.25-16.382813 0-22.632813l158.398438-158.402343c6.253906-6.25 16.386718-6.25 22.636718 0s6.25 16.382812 0 22.636718l-158.402343 158.398438c-3.15625 3.136718-7.25 4.691406-11.324219 4.691406zm0 0"></path>
                              <path d="m335.1875 351.1875c-4.09375 0-8.191406-1.554688-11.304688-4.691406l-158.398437-158.378906c-6.253906-6.25-6.253906-16.382813 0-22.632813 6.25-6.253906 16.382813-6.253906 22.632813 0l158.398437 158.398437c6.253906 6.25 6.253906 16.382813 0 22.632813-3.132813 3.117187-7.230469 4.671875-11.328125 4.671875zm0 0"></path>
                            </svg>
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* Review Cards */}
              <ReviewsMarquee reviews={reviews} />
            </div>
          </div>

          <hr className="text-slate-400 my-4 " />
          {/* Section 7 */}
          <div className="relative w-full overflow-hidden my-10  py-4">
            <div className=" mx-auto  ">
              {/* Accessories Section */}
              <div className=" flex justify-center w-full">
                <div className="flex flex-col  justify-center items-center   ">
                  <p className="font-semibold text-[#212121] text-base lg:text-lg uppercase">
                    Accessories to go with it....
                  </p>
                  <div className="flex flex-wrap justify-start   h-max w-full max-lg:mt-3 lg:mt-10 ">
                    {highestPricedAccessories.map((data, index) => (
                      <div
                        key={index}
                        className="w-[50%] md:w-[33%] xl:w-[25%] my-2 flex flex-col items-center justify-end overflow-hidden max-sm:p-1 sm:p-2 lg:p-3 pt-0 group"
                      >
                        <a
                          className="w-full flex flex-col items-center justify-end"
                          href="/accessories"
                        >
                          <article className="relative   isolate overflow-hidden">
                            <div className="max-lg:p-2 lg:p-4 w-[90%] relative  group-hover:scale-[97%] group-hover:opacity-50 duration-200 ease-in-out">
                              {/* Image Section */}
                              <div
                                className={`  
                      }`}
                              >
                                <img
                                  src={data.img}
                                  alt={data.category}
                                  className="object-cover"
                                />
                              </div>
                            </div>

                            <div className="z-10">
                              <div className="z-10 text-xs md:text-sm lg:text-base font-medium uppercase text-center text-[#616161]">
                                {data.name} {data.category}
                              </div>
                              <div className="z-10 overflow-hidden text-xs lg:text-sm text-center text-gray-700 font-medium">
                                ₹{data.price}
                              </div>
                            </div>
                          </article>
                        </a>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductPage2;
