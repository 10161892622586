import React, { useState, useEffect, useRef } from "react";
import "../admin.css";
import secureLocalStorage from "react-secure-storage";
import CheckAuth from "../../components/ServiceFunctions/CheckAuth";
import ImageUploadProduct from "../../components/ImageEdits/ImageUploadProduct";

const transformCustomizeToProductLocal = (customize, productId, action) => {
  const matchingEntry = customize.find((entry) => {
    return entry.Article_no === productId;
  });
  let productLocal = null;
  if (!matchingEntry) {
    return null;
  } else {
    if (action === "edit") {
      productLocal = {
        Article_no: matchingEntry?.Article_no || "",
        Leather: matchingEntry?.Leather || {},
        Sole: matchingEntry?.Sole || {},
        Edge: matchingEntry?.Edge || [],
        Lining: matchingEntry?.Lining || {},
        Eyelets: matchingEntry?.Eyelets || [],
        Buckle: matchingEntry?.Buckle || {},
        Shoelace: matchingEntry?.Shoelace || {},
        Edit: matchingEntry?.Edit || false,
        Altered: matchingEntry?.Altered
          ? matchingEntry?.Altered
          : {
              Leather: matchingEntry?.Leather || {},
              Sole: matchingEntry?.Sole || {},
              Edge: matchingEntry?.Edge || [],
              Lining: matchingEntry?.Lining || {},
              Eyelets: matchingEntry?.Eyelets || [],
              Buckle: matchingEntry?.Buckle || {},
              Shoelace: matchingEntry?.Shoelace || {},
            },
      };
    } else {
      productLocal = {
        Article_no: matchingEntry?.Article_no || "",
        Leather: matchingEntry?.Leather || {},
        Sole: matchingEntry?.Sole || {},
        Edge: matchingEntry?.Edge || [],
        Lining: matchingEntry?.Lining || {},
        Eyelets: matchingEntry?.Eyelets || [],
        Buckle: matchingEntry?.Buckle || {},
        Shoelace: matchingEntry?.Shoelace || {},
        Edit: matchingEntry?.Edit || false,
      };
    }
  }
  return productLocal;
};

const Images = ({ onNext, style, ProductId, subCategory, action }) => {
  const { email, type, phone } = CheckAuth();
  const [isPopupOpen, setIsPopupOpen] = useState(-1);
  const storedData = secureLocalStorage.getItem("kycData");
  const kycData = JSON.parse(storedData);
  const storedData2 = secureLocalStorage.getItem("Customize");
  let Customize = JSON.parse(storedData2);
  let storedData3 = secureLocalStorage.getItem("Catalogue");
  const Catalogue = JSON.parse(storedData3);
  let storedData4 = secureLocalStorage.getItem("Material_cost");
  const Material_cost = JSON.parse(storedData4);
  const [isOpen, setIsOpen] = useState(false);
  const [reason, setReason] = useState("");
  const [resendPhoneTimer, setResendPhoneTimer] = useState(90);
  const [otp, setOtp] = useState();
  const [userOtp, setUserOtp] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const statusRef = useRef(null);
  const [isOpenStatus, setIsOpenStatus] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const dropdownRef = useRef(null);
  const [product, setProduct] = useState({
    Article_no: ProductId,
    images: [
      {
        color_sorting: "",
        images: { Main: "", Back: "", Front: "", Side1: "", Side2: "" },
        Leather: "",
        color_leather: "",
        Finish: "",
        Sole: "",
        Sole_thickness: "",
        Construction: "",
        Welt: "",
        Edge: "",
        Eyelets: "",
        Lining: "",
        Shoelace: "",
        Buckles: "",
        Deleted: 0,
      },
    ],
  });
  const [showPopup, setShowPopup] = useState(false);
  const [ProductLocal, setProductLocal] = useState({});
  const colors = [
    { name: "Black", hex: "#1D1D1D" },
    { name: "Blue", hex: "#1B2191" },
    { name: "Brown", hex: "#964B00" },
    { name: "Burgundy", hex: "#800020" },
    { name: "Tan", hex: "#D5794B" },
    { name: "Gray", hex: "#808080" },
  ];

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (statusRef.current && !statusRef.current.contains(event.target)) {
        setIsOpenStatus(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const transformCatalogueToProduct = (catalogue, productId) => {
    const matchingEntry = catalogue.filter((entry) => {
      return entry.Article_no === productId;
    });
    let productMap = {
      Article_no: productId,
      images: [],
    };
    if (matchingEntry.length <= 0) {
      productMap.images.push({
        color_sorting: "",
        images: { Main: "", Back: "", Front: "", Side1: "", Side2: "" },
        Leather: "",
        color_leather: "",
        Finish: "",
        Sole: "",
        Sole_thickness: "",
        Construction: "",
        Welt: "",
        Edge: "",
        Eyelets: "",
        Lining: "",
        Shoelace: "",
        Buckles: "",
        Deleted: 0,
      });
    } else {
      matchingEntry.forEach((entry) => {
        if (entry.Status !== "" || type === "admin") {
          productMap.images.push({
            images: entry.images,
            color_sorting: entry.color_sorting,
            Leather: entry.Leather,
            color_leather: entry.color_leather,
            Finish: entry.Finish,
            Sole: entry.Sole,
            Sole_thickness: entry.Sole_thickness,
            Construction: entry.Construction,
            Welt: entry.Welt,
            Edge: entry.Edge,
            Eyelets: entry.Eyelets,
            Lining: entry.Lining,
            Shoelace: entry.Shoelace,
            Buckles: entry.Buckles,
            Deleted: 0,
          });
        }
      });
    }
    return productMap;
  };

  useEffect(() => {
    setProductLocal(
      transformCustomizeToProductLocal(Customize, ProductId, action)
    );
    setProduct(transformCatalogueToProduct(Catalogue, ProductId));
  }, [style, subCategory, ProductId]);

  useEffect(() => {}, [type]);

  // Handle adding more sections
  const handleAddMore = () => {
    setProduct((prevState) => {
      const newImageSection = {
        color_sorting: "",
        images: { Main: "", Back: "", Front: "", Side1: "", Side2: "" },
        Leather: "",
        color_leather: "",
        Finish: "",
        Sole: "",
        Sole_thickness: "",
        Construction: "",
        Welt: "",
        Edge: "",
        Eyelets: "",
        Lining: "",
        Shoelace: "",
        Buckles: "",
        Deleted: 0,
      };
      return { ...prevState, images: [...prevState.images, newImageSection] };
    });
  };

  // Handle removing a section
  const handleRemove = (index) => {
    setProduct((prevState) => {
      const updatedImages = [...prevState.images];
      updatedImages.splice(index, 1);
      return { ...prevState, images: updatedImages };
    });
  };

  // Handle image uploads
  const handleImageUpload = (e, position, index) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProduct((prevState) => {
          const updatedImages = [...prevState.images];
          updatedImages[index].images[position] = reader.result;
          return { ...prevState, images: updatedImages };
        });
      };
      reader.readAsDataURL(file);
    }
  };

  // Filter colors based on search query
  const filteredColors = colors.filter((color) =>
    color.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Handle color selection
  const handleColorSelect = (color, index) => {
    setProduct((prevState) => {
      const updatedImages = [...prevState.images];
      updatedImages[index] = {
        ...updatedImages[index],
        color_sorting: `${color.name}${color.hex}`,
      };
      return { ...prevState, images: updatedImages };
    });
    setIsOpen(null);
  };

  function getColor(key, materialCost) {
    const [leatherType, leatherCode] = key.split("*"); // Split key into type and code
    const leatherData = materialCost.find((item) => item.item === "Leathers")
      ?.Leathers[leatherType]?.[leatherCode];
    if (leatherData) return leatherData.Color;
    else return "Na";
  }

  function getImg(leatherType, leatherCode, materialCost) {
    const leatherData = materialCost.find((item) => {
      if (item.item === "Leathers") return true;
    })?.Leathers[leatherType];

    if (leatherData) {
      for (const key in leatherData) {
        if (leatherData[key].Color === leatherCode) {
          return leatherData[key].Img;
        }
      }
    }

    return "Na";
  }

  const dropdownData = [
    {
      title: "Type of Leather",
      category: "Leather",
      options:
        action !== "edit"
          ? Object.entries(ProductLocal?.Leather || {})
              .filter(([key]) => key !== "Consumption")
              .map(([key]) => key.replace(/_/g, " "))
          : Object.entries(ProductLocal?.Altered?.Leather || {})
              .filter(([key]) => key !== "Consumption")
              .map(([key]) => key.replace(/_/g, " ")),
    },
    {
      title: "Leather Color",
      category: "Leather",
      options:
        action !== "edit"
          ? ProductLocal?.Leather?.[
              product?.images?.[isPopupOpen]?.Leather
            ]?.Colors?.map((key) =>
              getColor(
                `${product?.images?.[isPopupOpen]?.Leather}*${key}`,
                Material_cost
              ).replace(/_/g, " ")
            )
          : ProductLocal?.Altered?.Leather?.[
              product?.images?.[isPopupOpen]?.Leather
            ]?.Colors?.map((key) =>
              getColor(
                `${product?.images?.[isPopupOpen]?.Leather}*${key}`,
                Material_cost
              ).replace(/_/g, " ")
            ),
    },
    {
      title: "Finishing",
      category: "Leather",
      options:
        action !== "edit"
          ? ProductLocal?.Leather?.[
              product?.images?.[isPopupOpen]?.Leather
            ]?.Finishings.map((key) => key.replace(/_/g, " "))
          : ProductLocal?.Altered?.Leather?.[
              product?.images?.[isPopupOpen]?.Leather
            ]?.Finishings.map((key) => key.replace(/_/g, " ")),
    },
    {
      title: "Type of Sole",
      category: "Sole",
      options:
        action !== "edit"
          ? Object.entries(ProductLocal?.Sole || {}).map(([key]) =>
              key.replace(/_/g, " ")
            )
          : Object.entries(ProductLocal?.Altered?.Sole || {}).map(([key]) =>
              key.replace(/_/g, " ")
            ),
    },
    {
      title: "Construction",
      category: "Sole",
      options:
        action !== "edit"
          ? Object.entries(
              ProductLocal?.Sole?.[
                product?.images?.[isPopupOpen]?.Sole.replaceAll(" ", "_")
              ]?.Constructions || {}
            ).flatMap(([key, val]) => {
              if (key === "Goodyear") {
                return val.map((data) => `${key}-${data}`.replace(/_/g, " "));
              } else {
                return `${key}-NA`.replace(/_/g, " ");
              }
            })
          : Object.entries(
              ProductLocal?.Altered?.Sole?.[
                product?.images?.[isPopupOpen]?.Sole.replaceAll(" ", "_")
              ]?.Constructions || {}
            ).flatMap(([key, val]) => {
              if (key === "Goodyear") {
                return val.map((data) => `${key}-${data}`.replace(/_/g, " "));
              } else {
                return `${key}-NA`.replace(/_/g, " ");
              }
            }),
    },
    {
      title: "Sole Thickness",
      category: "Sole",
      options:
        action !== "edit"
          ? ProductLocal?.Sole?.[
              product?.images?.[isPopupOpen]?.Sole.replaceAll(" ", "_")
            ]?.Thickness.map((key) => key.replace(/_/g, " "))
          : ProductLocal?.Altered?.Sole?.[
              product?.images?.[isPopupOpen]?.Sole.replaceAll(" ", "_")
            ]?.Thickness.map((key) => key.replace(/_/g, " ")),
    },
    {
      title: "Edge Type",
      category: "Others",
      options:
        action !== "edit"
          ? ProductLocal?.Edge?.map((key) => key.replace(/_/g, " "))
          : ProductLocal?.Altered?.Edge?.map((key) => key.replace(/_/g, " ")),
    },
    {
      title: "Eyelets",
      category: "Others",
      options:
        action !== "edit"
          ? ProductLocal?.Eyelets?.map((key) => key.replace(/_/g, " "))
          : ProductLocal?.Altered?.Eyelets?.map((key) =>
              key.replace(/_/g, " ")
            ),
    },
    {
      title: "Lining Colors",
      category: "Others",
      options:
        action !== "edit"
          ? Object.entries(ProductLocal?.Lining || {}).flatMap(([key]) =>
              `${key}`.replace(/_/g, " ")
            )
          : Object.entries(ProductLocal?.Altered?.Lining || {}).flatMap(
              ([key]) => `${key}`.replace(/_/g, " ")
            ),
    },
    {
      title: "Shoelaces",
      category: "Others",
      options:
        action !== "edit"
          ? Object.entries(ProductLocal?.Shoelace || {}).flatMap(([key]) =>
              `${key}`.replace(/_/g, " ")
            )
          : Object.entries(ProductLocal?.Altered?.Shoelace || {}).flatMap(
              ([key]) => `${key}`.replace(/_/g, " ")
            ),
    },
    {
      title: "Buckles",
      category: "Others",
      options:
        action !== "edit"
          ? Object.entries(ProductLocal?.Buckle || {}).flatMap(([key]) =>
              `${key}`.replace(/_/g, " ")
            )
          : Object.entries(ProductLocal?.Altered?.Buckle || {}).flatMap(
              ([key]) => `${key}`.replace(/_/g, " ")
            ),
    },
  ]?.filter((entry) => {
    if (ProductLocal?.Eyelets?.length > 0) {
      return entry.title !== "Buckles";
    } else {
      return entry.title !== "Shoelaces" && entry.title !== "Eyelets";
    }
  });

  const [openLeatherDropdowns, setOpenLeatherDropdowns] = useState(
    Array(
      dropdownData.filter((item) => item.category === "Leather").length
    ).fill(false)
  );
  const [openSoleDropdowns, setOpenSoleDropdowns] = useState(
    Array(dropdownData.filter((item) => item.category === "Sole").length).fill(
      false
    )
  );
  const [openOthersDropdowns, setOpenOthersDropdowns] = useState(
    Array(
      dropdownData.filter((item) => item.category === "Others").length
    ).fill(false)
  );

  const dropdownRefs = useRef([]);

  // Toggle dropdown for Leather category
  const toggleOpenLeatherDropdown = (index) => {
    setOpenLeatherDropdowns((prev) =>
      prev.map((isOpen, i) => (i === index ? !isOpen : false))
    );
  };

  // Toggle dropdown for Sole category
  const toggleOpenSoleDropdown = (index) => {
    setOpenSoleDropdowns((prev) =>
      prev.map((isOpen, i) => (i === index ? !isOpen : false))
    );
  };

  // Toggle dropdown for Others category
  const toggleOpenOthersDropdown = (index) => {
    setOpenOthersDropdowns((prev) =>
      prev.map((isOpen, i) => (i === index ? !isOpen : false))
    );
  };

  // Handle option selection
  const handleOptionClick = (index, option, item) => {
    setProduct((prevState) => {
      const updatedImages = [...prevState.images];
      if (item === "Construction") {
        updatedImages[index] = {
          ...updatedImages[index],
          [item]: option.split("-")[0],
          Welt: option.split("-")[1],
        };
      } else {
        updatedImages[index] = {
          ...updatedImages[index],
          [item]: option,
        };
      }
      return { ...prevState, images: updatedImages };
    });
    setOpenLeatherDropdowns(
      Array(
        dropdownData.filter((item) => item.category === "Leather").length
      ).fill(false)
    );
    setOpenSoleDropdowns(
      Array(
        dropdownData.filter((item) => item.category === "Sole").length
      ).fill(false)
    );
    setOpenOthersDropdowns(
      Array(
        dropdownData.filter((item) => item.category === "Others").length
      ).fill(false)
    );
  };

  const handleProductImagesSave = () => {
    const isValid = validateProduct(product);
    if (isValid) {
      fetch(`https://buwchlifestyles.com/api/save-images-in-s3/`, {
        method: "POST",
        body: JSON.stringify({
          material: "Catalogue",
          data: product,
          index: isPopupOpen,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to fetch data");
          }
          return response.json();
        })
        .then((data) => {
          if (data.status === "success") {
            setProduct(data.data);
            const index = Catalogue.findIndex(
              (item) =>
                item.Article_no === data.data.Article_no &&
                item.color === data.color &&
                email === item.email
            );

            if (index !== -1) {
              Catalogue[index] = data.data.images[data.index];
              Catalogue[index].Status = "Pending";
            } else {
              Catalogue.push({
                ...data.data.images[data.index],
                Article_no: data.data.Article_no,
                email: email,
                Listed_by: kycData?.username,
                Status: "Pending",
              });
            }
            secureLocalStorage.setItem("Catalogue", JSON.stringify(Catalogue));
            setIsPopupOpen(-1);
          }
        })
        .catch((error) => {
          console.error("Error: ", error);
        });
    } else alert("Please check all the required fields.");
  };

  const handleResendPhoneTimer = () => {
    setResendPhoneTimer(90);
    const timerInterval = setInterval(() => {
      setResendPhoneTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    setTimeout(() => {
      clearInterval(timerInterval);
    }, 90000);
  };

  const showResendCountdown = resendPhoneTimer > 0 && resendPhoneTimer < 90;

  const sendPhoneOtp = () => {
    const isValid = validateProduct(product);
    if (isValid) {
      fetch(`https://buwchlifestyles.com/api/register/send-phone-otp/`, {
        method: "POST",
        body: JSON.stringify({
          phone: phone,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to send OTP");
          }
          return response.json();
        })
        .then((response) => {
          setOtp(response.otp);
          handleResendPhoneTimer();
          setProductLocal((prev) => {
            const updated = { ...prev };
            updated.Edit = false;
            return updated;
          });
          setShowPopup(true);
        })
        .catch((error) => {
          console.error("Error: ", error);
        });
    } else alert("Please check all the required fields.");
  };

  const handleContinue = () => {
    const index = Customize.findIndex(
      (item) => item.Article_no === ProductLocal.Article_no
    );

    if (index !== -1) {
      Customize[index].Edit = ProductLocal.Edit;
      secureLocalStorage.setItem("Customize", JSON.stringify(Customize));
    }
    if (
      (product.Reason && product.Status) ||
      product.Status === "Approved" ||
      type !== "admin"
    ) {
      if (otp === userOtp || type !== "admin") {
        fetch(`https://buwchlifestyles.com/api/list-product/`, {
          method: "POST",
          body: JSON.stringify({
            customize_data: ProductLocal,
            catalogue_data: product,
            email: email,
          }),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("Failed to fetch data");
            }
            return response.json();
          })
          .then((data) => {
            secureLocalStorage.setItem(
              "Catalogue",
              JSON.stringify(data.catalogue)
            );
            Customize =
              JSON.parse(secureLocalStorage.getItem("Customize")) || [];
            data.customize.forEach((item) => {
              let existingItemIndex2 = Customize.findIndex((customItem) => {
                return customItem.Article_no === ProductLocal.Article_no;
              });
              if (existingItemIndex2 !== -1) {
                Customize[existingItemIndex2] = data.article_data;
              }
              let existingItemIndex = Customize.findIndex(
                (customItem) => customItem.Article_no === item.Article_no
              );
              if (
                existingItemIndex !== -1 &&
                Customize[existingItemIndex].Edit === true
              ) {
                return;
              }
              if (existingItemIndex !== -1) {
                Customize[existingItemIndex] = item;
              } else {
                Customize.push(item);
              }
            });
            secureLocalStorage.setItem("Customize", JSON.stringify(Customize));
            setProduct({
              style: style,
              category: subCategory,
              images: [
                {
                  color_sorting: "",
                  images: {
                    Main: "",
                    Back: "",
                    Front: "",
                    Side1: "",
                    Side2: "",
                  },
                  Leather: "",
                  color_leather: "",
                  Finish: "",
                  Sole: "",
                  Sole_thickness: "",
                  Construction: "",
                  Welt: "",
                  Edge: "",
                  Eyelets: "",
                  Lining: "",
                  Shoelace: "",
                  Buckles: "",
                  Deleted: 0,
                },
              ],
            });
            alert(data.message);
            setShowPopup(false);
            onNext("home");
          })
          .catch((error) => {
            console.error("Error: ", error);
          });
      } else {
        alert("Otp is incorrect.");
      }
    } else {
      alert("please enter the status and reason.");
    }
  };

  const validateProduct = (product) => {
    const validateImageKeys = (images) => {
      if (!images.Main) {
        return false;
      }
      return true;
    };

    const validateColorObject = (colorObj) => {
      // List of required keys for each color object
      const requiredKeys =
        type !== "admin"
          ? [
              "color_leather",
              "Leather",
              "Finish",
              "Sole",
              "Sole_thickness",
              "Construction",
              "Welt",
              "Edge",
              "Lining",
            ]
          : [
              "color_leather",
              "color_sorting",
              "Leather",
              "Finish",
              "Sole",
              "Sole_thickness",
              "Construction",
              "Welt",
              "Edge",
              "Lining",
            ];
      // Check each required key for non-emptiness
      for (let key of requiredKeys) {
        if (!colorObj[key]) {
          return false;
        }
      }
      // Check if either "Shoelace" or "Buckles" is non-empty
      if (!colorObj.Shoelace && !colorObj.Eyelets && !colorObj.Buckles) {
        return false;
      }
      // Validate "images" object
      if (!validateImageKeys(colorObj.images)) {
        return false;
      }
      return true;
    };
    // Check top-level keys
    if (!Array.isArray(product.images)) {
      return false;
    }

    // Validate each color object in "images" array
    for (let colorObj of product.images) {
      if (!validateColorObject(colorObj)) {
        return false;
      }
    }
    return true;
  };

  const statuses = [
    { status: "Pending", color: "#FFA500" }, // Orange
    { status: "Approved", color: "#008000" }, // Green
    { status: "Rejected", color: "#FF0000" }, // Red
    { status: "Unlisted", color: "#808080" }, // Gray
  ];

  const [isImagePopupOpen, setIsImagePopupOpen] = useState(-1);
  const [currentImageSlot, setCurrentImageSlot] = useState("Main");
  const imageSlots = ["Main", "Front", "Back", "Side1", "Side2"];

  const handleImagePopupOpen = (slot, index) => {
    setCurrentImageSlot(slot);
    setIsImagePopupOpen(index);
  };

  let faults = {};

  const handleDelete = () => {
    let faults = {};
    Object.entries(product?.images || {}).map(([key, value], index) => {
      const isMissingInAltered = [];
      for (const [k, v] of Object.entries(value)) {
        const alteredData = ProductLocal?.Altered || {};

        if (k === "Leather") {
          if (v !== "") {
            const alteredLeatherKeys = Object.keys(alteredData.Leather || {});
            if (!alteredLeatherKeys.includes(v.replace(/ /g, "_"))) {
              isMissingInAltered.push({ key: k, value: false });
            }
          }
        } else if (k === "Finish") {
          if (v !== "") {
            const alteredFinishKeys = Object.values(alteredData.Leather || {});
            if (
              !alteredFinishKeys.some((item) =>
                item?.Finishings?.includes(v.replace(/ /g, "_"))
              )
            ) {
              isMissingInAltered.push({ key: k, value: false });
            }
          }
        } else if (k === "Sole") {
          if (v !== "") {
            const alteredSoleKeys = Object.keys(alteredData.Sole || {});
            if (!alteredSoleKeys.includes(v.replace(/ /g, "_"))) {
              isMissingInAltered.push({ key: k, value: false });
            }
          }
        } else if (k === "Construction") {
          if (v !== "") {
            const alteredConstructionKey = Object.values(
              alteredData.Sole || {}
            );
            if (
              !alteredConstructionKey.some((item) =>
                Object.keys(item?.Constructions)?.includes(v.replace(/ /g, "_"))
              )
            ) {
              isMissingInAltered.push({ key: k, value: false });
            }
          }
        } else if (k === "Welt") {
          if (v !== "" && v !== "NA") {
            const alteredConstructionKeys = Object.values(
              alteredData.Sole || {}
            );
            if (
              !alteredConstructionKeys.some((item) =>
                Object.values(item?.Constructions || {})
                  .flat()
                  .includes(v.replace(/ /g, "_"))
              )
            ) {
              isMissingInAltered.push({ key: k, value: false });
            }
          }
        } else if (k === "Sole_thickness") {
          if (v !== "") {
            const alteredThicknessKey = Object.values(alteredData.Sole || {});
            if (
              !alteredThicknessKey.some((item) =>
                item?.Thickness?.includes(v.replace(/ /g, "_"))
              )
            ) {
              isMissingInAltered.push({ key: k, value: false });
            }
          }
        } else if (k === "Edge") {
          if (v !== "") {
            const alteredEdgeKeys = alteredData.Edge || [];
            if (!alteredEdgeKeys.includes(v.replace(/ /g, "_"))) {
              isMissingInAltered.push({ key: k, value: false });
            }
          }
        } else if (k === "Lining") {
          if (v !== "") {
            const alteredLiningKeys = Object.keys(alteredData.Lining || {});
            if (!alteredLiningKeys.includes(v.replace(/ /g, "_"))) {
              isMissingInAltered.push({ key: k, value: false });
            }
          }
        } else if (k === "Buckles") {
          if (v !== "") {
            const alteredBucklesKeys = Object.keys(alteredData.Buckle || {});
            if (!alteredBucklesKeys.includes(v.replace(/ /g, "_"))) {
              isMissingInAltered.push({ key: k, value: false });
            }
          }
        } else if (k === "Eyelets") {
          if (v !== "") {
            const alteredEyeletsKeys = alteredData.Eyelets || [];
            if (!alteredEyeletsKeys.includes(v.replace(/ /g, "_"))) {
              isMissingInAltered.push({ key: k, value: false });
            }
          }
        } else if (k === "Shoelace") {
          if (v !== "") {
            const alteredShoelaceKeys = Object.keys(alteredData.Shoelace || {});
            if (!alteredShoelaceKeys.includes(v.replace(/ /g, "_"))) {
              isMissingInAltered.push({ key: k, value: false });
            }
          }
        }
      }
      if (value.Deleted !== 1) faults[index] = isMissingInAltered;
    });
    let run = true;
    for (const data of Object.values(faults)) {
      if (data.length > 0) {
        run = false;
        break;
      }
    }
    if (!run && action === "edit") alert("Delete the Invalid Listing.");
    else {
      if (type === "admin") sendPhoneOtp();
      else {
        const isValid = validateProduct(product);
        if (isValid) {
          setProductLocal((prev) => {
            const updated = { ...prev };
            updated.Edit = false;
            return updated;
          });
          setShowPopup(true);
        } else alert("Please check all the required fields.");
      }
    }
  };

  return (
    <div className="bg-white max-md:p-2 md:p-4 2xl:p-6 rounded-lg  ">
      <div className=" flex flex-wrap max-2xl:gap-x-6 2xl:gap-x-12 gap-y-4 mt-3">
        {Object.entries(product?.images || {}).map(([key, value], index) => {
          const isMissingInAltered = [];
          for (const [k, v] of Object.entries(value)) {
            const alteredData = ProductLocal?.Altered || {};

            if (k === "Leather") {
              if (v !== "") {
                const alteredLeatherKeys = Object.keys(
                  alteredData.Leather || {}
                );
                if (!alteredLeatherKeys.includes(v.replace(/ /g, "_"))) {
                  isMissingInAltered.push({ key: k, value: false });
                }
              }
            } else if (k === "Finish") {
              if (v !== "") {
                const alteredFinishKeys = Object.values(
                  alteredData.Leather || {}
                );
                if (
                  !alteredFinishKeys.some((item) =>
                    item?.Finishings?.includes(v.replace(/ /g, "_"))
                  )
                ) {
                  isMissingInAltered.push({ key: k, value: false });
                }
              }
            } else if (k === "Sole") {
              if (v !== "") {
                const alteredSoleKeys = Object.keys(alteredData.Sole || {});
                if (!alteredSoleKeys.includes(v.replace(/ /g, "_"))) {
                  isMissingInAltered.push({ key: k, value: false });
                }
              }
            } else if (k === "Construction") {
              if (v !== "") {
                const alteredConstructionKey = Object.values(
                  alteredData.Sole || {}
                );
                if (
                  !alteredConstructionKey.some((item) =>
                    Object.keys(item?.Constructions)?.includes(
                      v.replace(/ /g, "_")
                    )
                  )
                ) {
                  isMissingInAltered.push({ key: k, value: false });
                }
              }
            } else if (k === "Welt") {
              if (v !== "" && v !== "NA") {
                const alteredConstructionKeys = Object.values(
                  alteredData.Sole || {}
                );
                if (
                  !alteredConstructionKeys.some((item) =>
                    Object.values(item?.Constructions || {})
                      .flat()
                      .includes(v.replace(/ /g, "_"))
                  )
                ) {
                  isMissingInAltered.push({ key: k, value: false });
                }
              }
            } else if (k === "Sole_thickness") {
              if (v !== "") {
                const alteredThicknessKey = Object.values(
                  alteredData.Sole || {}
                );
                if (
                  !alteredThicknessKey.some((item) =>
                    item?.Thickness?.includes(v.replace(/ /g, "_"))
                  )
                ) {
                  isMissingInAltered.push({ key: k, value: false });
                }
              }
            } else if (k === "Edge") {
              if (v !== "") {
                const alteredEdgeKeys = alteredData.Edge || [];
                if (!alteredEdgeKeys.includes(v.replace(/ /g, "_"))) {
                  isMissingInAltered.push({ key: k, value: false });
                }
              }
            } else if (k === "Lining") {
              if (v !== "") {
                const alteredLiningKeys = Object.keys(alteredData.Lining || {});
                if (!alteredLiningKeys.includes(v.replace(/ /g, "_"))) {
                  isMissingInAltered.push({ key: k, value: false });
                }
              }
            } else if (k === "Buckles") {
              if (v !== "") {
                const alteredBucklesKeys = Object.keys(
                  alteredData.Buckle || {}
                );
                if (!alteredBucklesKeys.includes(v.replace(/ /g, "_"))) {
                  isMissingInAltered.push({ key: k, value: false });
                }
              }
            } else if (k === "Eyelets") {
              if (v !== "") {
                const alteredEyeletsKeys = alteredData.Eyelets || [];
                if (!alteredEyeletsKeys.includes(v.replace(/ /g, "_"))) {
                  isMissingInAltered.push({ key: k, value: false });
                }
              }
            } else if (k === "Shoelace") {
              if (v !== "") {
                const alteredShoelaceKeys = Object.keys(
                  alteredData.Shoelace || {}
                );
                if (!alteredShoelaceKeys.includes(v.replace(/ /g, "_"))) {
                  isMissingInAltered.push({ key: k, value: false });
                }
              }
            }
          }
          faults[index] = isMissingInAltered;
          if (value?.Deleted !== 1) {
            return (
              <div className="mb-2 relative" key={index}>
                {Object.keys(product.images).length > 1 && (
                  <>
                    <button className="absolute top-2 right-2 z-10">
                      <img
                        onClick={() => {
                          if (
                            isMissingInAltered.length > 0 &&
                            action === "edit"
                          ) {
                            setProduct((prevState) => {
                              const updatedImages = [...prevState.images];
                              updatedImages[index] = {
                                ...updatedImages[index],
                                Deleted: 1,
                              };
                              return { ...prevState, images: updatedImages };
                            });
                          } else {
                            handleRemove(index);
                          }
                        }}
                        src="/images/delete.svg"
                        alt=""
                        className={`w-7 h-7 border-[1px]  hover:border-red-600 hover:bg-red-200 duration-200 ease-in-out rounded-full p-1 ${
                          isMissingInAltered.length > 0 && action === "edit"
                            ? "border-red-600 bg-red-200"
                            : "border-gray-800 bg-slate-50"
                        }`}
                      />
                    </button>
                  </>
                )}
                {type === "admin" && (
                  <div className="flex gap-2 items-center">
                    <div
                      className="relative w-24 md:w-36 group"
                      ref={dropdownRef}
                    >
                      <button
                        onClick={() =>
                          setIsOpen(isOpen === index ? null : index)
                        }
                        className="w-full px-2 py-2 mt-2 text-center text-xs md:text-sm  rounded-lg ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 group-hover:ring-2 group-hover:ring-[#eebd2d] group-hover:outline-none group-hover:ring-inset focus:ring-2 focus:ring-[#eebd2d] focus:outline-none focus:ring-inset  sm:leading-6  font-semibold"
                      >
                        {value.color_sorting ? (
                          <span className="flex items-center gap-2 ">
                            <span
                              className="w-4 h-4 rounded-full text-xs md:text-sm"
                              style={{
                                backgroundColor: `#${
                                  value.color_sorting.split("#")[1]
                                }`,
                              }}
                            ></span>
                            {value.color_sorting.split("#")[0]}
                          </span>
                        ) : (
                          "Select Color"
                        )}
                      </button>
                      {isOpen === index && (
                        <div className="absolute z-10 w-36   md:w-full bg-white border border-gray-300 rounded-sm shadow-lg mt-1 overflow-hidden">
                          <input
                            type="text"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            placeholder="Search color..."
                            className="w-full max-md:px-2 md:px-4 py-2 border-b border-gray-300 focus:outline-none text-xs md:text-sm"
                          />
                          <ul className="max-h-48 overflow-y-auto">
                            {colors
                              .filter((color) =>
                                color.name
                                  .toLowerCase()
                                  .includes(searchQuery.toLowerCase())
                              )
                              .map((color) => (
                                <li
                                  onClick={() =>
                                    handleColorSelect(color, index)
                                  }
                                  className="flex text-xs md:text-sm items-center gap-2 max-md:px-2 md:px-4 py-2 cursor-pointer hover:bg-gray-100"
                                >
                                  <span
                                    className="w-4 h-4 rounded-full"
                                    style={{ backgroundColor: color.hex }}
                                  ></span>
                                  {color.name}
                                </li>
                              ))}
                            {filteredColors.length === 0 && (
                              <li className="px-4 py-2 text-gray-500 text-xs md:text-sm">
                                No results found
                              </li>
                            )}
                          </ul>
                        </div>
                      )}
                    </div>
                    {/*  */}
                  </div>
                )}

                <div className="flex justify-start items-start mt-2">
                  <div className="flex flex-col">
                    <div>
                      <div
                        className={`cursor-pointer w-36 h-36 md:w-60 md:h-60 rounded-sm flex overflow-hidden items-center justify-center  duration-200 ease-in-out group ${
                          action === "edit"
                            ? isMissingInAltered.length > 0
                              ? "border-[2px] border-red-500 hover:border-red-600"
                              : "border-[1px] border-gray-300 hover:border-gray-400"
                            : "border-[1px] border-gray-300 hover:border-gray-400"
                        }`}
                        onClick={() => handleImagePopupOpen("Main", index)}
                      >
                        {value?.images?.Main ? (
                          <img
                            src={value?.images?.Main}
                            alt="Main preview"
                            className="w-full h-full object-cover"
                          />
                        ) : (
                          <span className="pencil-icon flex flex-col items-center justify-center text-[#313131] opacity-[65%] group-hover:opacity-[100%] duration-200 ease-in-out">
                            <img
                              src="/images/addimage.svg"
                              alt=""
                              className="w-7 h-7"
                            />
                            <span className="text-sm font-semibold">
                              Main Image
                            </span>
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="ml-4">
                    <div className="flex items-center flex-wrap w-36 md:w-60 justify-between gap-y-4">
                      {imageSlots.slice(1).map((slot) => (
                        <div
                          key={slot}
                          className={`cursor-pointer rounded-sm relative overflow-hidden w-16 h-16 md:w-28 md:h-28 flex items-center justify-center border-[1px] border-gray-300 hover:border-gray-400 duration-200 ease-in-out group ${
                            action === "edit"
                              ? isMissingInAltered.length > 0
                                ? "border-[2px] border-red-500 hover:border-red-600"
                                : "border-[1px] border-gray-300 hover:border-gray-400"
                              : "border-[1px] border-gray-300 hover:border-gray-400"
                          }`}
                          onClick={() => handleImagePopupOpen(slot, index)}
                        >
                          {value?.images?.[slot] ? (
                            <img
                              src={value?.images?.[slot]}
                              alt={`${slot} preview`}
                              className="w-full h-full object-cover"
                            />
                          ) : (
                            <span className="pencil-icon flex flex-col items-center justify-center text-[#313131] opacity-[65%] group-hover:opacity-[100%] duration-200 ease-in-out">
                              <img
                                src="/images/addimage.svg"
                                alt=""
                                className="w-5 h-5"
                              />
                              <span className="text-[9px] md:text-xs font-medium">
                                {slot} Image
                              </span>
                            </span>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                  {isImagePopupOpen !== -1 && (
                    <ImageUploadProduct
                      data={product?.images?.[isImagePopupOpen] || {}}
                      currentImageSlot={currentImageSlot}
                      handlePreviousSlot={() => {
                        const currentIndex =
                          imageSlots.indexOf(currentImageSlot);
                        setCurrentImageSlot(
                          imageSlots[
                            (currentIndex - 1 + imageSlots.length) %
                              imageSlots.length
                          ]
                        );
                      }}
                      handleNextSlot={() => {
                        const currentIndex =
                          imageSlots.indexOf(currentImageSlot);
                        setCurrentImageSlot(
                          imageSlots[(currentIndex + 1) % imageSlots.length]
                        );
                      }}
                      handleImageDelete={() => {
                        setProduct((prevState) => {
                          const updatedImages = [...prevState.images];
                          updatedImages[isImagePopupOpen].images[
                            currentImageSlot
                          ] = "";
                          return { ...prevState, images: updatedImages };
                        });
                      }}
                      handleFileChange={(e) => {
                        handleImageUpload(
                          e,
                          currentImageSlot,
                          isImagePopupOpen
                        );
                      }}
                      getImg={(img, val) => {
                        setProduct((prevState) => {
                          const updatedImages = [...prevState?.images];
                          updatedImages[isImagePopupOpen].images[
                            currentImageSlot
                          ] = img;
                          return { ...prevState, images: updatedImages };
                        });
                        if (val === "save") setIsImagePopupOpen(-1);
                      }}
                    />
                  )}
                </div>

                <div className="relative">
                  {/* Save Button */}
                  <button
                    title="Save"
                    className="mt-3 cursor-pointer flex items-center group border-[2px] fill-gray-600 hover:fill-yellow-500 border-gray-600 hover:border-yellow-500  rounded-md duration-200 p-1"
                    onClick={() => setIsPopupOpen(index)}
                  >
                    <svg
                      id="fi_5754877"
                      enableBackground="new 0 0 30 30"
                      viewBox="0 0 30 30"
                      className="w-4 h-4 lg:w-6 lg:h-6"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="_x36_4">
                        <path d="m24.875 7.3499756-3.7399902-3.5999756c-.5-.4899902-1.1599731-.75-1.8599854-.75h-12.289978c-1.4700318 0-2.670044 1.1900024-2.670044 2.6699829v18.6500244c0 1.4799805 1.2000122 2.6799927 2.670044 2.6799927h16.0299683c1.4700317 0 2.6699829-1.2000122 2.6699829-2.6799927v-15.0500488c0-.7199707-.2899781-1.4199829-.8099976-1.9199829zm-10.1199951 15.1500244c-1.5700073 0-2.8499756-1.2800293-2.8499756-2.8500366 0-1.5799561 1.2799683-2.8599854 2.8499756-2.8599854s2.8600464 1.2800293 2.8600464 2.8599854c0 1.5700073-1.2900391 2.8500366-2.8600464 2.8500366zm5.8000488-14.3300171c0 1.3099976-1.0700073 2.3699951-2.3600464 2.3699951h-6.8699951c-1.289978 0-2.3599854-1.0599976-2.3599854-2.3699951v-2.289978h10.2299805l1.3600464 1.3099976z"></path>
                      </g>
                    </svg>
                    <span className="text-xs lg:text-sm  text-gray-600 group-hover:text-yellow-500 duration-200 font-bold px-1">
                      Shoe Specs
                    </span>
                  </button>

                  {/* Popup */}
                  {isPopupOpen !== -1 && (
                    <div className="admin-popup inset-0 z-20  flex items-center justify-center">
                      <div className=" max-md:h-[500px] md:h-[600px] xl:h-max w-[90%] max-md:mx-5 md:m-20 lg:m-40  flex flex-col items-start justify-start bg-white p-10 pt-6 rounded-lg overflow-y-auto">
                        <div className="flex justify-end w-full mb-3">
                          <button
                            className=""
                            onClick={() => setIsPopupOpen(-1)}
                            aria-label="Close"
                          >
                            <svg
                              viewBox="0 0 512 512"
                              xmlns="http://www.w3.org/2000/svg"
                              id="fi_1828945"
                              className=" hover:fill-red-600 hover:bg-red-300 rounded-full duration-300 ease-in-out hover:scale-90  w-4 lg:w-6 h-4 lg:h-6"
                            >
                              <path d="m256 512c-141.164062 0-256-114.835938-256-256s114.835938-256 256-256 256 114.835938 256 256-114.835938 256-256 256zm0-480c-123.519531 0-224 100.480469-224 224s100.480469 224 224 224 224-100.480469 224-224-100.480469-224-224-224zm0 0"></path>
                              <path d="m176.8125 351.1875c-4.097656 0-8.195312-1.554688-11.308594-4.691406-6.25-6.25-6.25-16.382813 0-22.632813l158.398438-158.402343c6.253906-6.25 16.386718-6.25 22.636718 0s6.25 16.382812 0 22.636718l-158.402343 158.398438c-3.15625 3.136718-7.25 4.691406-11.324219 4.691406zm0 0"></path>
                              <path d="m335.1875 351.1875c-4.09375 0-8.191406-1.554688-11.304688-4.691406l-158.398437-158.378906c-6.253906-6.25-6.253906-16.382813 0-22.632813 6.25-6.253906 16.382813-6.253906 22.632813 0l158.398437 158.398437c6.253906 6.25 6.253906 16.382813 0 22.632813-3.132813 3.117187-7.230469 4.671875-11.328125 4.671875zm0 0"></path>
                            </svg>
                          </button>
                        </div>
                        {/* Leather Section */}
                        <div className="w-full border-[2px] border-yellow-400/50 bg-yellow-100/10 rounded-md p-3 relative mt-3">
                          <h2 className="text-base  font-semibold bg-yellow-300 rounded-lg px-2 py-0.5 absolute text-[#313131] border-4 border-yellow-100 -top-5 w-28 text-center">
                            Leather
                          </h2>
                          <div className="flex gap-y-3 gap-x-7 flex-wrap mt-4">
                            {dropdownData
                              ?.filter(
                                (dropdown) => dropdown.category === "Leather"
                              )
                              ?.map((dropdown, index) => {
                                const item =
                                  dropdown.title === "Type of Leather"
                                    ? "Leather"
                                    : dropdown.title === "Leather Color"
                                    ? "color_leather"
                                    : dropdown.title === "Finishing" &&
                                      "Finish";
                                return (
                                  <div
                                    className="relative inline-block w-64"
                                    ref={(el) =>
                                      (dropdownRefs.current[index] = el)
                                    }
                                  >
                                    <div
                                      className={`text-base font-semibold ${
                                        action === "edit"
                                          ? isMissingInAltered.some(
                                              (missingItem) =>
                                                missingItem.key === item
                                            )
                                            ? "text-red-500"
                                            : "text-[#212121]"
                                          : "text-[#212121]"
                                      }`}
                                    >
                                      {dropdown.title}:
                                    </div>
                                    <button
                                      className="w-full border-[1px] border-[#414141] bg-white text-[#414141] hover:bg-gray-100 font-semibold py-1 px-2 rounded-sm flex items-center justify-between focus:outline-none mt-1"
                                      onClick={() =>
                                        toggleOpenLeatherDropdown(index)
                                      }
                                    >
                                      <span>
                                        {dropdown.title ===
                                        "Type of Leather" ? (
                                          product?.images?.[isPopupOpen]
                                            ?.Leather || "Select an option"
                                        ) : dropdown.title ===
                                          "Leather Color" ? (
                                          <>
                                            <span className="flex gap-2 items-center">
                                              <img
                                                src={getImg(
                                                  product?.images?.[isPopupOpen]
                                                    ?.Leather,
                                                  product?.images?.[isPopupOpen]
                                                    ?.color_leather,
                                                  Material_cost
                                                )}
                                                className="w-3 h-3"
                                                alt=""
                                              />
                                              {product?.images?.[isPopupOpen]
                                                ?.color_leather ||
                                                "Select an option"}
                                            </span>
                                          </>
                                        ) : (
                                          (dropdown.title === "Finishing" &&
                                            product?.images?.[isPopupOpen]
                                              ?.Finish) ||
                                          "Select an option"
                                        )}
                                      </span>
                                      <svg
                                        className={`w-4 h-4 ml-2 transition-transform duration-200 ${
                                          openLeatherDropdowns[index]
                                            ? "transform rotate-180"
                                            : ""
                                        }`}
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth="2"
                                          d="M19 9l-7 7-7-7"
                                        />
                                      </svg>
                                    </button>

                                    {openLeatherDropdowns[index] && (
                                      <ul className="absolute w-full bg-white border-[1px] border-t-0 border-[#414141] rounded-sm z-10 transition-all duration-200 ease-out transform scale-95 opacity-100 dropdown-animation">
                                        {dropdown.options ? (
                                          dropdown.options.map((option) => (
                                            <li
                                              className="px-2 py-1 text-gray-700 hover:bg-yellow-100 hover:text-yellow-800 cursor-pointer"
                                              onClick={() => {
                                                handleOptionClick(
                                                  isPopupOpen,
                                                  option,
                                                  item
                                                );
                                              }}
                                            >
                                              {dropdown.title ===
                                              "Leather Color" ? (
                                                <>
                                                  <span className="flex gap-2 items-center">
                                                    <img
                                                      src={getImg(
                                                        product?.images?.[
                                                          isPopupOpen
                                                        ]?.Leather,
                                                        option,
                                                        Material_cost
                                                      )}
                                                      className="w-3 h-3"
                                                      alt=""
                                                    />
                                                    {option}
                                                  </span>
                                                </>
                                              ) : (
                                                <>{option}</>
                                              )}
                                            </li>
                                          ))
                                        ) : (
                                          <li className="px-2 py-1 text-gray-700 hover:bg-yellow-100 hover:text-yellow-800 cursor-pointer">
                                            No Leather Selected
                                          </li>
                                        )}
                                      </ul>
                                    )}
                                  </div>
                                );
                              })}
                          </div>
                        </div>

                        {/* Sole Section */}
                        <div className="w-full border-[2px] border-yellow-400/50 bg-yellow-100/10 rounded-md p-3 relative mt-8">
                          <h2 className="text-base  font-semibold bg-yellow-300 rounded-lg px-2 py-0.5 absolute text-[#313131] border-4 border-yellow-100 -top-5 w-28 text-center">
                            Sole
                          </h2>
                          <div className="flex gap-y-3 gap-x-7 flex-wrap mt-4">
                            {dropdownData
                              .filter(
                                (dropdown) => dropdown.category === "Sole"
                              )
                              .map((dropdown, index) => {
                                const item =
                                  dropdown.title === "Type of Sole"
                                    ? "Sole"
                                    : dropdown.title === "Construction"
                                    ? "Construction"
                                    : dropdown.title === "Sole Thickness" &&
                                      "Sole_thickness";
                                return (
                                  <div
                                    className="relative inline-block w-64"
                                    ref={(el) =>
                                      (dropdownRefs.current[index] = el)
                                    }
                                  >
                                    <div
                                      className={`text-base font-semibold ${
                                        action === "edit"
                                          ? isMissingInAltered.some(
                                              (missingItem) => {
                                                if (
                                                  missingItem.key === "Welt" &&
                                                  item === "Construction"
                                                ) {
                                                  return true;
                                                } else {
                                                  return (
                                                    missingItem.key === item
                                                  );
                                                }
                                              }
                                            )
                                            ? "text-red-500"
                                            : "text-[#212121]"
                                          : "text-[#212121]"
                                      }`}
                                    >
                                      {dropdown.title}:
                                    </div>
                                    <button
                                      className="w-full border-[1px] border-[#414141] bg-white text-[#414141] hover:bg-gray-100 font-semibold py-1 px-2 rounded-sm flex items-center justify-between focus:outline-none mt-1"
                                      onClick={() =>
                                        toggleOpenSoleDropdown(index)
                                      }
                                    >
                                      {dropdown.title === "Type of Sole"
                                        ? product?.images?.[isPopupOpen]
                                            ?.Sole || "Select an option"
                                        : dropdown.title === "Construction"
                                        ? product?.images?.[isPopupOpen]
                                            ?.Construction || "Select an option"
                                        : dropdown.title === "Sole Thickness" &&
                                          (product?.images?.[isPopupOpen]
                                            ?.Sole_thickness ||
                                            "Select an option")}
                                      <svg
                                        className={`w-4 h-4 ml-2 transition-transform duration-200 ${
                                          openSoleDropdowns[index]
                                            ? "transform rotate-180"
                                            : ""
                                        }`}
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth="2"
                                          d="M19 9l-7 7-7-7"
                                        />
                                      </svg>
                                    </button>

                                    {openSoleDropdowns[index] && (
                                      <ul className="absolute w-full bg-white border-[1px] border-t-0 border-[#414141] rounded-sm z-10 transition-all duration-200 ease-out transform scale-95 opacity-100 dropdown-animation">
                                        {dropdown.options ? (
                                          dropdown.options.map((option) => (
                                            <li
                                              className="px-2 py-1 text-gray-700 hover:bg-yellow-100 hover:text-yellow-800 cursor-pointer"
                                              onClick={() => {
                                                handleOptionClick(
                                                  isPopupOpen,
                                                  option,
                                                  item
                                                );
                                              }}
                                            >
                                              {option}
                                            </li>
                                          ))
                                        ) : (
                                          <li className="px-2 py-1 text-gray-700 hover:bg-yellow-100 hover:text-yellow-800 cursor-pointer">
                                            No Sole Selected
                                          </li>
                                        )}
                                      </ul>
                                    )}
                                  </div>
                                );
                              })}
                          </div>
                        </div>

                        {/* Others Section */}
                        <div className="w-full border-[2px] border-yellow-400/50 bg-yellow-100/10 rounded-md p-3 relative mt-8">
                          <h2 className="text-base  font-semibold bg-yellow-300 rounded-lg px-2 py-0.5 absolute text-[#313131] border-4 border-yellow-100 -top-5 w-28 text-center">
                            Trims
                          </h2>
                          <div className="flex gap-y-3 gap-x-7 flex-wrap mt-4">
                            {dropdownData
                              .filter(
                                (dropdown) => dropdown.category === "Others"
                              )
                              .map((dropdown, index) => {
                                const item =
                                  dropdown.title === "Edge Type"
                                    ? "Edge"
                                    : dropdown.title === "Lining Colors"
                                    ? "Lining"
                                    : dropdown.title === "Buckles"
                                    ? "Buckles"
                                    : dropdown.title === "Eyelets"
                                    ? "Eyelets"
                                    : dropdown.title === "Shoelaces" &&
                                      "Shoelace";
                                return (
                                  <div
                                    className="relative inline-block w-64"
                                    ref={(el) =>
                                      (dropdownRefs.current[index] = el)
                                    }
                                  >
                                    <div
                                      className={`text-base font-semibold ${
                                        action === "edit"
                                          ? isMissingInAltered.some(
                                              (missingItem) =>
                                                missingItem.key === item
                                            )
                                            ? "text-red-500"
                                            : "text-[#212121]"
                                          : "text-[#212121]"
                                      }`}
                                    >
                                      {dropdown.title}:
                                    </div>
                                    <button
                                      className="w-full border-[1px] border-[#414141] bg-white text-[#414141] hover:bg-gray-100 font-semibold py-1 px-2 rounded-sm flex items-center justify-between focus:outline-none mt-1"
                                      onClick={() =>
                                        toggleOpenOthersDropdown(index)
                                      }
                                    >
                                      {dropdown.title === "Edge Type"
                                        ? product?.images?.[isPopupOpen]
                                            ?.Edge || "Select an option"
                                        : dropdown.title === "Lining Colors"
                                        ? product?.images?.[isPopupOpen]
                                            ?.Lining || "Select an option"
                                        : dropdown.title === "Buckles"
                                        ? product?.images?.[isPopupOpen]
                                            ?.Buckles || "Select an option"
                                        : dropdown.title === "Eyelets"
                                        ? product?.images?.[isPopupOpen]
                                            ?.Eyelets || "Select an option"
                                        : dropdown.title === "Shoelaces" &&
                                          (product?.images?.[isPopupOpen]
                                            ?.Shoelace ||
                                            "Select an option")}
                                      <svg
                                        className={`w-4 h-4 ml-2 transition-transform duration-200 ${
                                          openOthersDropdowns[index]
                                            ? "transform rotate-180"
                                            : ""
                                        }`}
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth="2"
                                          d="M19 9l-7 7-7-7"
                                        />
                                      </svg>
                                    </button>

                                    {openOthersDropdowns[index] && (
                                      <ul className="absolute w-full bg-white border-[1px] border-t-0 border-[#414141] rounded-sm z-10 transition-all duration-200 ease-out transform scale-95 opacity-100 dropdown-animation">
                                        {dropdown.options.map((option) => (
                                          <li
                                            className="px-2 py-1 text-gray-700 hover:bg-yellow-100 hover:text-yellow-800 cursor-pointer"
                                            onClick={() => {
                                              handleOptionClick(
                                                isPopupOpen,
                                                option,
                                                item
                                              );
                                            }}
                                          >
                                            {option}
                                          </li>
                                        ))}
                                      </ul>
                                    )}
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                        <div className="flex justify-end w-full mt-3">
                          <button
                            className="rounded-lg relative px-4 py-1.5 cursor-pointer flex items-center border-[3px]  border-yellow-200 bg-yellow-400 hover:border-yellow-300  hover:bg-yellow-500     overflow-hidden text-white duration-300 ease-in-out text-xs md:text-sm font-semibold"
                            onClick={handleProductImagesSave}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            );
          }
        })}
        {/* Add More Colors Button */}
        <div className="flex justify-end items-end mb-2 ">
          <button
            className="rounded-lg relative w-28 md:w-36 h-10 cursor-pointer flex items-center border-[3px]  border-[#ffe4ad] bg-[#FFC94A] group hover:bg-[#FFC94A] active:bg-[#FFC94A] active:border-[#FFC94A] overflow-hidden"
            onClick={() => handleAddMore()}
          >
            <span className="text-white text-xs md:text-sm font-semibold max-md:ml-2 md:ml-4 transform group-hover:translate-x-20 transition-all duration-300">
              Add Color
            </span>
            <span className="absolute right-0 h-full w-7 md:w-10 rounded-lg bg-[#FFC94A] flex items-center justify-center transform group-hover:translate-x-0 group-hover:w-full transition-all duration-300">
              <svg
                className="svg w-4 h-4 md:w-6 md:h-6 text-white"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line x1="12" x2="12" y1="5" y2="19"></line>
                <line x1="5" x2="19" y1="12" y2="12"></line>
              </svg>
            </span>
          </button>
        </div>
      </div>
      {/* save button */}
      <div className="flex items-center justify-end w-full mt-3 gap-3">
        <button
          className="bg-white text-center w-16 rounded-lg h-9 lg:h-11 relative text-[#313131] text-xl font-semibold group border "
          type="button"
          onClick={() => {
            setProduct({
              style: style,
              category: subCategory,
              images: [
                {
                  color_sorting: "",
                  images: {
                    Main: "",
                    Back: "",
                    Front: "",
                    Side1: "",
                    Side2: "",
                  },
                  Leather: "",
                  color_leather: "",
                  Finish: "",
                  Sole: "",
                  Sole_thickness: "",
                  Construction: "",
                  Welt: "",
                  Edge: "",
                  Eyelets: "",
                  Lining: "",
                  Shoelace: "",
                  Buckles: "",
                  Deleted: 0,
                },
              ],
            });
          }}
        >
          <p className="text-xs lg:text-sm">Reset</p>
        </button>
        <button
          className="bg-white text-center w-20 lg:w-32 rounded-lg h-9 lg:h-11 relative text-[#313131] text-xl font-semibold group border "
          type="button"
          onClick={() => {
            handleDelete();
          }}
        >
          <div className="bg-yellow-400 rounded-md h-[26px] lg:h-[34px] w-1/3 flex items-center justify-center absolute left-1 top-[4px] group-hover:w-[70px] group-hover:lg:w-[118px] z-10 duration-500 ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1024 1024"
              className="rotate-180 w-4 lg:w-6 h-4 lg:h-6"
            >
              <path
                d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z"
                fill="#313131"
              ></path>
              <path
                d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z"
                fill="#313131"
              ></path>
            </svg>
          </div>
          <p className="max-lg:translate-x-4 lg:translate-x-5 text-xs lg:text-sm">
            Save
          </p>
        </button>
      </div>
      {/* Pop-up */}
      {showPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className=" w-[350px] sm:w-[450px] lg:w-[800px] bg-white flex rounded-xl h-max items-center justify-between ">
            <div className="h-full w-5/12 lg:w-2/5  rounded-l-xl overflow-hidden  flex items-center justify-center">
              <div className="bg-white h-full flex justify-center items-center  rounded-l-lg  p-2">
                <img
                  className="object-cover "
                  src="/images/boot-s.jpg "
                  alt=""
                />
              </div>
            </div>

            <div className="max-lg:p-2 lg:p-7 bg-white w-7/12 lg:w-3/5 rounded-r-xl relative border-l-4 border-yellow-400">
              <button
                className="float-right"
                onClick={() => setShowPopup(false)}
                aria-label="Close"
              >
                <svg
                  viewBox="0 0 512 512"
                  xmlns="http://www.w3.org/2000/svg"
                  id="fi_1828945"
                  className=" hover:fill-red-600 hover:bg-red-300 rounded-full duration-300 ease-in-out hover:scale-90  w-4 lg:w-5 h-4 lg:h-5"
                >
                  <path d="m256 512c-141.164062 0-256-114.835938-256-256s114.835938-256 256-256 256 114.835938 256 256-114.835938 256-256 256zm0-480c-123.519531 0-224 100.480469-224 224s100.480469 224 224 224 224-100.480469 224-224-100.480469-224-224-224zm0 0"></path>
                  <path d="m176.8125 351.1875c-4.097656 0-8.195312-1.554688-11.308594-4.691406-6.25-6.25-6.25-16.382813 0-22.632813l158.398438-158.402343c6.253906-6.25 16.386718-6.25 22.636718 0s6.25 16.382812 0 22.636718l-158.402343 158.398438c-3.15625 3.136718-7.25 4.691406-11.324219 4.691406zm0 0"></path>
                  <path d="m335.1875 351.1875c-4.09375 0-8.191406-1.554688-11.304688-4.691406l-158.398437-158.378906c-6.253906-6.25-6.253906-16.382813 0-22.632813 6.25-6.253906 16.382813-6.253906 22.632813 0l158.398437 158.398437c6.253906 6.25 6.253906 16.382813 0 22.632813-3.132813 3.117187-7.230469 4.671875-11.328125 4.671875zm0 0"></path>
                </svg>
              </button>
              {/* Supplier  */}
              {type.toLowerCase() !== "admin" ? (
                <p className="text-left font-medium text-gray-700 text-xs lg:text-base mb-3 mt-4">
                  Your listing will be saved in our catalogue for our
                  back-office to verify (within <strong>6-12 hours</strong>), or
                  revert with suggested changes.
                </p>
              ) : (
                <>
                  {/* Admin  */}
                  <div className=" mb-3 mt-4">
                    <div className="flex flex-col items-start justify-center gap-3">
                      {/* Status Dropdown */}
                      <div
                        className="w-full flex justify-start items-center max-md:hidden relative text-sm font-semibold gap-2"
                        ref={statusRef}
                      >
                        <p className="w-16">Status:</p>
                        <button
                          className={`w-40 flex items-center justify-between text-[#313131] font-semibold text-sm border-[1px] bg-gray-100 px-2 py-1 ${
                            isOpenStatus ? "rounded-t-md " : "rounded-md"
                          }`}
                          onClick={() => {
                            setIsOpenStatus(!isOpenStatus);
                          }}
                        >
                          <div className="flex items-center justify-start gap-2">
                            <div
                              className="inline-block w-3 h-3 rounded-full "
                              style={{
                                backgroundColor:
                                  statuses.find(
                                    (s) => s.status === selectedStatus
                                  )?.color || "#fff",
                              }}
                            ></div>
                            {selectedStatus || "Status"}
                          </div>
                          <svg
                            className="ml-1 w-4 h-4"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M19 9l-7 7-7-7"
                            ></path>
                          </svg>
                        </button>
                        {isOpenStatus && (
                          <div
                            className={`absolute left-[72px] top-[20px] fadein-animation overflow-hidden rounded-b-lg shadow-[0px_1px_1px_0px_#00000024] mt-2 w-40 z-50 border-[1px] border-t-0 transition-opacity duration-500 ease-in-out bg-gray-100`}
                          >
                            <ul className="py-1 text-sm text-gray-600">
                              {statuses.map(({ status, color }, index) => (
                                <li
                                  key={status}
                                  onClick={() => {
                                    setSelectedStatus(status);
                                    setProduct((prev) => {
                                      const updated = { ...prev };
                                      updated.Status = status;
                                      return updated;
                                    });
                                    setIsOpenStatus(false);
                                  }}
                                  className={`px-2 py-1 hover:text-[#212121] hover:bg-white font-medium duration-200 ease-in-out cursor-pointer ${
                                    selectedStatus === status
                                      ? "text-[#212121] font-semibold"
                                      : "text-[#616161]"
                                  }`}
                                >
                                  <div
                                    className={`inline-block w-2 h-2 rounded-full  mr-2`}
                                    style={{ backgroundColor: color }}
                                  ></div>
                                  {status}
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </div>
                      {(selectedStatus === "Pending" ||
                        selectedStatus === "Rejected") && (
                        <div className="w-full flex justify-start items-start max-md:hidden relative text-sm font-semibold gap-2">
                          <p className="w-16">Reason:</p>
                          <textarea
                            className="px-2 block w-full  py-1.5 text-[#212121] ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 hover:ring-2 hover:ring-[#eebd2d] hover:outline-none hover:ring-inset focus:ring-2 focus:ring-[#eebd2d] focus:outline-none focus:ring-inset text-sm sm:leading-6"
                            name=""
                            id=""
                            style={{ resize: "none" }}
                            onChange={(e) => {
                              setProduct((prev) => {
                                const updated = { ...prev };
                                updated.Reason = e.target.value;
                                return updated;
                              });
                              setReason(e.target.value);
                            }}
                          ></textarea>
                        </div>
                      )}
                      {/* OTP Section */}
                      <div className="w-full flex justify-start items-center gap-2 text-sm font-semibold">
                        <p className="w-16">OTP:</p>
                        <input
                          type="text"
                          maxLength="5"
                          placeholder="Enter OTP"
                          onChange={(e) => setUserOtp(e.target.value)}
                          className="px-2 py-1 block w-40 text-[#212121] ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 hover:ring-2 hover:ring-[#eebd2d] hover:outline-none hover:ring-inset focus:ring-2 focus:ring-[#eebd2d] focus:outline-none focus:ring-inset text-sm"
                        />
                        <button
                          onClick={() => {
                            sendPhoneOtp();
                          }}
                          disabled={showResendCountdown}
                          className={` text-sm font-medium    ease-in-out duration-300 ${
                            showResendCountdown
                              ? ""
                              : "cursor-pointer underline hover:text-[#e2a731]"
                          }`}
                        >
                          {showResendCountdown ? (
                            <>{resendPhoneTimer}s</>
                          ) : (
                            <>Resend</>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              )}
              <div className="flex items-end justify-end gap-4  text-[#313131]">
                <button
                  className="relative max-lg:px-4 max-md:py-1 md:py-1.5 lg:px-8 lg:py-1.5 text-[10px] md:text-xs lg:text-sm rounded-md bg-white isolation-auto z-10 border-2 border-[#FFC94A] font-semibold before:absolute before:w-full before:transition-all before:duration-500 before:hover:w-full before:-left-full before:hover:left-0 before:rounded-full  before:bg-[#FFC94A] before:-z-10  before:aspect-square before:hover:scale-[170%] overflow-hidden before:hover:duration-500"
                  onClick={handleContinue}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Images;
