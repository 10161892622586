import React from "react";

function Ledger() {
  const data = [
    {
      id: 0,
      date: "12/05/25",
      particulars: "To Balance b/d",
      amount: "₹ 2,00,000.00",
    },
    {
      id: 1,
      date: "14/05/25",
      particulars: "GHSK INC",
      amount: "₹ 5,00,000.00",
    },
    {
      id: 2,
      date: "15/05/25",
      particulars: "OPAL & Co",
      amount: "₹ 10,00,000.00",
    },
  ];
  return (
    <div>
      <table className="min-w-full font-medium table-auto shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] rounded-lg overflow-hidden mt-2">
        <thead className="rounded-lg">
          <tr className="border rounded-t-xl bg-[#000000] text-[#ffffff]">
            <th className="px-4 py-2 text-left">Date</th>
            <th className="px-4 py-2 text-left">Particulars</th>
            <th className="px-4 py-2 text-left flex items-center gap-2">
              Amount
            </th>
            <th className="px-4 py-2 text-left border-l-2 border-zinc-200">
              Date
            </th>
            <th className="px-4 py-2 text-left">Particulars</th>
            <th className="px-4 py-2 text-left flex items-center gap-2">
              Amount
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <>
              <tr key={item.id} className="border-b bg-white">
                <td className="px-4 py-2 text-left">{item.date}</td>
                <td className="px-4 py-2 border-l-2 border-zinc-200 w-[27%]">
                  {item.particulars}
                </td>
                <td className="px-4 py-2 border-l-2 border-zinc-200 w-fit">
                  {item.amount}
                </td>

                <td className="px-4 py-2 text-left border-l-2 border-zinc-200">
                  {item.date}
                </td>
                <td className="px-4 py-2 border-l-2 border-zinc-200 w-[27%]">
                  {item.particulars}
                </td>
                <td className="px-4 py-2 border-l-2 border-zinc-200 w-fit">
                  {item.amount}
                </td>
              </tr>
            </>
          ))}
          <tr className="border-b bg-white">
            <td className="px-4 py-2 text-left"></td>
            <td className="px-4 py-2 border-l-2 border-zinc-200 w-[27%]"></td>
            <td className="px-4 py-2 border-l-2 border-y-zinc-300 w-fit border-y-2">
              10,000,000.00
            </td>

            <td className="px-4 py-2 text-left border-l-2 border-zinc-200"></td>
            <td className="px-4 py-2 border-l-2 border-zinc-200 w-[27%]"></td>
            <td className="px-4 py-2 border-l-2 border-y-zinc-300 w-fit border-y-2">
              10,000,000.00
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default Ledger;
