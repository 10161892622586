import React, { useState } from "react";
import Popup from "./Popup";
import Ledger from "../AdminComponent/Ledger.jsx";
function Slider({ receiveItem, setReceiveItem, ledgerType }) {
  const [challanPopup, setChallanPopup] = useState(false);
  const data = [
    {
      id: 0,
      date: "12/05/25",
      particulars: "To Balance b/d",
      amount: "₹ 2,00,000.00",
    },
    {
      id: 1,
      date: "14/05/25",
      particulars: "Two Bros INC",
      amount: "₹ 5,00,000.00",
    },
    {
      id: 2,
      date: "15/05/25",
      particulars: "HUAS & Co",
      amount: "₹ 10,00,000.00",
    },
  ];

  return (
    <div className="slider-container rounded-md">
      <div className="w-[90%] absolute right-0 rounded-md h-full bg-white shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] p-5 overflow-x-auto text-[13px] rightToLeft">
        <div className="flex items-center justify-between flex-wrap gap-2">
          <p className="font-bold flex items-center gap-2">
            <span
              onClick={() => setReceiveItem(null)}
              className="bg-[#000000] p-1.5 rounded-lg flex items-center justify-center cursor-pointer"
            >
              <svg
                className="w-2.5 h-2.5 fill-[#ffffff]"
                version="1.1"
                id="fi_271220"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsHlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 492 492"
                xmlSpace="preserve"
              >
                <g>
                  <g>
                    <path
                      d="M198.608,246.104L382.664,62.04c5.068-5.056,7.856-11.816,7.856-19.024c0-7.212-2.788-13.968-7.856-19.032l-16.128-16.12
          C361.476,2.792,354.712,0,347.504,0s-13.964,2.792-19.028,7.864L109.328,227.008c-5.084,5.08-7.868,11.868-7.848,19.084
          c-0.02,7.248,2.76,14.028,7.848,19.112l218.944,218.932c5.064,5.072,11.82,7.864,19.032,7.864c7.208,0,13.964-2.792,19.032-7.864
          l16.124-16.12c10.492-10.492,10.492-27.572,0-38.06L198.608,246.104z"
                    ></path>
                  </g>
                </g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
              </svg>
            </span>
            {receiveItem}
          </p>
          <div className="flex items-center gap-2">
            {ledgerType === "Debtor" ? (
              <span className="font-semibold flex items-center gap-1 rounded-full border px-2 py-1 cursor-pointer">
                Download
                <svg
                  className="w-5 h-5"
                  viewBox="0 0 32 32"
                  xmlns="http://www.w3.org/2000/svg"
                  id="fi_2989976"
                >
                  <g
                    id="a9148db4-8702-4948-b3d7-c33f0782daf4"
                    data-name="Download"
                  >
                    <path d="m28 24v-4a1 1 0 0 0 -2 0v4a1 1 0 0 1 -1 1h-18a1 1 0 0 1 -1-1v-4a1 1 0 0 0 -2 0v4a3 3 0 0 0 3 3h18a3 3 0 0 0 3-3zm-6.38-5.22-5 4a1 1 0 0 1 -1.24 0l-5-4a1 1 0 0 1 1.24-1.56l3.38 2.7v-13.92a1 1 0 0 1 2 0v13.92l3.38-2.7a1 1 0 1 1 1.24 1.56z"></path>
                  </g>
                </svg>
              </span>
            ) : (
              <>
                <button
                  onClick={() => {
                    setChallanPopup(true);
                  }}
                  className="font-semibold flex items-center gap-1.5 rounded-full px-3 py-1 cursor-pointer bg-[#FFD61F] text-[#000000]"
                >
                  Upload Challan
                  <span>
                    <svg
                      className="w-3.5 h-3.5"
                      xmlns="http://www.w3.org/2000/svg"
                      id="fi_3097412"
                      data-name="Layer 2"
                      viewBox="0 0 24 24"
                    >
                      <path d="M22,13a1,1,0,0,0-1,1v4.213A2.79,2.79,0,0,1,18.213,21H5.787A2.79,2.79,0,0,1,3,18.213V14a1,1,0,0,0-2,0v4.213A4.792,4.792,0,0,0,5.787,23H18.213A4.792,4.792,0,0,0,23,18.213V14A1,1,0,0,0,22,13Z"></path>
                      <path d="M6.707,8.707,11,4.414V17a1,1,0,0,0,2,0V4.414l4.293,4.293a1,1,0,0,0,1.414-1.414l-6-6a1,1,0,0,0-1.414,0l-6,6A1,1,0,0,0,6.707,8.707Z"></path>
                    </svg>
                  </span>
                </button>
                <span className="font-semibold flex items-center gap-1 rounded-full border px-2 py-1 cursor-pointer">
                  Download
                  <svg
                    className="w-5 h-5"
                    viewBox="0 0 32 32"
                    xmlns="http://www.w3.org/2000/svg"
                    id="fi_2989976"
                  >
                    <g
                      id="a9148db4-8702-4948-b3d7-c33f0782daf4"
                      data-name="Download"
                    >
                      <path d="m28 24v-4a1 1 0 0 0 -2 0v4a1 1 0 0 1 -1 1h-18a1 1 0 0 1 -1-1v-4a1 1 0 0 0 -2 0v4a3 3 0 0 0 3 3h18a3 3 0 0 0 3-3zm-6.38-5.22-5 4a1 1 0 0 1 -1.24 0l-5-4a1 1 0 0 1 1.24-1.56l3.38 2.7v-13.92a1 1 0 0 1 2 0v13.92l3.38-2.7a1 1 0 1 1 1.24 1.56z"></path>
                    </g>
                  </svg>
                </span>
              </>
            )}
          </div>
        </div>
        {/* Ledger Table */}
        <Ledger />
      </div>

      {challanPopup && (
        <Popup
          type={"challan"}
          onClose={() => {
            setChallanPopup(false);
          }}
        />
      )}
    </div>
  );
}

export default Slider;
