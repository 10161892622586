import React from "react";

function Searchbar() {
  return (
    <div className="flex items-center justify-between border rounded-full pl-2 pr-0.5 py-0.5 bg-white">
      <input
        type="text"
        //   value={filterTable}
        //   onChange={(e) => setFiterTable(e.target.value)}
        className="text-[13px] text-gray-800 placeholder-gray-400 focus:outline-none w-full bg-transparent placeholder:text-xs"
        placeholder={`Search`}
      />
      <button className="flex items-center justify-center p-1.5 bg-yellow-400 rounded-full hover:bg-yellow-500 transition-all duration-300">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="2"
          stroke="currentColor"
          className="w-3.5 h-3.5 text-black"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M21 21l-4.35-4.35M10 18a8 8 0 100-16 8 8 0 000 16z"
          />
        </svg>
      </button>
    </div>
  );
}

export default Searchbar;
