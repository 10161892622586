import React, { useState, useEffect } from "react";
import "./Account.css";
import BillingAddress from "./BillingAddress";
import Header from "../../components/Header/Header.jsx";
import secureLocalStorage from "react-secure-storage";
import CheckAuth from "../../components/ServiceFunctions/CheckAuth.jsx";

const AccountPage = () => {
  const { phone, email } = CheckAuth();
  let storedData1 = secureLocalStorage.getItem("kycData");
  let userKycData = JSON.parse(storedData1);
  const fullName =
    userKycData &&
    userKycData["username"] !== undefined &&
    userKycData["username"] !== null &&
    userKycData["username"];
  const [selectedImage, setSelectedImage] = useState("/images/user.svg");
  const [selectedAddressId, setSelectedAddressId] = useState(null);

  useEffect(() => {
    const newAddresses = Object?.keys(userKycData)
      .filter(key => key.startsWith('Shippingaddress'))
      .map(key => ({
        country: userKycData[key]?.country || "",
        postalCode: userKycData[key]?.pin || "",
        state: userKycData[key]?.state || "",
        district: userKycData[key]?.district || "",
        locality: userKycData[key]?.locality || "",
        street1: userKycData[key]?.addressline1 || "",
        street2: userKycData[key]?.addressline2 || "",
        id: Date.now() + Math.random(), // Use a unique ID
      }));
    setAddresses(newAddresses);
  }, [JSON.stringify(userKycData)]);

  useEffect(() => {
    if (userKycData?.profile_pic_content !== undefined) {
      if (userKycData?.profile_pic_content) {
        setSelectedImage(userKycData?.profile_pic_content);
      } else {
        setSelectedImage("/images/user.svg");
      }
    }
  }, [userKycData?.profile_pic_content]);

  // Function to handle address selection
  const handleSelectAddress = (id) => {
    setSelectedAddressId(id);
  };

  const [addresses, setAddresses] = useState([]);

  const [newAddress, setNewAddress] = useState({
    receiverName: "", // Add this field
    country: "",
    postalCode: "",
    state: "",
    district: "",
    locality: "",
    street1: "",
    street2: "",
  });

  const [countries, setCountries] = useState([]);
  const [localityOptions, setLocalityOptions] = useState([]);
  const [pincode, setPincode] = useState("");
  const [receiverName, setReceiverName] = useState("");

  // Fetch countries from the REST Countries API
  const fetchCountries = async () => {
    try {
      const response = await fetch("https://restcountries.com/v3.1/all");
      if (response.ok) {
        const data = await response.json();
        const countryList = data.map((country) => ({
          code: country.cca2, // ISO 3166-1 alpha-2 code
          name: country.name.common, // Common name of the country
        }));
        // Sort countries alphabetically
        countryList.sort((a, b) => a.name.localeCompare(b.name));
        setCountries(countryList);
      } else {
        console.error("Failed to fetch countries");
      }
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };

  // Fetch locality and state based on Indian postal code
  const fetchPincodeData = (value) => {
    setPincode(value);
    if (/^\d{6}$/.test(value)) {
      fetch(`https://api.postalpincode.in/pincode/${value}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to fetch data");
          }
          return response.json();
        })
        .then((PostOffice) => {
          const state = PostOffice[0]?.PostOffice[0]?.State || "";
          const district = PostOffice[0]?.PostOffice[0]?.District || ""; // Get district
          const area = PostOffice[0]?.PostOffice.map((post) => post.Name) || [];
          setNewAddress((prev) => ({
            ...prev,
            state: state,
            locality: area[0] || "", // Default to the first locality
            district: district || "", // Set district
          }));
          setLocalityOptions(area);
        })
        .catch((error) => {
          console.error("Error: ", error);
        });
    }
  };

  // Fetch locality and state for non-Indian countries
  const fetchLocationData = async (countryCode, postalCode) => {
    try {
      const response = await fetch(
        `https://api.zippopotam.us/${countryCode.toLowerCase()}/${postalCode}`
      );
      if (response.ok) {
        const data = await response.json();
        const { places } = data;
        if (places && places.length > 0) {
          setNewAddress((prevAddress) => ({
            ...prevAddress,
            locality: places[0]["place name"],
            state: places[0]["state"],
          }));
        }
      } else {
        console.error("Invalid postal code or country.");
      }
    } catch (error) {
      console.error("Error fetching location data:", error);
    }
  };

  // Call the API when the component mounts to fetch countries
  useEffect(() => {
    fetchCountries();
  }, []);
  // Call appropriate API based on country and postal code
  useEffect(() => {
    if (newAddress.country === "IN") {
      fetchPincodeData(newAddress.postalCode);
    } else if (newAddress.country && newAddress.postalCode) {
      fetchLocationData(newAddress.country, newAddress.postalCode);
    }
  }, [newAddress.country, newAddress.postalCode]);

  const [editMode, setEditMode] = useState(null); // null means add mode
  const [isModalOpen, setIsModalOpen] = useState(-1); // For popup

  // Function to handle opening the modal
  const handleOpenModal = (address, index) => {
    if (address !== null) {
      setEditMode(address.id);
      setNewAddress(address);
    } else {
      setEditMode(null);
      setNewAddress({ street: "", city: "", postalCode: "", country: "" });
    }
    setIsModalOpen(index);
  };

  // Function to handle adding/editing address
  const handleSaveAddress = () => {
    if (editMode) {
      const updatedAddresses = addresses.map((address) =>
        address.id === editMode ? { ...newAddress, id: editMode } : address
      );
      setAddresses(updatedAddresses);
    } else {
      fetch(`https://buwchlifestyles.com/api/save-address/`, {
        method: "POST",
        body: JSON.stringify({
          email: email,
          phone: phone,
          address: newAddress,
          fullName: fullName,
          receiverName: receiverName,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to send OTP");
          }
          return response.json();
        })
        .then((response) => {
          secureLocalStorage.setItem("kycData", JSON.stringify(response.user));
          let storedData1 = secureLocalStorage.getItem("kycData");
          let userKycData = JSON.parse(storedData1);
        })
        .catch((error) => {
          console.error("Error: ", error);
        });
      setAddresses([...addresses, { ...newAddress, id: Date.now() }]);
    }
    setIsModalOpen(-1);
  };

  // Function to handle deleting address
  const handleDeleteAddress = (id) => {
    setAddresses(addresses.filter((address) => address.id !== id));
    setIsModalOpen(-1); // Close the modal after deletion
  };

  // Currency options with flag image URLs
  const currencies = [
    {
      code: "INR",
      name: "Indian Rupee (₹)",
      flag: "https://flagcdn.com/in.svg",
    },
    { code: "USD", name: "US Dollar ($)", flag: "https://flagcdn.com/us.svg" },
    { code: "EUR", name: "Euro (€)", flag: "https://flagcdn.com/eu.svg" },
    {
      code: "GBP",
      name: "British Pound (£)",
      flag: "https://flagcdn.com/gb.svg",
    },
    {
      code: "JPY",
      name: "Japanese Yen (¥)",
      flag: "https://flagcdn.com/jp.svg",
    },
    {
      code: "CNY",
      name: "Chinese Yuan (CN¥)",
      flag: "https://flagcdn.com/cn.svg",
    },
  ];

  const [selectedCurrency, setSelectedCurrency] = useState("INR");
  const handleCurrencyChange = (e) => {
    const newCurrency = e.target.value;
    setSelectedCurrency(newCurrency);
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result); // Update selected image
        fetch(`https://buwchlifestyles.com/api/update-image/`, {
          method: "POST",
          body: JSON.stringify({
            email: email,
            img: reader.result,
          }),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("Failed to send OTP");
            }
            return response.json();
          })
          .then((response) => {
            secureLocalStorage.setItem(
              "kycData",
              JSON.stringify(response.user)
            );
            let userKycData = JSON.parse(secureLocalStorage.getItem("kycData"));
          })
          .catch((error) => {
            console.error("Error: ", error);
          });
      };
      reader.readAsDataURL(file); // Convert file to data URL
    }
  };

  const handleImageRemove = () => {
    setSelectedImage("/images/user.svg");
    fetch(`https://buwchlifestyles.com/api/remove-image/`, {
      method: "POST",
      body: JSON.stringify({
        email: email,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to send OTP");
        }
        return response.json();
      })
      .then((response) => {
        secureLocalStorage.setItem("kycData", JSON.stringify(response.user));
        let userKycData = JSON.parse(secureLocalStorage.getItem("kycData"));
      })
      .catch((error) => {
        console.error("Error: ", error);
      });
  };

  return (
    <>
      <Header />
      <div className="after-header max-w-3xl mx-auto pb-4 bg-white">
        <h1 className="pt-4 text-2xl font-bold text-center text-black mb-4">
          Account Information
        </h1>

        {/* Profile Picture  */}
        <div className="flex justify-center items-center relative mb-3 ">
          <div className="w-[120px] h-[120px] md:w-[180px] md:h-[180px] p-1 border-2 rounded-full hover:border-gray-400/50 cursor-pointer bg-white hover:bg-slate-300 group transition-all duration-500 z-0 relative overflow-hidden">
            {/* Image Preview */}
            <div className="w-full bg-white group-hover:opacity-10 h-full flex items-center justify-center p-2 rounded-full object-cover transition-all duration-200">
              <span className="w-full object-cover inline-block">
                <img className="" src={selectedImage} alt="Profile" />
              </span>
            </div>

            {/* Edit and Delete Buttons */}
            <div className="absolute inset-0 justify-center items-center gap-3 hidden group-hover:flex delay-700 duration-500 group">
              <div>
                {/* Hidden File Input */}
                <input
                  id="image-profile"
                  type="file"
                  accept="image/*"
                  onChange={(e) => {
                    handleImageUpload(e);
                  }}
                  style={{ display: "none" }}
                />

                {/* Edit Button */}
                <label htmlFor="image-profile" className="cursor-pointer">
                  <img
                    className="w-10 h-10 border-2 border-yellow-300 bg-yellow-50 p-1 rounded-full duration-200 hover:bg-yellow-300"
                    src="/images/edit.svg"
                    alt="Edit"
                  />
                </label>
              </div>

              {/* Delete Button */}
              <div>
                <img
                  className="w-10 h-10 border-2 border-red-300 bg-red-50 p-1 rounded-full hover:bg-red-300 duration-200"
                  src="/images/delete.svg"
                  onClick={() => handleImageRemove()} // Handle delete action
                  alt="Delete"
                />
              </div>
            </div>
          </div>
        </div>

        {/* Change Password  */}

        <div className="flex justify-center items-center mb-3">
          <a href="/changepassword" className="">
            <button class="flex overflow-hidden items-center text-sm font-medium focus-visible:outline-none focus-visible:ring-1  bg-black/80 text-gray-100 hover:text-gray-50  hover:bg-black/90 h-9 px-4 py-1.5 max-w-48 whitespace-pre md:flex group relative w-full justify-center gap-2 rounded-md transition-all duration-300 ease-out ring-gray-50 ring-2   hover:ring-2 hover:ring-yellow-400 hover:ring-offset-2 ">
              <span class="absolute right-0 -mt-12 h-32 w-8 translate-x-12 rotate-12 bg-[#ffffff] opacity-20 transition-all duration-1000 ease-out group-hover:-translate-x-52"></span>
              <div class="flex items-center">
                <span class="ml-1 text-white">Change Password</span>
              </div>
              <div class="ml-2 flex items-center gap-1 text-sm md:flex">
                <svg
                  viewBox="0 0 24 24"
                  class="w-4 h-4 fill-gray-500 transition-all duration-300 group-hover:fill-[#ffd932]"
                  xmlns="http://www.w3.org/2000/svg"
                  id="fi_9356210"
                >
                  <g id="Layer_2" data-name="Layer 2">
                    <path d="m12.64 5.42-10.29 10.29a2.38 2.38 0 0 0 -.68 1.41l-.41 3.6a1.81 1.81 0 0 0 1.81 2h.21l3.6-.41a2.42 2.42 0 0 0 1.41-.67l10.29-10.3z"></path>
                    <path d="m22.06 4.55-2.61-2.61a2.35 2.35 0 0 0 -3.33 0l-2.42 2.42 5.94 5.94 2.42-2.42a2.35 2.35 0 0 0 0-3.33z"></path>
                  </g>
                </svg>
              </div>
            </button>
          </a>
        </div>

        {/* Full Name */}
        <div className="mb-3">
          <label className="block text-sm font-semibold leading-4 text-[#212121] mb-1">
            Full Name
          </label>
          <input
            type="text"
            className="px-2 block w-full  py-1.5 text-[#212121] ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 hover:ring-2 hover:ring-[#eebd2d] hover:outline-none hover:ring-inset focus:ring-2 focus:ring-[#eebd2d] focus:outline-none focus:ring-inset text-sm sm:leading-6 cursor-not-allowed"
            placeholder="Enter full name"
            id="fullname"
            name="fullname"
            value={fullName}
            disabled
          />
        </div>

        {/* Email Address */}
        <div className="mb-3">
          <label className="block text-sm font-semibold leading-4 text-[#212121] mb-1">
            Email Address
          </label>
          <input
            type="email"
            className="px-2 block w-full  py-1.5 text-[#212121] ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 hover:ring-2 hover:ring-[#eebd2d] hover:outline-none hover:ring-inset focus:ring-2 focus:ring-[#eebd2d] focus:outline-none focus:ring-inset text-sm sm:leading-6 cursor-not-allowed"
            placeholder="Enter email address"
            id="email"
            name="email"
            value={email}
            disabled
          />
        </div>

        {/* Mobile Number */}
        <div className="mb-3">
          <label className="block text-sm font-semibold leading-4 text-[#212121] mb-1">
            Mobile Number
          </label>
          <input
            type="tel"
            className="px-2 block w-full  py-1.5 text-[#212121] ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 hover:ring-2 hover:ring-[#eebd2d] hover:outline-none hover:ring-inset focus:ring-2 focus:ring-[#eebd2d] focus:outline-none focus:ring-inset text-sm sm:leading-6 "
            placeholder="Enter mobile number"
            id="phone"
            name="phone"
            value={phone}
          />
        </div>

        {/* Change Currency */}
        <div className="mb-3">
          <label className="block text-sm font-semibold leading-4 text-[#212121] mb-1">
            Change Currency
          </label>
          <div className="w-full   bg-[#ffffff] group hover:bg-[#f0f0f0] duration-300 ease-in-out p-2 py-1.5 overflow-hidden flex items-center justify-center  gap-2 ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 hover:ring-2 hover:ring-[#eebd2d] hover:outline-none hover:ring-inset focus:ring-2 focus:ring-[#eebd2d] focus:outline-none focus:ring-inset outline-none border-0">
            {/* Display selected flag */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
              className="w-full "
            >
              <img
                src={
                  currencies.find((cur) => cur.code === selectedCurrency)?.flag
                }
                alt={selectedCurrency}
                style={{ marginRight: "5px" }}
                className=" w-8 h-5"
              />
              <select
                value={selectedCurrency}
                onChange={handleCurrencyChange}
                className="w-[87%] md:w-[93%] absolute bg-[#fff] py-1.5 group-hover:bg-[#f0f0f0] duration-300 ease-in-out left-10 text-left text-xs ring-0 focus:outline-none "
              >
                {currencies.map((currency) => (
                  <option className="pop-up-animation" value={currency.code}>
                    {currency.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        {/* Shipping Address */}
        <div className="mb-3 ">
          <h3 className="text-lg font-bold mb-2 text-[#111111]">
            Shipping Address:
          </h3>
          <label className="block text-sm font-semibold leading-4 text-[#212121] mb-1">
            Receiver's Name
          </label>
          <input
            id="receiname"
            name="receiname"
            type="text"
            value={receiverName}
            onChange={(e) => {
              setReceiverName(e.target.value);
            }}
            className="px-2 block w-full  py-1.5 text-[#212121] ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 hover:ring-2 hover:ring-[#eebd2d] hover:outline-none hover:ring-inset focus:ring-2 focus:ring-[#eebd2d] focus:outline-none focus:ring-inset text-sm sm:leading-6 "
            placeholder="Enter receiver's name"
          />

          <div className="mt-3">
            <div className="flex flex-wrap gap-2 ">
              {addresses.map((address, index) => (
                <div
                  className={`w-[48%] md:w-[24%] bg-white shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] max-md:p-2.5 md:p-3 space-y-1 relative overflow-hidden cursor-pointer duration-300 ease-in-out ${
                    selectedAddressId === address.id
                      ? "border-[#e2a731] border-2"
                      : "border-gray-400 border-2"
                  }`}
                  onClick={() => handleSelectAddress(address.id)} // Select address on card click
                >
                  {/* Edit Button */}
                  <div
                    className={`w-16 h-16 rounded-full absolute -right-5 -top-7 duration-300 ease-in-out ${
                      selectedAddressId === address.id
                        ? "bg-[#e2a731]"
                        : "bg-gray-400"
                    }`}
                  >
                    <p
                      className="absolute bottom-2.5 left-3.5 text-white text-sm"
                      onClick={(event) => {
                        event.stopPropagation(); // Prevent card click when editing
                        handleOpenModal(address, index); // Open modal in edit mode
                      }}
                    >
                      <img
                        className="w-5 h-5 fill-current"
                        src="/images/edit-white.svg"
                        alt="Edit"
                      />
                    </p>
                  </div>

                  <div className="fill-current w-8">
                    <svg
                      clipRule="evenodd"
                      fillRule="evenodd"
                      strokeLinejoin="round"
                      strokeMiterlimit="2"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      className={
                        selectedAddressId === address.id
                          ? "text-[#e2a731] duration-300 ease-in-out"
                          : "text-gray-400 duration-300 ease-in-out"
                      }
                    >
                      <g id="Icon">
                        <path d="m12 1.25c-3.723 0-6.75 3.054-6.75 6.821 0 1.276.562 2.859 1.38 4.438 1.801 3.477 4.801 6.979 4.801 6.979.142.166.35.262.569.262s.427-.096.569-.262c0 0 3-3.502 4.801-6.979.818-1.579 1.38-3.162 1.38-4.438 0-3.767-3.027-6.821-6.75-6.821zm0 4c-1.518 0-2.75 1.232-2.75 2.75s1.232 2.75 2.75 2.75 2.75-1.232 2.75-2.75-1.232-2.75-2.75-2.75z"></path>
                        <path d="m16.784 17.377c.813.244 1.483.546 1.946.9.3.228.52.451.52.723 0 .16-.091.305-.219.45-.212.239-.527.454-.917.654-1.378.705-3.606 1.146-6.114 1.146s-4.736-.441-6.114-1.146c-.39-.2-.705-.415-.917-.654-.128-.145-.219-.29-.219-.45 0-.272.22-.495.52-.723.463-.354 1.133-.656 1.946-.9.396-.119.621-.538.502-.934-.119-.397-.538-.622-.934-.503-1.238.373-2.2.884-2.778 1.449-.508.495-.756 1.049-.756 1.611 0 .702.397 1.402 1.204 1.986 1.416 1.024 4.26 1.764 7.546 1.764s6.13-.74 7.546-1.764c.807-.584 1.204-1.284 1.204-1.986 0-.562-.248-1.116-.756-1.611-.578-.565-1.54-1.076-2.778-1.449-.396-.119-.815.106-.934.503-.119.396.106.815.502.934z"></path>
                      </g>
                    </svg>
                  </div>
                  <h1 className="font-bold text-base leading-4 mt-2 text-zinc-800 ">
                    {address.receiverName}
                  </h1>
                  <h1 className="font-semibold text-sm leading-4 text-zinc-600 mt-2">
                    {address.street1}
                  </h1>
                  <p className="text-xs text-zinc-600 leading-4 m-0">
                    {address.locality}, {address.state}, {address.postalCode},{" "}
                    {address.country}
                  </p>
                </div>
              ))}
              {/* Add New Address Card */}
              <div
                onClick={() => handleOpenModal(null, addresses.length + 1)} // Open modal for adding a new address
                className="w-[48%] md:w-[24%] p-3 flex items-center justify-center  shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] bg-white flex-shrink-0 cursor-pointer"
              >
                <h4 className="text-sm font-semibold leading-4 text-[#212121] flex justify-center flex-col items-center gap-2">
                  <img src="/images/plus.svg" className="w-7 h-7" alt="" />
                  New Address
                </h4>
              </div>
            </div>

            {/* Modal Popup */}
            {isModalOpen !== -1 && (
              <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                <div className="bg-white p-6 rounded-lg shadow-lg w-[400px]">
                  <div className="flex justify-between mb-3">
                    <h3 className="text-lg font-bold text-[#111111] ">
                      {editMode ? "Edit Address" : "Add New Address"}
                    </h3>

                    <button
                      onClick={() => handleDeleteAddress(newAddress.id)} // Pass the id of the current address
                      className="px-2 py-1 text-sm bg-red-500 text-white border-[1px] border-red-600 rounded-md"
                    >
                      Delete
                    </button>
                  </div>
                  <div className="mb-3">
                    <select
                      className="px-2 block w-full py-1.5 text-[#212121] ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 hover:ring-2 hover:ring-[#eebd2d] hover:outline-none focus:ring-2 focus:ring-[#eebd2d] text-sm sm:leading-6 mb-2 focus:outline-none"
                      value={newAddress.country}
                      required
                      onChange={(e) =>
                        setNewAddress({
                          ...newAddress,
                          country: e.target.value,
                          state: "",
                          locality: "",
                          district: "",
                        })
                      }
                    >
                      <option value="" disabled>
                        Select Country
                      </option>
                      {countries.map((country) => (
                        <option value={country.code}>{country.name}</option>
                      ))}
                    </select>

                    <input
                      type="number"
                      className="px-2 block w-full py-1.5 text-[#212121] ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 hover:ring-2 hover:ring-[#eebd2d] hover:outline-none focus:ring-2 focus:ring-[#eebd2d] text-sm sm:leading-6 mb-2 focus:outline-none"
                      placeholder="Postal Code"
                      disabled={!newAddress.country}
                      value={newAddress.postalCode}
                      required
                      onChange={(e) =>
                        setNewAddress({
                          ...newAddress,
                          postalCode: e.target.value,
                        })
                      }
                    />

                    <input
                      type="text"
                      className="px-2 block w-full py-1.5 text-[#212121] ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 hover:ring-2 hover:ring-[#eebd2d] hover:outline-none focus:ring-2 focus:ring-[#eebd2d] text-sm sm:leading-6 mb-2 focus:outline-none"
                      placeholder="State"
                      disabled={!newAddress.country}
                      value={newAddress.state}
                      required
                      onChange={(e) =>
                        setNewAddress({ ...newAddress, state: e.target.value })
                      }
                    />

                    {/* District input that shows only if India is selected */}
                    {newAddress.country === "IN" && (
                      <input
                        type="text"
                        className="px-2 block w-full py-1.5 text-[#212121] ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 hover:ring-2 hover:ring-[#eebd2d] hover:outline-none focus:ring-2 focus:ring-[#eebd2d] text-sm sm:leading-6 mb-2 focus:outline-none"
                        placeholder="District"
                        disabled={!newAddress.country}
                        value={newAddress.district}
                        required
                        onChange={(e) =>
                          setNewAddress({
                            ...newAddress,
                            district: e.target.value,
                          })
                        }
                      />
                    )}

                    {newAddress.country === "IN" ? (
                      <select
                        className="px-2 block w-full py-1.5 text-[#212121] ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 hover:ring-2 hover:ring-[#eebd2d] hover:outline-none focus:ring-2 focus:ring-[#eebd2d] text-sm sm:leading-6 mb-2 focus:outline-none"
                        name="locality"
                        id="locality"
                        disabled={!newAddress.country}
                        value={newAddress.locality}
                        required
                        onChange={(e) =>
                          setNewAddress({
                            ...newAddress,
                            locality: e.target.value,
                          })
                        }
                      >
                        <option value="">Select Locality</option>
                        {localityOptions.map((city, index) => (
                          <option value={city}>{city}</option>
                        ))}
                      </select>
                    ) : (
                      <input
                        type="text"
                        className="px-2 block w-full py-1.5 text-[#212121] ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 hover:ring-2 hover:ring-[#eebd2d] hover:outline-none focus:ring-2 focus:ring-[#eebd2d] text-sm sm:leading-6 mb-2 focus:outline-none"
                        placeholder="Enter Locality"
                        value={newAddress.locality}
                        disabled={!newAddress.country}
                        required
                        onChange={(e) =>
                          setNewAddress({
                            ...newAddress,
                            locality: e.target.value,
                          })
                        }
                      />
                    )}

                    <input
                      type="text"
                      className="px-2 block w-full py-1.5 text-[#212121] ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 hover:ring-2 hover:ring-[#eebd2d] hover:outline-none focus:ring-2 focus:ring-[#eebd2d] text-sm sm:leading-6 focus:outline-none mb-2"
                      placeholder="Street Address 1"
                      disabled={!newAddress.country}
                      value={newAddress.street1}
                      required
                      onChange={(e) =>
                        setNewAddress({
                          ...newAddress,
                          street1: e.target.value,
                        })
                      }
                    />

                    <input
                      type="text"
                      className="px-2 block w-full py-1.5 text-[#212121] ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 hover:ring-2 hover:ring-[#eebd2d] hover:outline-none focus:ring-2 focus:ring-[#eebd2d] text-sm sm:leading-6 focus:outline-none"
                      placeholder="Street Address 2"
                      disabled={!newAddress.country}
                      value={newAddress.street2}
                      onChange={(e) =>
                        setNewAddress({
                          ...newAddress,
                          street2: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="flex justify-end space-x-3 mt-4 text-sm font-medium">
                    <button
                      onClick={() => setIsModalOpen(-1)}
                      className="px-4 py-2 text-gray-600 border border-gray-300 rounded-md"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={handleSaveAddress}
                      className="px-4 py-2 bg-[#eebd2d] text-white rounded-md"
                    >
                      {editMode ? "Save Changes" : "Add Address"}
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Billing Address */}

        <BillingAddress />

        {/* Business Benefits */}
        {/* <div class="relative p-4 flex items-center mb-3  overflow-hidden font-medium transition-all bg-[#FFC94A] rounded-md group">
        <span class="absolute top-0 right-0 inline-block w-3 h-3 md:w-4 md:h-4 transition-all duration-500 ease-in-out bg-[#e2a731] rounded ">
          <span class="absolute top-0 right-0 w-5 h-5 rotate-45 translate-x-1/2 -translate-y-1/2 bg-slate-200"></span>
        </span>
        <span class="absolute bottom-0 rotate-180 left-0 inline-block w-3 h-3 md:w-4 md:h-4 transition-all duration-500 ease-in-out bg-[#e2a731] rounded ">
          <span class="absolute top-0 right-0 w-5 h-5 rotate-45 translate-x-1/2 -translate-y-1/2 bg-slate-200"></span>
        </span>
        <span class="relative w-full text-left text-[#212121] transition-colors duration-200 ease-in-out group-hover:text-[#111111]">
          <h3 className="font-bold mb-3">Why Switch as Business User?</h3>
          <p>
            Enjoy tax benefits, better invoicing options, and management tools
            tailored for business users.
          </p>
        </span>
      </div> */}
        <div className="flex md:flex-row max-md:flex-col justify-between bg-gray-100 p-2 rounded-2xl mb-3 border-2 ">
          <div class="relative flex flex-col justify-between gap-4 p-3 w-full md:w-[51%] bg-gray-100  shadow-[inset_0_-16px_24px_0_rgba(255,255,255,0.25)]">
            <div class="">
              <span class="card_title text-[#414141] font-bold text-lg">
                Individual
              </span>
              <p class="font-medium text-paragraph text-xs mt-1 w-[65%]">
                Offer simplicity and control for personal financial needs.
              </p>
            </div>
            <ul class="card__list flex flex-col gap-2 items-start">
              <li class="card__list_item flex items-start gap-2">
                <span class="check flex justify-center items-center w-3 h-3 md:w-4 md:h-4 bg-gray-500 rounded-full max-md:mt-1 md:mt-0.5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                    fill="#fff"
                    class="bg-gray-500 rounded-full text-black"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12.416 3.376a.75.75 0 0 1 .208 1.04l-5 7.5a.75.75 0 0 1-1.154.114l-3-3a.75.75 0 0 1 1.06-1.06l2.353 2.353 4.493-6.74a.75.75 0 0 1 1.04-.207Z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </span>
                <span class="list_text text-[#414141] text-sm">
                  Retail Quantities
                </span>
              </li>
              <li class="card__list_item flex items-start gap-2">
                <span class="check flex justify-center items-center w-3 h-3 md:w-4 md:h-4 bg-gray-500 rounded-full max-md:mt-1 md:mt-0.5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                    fill="#fff"
                    class="bg-gray-500 rounded-full text-black"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12.416 3.376a.75.75 0 0 1 .208 1.04l-5 7.5a.75.75 0 0 1-1.154.114l-3-3a.75.75 0 0 1 1.06-1.06l2.353 2.353 4.493-6.74a.75.75 0 0 1 1.04-.207Z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </span>
                <span class="list_text text-[#414141] text-sm">
                  Made-to-order
                </span>
              </li>
              <li class="card__list_item flex items-start gap-2">
                <span class="check flex justify-center items-center w-3 h-3 md:w-4 md:h-4 bg-gray-500 rounded-full max-md:mt-1 md:mt-0.5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                    fill="#fff"
                    class="bg-gray-500 rounded-full text-black"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12.416 3.376a.75.75 0 0 1 .208 1.04l-5 7.5a.75.75 0 0 1-1.154.114l-3-3a.75.75 0 0 1 1.06-1.06l2.353 2.353 4.493-6.74a.75.75 0 0 1 1.04-.207Z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </span>
                <span class="list_text text-[#414141] text-sm">
                  Logistics Quotes
                </span>
              </li>
              <li class="card__list_item flex items-start gap-2">
                <span class="check flex justify-center items-center w-3 h-3 md:w-4 md:h-4 bg-gray-500 rounded-full max-md:mt-1 md:mt-0.5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                    fill="#fff"
                    class="bg-gray-500 rounded-full text-black"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12.416 3.376a.75.75 0 0 1 .208 1.04l-5 7.5a.75.75 0 0 1-1.154.114l-3-3a.75.75 0 0 1 1.06-1.06l2.353 2.353 4.493-6.74a.75.75 0 0 1 1.04-.207Z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </span>
                <span class="list_text text-[#414141] text-sm">
                  Loyalty Benefits
                </span>
              </li>
              <li class="card__list_item flex items-start gap-2">
                <span class="check flex justify-center items-center w-3 h-3 md:w-4 md:h-4 bg-gray-500 rounded-full max-md:mt-1 md:mt-0.5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                    fill="#fff"
                    class="bg-gray-500 rounded-full text-black"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12.416 3.376a.75.75 0 0 1 .208 1.04l-5 7.5a.75.75 0 0 1-1.154.114l-3-3a.75.75 0 0 1 1.06-1.06l2.353 2.353 4.493-6.74a.75.75 0 0 1 1.04-.207Z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </span>
                <span class="list_text text-[#414141] text-sm">
                  Designer Catalogue
                </span>
              </li>
            </ul>
            <button class=" w-full group relative">
              <a
                href=""
                className="relative flex items-center w-full py-2 overflow-hidden font-medium transition-all bg-[#5e5e5e] rounded-md  mt-3"
              >
                <span class="absolute bottom-0 left-0 w-full h-full transition-all duration-500 ease-in-out delay-200 -translate-x-full bg-[#4b4b4b] rounded-md group-hover:translate-x-0"></span>
                <span class="relative max-md:text-xs w-full text-center font-semibold text-[#fff] transition-colors duration-200 ease-in-out group-hover:text-[#fff]">
                  Continue as Individual
                </span>
              </a>
            </button>
          </div>
          <div class="card relative flex flex-col justify-between gap-4 p-3 w-full md:w-[49%] bg-[#ffd667] rounded-xl shadow-[inset_0_-16px_24px_0_rgba(255,255,255,0.25)] border-4 border-transparent  bg-clip-padding bg-gradient-to-t from-[#ffd667] to-[#fcc93e]">
            <div class="card_title__container">
              <span class="card_title text-[#212121] font-bold text-lg">
                Business
              </span>
              <p class="font-semibold text-paragraph text-xs mt-1 w-[85%]">
                Offer growth, flexibility, and greater financial control.
              </p>
            </div>
            <ul class="card__list flex flex-col gap-2 items-start">
              <li class="card__list_item flex items-start  gap-2">
                <span class="check flex justify-center items-center  max-md:mt-1 md:mt-0.5 w-3 h-3 md:w-4 md:h-4 bg-[#c58f22] rounded-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                    fill="#fff"
                    class="bg-[#c58f22]  rounded-full text-black"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12.416 3.376a.75.75 0 0 1 .208 1.04l-5 7.5a.75.75 0 0 1-1.154.114l-3-3a.75.75 0 0 1 1.06-1.06l2.353 2.353 4.493-6.74a.75.75 0 0 1 1.04-.207Z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </span>
                <span class="list_text text-[#212121] font-semibold text-sm">
                  MOQ in multiples of 40 pairs
                </span>
              </li>
              <li class="card__list_item flex items-start gap-2">
                <span class="check flex justify-center items-center  max-md:mt-1 md:mt-0.5 w-3 h-3 md:w-4 md:h-4 bg-[#c58f22] rounded-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                    fill="#fff"
                    class="bg-[#c58f22]  rounded-full text-black"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12.416 3.376a.75.75 0 0 1 .208 1.04l-5 7.5a.75.75 0 0 1-1.154.114l-3-3a.75.75 0 0 1 1.06-1.06l2.353 2.353 4.493-6.74a.75.75 0 0 1 1.04-.207Z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </span>
                <span class="list_text text-[#212121] font-semibold text-sm">
                  AI-powered design Studio
                </span>
              </li>
              <li class="card__list_item flex items-start gap-2">
                <span class="check flex justify-center items-center  max-md:mt-1 md:mt-0.5 w-3 h-3 md:w-4 md:h-4 bg-[#c58f22] rounded-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                    fill="#fff"
                    class="bg-[#c58f22]  rounded-full text-black"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12.416 3.376a.75.75 0 0 1 .208 1.04l-5 7.5a.75.75 0 0 1-1.154.114l-3-3a.75.75 0 0 1 1.06-1.06l2.353 2.353 4.493-6.74a.75.75 0 0 1 1.04-.207Z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </span>
                <span class="list_text text-[#212121] font-semibold text-sm">
                  Stage-by-Stage Production Updates
                </span>
              </li>
              <li class="card__list_item flex items-start gap-2">
                <span class="check flex justify-center items-center  max-md:mt-1 md:mt-0.5 w-3 h-3 md:w-4 md:h-4 bg-[#c58f22] rounded-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                    fill="#fff"
                    class="bg-[#c58f22]  rounded-full text-black"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12.416 3.376a.75.75 0 0 1 .208 1.04l-5 7.5a.75.75 0 0 1-1.154.114l-3-3a.75.75 0 0 1 1.06-1.06l2.353 2.353 4.493-6.74a.75.75 0 0 1 1.04-.207Z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </span>
                <span class="list_text text-[#212121] font-semibold text-sm">
                  Drop-shipping API Gateway
                </span>
              </li>
              <li class="card__list_item flex items-start gap-2">
                <span class="check flex justify-center items-center  max-md:mt-1 md:mt-0.5 w-3 h-3 md:w-4 md:h-4 bg-[#c58f22] rounded-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                    fill="#fff"
                    class="bg-[#c58f22]  rounded-full text-black"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12.416 3.376a.75.75 0 0 1 .208 1.04l-5 7.5a.75.75 0 0 1-1.154.114l-3-3a.75.75 0 0 1 1.06-1.06l2.353 2.353 4.493-6.74a.75.75 0 0 1 1.04-.207Z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </span>
                <span class="list_text text-[#212121] font-semibold text-sm">
                  Self Brand/Logo styling and affixation
                </span>
              </li>
              <li class="card__list_item flex items-start gap-2">
                <span class="check flex justify-center items-center  max-md:mt-1 md:mt-0.5 w-3 h-3 md:w-4 md:h-4 bg-[#c58f22] rounded-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                    fill="#fff"
                    class="bg-[#c58f22]  rounded-full text-black"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12.416 3.376a.75.75 0 0 1 .208 1.04l-5 7.5a.75.75 0 0 1-1.154.114l-3-3a.75.75 0 0 1 1.06-1.06l2.353 2.353 4.493-6.74a.75.75 0 0 1 1.04-.207Z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </span>
                <span class="list_text text-[#212121] font-semibold text-sm">
                  Accessory Customization
                </span>
              </li>
            </ul>
            <button class="w-full group">
              <a
                href="/business"
                className="relative flex items-center w-full py-2 overflow-hidden font-medium transition-all bg-[#e4a92b] rounded-lg mt-3"
              >
                <span class="absolute bottom-0 left-0 w-full h-full transition-all duration-500 ease-in-out delay-200 -translate-x-full bg-[#cf9624] rounded-md group-hover:translate-x-0"></span>
                <span class="relative  max-md:text-xs w-full text-center font-semibold text-white transition-colors duration-200 ease-in-out group-hover:text-[#111111]">
                  Switch as Business
                </span>
              </a>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountPage;
