import { useState, useEffect } from "react";
import { FaQuestionCircle } from "react-icons/fa";
import { FiStar } from "react-icons/fi";
import { GoStarFill } from "react-icons/go";

function CarouselCard({ cardData }) {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToSlide = (index) => {
    setCurrentIndex(index);
  };
  return (
    <div className="carousel-container2 relative">
      <div
        className="carousel-slide2 flex transition-transform duration-500"
        style={{
          transform: `translateX(-${currentIndex * 100}%)`,
        }}
      >
        {cardData.map((item) => (
          <div className="carousel-item2 flex-shrink-0 max-lg:max-w-[145px] lg:min-w-[200px]">
            {/* {item.type !== "Customize" && ( */}
            <div className="flex gap-2 items-center ">
              <span
                className="text-xs lg:text-sm font-semibold"
                style={{ color: item.color }}
              >
                {item.Status}
              </span>
              {(item.Status == "Rejected" || item.Status === "Pending") && (
                <div className="cursor-default flex items-center text-sm font-medium whitespace-pre group relative justify-center ">
                  <div className="flex items-center max-lg:gap-1 lg:gap-2">
                    <div className="inline-block z-10 max-lg:pt-1 lg:pt-2">
                      <button className="focus:outline-none group">
                        <FaQuestionCircle className="transform transition-transform duration-300 group-hover:fill-red-500 w-3 h-3 lg:w-4 lg:h-4" />
                      </button>
                      <span className="absolute top-10 left-3 transform max-lg:-translate-x-[60%] lg:-translate-x-1/2 z-10 px-2 py-2 text-[10px] max-md:leading-[14px] md:text-xs font-normal text-white bg-gray-700 rounded-lg shadow-sm transition-transform duration-300 ease-in-out scale-0 group-hover:scale-100 w-32 lg:w-44 text-wrap">
                        {item.Message}
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {/* )} */}
            <img
              src={item.img}
              alt={item.type}
              className="mx-2 max-lg:max-w-[130px] lg:w-[180px] h-full"
            />
          </div>
        ))}
      </div>

      {/* Carousel Indicators */}
      <div className="carousel-indicators2 flex justify-center gap-2 mt-3">
        {cardData.map((item, index) => (
          <div className="relative group">
            {item.type === "Customize" ? (
              <>
                {index === currentIndex ? (
                  <button>
                    <GoStarFill
                      onClick={() => goToSlide(index)}
                      className="w-3 h-3 lg:w-4 lg:h-4 transition-all duration-300"
                    />
                  </button>
                ) : (
                  <button>
                    <FiStar
                      onClick={() => goToSlide(index)}
                      className="w-3 h-3 lg:w-4 lg:h-4 transition-all duration-300"
                    />
                  </button>
                )}
              </>
            ) : (
              <button
                onClick={() => goToSlide(index)}
                className="w-4 h-4 lg:w-4 lg:h-4 rounded-full transition-all duration-300 border-2"
                style={{
                  backgroundColor:
                    index === currentIndex
                      ? item.Status === ""
                        ? "#000000"
                        : item.color
                      : "transparent",
                  borderColor: item.Status === "" ? "#000000" : item.color,
                }}
              ></button>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default CarouselCard;
