import { useState, useEffect, useRef } from "react";
import secureLocalStorage from "react-secure-storage";

const transformCustomizeToProductLocal = (customize, productId, action) => {
  const matchingEntry = customize.find((entry) => {
    return entry?.Article_no === productId;
  });
  let productLocal = null;
  if (!matchingEntry) {
    return null;
  } else {
    if (action === "edit") {
      productLocal = {
        Article_no: matchingEntry?.Article_no || "",
        Leather: matchingEntry?.Leather || {},
        Sole: matchingEntry?.Sole || {},
        Edge: matchingEntry?.Edge || [],
        Lining: matchingEntry?.Lining || {},
        Eyelets: matchingEntry?.Eyelets || [],
        Buckle: matchingEntry?.Buckle || {},
        Shoelace: matchingEntry?.Shoelace || {},
        Edit: matchingEntry?.Edit || false,
        Altered: matchingEntry?.Altered?.Sole
          ? matchingEntry?.Altered
          : {
              ...matchingEntry?.Altered,
              Sole: matchingEntry?.Sole,
            },
      };
    } else {
      productLocal = {
        Article_no: matchingEntry?.Article_no || "",
        Leather: matchingEntry?.Leather || {},
        Sole: matchingEntry?.Sole || {},
        Edge: matchingEntry?.Edge || [],
        Lining: matchingEntry?.Lining || {},
        Eyelets: matchingEntry?.Eyelets || [],
        Buckle: matchingEntry?.Buckle || {},
        Shoelace: matchingEntry?.Shoelace || {},
        Edit: matchingEntry?.Edit || false,
      };
    }
  }
  return productLocal;
};

const SoleSelection = ({
  onNext,
  style,
  ProductId,
  materialCost,
  subCategory,
  action,
}) => {
  const storedData = secureLocalStorage.getItem("Customize");
  const Customize = JSON.parse(storedData);
  const [isMenuOpen, setIsMenuOpen] = useState(true);
  const [selectedSole, setSelectedSole] = useState("");
  const [fadeEffect, setFadeEffect] = useState(false);
  const [soleOptions, setSoleOptions] = useState([]);
  const [weltOptions, setWeltOptions] = useState([]);
  const [thicknessOptions, setThicknessOptions] = useState([]);
  const [constructionOptions, setConstructionOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [ProductLocal, setProductLocal] = useState({});

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(null);
    }
  };
  useEffect(() => {
    if (isOpen !== null) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  useEffect(() => {
    materialCost?.forEach((item) => {
      if (item.Soles) {
        setSoleOptions(item.Soles);
      }
      if (item?.Constructions) {
        setConstructionOptions(item?.Constructions);
      }
      if (item.Welts) {
        setWeltOptions(item.Welts);
      }
      if (item.Thickness) {
        setThicknessOptions(item.Thickness);
      }
    });
  }, []);

  useEffect(() => {
    setProductLocal(
      transformCustomizeToProductLocal(Customize, ProductId, action)
    );
    setSelectedSole(Object.keys(soleOptions)[0]);
  }, [style, subCategory, ProductId, JSON.stringify(soleOptions)]);

  const colors = Object.entries(soleOptions[selectedSole]?.Color || {}).map(
    ([colorKey, colorValue], index) => ({
      id: index,
      color: colorKey,
      img: colorValue,
    })
  );

  const thickness = Object.entries(thicknessOptions || {}).map(
    ([key, val]) => ({
      ["name"]: key,
      ["img"]: val.Img,
    })
  );

  const handleSoleSelect = (type) => {
    if (action === "edit") {
      setProductLocal((prev) => {
        const updated = { ...prev };
        if (!updated.Altered.Sole) {
          updated.Altered.Sole = updated.Sole;
        }

        // Remove empty Sole entries
        if (updated.Altered.Sole) {
          Object.keys(updated.Altered.Sole).forEach((key) => {
            const sole = updated.Altered.Sole[key];
            if (
              sole.Colors?.length === 0 &&
              Object.keys(sole?.Constructions)?.length === 0
            ) {
              delete updated.Altered.Sole[key];
            }
          });
        } else {
          updated.Altered.Sole = {};
        }

        setSelectedSole(type);
        setFadeEffect(true);
        setIsMenuOpen(false);

        if (!updated.Altered.Sole[type]) {
          updated.Altered.Sole[type] = { Constructions: {}, Colors: [] };
        }

        return updated;
      });
    } else {
      setProductLocal((prev) => {
        const updated = { ...prev };

        // Remove empty Sole entries
        if (updated.Sole) {
          Object.keys(updated.Sole).forEach((key) => {
            const sole = updated.Sole[key];
            if (
              sole.Colors?.length === 0 &&
              Object.keys(sole?.Constructions)?.length === 0
            ) {
              delete updated.Sole[key];
            }
          });
        } else {
          updated.Sole = {};
        }

        setSelectedSole(type);
        setFadeEffect(true);
        setIsMenuOpen(false);

        if (!updated.Sole[type]) {
          updated.Sole[type] = { Constructions: {}, Colors: [] };
        }

        return updated;
      });
    }
    setTimeout(() => setFadeEffect(false), 500);
  };

  const handleConstructionToggle = (construction) => {
    if (action === "edit") {
      setProductLocal((prev) => {
        const updated = JSON.parse(JSON.stringify(prev));
        // Remove empty Sole entries
        if (updated.Altered.Sole) {
          Object.keys(updated.Altered.Sole).forEach((key) => {
            const sole = updated.Altered.Sole[key];

            // Safely check if 'Colors' and 'Constructions' are arrays and not empty
            if (
              sole.Colors?.length === 0 &&
              Object.keys(sole?.Constructions)?.length === 0
            ) {
              delete updated.Altered.Sole[key];
            }
          });
        } else {
          updated.Altered.Sole = {};
        }

        if (!updated.Altered.Sole[selectedSole]) {
          updated.Altered.Sole[selectedSole] = {
            Colors: [],
            Constructions: {},
            Thickness: [],
          };
        }

        const currentConstruction = Object.keys(
          updated.Altered.Sole[selectedSole]?.Constructions
        ).includes(construction);
        if (currentConstruction) {
          delete updated.Altered.Sole[selectedSole]?.Constructions[
            construction
          ];
        } else {
          updated.Altered.Sole[selectedSole].Constructions[construction] = [];
        }
        return updated;
      });
    } else {
      setProductLocal((prev) => {
        const updated = JSON.parse(JSON.stringify(prev));
        // Remove empty Sole entries
        if (updated.Sole) {
          Object.keys(updated.Sole).forEach((key) => {
            const sole = updated.Sole[key];

            // Safely check if 'Colors' and 'Constructions' are arrays and not empty
            if (
              sole.Colors?.length === 0 &&
              Object.keys(sole?.Constructions)?.length === 0
            ) {
              delete updated.Sole[key];
            }
          });
        } else {
          updated.Sole = {};
        }

        if (!updated.Sole[selectedSole]) {
          updated.Sole[selectedSole] = {
            Colors: [],
            Constructions: {},
            Thickness: [],
          };
        }

        const currentConstruction = Object.keys(
          updated.Sole[selectedSole]?.Constructions
        ).includes(construction);
        if (currentConstruction) {
          delete updated.Sole[selectedSole]?.Constructions[construction];
        } else {
          updated.Sole[selectedSole].Constructions[construction] = [];
        }
        return updated;
      });
    }
  };

  const handleColorToggle = (color) => {
    if (action === "edit") {
      setProductLocal((prev) => {
        const updated = JSON.parse(JSON.stringify(prev));

        // Remove empty Sole entries
        if (updated.Altered.Sole) {
          Object.keys(updated.Altered.Sole).forEach((key) => {
            const sole = updated.Altered.Sole[key];
            if (
              sole.Colors?.length === 0 &&
              Object.keys(sole?.Constructions)?.length === 0
            ) {
              delete updated.Altered.Sole[key];
            }
          });
        } else {
          updated.Altered.Sole = {};
        }

        if (!updated.Altered.Sole[selectedSole]) {
          updated.Altered.Sole[selectedSole] = {
            Colors: [],
            Constructions: {},
            Thickness: [],
          };
        }

        const colorsArray = updated.Altered.Sole[selectedSole].Colors;

        if (colorsArray.includes(color)) {
          updated.Altered.Sole[selectedSole].Colors = colorsArray.filter(
            (c) => c !== color
          );
        } else {
          updated.Altered.Sole[selectedSole].Colors.push(color);
        }

        return updated;
      });
    } else {
      setProductLocal((prev) => {
        const updated = JSON.parse(JSON.stringify(prev));

        // Remove empty Sole entries
        if (updated.Sole) {
          Object.keys(updated.Sole).forEach((key) => {
            const sole = updated.Sole[key];
            if (
              sole.Colors?.length === 0 &&
              Object.keys(sole?.Constructions)?.length === 0
            ) {
              delete updated.Sole[key];
            }
          });
        } else {
          updated.Sole = {};
        }

        if (!updated.Sole[selectedSole]) {
          updated.Sole[selectedSole] = {
            Colors: [],
            Constructions: {},
            Thickness: [],
          };
        }

        const colorsArray = updated.Sole[selectedSole].Colors;

        if (colorsArray.includes(color)) {
          updated.Sole[selectedSole].Colors = colorsArray.filter(
            (c) => c !== color
          );
        } else {
          updated.Sole[selectedSole].Colors.push(color);
        }

        return updated;
      });
    }
  };

  const handleThicknessToggle = (thickness) => {
    if (action === "edit") {
      setProductLocal((prev) => {
        const updated = JSON.parse(JSON.stringify(prev));

        // Remove empty Sole entries
        if (updated.Altered.Sole) {
          Object.keys(updated.Altered.Sole).forEach((key) => {
            const sole = updated.Altered.Sole[key];
            if (
              sole.Colors?.length === 0 &&
              Object.keys(sole?.Constructions)?.length === 0
            ) {
              delete updated.Altered.Sole[key];
            }
          });
        } else {
          updated.Altered.Sole = {};
        }

        if (!updated.Altered.Sole[selectedSole]) {
          updated.Altered.Sole[selectedSole] = {
            Colors: [],
            Constructions: {},
            Thickness: [],
          };
        }

        const thicknessArray = updated.Altered.Sole[selectedSole].Thickness;

        if (thicknessArray.includes(thickness)) {
          updated.Altered.Sole[selectedSole].Thickness = thicknessArray.filter(
            (c) => c !== thickness
          );
        } else {
          updated.Altered.Sole[selectedSole].Thickness.push(thickness);
        }

        return updated;
      });
    } else {
      setProductLocal((prev) => {
        const updated = JSON.parse(JSON.stringify(prev));

        // Remove empty Sole entries
        if (updated.Sole) {
          Object.keys(updated.Sole).forEach((key) => {
            const sole = updated.Sole[key];
            if (
              sole.Colors?.length === 0 &&
              Object.keys(sole?.Constructions)?.length === 0
            ) {
              delete updated.Sole[key];
            }
          });
        } else {
          updated.Sole = {};
        }

        if (!updated.Sole[selectedSole]) {
          updated.Sole[selectedSole] = {
            Colors: [],
            Constructions: {},
            Thickness: [],
          };
        }

        const thicknessArray = updated.Sole[selectedSole].Thickness;

        if (thicknessArray.includes(thickness)) {
          updated.Sole[selectedSole].Thickness = thicknessArray.filter(
            (c) => c !== thickness
          );
        } else {
          updated.Sole[selectedSole].Thickness.push(thickness);
        }

        return updated;
      });
    }
  };

  const handleWeltToggle = (welt) => {
    if (action === "edit") {
      setProductLocal((prev) => {
        const updated = JSON.parse(JSON.stringify(prev));

        // Remove empty Sole entries
        if (updated.Altered.Sole) {
          Object.keys(updated.Altered.Sole).forEach((key) => {
            const sole = updated.Altered.Sole[key];
            if (
              sole.Colors?.length === 0 &&
              Object.keys(sole?.Constructions)?.length === 0
            ) {
              delete updated.Altered.Sole[key];
            }
          });
        } else {
          updated.Altered.Sole = {};
        }

        if (!updated.Altered.Sole[selectedSole]) {
          updated.Altered.Sole[selectedSole] = {
            Colors: [],
            Constructions: {},
            Thickness: [],
          };
        }
        const weltsArray =
          updated.Altered.Sole[selectedSole]?.Constructions["Goodyear"];

        // Add or remove the color
        if (weltsArray.includes(welt)) {
          updated.Altered.Sole[selectedSole].Constructions["Goodyear"] =
            weltsArray.filter((w) => w !== welt);
        } else {
          updated.Altered.Sole[selectedSole].Constructions["Goodyear"] = [
            ...weltsArray,
            welt,
          ];
        }
        return updated;
      });
    } else {
      setProductLocal((prev) => {
        const updated = JSON.parse(JSON.stringify(prev));

        // Remove empty Sole entries
        if (updated.Sole) {
          Object.keys(updated.Sole).forEach((key) => {
            const sole = updated.Sole[key];
            if (
              sole.Colors?.length === 0 &&
              Object.keys(sole?.Constructions)?.length === 0
            ) {
              delete updated.Sole[key];
            }
          });
        } else {
          updated.Sole = {};
        }

        if (!updated.Sole[selectedSole]) {
          updated.Sole[selectedSole] = {
            Colors: [],
            Constructions: {},
            Thickness: [],
          };
        }
        const weltsArray =
          updated.Sole[selectedSole]?.Constructions["Goodyear"];

        // Add or remove the color
        if (weltsArray.includes(welt)) {
          updated.Sole[selectedSole].Constructions["Goodyear"] =
            weltsArray.filter((w) => w !== welt);
        } else {
          updated.Sole[selectedSole].Constructions["Goodyear"] = [
            ...weltsArray,
            welt,
          ];
        }
        return updated;
      });
    }
  };

  const options = Object.keys(weltOptions).map((key, index) => ({
    id: index + 1,
    label: key.replace(/_/g, " "),
  }));

  const handleSave = () => {
    if (action === "edit") {
      const sole = ProductLocal?.Altered.Sole || {};

      // Remove empty Sole entries
      const cleanedSole = { ...sole };
      Object.keys(cleanedSole).forEach((key) => {
        const soleEntry = cleanedSole[key];

        const hasValidColors =
          Array.isArray(soleEntry.Colors) && soleEntry.Colors?.length > 0;

        const hasValidThickness =
          Array.isArray(soleEntry.Thickness) && soleEntry.Thickness?.length > 0;

        const hasValidConstructions =
          soleEntry?.Constructions &&
          Object.keys(soleEntry?.Constructions)?.length > 0;

        // Remove Sole entry if both Colors and Constructions are invalid
        if (!hasValidColors && !hasValidConstructions && !hasValidThickness) {
          delete cleanedSole[key];
        }
      });

      // Validate Sole entries
      const validateEntry = (key, value) => {
        if (
          !value ||
          typeof value !== "object" ||
          Object.keys(value)?.length === 0
        ) {
          return { isValid: false, reason: "empty", key };
        }

        const hasColors =
          Array.isArray(value.Colors) && value.Colors?.length > 0;
        const hasThickness =
          Array.isArray(value.Thickness) && value.Thickness?.length > 0;

        const hasConstructions =
          value?.Constructions &&
          Object.keys(value?.Constructions).some((constructionKey) => {
            if (constructionKey === "Goodyear") {
              return (
                Array.isArray(value?.Constructions[constructionKey]) &&
                value?.Constructions[constructionKey]?.length > 0
              );
            }
            return true;
          });

        if (!hasColors || !hasConstructions || !hasThickness) {
          return {
            isValid: false,
            reason: "missing Colors or Constructions or Thickness",
            key,
          };
        }

        return { isValid: true };
      };

      let hasValidEntries = false;
      const invalidEntries = [];

      for (const [key, value] of Object.entries(cleanedSole)) {
        const { isValid, reason, key: invalidKey } = validateEntry(key, value);

        if (isValid) {
          hasValidEntries = true;
        } else if (reason === "missing Colors or Constructions or Thickness") {
          invalidEntries.push(invalidKey);
        }
      }

      if (invalidEntries?.length > 0) {
        alert(
          `The following entries are invalid. Please ensure they have both Colors, valid Constructions and valid Thickness: ${invalidEntries.join(
            ", "
          )}.`
        );
        return;
      }

      if (!hasValidEntries) {
        alert(
          "At least one Sole entry must have both Colors, valid Constructions and valid Thickness."
        );
        return;
      }

      // Update ProductLocal with cleaned and validated Sole
      const updatedProductLocal = {
        ...ProductLocal,
        Altered: { ...ProductLocal.Altered, Sole: cleanedSole },
      };
      const index = Customize.findIndex(
        (item) => item?.Article_no === ProductLocal?.Article_no
      );

      if (index !== -1) {
        Customize[index].Altered = updatedProductLocal.Altered;
      }
    } else {
      const sole = ProductLocal?.Sole || {};

      // Remove empty Sole entries
      const cleanedSole = { ...sole };
      Object.keys(cleanedSole).forEach((key) => {
        const soleEntry = cleanedSole[key];

        const hasValidColors =
          Array.isArray(soleEntry.Colors) && soleEntry.Colors?.length > 0;

        const hasValidThickness =
          Array.isArray(soleEntry.Thickness) && soleEntry.Thickness?.length > 0;

        const hasValidConstructions =
          soleEntry?.Constructions &&
          Object.keys(soleEntry?.Constructions)?.length > 0;

        // Remove Sole entry if both Colors and Constructions are invalid
        if (!hasValidColors && !hasValidConstructions && !hasValidThickness) {
          delete cleanedSole[key];
        }
      });

      // Validate Sole entries
      const validateEntry = (key, value) => {
        if (
          !value ||
          typeof value !== "object" ||
          Object.keys(value)?.length === 0
        ) {
          return { isValid: false, reason: "empty", key };
        }

        const hasColors =
          Array.isArray(value.Colors) && value.Colors?.length > 0;
        const hasThickness =
          Array.isArray(value.Thickness) && value.Thickness?.length > 0;

        const hasConstructions =
          value?.Constructions &&
          Object.keys(value?.Constructions).some((constructionKey) => {
            if (constructionKey === "Goodyear") {
              return (
                Array.isArray(value?.Constructions[constructionKey]) &&
                value?.Constructions[constructionKey]?.length > 0
              );
            }
            return true;
          });

        if (!hasColors || !hasConstructions || !hasThickness) {
          return {
            isValid: false,
            reason: "missing Colors or Constructions or Thickness",
            key,
          };
        }

        return { isValid: true };
      };

      let hasValidEntries = false;
      const invalidEntries = [];

      for (const [key, value] of Object.entries(cleanedSole)) {
        const { isValid, reason, key: invalidKey } = validateEntry(key, value);

        if (isValid) {
          hasValidEntries = true;
        } else if (reason === "missing Colors or Constructions or Thickness") {
          invalidEntries.push(invalidKey);
        }
      }

      if (invalidEntries?.length > 0) {
        alert(
          `The following entries are invalid. Please ensure they have both Colors, valid Constructions and valid Thickness: ${invalidEntries.join(
            ", "
          )}.`
        );
        return;
      }

      if (!hasValidEntries) {
        alert(
          "At least one Sole entry must have both Colors, valid Constructions and valid Thickness."
        );
        return;
      }

      // Update ProductLocal with cleaned and validated Sole
      const updatedProductLocal = {
        ...ProductLocal,
        Sole: cleanedSole,
      };
      const index = Customize.findIndex(
        (item) => item?.Article_no === ProductLocal?.Article_no
      );

      if (index !== -1) {
        Customize[index].Sole = updatedProductLocal.Sole;
      }
    }
    secureLocalStorage.setItem("Customize", JSON.stringify(Customize));
    alert("Sole's specifications saved in local storage");
    onNext(style, ProductLocal?.Article_no);
    setProductLocal(
      transformCustomizeToProductLocal(
        Customize,
        ProductLocal?.Article_no,
        action
      ) || {}
    );
  };

  const constructions =
    action !== "edit"
      ? ProductLocal?.Sole?.[selectedSole]?.Constructions
      : ProductLocal?.Altered?.Sole?.[selectedSole]?.Constructions;

  const hasGoodyear = Object.keys(constructions ?? {}).includes("Goodyear");

  return (
    <>
      <div className="bg-white max-md:p-2 md:p-4 2xl:p-6 rounded-lg">
        <div className="lg:hidden max-lg:block">
          <button
            className="lg:hidden block text-[#212121] py-2 focus:outline-none"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>

          <div
            className={`fixed top-0 left-0 h-full w-64 bg-white shadow-lg transform transition-transform duration-300 ease-in-out z-50 ${
              isMenuOpen ? "translate-x-0" : "-translate-x-full"
            } lg:translate-x-0 lg:relative lg:top-auto lg:left-auto lg:h-auto lg:shadow-none lg:bg-transparent lg:block`}
          >
            <div className="mt-4 max-w-5xl px-4">
              <label
                htmlFor="soleType"
                className="block text-sm xl:text-base font-semibold leading-4 text-[#212121]"
              >
                Type of Sole:
              </label>
              <div className="flex flex-wrap gap-2 mt-3">
                {Object.keys(soleOptions).map((type) => {
                  const isInOriginal =
                    (ProductLocal?.Sole?.[type]?.Constructions &&
                      Object.keys(ProductLocal?.Sole?.[type]?.Constructions)
                        ?.length > 0) ||
                    ProductLocal?.Sole?.[type]?.Colors?.length > 0;
                  const isInAltered =
                    (ProductLocal?.Altered?.Sole?.[type]?.Constructions &&
                      Object.keys(
                        ProductLocal?.Altered?.Sole?.[type]?.Constructions
                      )?.length > 0) ||
                    ProductLocal?.Altered?.Sole?.[type]?.Colors?.length > 0;
                  let colorClass = "fill-gray-300"; // Default color (gray)
                  if (isInOriginal && isInAltered) {
                    colorClass = "fill-yellow-500 bg-yellow-500";
                  } else if (isInOriginal && !isInAltered) {
                    colorClass = "fill-red-500"; // Red: Present only in ProductLocal.Leather
                  } else if (!isInOriginal && isInAltered) {
                    colorClass = "fill-green-400 bg-green-400"; // Green: Present only in ProductLocal.Altered.Leather
                  }

                  return (
                    <button
                      onClick={() => handleSoleSelect(type)}
                      className={`px-3 py-2 rounded-md flex items-center gap-1.5 font-medium w-full text-left text-sm xl:text-base ${
                        selectedSole === type ? "bg-yellow-100" : "bg-white"
                      }`}
                    >
                      <svg
                        viewBox="0 0 16 16"
                        xmlns="http://www.w3.org/2000/svg"
                        className={`w-5 h-5 rounded-full ${
                          action === "edit"
                            ? colorClass
                            : isInOriginal
                            ? "fill-yellow-500 bg-yellow-500"
                            : "fill-gray-300"
                        }`}
                      >
                        <g>
                          <path d="m8 0c-4.41113 0-8 3.58887-8 8s3.58887 8 8 8 8-3.58887 8-8-3.58887-8-8-8zm0 14c-3.30859 0-6-2.69141-6-6s2.69141-6 6-6 6 2.69141 6 6-2.69141 6-6 6z"></path>
                        </g>
                      </svg>
                      {type}
                    </button>
                  );
                })}
              </div>
            </div>
          </div>

          {isMenuOpen && (
            <div
              className="fixed inset-0 bg-black opacity-50 z-40"
              onClick={() => setIsMenuOpen(false)}
            />
          )}
        </div>
        <div className="rounded-xl border-2  border-gray-300 hover:border-yellow-400 duration-300 ease-in-out group max-xl:p-2.5 xl:p-4 relative">
          <div className="max-w-5xl lg:block max-lg:hidden">
            <label
              htmlFor="soleType"
              className="absolute border-2 rounded-lg w-32 py-1  text-center text-sm md:text-base font-semibold -top-5 left-5 duration-300 ease-in-out border-gray-300 bg-gray-50  group-hover:border-yellow-400 group-hover:bg-yellow-50"
            >
              Sole
            </label>
            {/* sole */}
            <div className="flex md:flex-row  items-start max-xl:gap-3 xl:gap-5 mt-3 mb-4">
              <label
                htmlFor="leatherType"
                className="min-w-40 block text-sm xl:text-base font-semibold leading-4 text-[#212121] mt-1"
              >
                Type:
              </label>
              <div className="flex flex-wrap gap-y-3 gap-x-5  ">
                {Object.keys(soleOptions).map((type) => {
                  const isInOriginal =
                    (ProductLocal?.Sole?.[type]?.Constructions &&
                      Object.keys(ProductLocal?.Sole?.[type]?.Constructions)
                        ?.length > 0) ||
                    ProductLocal?.Sole?.[type]?.Colors?.length > 0;
                  const isInAltered =
                    (ProductLocal?.Altered?.Sole?.[type]?.Constructions &&
                      Object.keys(
                        ProductLocal?.Altered?.Sole?.[type]?.Constructions
                      )?.length > 0) ||
                    ProductLocal?.Altered?.Sole?.[type]?.Colors?.length > 0;
                  let colorClass = "fill-gray-300";
                  if (isInOriginal && isInAltered) {
                    colorClass = "fill-yellow-400";
                  } else if (isInOriginal && !isInAltered) {
                    colorClass = "fill-red-500";
                  } else if (!isInOriginal && isInAltered) {
                    colorClass = "fill-green-400";
                  }

                  return (
                    <div className="flex items-center">
                      <button
                        onClick={() => handleSoleSelect(type)}
                        className={` py-1 flex items-center gap-2 font-medium w-48 text-[#313131] text-sm xl:text-base  ${
                          selectedSole === type
                            ? "border-b-[3px] border-[#ffc94a]  font-semibold text-[#e2ac2f]"
                            : "underline-animation "
                        } ${
                          isInOriginal || isInAltered ? "font-semibold" : ""
                        }`}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          id="Layer_1"
                          data-name="Layer 1"
                          viewBox="0 0 24 24"
                          className={`w-5 h-5 ${
                            action === "edit"
                              ? colorClass
                              : isInOriginal
                              ? "fill-yellow-400"
                              : "fill-gray-300"
                          }`}
                        >
                          <path d="m17,0H7C4.243,0,2,2.243,2,5v15.996c0,1.235.742,2.329,1.889,2.786,1.149.46,2.439.173,3.317-.755l4.793-5.505,4.822,5.536c.581.613,1.367.939,2.172.939.374,0,.752-.07,1.117-.216,1.147-.457,1.889-1.551,1.889-2.786V5c0-2.757-2.243-5-5-5Zm-.297,8.467l-1.971,1.606.815,2.484c.112.336-.002.706-.282.922-.281.216-.667.231-.964.038l-2.295-1.495-2.257,1.51c-.14.094-.302.141-.464.141-.176,0-.352-.056-.5-.166-.283-.212-.401-.58-.295-.917l.784-2.513-1.981-1.612c-.267-.226-.364-.594-.243-.922.12-.328.432-.546.782-.546h2.501l.885-2.483c.121-.326.433-.542.781-.542s.66.216.781.542l.885,2.483h2.501c.35,0,.663.219.783.548.12.329.022.698-.246.923Z" />
                        </svg>
                        {type.replace(/_/g, " ")}
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>

            {/* Color selection */}
            <div
              className={`mb-4 flex md:flex-row max-md:flex-col items-start  max-xl:gap-3 xl:gap-5  ${
                fadeEffect ? "fade-effect" : ""
              }`}
            >
              <label
                htmlFor="leathercolor"
                className="min-w-40 block text-sm xl:text-base font-semibold leading-4 text-[#212121] mt-2 "
              >
                Colors:
              </label>
              <div className="flex flex-wrap  gap-2 ">
                {Object.values(colors).map((val) => {
                  const isInOriginal = ProductLocal?.Sole?.[
                    selectedSole
                  ]?.Colors?.includes(val.color);
                  const isInAltered = ProductLocal?.Altered?.Sole?.[
                    selectedSole
                  ]?.Colors?.includes(val.color);
                  // Determine the color based on conditions
                  let colorClass =
                    "border-[#fff] before:border-[3px] before:text-[#fff]";
                  if (isInOriginal && isInAltered) {
                    colorClass =
                      "peer-checked:border-[#eebd2d] peer-checked:text-[#eebd2d] peer-checked:before:border-[#eebd2d] peer-checked:before:bg-[#eebd2d]";
                  } else if (isInOriginal && !isInAltered) {
                    colorClass =
                      "peer-checked:border-[#ef4444] peer-checked:text-[#ef4444] peer-checked:before:border-[#ef4444]";
                  } else if (!isInOriginal && isInAltered) {
                    colorClass =
                      "peer-checked:border-[#4ade80] peer-checked:text-[#4ade80] peer-checked:before:border-[#4ade80] peer-checked:before:bg-[#4ade80]";
                  }
                  return (
                    <div className="flex  justify-center items-center   gap-2 ">
                      <div className="flex flex-col items-center justify-center">
                        <label className="text-slate-400">
                          <input
                            type="checkbox"
                            className="h-[1px] opacity-0 overflow-hidden absolute whitespace-nowrap w-[1px] peer"
                            checked={isInOriginal || isInAltered || false}
                            onChange={() => handleColorToggle(val.color)}
                          />
                          <span
                            className={`${
                              action === "edit"
                                ? colorClass
                                : isInOriginal
                                ? "peer-checked:border-[#eebd2d] peer-checked:text-[#eebd2d] peer-checked:before:border-[#eebd2d] peer-checked:before:bg-[#eebd2d]"
                                : "border-[#fff] before:border-[3px] before:text-[#fff]"
                            } peer-checked:before:opacity-100 peer-checked:before:scale-100  flex flex-col items-center justify-center w-14 h-14 xl:w-[86px] xl:h-[86px] rounded-lg  transition-all duration-200 cursor-pointer relative border-2 bg-[#fff] before:absolute before:block max-xl:before:w-1 xl:before:w-2 max-xl:before:h-1 xl:before:h-2  before:rounded-full before:top-1 before:left-1 before:opacity-0 before:transition-transform before:scale-0 before:text-xs  before:items-center before:justify-center hover:border-[#eebd2d] hover:before:scale-100 hover:before:opacity-100`}
                          >
                            {" "}
                            <img
                              className="w-10 h-10 xl:w-[70px] xl:h-[70px] rounded-full  flex items-center justify-center border-[1px]"
                              src={val.img}
                              alt=""
                              srcset=""
                            />
                          </span>
                        </label>

                        <p className="font-semibold text-xs xl:text-sm">
                          {val.color.replace(/_/g, " ")}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            {/* Thickness selection */}
            <div
              className={`mb-4 flex md:flex-row max-md:flex-col items-start  max-xl:gap-3 xl:gap-5  ${
                fadeEffect ? "fade-effect" : ""
              }`}
            >
              <label
                htmlFor="leatherThickness"
                className="min-w-40 block text-sm xl:text-base font-semibold leading-4 text-[#212121] mt-2 "
              >
                Thickness:
              </label>
              <div className="flex flex-wrap  gap-2 ">
                {thickness.map((val) => {
                  const isInOriginal = ProductLocal?.Sole?.[
                    selectedSole
                  ]?.Thickness?.includes(val.name);
                  const isInAltered = ProductLocal?.Altered?.Sole?.[
                    selectedSole
                  ]?.Thickness?.includes(val.name);
                  // Determine the color based on conditions
                  let colorClass =
                    "border-[#fff] before:border-[3px] before:text-[#fff]";
                  if (isInOriginal && isInAltered) {
                    colorClass =
                      "peer-checked:border-[#eebd2d] peer-checked:text-[#eebd2d] peer-checked:before:border-[#eebd2d] peer-checked:before:bg-[#eebd2d]";
                  } else if (isInOriginal && !isInAltered) {
                    colorClass =
                      "peer-checked:border-[#ef4444] peer-checked:text-[#ef4444] peer-checked:before:border-[#ef4444]";
                  } else if (!isInOriginal && isInAltered) {
                    colorClass =
                      "peer-checked:border-[#4ade80] peer-checked:text-[#4ade80] peer-checked:before:border-[#4ade80] peer-checked:before:bg-[#4ade80]";
                  }
                  return (
                    <div className="flex  justify-center items-center   gap-2 ">
                      <div className="flex flex-col items-center justify-center">
                        <label className="text-slate-400">
                          <input
                            type="checkbox"
                            className="h-[1px] opacity-0 overflow-hidden absolute whitespace-nowrap w-[1px] peer"
                            checked={isInOriginal || isInAltered || false}
                            onChange={() => handleThicknessToggle(val.name)}
                          />
                          <span
                            className={`${
                              action === "edit"
                                ? colorClass
                                : isInOriginal
                                ? "peer-checked:border-[#eebd2d] peer-checked:text-[#eebd2d] peer-checked:before:border-[#eebd2d] peer-checked:before:bg-[#eebd2d]"
                                : "border-[#fff] before:border-[3px] before:text-[#fff]"
                            } peer-checked:before:opacity-100 peer-checked:before:scale-100  flex flex-col items-center justify-center w-14 h-14 xl:w-[86px] xl:h-[86px] rounded-lg  transition-all duration-200 cursor-pointer relative border-2 bg-[#fff] before:absolute before:block max-xl:before:w-1 xl:before:w-2 max-xl:before:h-1 xl:before:h-2  before:rounded-full before:top-1 before:left-1 before:opacity-0 before:transition-transform before:scale-0 before:text-xs  before:items-center before:justify-center hover:border-[#eebd2d] hover:before:scale-100 hover:before:opacity-100`}
                          >
                            {" "}
                            <img
                              className="w-10 h-10 xl:w-[70px] xl:h-[70px] rounded-full  flex items-center justify-center border-[1px]"
                              src={val.img}
                              alt=""
                              srcset=""
                            />
                          </span>
                        </label>

                        <p className="font-semibold text-xs xl:text-sm">
                          {val.name.replace(/_/g, " ")}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            {/* construction */}
            <div className=" max-lg:mt-0 lg:mt-3 max-w-4xl">
              <div
                className={` flex md:flex-row max-md:flex-col items-start max-xl:gap-3 xl:gap-5 ${
                  fadeEffect ? "fade-effect" : ""
                }`}
              >
                <label
                  htmlFor="soleConstruction"
                  className="min-w-40 block text-sm xl:text-base font-semibold leading-4 text-[#212121] mt-2"
                >
                  Construction:
                </label>
                <div className="flex max-xl:gap-x-2 xl:gap-x-5 flex-wrap">
                  {Object.keys(constructionOptions).map((construction) => {
                    const isInOriginal = ProductLocal?.Sole?.[selectedSole]
                      ?.Constructions
                      ? Object.keys(
                          ProductLocal?.Sole?.[selectedSole]?.Constructions
                        )?.includes(construction)
                      : false;
                    const isInAltered = ProductLocal?.Altered?.Sole?.[
                      selectedSole
                    ]?.Constructions
                      ? Object.keys(
                          ProductLocal?.Altered?.Sole?.[selectedSole]
                            ?.Constructions
                        )?.includes(construction)
                      : false;

                    return (
                      <button
                        value={construction}
                        className="rounded-md flex items-center text-sm xl:text-base gap-1.5 text-[#313131] font-medium px-2 py-2"
                        onClick={() => {
                          handleConstructionToggle(construction);
                        }}
                      >
                        <div className="checkbox-wrapper-31 ">
                          <input
                            type="checkbox"
                            checked={
                              action === "edit"
                                ? (isInOriginal && isInAltered) ||
                                  isInAltered ||
                                  false
                                : isInOriginal || false
                            }
                            readOnly
                          />
                          <svg
                            viewBox="0 0 35.6 35.6"
                            className={`${
                              action === "edit"
                                ? isInOriginal && isInAltered
                                  ? "checked-both"
                                  : isInOriginal
                                  ? "checked-original"
                                  : isInAltered
                                  ? "checked-altered"
                                  : ""
                                : isInOriginal
                                ? "checked-both"
                                : ""
                            }`}
                          >
                            <circle
                              className="background"
                              cx="17.8"
                              cy="17.8"
                              r="17.8"
                            ></circle>
                            <circle
                              className="stroke"
                              cx="17.8"
                              cy="17.8"
                              r="14.37"
                            ></circle>
                            <polyline
                              className="check"
                              points="11.78 18.12 15.55 22.23 25.17 12.87"
                            ></polyline>
                          </svg>
                        </div>
                        <span className="cursor-default checkbox-wrapper-31-text">
                          {construction.replace(/_/g, " ")}
                        </span>
                      </button>
                    );
                  })}
                </div>
              </div>
            </div>

            {/* Welts Pop-up */}
            {hasGoodyear && (
              <>
                <div className="max-lg:mt-0 lg:mt-3 max-w-4xl">
                  <div
                    className={`flex md:flex-row max-md:flex-col items-start max-xl:gap-3 xl:gap-5 ${
                      fadeEffect ? "fade-effect" : ""
                    }`}
                  >
                    <label
                      htmlFor="weltType"
                      className="min-w-40 block text-sm xl:text-base font-semibold leading-4 text-[#212121] mt-2"
                    >
                      Welts:
                    </label>
                    <div>
                      <div
                        className="relative w-44 md:w-44 group"
                        ref={dropdownRef}
                      >
                        <button
                          onClick={() => setIsOpen(!isOpen)}
                          className={`w-full px-2 py-2 text-center text-xs md:text-sm  rounded-lg ring-inset placeholder:text-gray-400 group-hover:ring-2 group-hover:ring-[#eebd2d] group-hover:outline-none group-hover:ring-inset focus:ring-2 focus:ring-[#eebd2d] focus:outline-none focus:ring-inset  sm:leading-6  font-semibold ${
                            ProductLocal?.Sole?.[selectedSole]?.Constructions?.[
                              "Goodyear"
                            ]?.length > 0
                              ? "ring-[#eebd2d] ring-2"
                              : "ring-gray-300 ring-1"
                          }`}
                        >
                          "Goodyear Welts"
                        </button>

                        {isOpen && (
                          <div className="absolute z-10 w-40 rounded-b-lg  md:w-full bg-white border border-gray-300 rounded-sm shadow-lg py-2 overflow-hidden">
                            <ul className="max-h-48 ">
                              {options.map((option) => {
                                const isInOriginal = Array.isArray(
                                  ProductLocal?.Sole?.[selectedSole]
                                    ?.Constructions?.["Goodyear"]
                                )
                                  ? ProductLocal?.Sole?.[
                                      selectedSole
                                    ]?.Constructions?.["Goodyear"]?.includes(
                                      option.label
                                    )
                                  : false;
                                const isInAltered = Array.isArray(
                                  ProductLocal?.Altered?.Sole?.[selectedSole]
                                    ?.Constructions?.["Goodyear"]
                                )
                                  ? ProductLocal?.Altered?.Sole?.[
                                      selectedSole
                                    ]?.Constructions?.["Goodyear"]?.includes(
                                      option.label
                                    )
                                  : false;

                                return (
                                  <li
                                    onClick={() =>
                                      handleWeltToggle(option.label)
                                    }
                                    className="flex  text-xs md:text-sm items-center gap-2 max-md:px-2 md:px-4 py-2 cursor-pointer hover:bg-gray-100"
                                  >
                                    <span className="checkbox-wrapper-31 scale-90">
                                      <input
                                        type="checkbox"
                                        checked={
                                          action === "edit"
                                            ? (isInOriginal && isInAltered) ||
                                              isInAltered ||
                                              false
                                            : isInOriginal || false
                                        }
                                        readOnly
                                      />
                                      <svg
                                        viewBox="0 0 35.6 35.6"
                                        className={`${
                                          action === "edit"
                                            ? isInOriginal && isInAltered
                                              ? "checked-both"
                                              : isInOriginal
                                              ? "checked-original"
                                              : isInAltered
                                              ? "checked-altered"
                                              : ""
                                            : isInOriginal
                                            ? "checked-both"
                                            : ""
                                        }`}
                                      >
                                        <circle
                                          className="background"
                                          cx="17.8"
                                          cy="17.8"
                                          r="17.8"
                                        ></circle>
                                        <circle
                                          className="stroke"
                                          cx="17.8"
                                          cy="17.8"
                                          r="14.37"
                                        ></circle>
                                        <polyline
                                          className="check"
                                          points="11.78 18.12 15.55 22.23 25.17 12.87"
                                        ></polyline>
                                      </svg>
                                    </span>
                                    {option.label.replace(/_/g, " ")}
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        {/* Save Button */}
        <div className="flex items-center justify-end w-full mt-3 gap-3">
          <button
            className="bg-white text-center w-16 rounded-lg h-9 lg:h-11 relative text-[#313131] text-xl font-semibold group border "
            type="button"
            onClick={() => {
              setProductLocal((prev) => {
                const updated = { ...prev };
                if (updated.Sole) {
                  delete updated.Sole;
                }
                secureLocalStorage.setItem("Product", JSON.stringify(updated));
                return updated;
              });
            }}
          >
            <p className="text-xs lg:text-sm">Reset</p>
          </button>
          <button
            className="bg-white text-center w-20 lg:w-32 rounded-lg h-9 lg:h-11 relative text-[#313131] text-xl font-semibold group border "
            type="button"
            onClick={() => {
              handleSave();
            }}
          >
            <div className="bg-yellow-400 rounded-md h-[26px] lg:h-[34px] w-1/3 flex items-center justify-center absolute left-1 top-[4px] group-hover:w-[70px] group-hover:lg:w-[118px] z-10 duration-500 ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1024 1024"
                className="rotate-180 w-4 lg:w-6 h-4 lg:h-6"
              >
                <path
                  d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z"
                  fill="#313131"
                ></path>
                <path
                  d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z"
                  fill="#313131"
                ></path>
              </svg>
            </div>
            <p className="max-lg:translate-x-4 lg:translate-x-5 text-xs lg:text-sm">
              Next
            </p>
          </button>
        </div>
      </div>
    </>
  );
};

export default SoleSelection;
