import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../pages/Home/Home";
import ProductPage from "../pages/Products/ProductPage";
import ProductPage2 from "../pages/Products/ProductPage2";
import ChangePassword from "../pages/ChangePassword/ChangePassword.jsx";
import ForgetPassword from "../pages/ChangePassword/ForgetPassword.jsx";
import Account from "../pages/Account/Account.jsx";
import Business from "../pages/Account/Business.jsx";
import Dashboard from "../pages/Dashboard/Dashboard.jsx";
import Register from "../pages/Register/Register.jsx";
import Connect from "../pages/ConnectPage/Connect.jsx";
import Cart from "../pages/CartPage/Cart.jsx";
import PrivacyPolicy from "../pages/Support/PrivacyPolicy.jsx";
import TermsOfUse from "../pages/Support/TermsOfUse.jsx";
import ProductionStepper from "../pages/Production Stage/Production-stepper.jsx";

function AllRoutes() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/:user/dashboard/*" element={<Dashboard />} />
        <Route path="/forgetpassword" element={<ForgetPassword />} />
        <Route path="/changepassword" element={<ChangePassword />} />
        <Route path="/account" element={<Account />} />
        <Route path="/business" element={<Business />} />
        <Route path="/register" element={<Register />} />
        <Route path="/catalogue" element={<ProductPage />} />
        <Route path="/catalogue/:article" element={<ProductPage2 />} />
        <Route path="/connect" element={<Connect />} />
        <Route path="/accessories" element={<Home />} />
        <Route path="/blogs" element={<Home />} />
        <Route path="/guide" element={<Home />} />
        <Route path="/shoemaking" element={<Home />} />
        <Route path="/affiliates" element={<Home />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/privacy policy" element={<PrivacyPolicy />} />
        <Route path="/terms of use" element={<TermsOfUse />} />
        <Route path="/production stepper" element={<ProductionStepper />} />
      </Routes>
    </>
  );
}

export default AllRoutes;
