import React, { useState } from "react";
import Loader2 from "../../components/Loaders/Loader2";
import { IoEyeSharp } from "react-icons/io5";
import { HiMiniEyeSlash } from "react-icons/hi2";
import CheckAuth from "../../components/ServiceFunctions/CheckAuth";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

function ChangePassword() {
  const [pass, setPass] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [a, setA] = useState(false);
  const [loader, setLoader] = useState(false);
  const [confirmpass, setConfirmpass] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const { isAuthenticated, email, phone, pan } = CheckAuth();
  const navigate = useNavigate();

  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  }

  // logout function
  const logout = () => {
    setLoader(true);
    const refreshToken = getCookie("refresh_token");
    secureLocalStorage.clear();
    if (refreshToken) {
      fetch(`https://buwchlifestyles.com/api/logout/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          refresh_token: refreshToken,
        }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to logout");
          }
          return response.json();
        })
        .then((response) => {
          document.cookie =
            "access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          document.cookie =
            "refresh_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          if (response.error === "Logged out successfully") {
            const currentUrl = new URL(window.location.href);
            if (currentUrl.pathname === "/") {
              window.location.reload();
            } else {
              window.location.href = "/";
            }
          } else {
            alert(response.error);
            window.location.href = "/userLost";
          }
        })
        .catch((error) => {
          // console.error("Error during logout:", error);
        });
    } else {
      setLoader(false);
      alert("refresh token not provided or connection failure");
      window.location.href = "/accessDenied";
    }
  };
  // logout function

  const handleRegister = (e) => {
    if (!isAuthenticated) {
      alert("Please login");
    }
    e.preventDefault();
    if (pass !== confirmpass) {
      alert("Passwords do not match, please re-renter your password.");
      setLoader(false);
    } else if (a !== true) {
      alert("Please Check your password characters again.");
      setLoader(false);
    } else {
      fetch(`https://buwchlifestyles.com/api/change-password/`, {
        method: "POST",
        body: JSON.stringify({
          email: email,
          password: pass,
          currentPassword: currentPassword,
          phone: phone,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to login");
          }
          return response.json();
        })
        .then((response) => {
          alert(response.data);
          if (
            response.data ==
            "Successfully changed the password, please login to continue"
          ) {
            logout();
            setLoader(false);
            navigate("/login");
          } else setLoader(false);
          navigate("/");
        })
        .catch((error) => {
          // console.error("Error: ", error);
        });
    }
  };

  const validate = (value) => {
    setPass(value);

    const strongPasswordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&])[A-Za-z\d!@#$%^&]{8,}$/;

    if (strongPasswordRegex.test(value)) {
      if (value.length >= 8) {
        setErrorMessage(
          <span className=" text-success text-sm font-semibold">
            Strong Password
          </span>
        );
        setA(true);
      } else {
        setErrorMessage(
          <span className="text-warning text-sm font-semibold">
            Medium Password
          </span>
        );
        setA(true);
      }
    } else {
      setErrorMessage(
        <span className="text-danger font-size1">
          Password must have at least 8 characters, including at least 1
          lowercase character, 1 uppercase character, 1 number, and 1 special
          character from (@#$%^&*)
        </span>
      );
      setA(false);
    }
  };

  return (
    <>
      {loader && <Loader2 />}
      <div className="bg-fixed flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 mt-5 after-header">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <a href="/">
            <img
              className="mx-auto h-16 w-auto"
              src="/images/BuwchLogo.svg"
              alt="Your Company"
            />
          </a>
          <h2 className=" text-center text-2xl font-bold leading-9 tracking-tight text-[#212121]">
            Change Your Password
          </h2>
        </div>
        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form
            className="space-y-6"
            action="#"
            method="POST"
            onSubmit={handleRegister}
          >
            <div>
              <div className="group">
                <label className="oldpassword block text-sm font-semibold leading-4 text-[#212121]">
                  Current Password
                </label>

                <div className="mt-2 ">
                  <div className="flex relative">
                    <input
                      value={currentPassword}
                      onChange={(e) => setCurrentPassword(e.target.value)}
                      type={`${showPassword2 === false ? "password" : "text"}`}
                      id="oldpassword"
                      name="oldpassword"
                      placeholder="Current Password"
                      className="px-2 block w-full border-0 py-1.5 text-[#212121] ring-1 ring-inset ring-gray-400 placeholder:text-gray-400  group-hover:ring-2 group-hover:ring-[#eebd2d] group-hover:outline-none group-hover:ring-inset focus:ring-2 focus:ring-inset focus:ring-[#eebd2d] focus:outline-none text-sm sm:leading-6 pr-10"
                      required
                    />
                    <div
                      className="absolute inset-y-0 right-2 px-2 flex align-items-center "
                      id="basic-addon1"
                      onClick={() => setShowPassword2(!showPassword2)}
                    >
                      {showPassword2 === false ? (
                        <HiMiniEyeSlash size={22} />
                      ) : (
                        <IoEyeSharp size={22} color="#FFC94A" />
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-3 group">
                <label className="newpassword  block text-sm font-semibold leading-4 text-[#212121]">
                  New Password
                </label>

                <div className="mt-2 ">
                  <div className="flex relative">
                    <input
                      value={pass}
                      onChange={(e) => validate(e.target.value)}
                      type={`${showPassword === false ? "password" : "text"}`}
                      placeholder="New Password"
                      id="password"
                      name="password"
                      className="px-2 block w-full border-0 py-1.5 text-[#212121] ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 group-hover:ring-2 group-hover:ring-[#eebd2d] group-hover:outline-none group-hover:ring-inset focus:ring-2 focus:ring-inset focus:ring-[#eebd2d] focus:outline-none text-sm sm:leading-6 pr-10"
                      required
                    />
                    <div
                      className="absolute inset-y-0 right-2 px-2 flex align-items-center "
                      id="basic-addon1"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword === false ? (
                        <HiMiniEyeSlash size={22} />
                      ) : (
                        <IoEyeSharp size={22} color="#FFC94A" />
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-3 group">
                <label className="confirmpassword  block text-sm font-semibold leading-4 text-[#212121]">
                  Confirm New Password
                </label>

                <div className="mt-2 ">
                  <div className="flex relative">
                    <input
                      type={`${showPassword1 === false ? "password" : "text"}`}
                      value={confirmpass}
                      onChange={(e) => setConfirmpass(e.target.value)}
                      placeholder="Confirm Password"
                      id="confirmpassword"
                      name="confirmpassword"
                      className="px-2 block w-full border-0 py-1.5 text-[#212121] ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 group-hover:ring-2 group-hover:ring-[#eebd2d] group-hover:outline-none group-hover:ring-inset focus:ring-2 focus:ring-inset focus:ring-[#eebd2d] focus:outline-none text-sm sm:leading-6 pr-10"
                      required
                    />
                    <div
                      className="absolute inset-y-0 right-2 px-2 flex align-items-center "
                      id="basic-addon1"
                      onClick={() => setShowPassword1(!showPassword1)}
                    >
                      {showPassword1 === false ? (
                        <HiMiniEyeSlash size={22} />
                      ) : (
                        <IoEyeSharp size={22} color="#FFC94A" />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 my-1">
              {errorMessage === "" ? null : <span>{errorMessage}</span>}
            </div>
            <div className="">
              <button
                className="flex w-full justify-center  bg-[#FFC94A] px-3 py-2 duration-300 ease-in-out text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[#e2a731] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#dc9119]"
                type="submit"
              >
                Update Password
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
export default ChangePassword;
