import React from "react";
import Header from "../../components/Header/Header";
import { TbPointFilled } from "react-icons/tb";

function TermsConditions() {
  return (
    <>
      <Header />
      <div className="after-header pb-5 max-w-[1600px] mx-auto">
        <div className="w-[90%] mx-auto">
          <div className="w-full flex flex-wrap justify-start mt-4">
            <div className="w-full p-4">
              <div className="text-[#212121] font-medium">
                <p className="text-center font-semibold text-2xl md:text-3xl  text-yellow-500">
                  Terms of Use
                </p>
                <p className=" text-sm mt-4">
                  The users of this website understand that any use of the
                  information / contents and any purchase or sourcing related
                  decisions are at the sole discretion &amp; risk of such
                  persons. The information presented under newsletter and blogs
                  is solely for informational purposes.
                </p>
                <p className="text-base mt-6 font-semibold">
                  Terms and Conditions for Client:
                </p>
                <p className="mt-3 text-sm  space-y-6">
                  <ol type="1" className="list-decimal pl-5 space-y-4 ">
                    <li>
                      <p className="m-0 text-base font-semibold ">Accounts</p>
                      To use our Services, you must provide accurate and
                      up-to-date information on your Address and Business
                      details. You may need to provide details and documents
                      such as your Legal Name, GST No., Company or LLP
                      Identifier No., Shop &amp; Establishment Certificate or
                      Import-Export Code. It is your responsibility to keep your
                      Email, WhatsApp and Password secure. Unauthorized use of
                      your Account due to your inability to secure your
                      credentials will be your responsibility and may result in
                      losses incurred by BUWCH Lifestyles, or other third-party
                      firms.
                    </li>
                    <br />
                    <li>
                      <p className="m-0 text-base font-semibold">
                        Subscription
                      </p>
                      Any User subscribing to, using, or accessing the services
                      available through this site, is registered as a single
                      user of the services. The applicable rate of the
                      Subscription Fees for some of the services provided herein
                      shall be such as mentioned on the site and/or as discussed
                      with the concerned users from time to time. User’s
                      liability for the Subscription Fees, if any, shall accrue
                      from the Date of Commencement of services or registration.
                    </li>
                    <br />
                    <li>
                      <p className="m-0 text-base font-semibold">
                        Processing of Transactions
                      </p>
                      All transactions/changes, as applied to/requested, by the
                      user shall be processed as per the applicable rules, laws
                      and regulations, business practices and as agreed with the
                      users/clients. Revision of Mobile No.,
                      Orders-in-production, Products Listed and Shipping Address
                      shall be authorized exclusively through OTP verification
                      utilizing the registered mobile number. BUWCH Lifestyles
                      shall deem the User to have comprehended and consented to
                      said modifications upon completion of OTP-based
                      authentication.
                    </li>{" "}
                    <br />
                    <li>
                      <p className="m-0 text-base font-semibold">
                        Use of Password
                      </p>
                      <ol type="i" className="list-lowercase pl-5 space-y-2 ">
                        <li>
                          In the event a User is given a password, the User
                          shall ensure that such password is kept in a secure
                          manner. To protect the secrecy of their user
                          identification and/or password the user shall take all
                          such measures as may be necessary and shall not reveal
                          the same to any other person(s).
                        </li>
                        <li>
                          Since a user identification is necessary to access
                          some of the services available through the site; the
                          user shall use only his own user identification while
                          availing such services. In the event of theft and/or
                          loss of user identification and/or password and/or
                          security word, the user shall notify the Company or
                          its management/representatives immediately by
                          telephone and/or concurrently provide BUWCH Lifestyles
                          with a written notice to that effect. The user shall
                          remain solely liable for the use/misuse of the
                          services by any third party until such theft or loss
                          is duly notified.
                        </li>
                        <li>
                          The password and username being made available to the
                          user shall be used only by the user/organization named
                          in the Account, and the authorised employees of said
                          organization. BUWCH Lifestyles will legally recognize
                          only the named User as the sole valid counterparty
                          while discharging of liabilities or delivery of goods
                          &amp; services.
                        </li>
                        <li>
                          Password reset shall be authorized solely through OTP
                          verification via the user&#39;s registered Email and
                          Mobile number. The Company will not be held liable in
                          the event of compromise of said OTPs by the User or if
                          the User loses access to the registered Email or
                          Mobile number through any means.
                        </li>
                      </ol>
                    </li>
                    <br />
                    <li>
                      <p className="m-0 text-base font-semibold">
                        Unauthorised Access
                      </p>
                      As a condition for your use of this site, you will not use
                      the site for any purpose that is unlawful or prohibited by
                      these terms, conditions, and notices. You may not use the
                      site in any manner that could damage, disable, overburden,
                      or impair the network(s) connected to this site or
                      interfere with any other party’s use and enjoyment of this
                      site. You may not attempt to gain unauthorised access to
                      this site, other accounts, computer systems or networks
                      connected to the site, through hacking, password mining or
                      any other means. You may not obtain or attempt to obtain
                      any materials or information through any means not
                      intentionally made available through the site.
                    </li>
                    <br />
                    <li>
                      <p className="m-0 text-base font-semibold">
                        Discontinuation or Modification to Services
                      </p>
                      BUWCH Lifestyles reserves the unilateral right to add to,
                      change and/or end the service(s) available through the
                      website at any time with or without notice to the User.
                      Except for prepaid goods &amp; services, a pro-rated
                      refund shall be affected for the remaining unused period
                      to the subscribed User.
                    </li>
                    <br />
                    <li>
                      <p className="m-0 text-base font-semibold">
                        Suspension of Service
                      </p>
                      If any monies payable by the user to BUWCH Lifestyles are
                      not paid on the due date or if at its sole discretion any
                      of the users of this site/portal is found to be using this
                      site for illegal purposes or is observed having violated
                      any of the terms and conditions as are applicable for
                      usage of this site, the Company may without prejudice to
                      any other rights or remedies that may be available to it
                      suspend the service (s) provided to such user. Upon
                      subsequent payment by the user of such monies as demanded
                      by BUWCH Lifestyles, the Company or its representatives
                      may at its discretion and subject to such terms as it
                      deems proper, reconnect/resume such service(s).
                    </li>
                    <br />
                    <li>
                      <p className="m-0 text-base font-semibold">
                        User Obligations
                      </p>
                      The user undertakes to:
                      <ol type="i" className="list-lowercase pl-5 space-y-2">
                        <li>
                          agree to receive periodic alerts and notifications and
                          Email &amp; WhatsApp marketing materials.
                        </li>
                        <li>
                          ensure that you obtain all necessary rights to the
                          content you post or share on the site as reviews or
                          comments.
                        </li>
                        <li>
                          Ensure upon entering the GSTIN, Email ID, and Full
                          Name, users acknowledge that these details cannot be
                          modified thereafter. It is the user&#39;s sole
                          responsibility to ensure the accuracy of information
                          provided in their government- issued IDs and/or
                          Certificates. Account activation on this portal is
                          contingent upon successful KYC verification.
                        </li>
                      </ol>
                    </li>
                    <br />
                    <li>
                      <p className="m-0 text-base font-semibold">
                        Non-exclusive Remedy
                      </p>
                      Termination or expiration of this arrangement, in part or
                      in whole, shall not limit either party from pursuing other
                      remedies available to it, nor shall either party be
                      relieved of its obligation to pay all fees that are due
                      and owing under this arrangement through the effective
                      date of termination. Neither party shall be liable to the
                      other for any damages resulting solely from termination as
                      permitted herein.
                    </li>
                    <br />
                    <li>
                      <p className="m-0 text-base font-semibold">
                        Exclusion of Warranties
                      </p>
                      <ol type="i" className="list-lowercase pl-5 space-y-2">
                        <li>
                          The use of the Platform and the Services is at your
                          own risk and consequence. In no event shall the
                          Company be held liable for any damages or claims
                          whatsoever in connection with any inaccuracy, error,
                          failure, omission, delay or otherwise on your part
                          towards any third-party;
                        </li>
                        <li>
                          Nothing in these Terms shall exclude or limit your
                          warranty or liability for losses unless they are
                          excluded or limited by applicable law. Some
                          jurisdictions do not allow the exclusion of certain
                          warranties or conditions or the limitation or
                          exclusion of liability for loss or damage caused by
                          negligence, breach of contract or breach of implied
                          terms, or incidental or consequential damages.
                          Accordingly, only the limitations which are lawful in
                          your jurisdiction will apply to you and our liability
                          will be limited to the maximum extent permitted by
                          law.
                        </li>
                        <br />
                        <p className="font-semibold m-0 text-sm">
                          We further DO NOT represent or warrant to you that:
                        </p>
                        <li>
                          Your use of our Platform and Services will meet your
                          requirements;
                        </li>
                        <li>
                          Your use of the Platform and Services will be
                          uninterrupted, timely, secure or free from bugs or
                          coding error, including specifically from server
                          downtime;
                        </li>
                        <li>
                          The product pictures and 3D objects are for reference
                          purposes only and might not accurately reflect all the
                          exact features of the actual goods.
                        </li>
                        <li>
                          You agree that any material downloaded or otherwise
                          obtained through the use of our Services is done at
                          your own discretion and risk and we shall not be
                          liable for any damage to your computer system or other
                          device or loss of data that results from the download
                          of any such material.
                        </li>
                        <li>
                          No advice or information, whether oral or written,
                          obtained by you from us or from the use of Services,
                          shall create any warranty not expressly stated in
                          these Terms.
                        </li>
                      </ol>
                    </li>
                    <br />
                    <li>
                      <p className="m-0 text-base font-semibold">
                        Restrictions
                      </p>
                      <ol type="i" className="list-lowercase pl-5 space-y-2">
                        <li>
                          You agree not to attempt to reverse-engineer,
                          disseminate, copy, exploit, or represent yourself as
                          an agent or reseller of the Platform and the Services,
                          or any part thereof.
                        </li>
                        <li>
                          You agree that you are granted a non-exclusive license
                          for the purpose of enabling you to use and access our
                          Platform and Services. Except with our prior written
                          consent, you may not assign (or grant a sub-license
                          of) your rights to use the Service or otherwise
                          transfer any part of your rights to use the Service.
                        </li>
                        <li>
                          You declare and warrant that you possess the legal
                          right to enter into these Terms and to use the
                          Platform and Services in accordance with these Terms.
                        </li>
                        <li>
                          You agree not to upload or transmit any vulgar,
                          obscene, abusive, defamatory, libellous, pornographic,
                          paedophilic, invasive of another’s privacy including
                          bodily privacy, or unlawful content, including content
                          which is harmful to children, objectionable or results
                          in a breach of privacy of any other person, content
                          which is insulting or harassing on the basis of
                          gender, libelous, racially or ethnically
                          objectionable, relating or encouraging money
                          laundering or gambling, or otherwise inconsistent with
                          or contrary to the laws in force in India;
                        </li>
                        <li>
                          You agree not to carry out or facilitate any actions
                          which result in a detrimental effect on the Website or
                          the Services or any related computer resource,
                          including DDoS attacks, data security breaches, or
                          otherwise any disruption to the servers, hardware, or
                          network of any other person or the Website or the
                          Services;
                        </li>
                      </ol>
                    </li>
                    <br />
                    <li>
                      <p className="m-0 text-base font-semibold">
                        Intellectual Property Rights
                      </p>
                      <ol type="i" className="list-lowercase pl-5 space-y-2">
                        <li>
                          You acknowledge and agree that the Company, Associate
                          Companies or their respective licensors, own all legal
                          rights, title and interest in and to the Services,
                          including any Intellectual Property Rights which
                          subsist in the Service.
                        </li>
                        <li>
                          You agree that you shall have no right to the
                          intellectual property, Trademarked, Copyright or
                          Patented information made available or accessible
                          through the Website or any of its Services;
                        </li>
                        <li>
                          Unless you receive our express authorization in
                          writing, you agree that in using the Services or
                          accessing our Platform, you shall not use or reproduce
                          design, styling, service mark, product name or the
                          Company logo/brand in a way that is likely or intended
                          to cause confusion about the owner or authorized user
                          of such marks, names or logos.
                        </li>
                        <li>
                          No use, reproduction, transformation or storage of
                          Information designated as proprietary or confidential
                          shall be made by the user without the prior written
                          permission of BUWCH Lifestyles, except where required
                          to be disclosed pursuant to any applicable laws or
                          legal process issued by any court or the rules of any
                          competent regulatory body.
                        </li>
                        <li>
                          The User agrees not to copy, reproduce, recompile,
                          decompile, assemble, disassemble, distribute, publish,
                          display, modify or upload to, create derivative works
                          from, transmit, or in any way exploit any part of the
                          Service, except for downloading material from the
                          Service for their own personal, non-commercial use.
                        </li>
                      </ol>
                    </li>
                    <br />
                    <li>
                      <p className="m-0 text-base font-semibold">
                        Miscellaneous
                      </p>
                      In order to provide the Services and access to the
                      Platform, the Company may be required to create a user
                      profile that may be linked to third-party platforms or
                      Associate Company’s platforms as may be necessary or
                      requested by you subject to verification. The registration
                      of the user profile shall require the Company to retain
                      certain User Data for providing a safe and efficient user
                      experience;
                      <br />
                      The Company, and their respective affiliates,
                      subsidiaries, directors, employees, promoters and agents
                      shall not be liable for:
                      <ol type="i" className="list-lowercase pl-5 space-y-2">
                        <li>
                          any direct, indirect, incidental, special,
                          consequential or exemplary loss or damage suffered by
                          the User, however, caused and shall include any loss
                          of profit (whether incurred directly or indirectly),
                          any loss of goodwill or business reputation, any loss
                          of data suffered, cost of procurement of substitute
                          goods or Services, or other intangible loss, arising
                          out of the use of, or inability to use or access of
                          our Platform or Service or any security breach or any
                          virus, bug, unauthorised intervention, technical
                          malfunctioning or defect, whether or not forceable and
                          whether or not we have been advised of the possibility
                          of such damage.
                        </li>
                        <li>
                          Any loss or damage incurred by you owing to any
                          temporary disablement or permanent discontinuance of
                          our Platform or Services and any deletion, corruption
                          or failure to store any content, data or other
                          communications maintained in the course of your use of
                          the Platform; and
                        </li>
                        <li>
                          which may be incurred by you, including but not
                          limited to loss or damage as a result of any reliance
                          placed by you on the completeness, accuracy or
                          existence of any advertising, or as a result of any
                          relationship or transaction between you and any
                          advertiser or sponsor whose advertisement appears on
                          the Services.
                        </li>
                        <li>
                          The Privacy Policy shall be read to form a part of
                          these Terms;
                        </li>
                        <li>
                          These Terms shall be governed by and under the laws of
                          India and shall be subject to the exclusive
                          jurisdiction of Delhi. Any failure or delay by the
                          Company to exercise or enforce any right or provision
                          of the Terms shall not constitute a waiver of such
                          right or provision. If any part of the Terms is found
                          by a court of competent jurisdiction to be invalid,
                          the parties agree that the court should endeavour to
                          give effect to the parties’ intentions as reflected in
                          the provision, and the other provisions of the Terms
                          shall remain in full force and effect. You agree that
                          regardless of any statutory period of limitation, any
                          claim shall be filed within one (1) year after the
                          relevant cause of action arose.
                        </li>
                      </ol>
                    </li>
                  </ol>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TermsConditions;
