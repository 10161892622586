import React, { useState } from "react";
import { FaLocationArrow } from "react-icons/fa6";

function Financialfilters() {
  const [dropdownState, setDropdownState] = useState({
    openDropdown: null,
    selectedYear: "Financial Year",
    selectedQuarter: "Quarter",
  });
  const toggleDropdown = (dropdown) => {
    setDropdownState((prevState) => ({
      ...prevState,
      openDropdown: prevState.openDropdown === dropdown ? null : dropdown,
    }));
  };
  // const selectYear = (year) => {
  //   setDropdownState({
  //     openDropdown: false,
  //     selectedYear: year,
  //   });
  // };

  return (
    <div className="flex flex-wrap items-end gap-10 ">
      {/* Choose Financial Year */}
      <div className="relative  text-left w-full md:w-auto">
        <p className="text-sm font-semibold w-full">
          Financial Year <span className="text-red-600">*</span>
        </p>
        <div
          type="button"
          className={`dropdown-button flex items-center justify-between gap-2 px-2 py-1 bg-white rounded-md transition-all duration-300 focus:outline-none mt-1 w-full md:min-w-[160px] cursor-pointer ${
            dropdownState.openDropdown === "true"
              ? "shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)]"
              : "shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)]"
          }`}
          onClick={() => toggleDropdown("Financial Year")}
        >
          <span className="text-[13px] font-medium">
            {dropdownState.selectedYear}
          </span>
          <svg
            className={`w-5 h-5 text-gray-400 ${
              dropdownState.openDropdown === "Financial Year"
                ? "rotate-180 duration-500"
                : ""
            }`}
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" />
          </svg>
        </div>

        <div className={`z-20 absolute md:top-14 w-[70%] md:w-full left-0 overflow-hidden bg-white rounded-md   transition-all duration-500 ease-in-out ${dropdownState.openDropdown === "Financial Year" ? "h-20 border-b-[2px] border-[#FFD61F] shadow-lg opacity-100":"h-0 opacity-50"}`}>
            {[
              "2023-2024",
              "2024-2025",
            ].map((option) => (
              <a
                href="#"
                key={option}
                className="block px-4 py-2 text-[13px] font-medium hover:bg-[#fff4b5a1] hover:text-[#000000] border-b"
                onClick={() =>
                  setDropdownState((prevState) => ({
                    ...prevState,
                    selectedYear: option,
                    openDropdown: null,
                  }))
                }
              >
                {option}
              </a>
            ))}
          </div>
      </div>

      {/* Choose Quarter */}
      <div className="relative text-left ">
        <p className="text-sm font-semibold">
          Quarter <span className="text-red-600">*</span>
        </p>
        <button
          type="button"
          className={`dropdown-button flex items-center justify-between gap-2 px-2 py-1 bg-white rounded-md transition-all duration-300 focus:outline-none mt-1 min-w-[160px] ${
            dropdownState.openDropdown === "quarter"
              ? "shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)]"
              : "shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)]"
          }`}
          onClick={() => toggleDropdown("quarter")}
        >
          <span className="text-[13px] font-medium">
            {dropdownState.selectedQuarter}
          </span>
          <svg
            className={`w-5 h-5 text-gray-400 ${
              dropdownState.openDropdown === "quarter"
                ? "rotate-180 duration-500"
                : ""
            }`}
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" />
          </svg>
        </button>

        
          <div className={`z-20 absolute md:top-14 w-[70%] md:w-full left-0 overflow-hidden bg-white rounded-md transition-all duration-500 ease-in-out ${dropdownState.openDropdown === "quarter" ? "h-32 border-b-[2px] border-[#FFD61F] shadow-lg opacity-100":"h-0 opacity-50"}`}>
            {[
              "Q1 (Apr-Jun)",
              "Q2 (Jul-Sep)",
              "Q3 (Oct-Dec)",
              "Q4 (Jan-Mar)",
            ].map((option) => (
              <a
                href="#"
                key={option}
                className="block px-4 py-2 text-[13px] font-medium hover:bg-[#fff4b5a1] hover:text-[#000000] border-b"
                onClick={() =>
                  setDropdownState((prevState) => ({
                    ...prevState,
                    selectedQuarter: option,
                    openDropdown: null,
                  }))
                }
              >
                {option}
              </a>
            ))}
          </div>
   
      </div>

      <button className="px-3 py-1 text-[13px] rounded font-semibold bg-[#0EA5E9] text-white relative overflow-hidden group z-[5] hover:text-white duration-1000 flex items-center gap-1">
        View
        <span>
          <FaLocationArrow />
        </span>
      </button>
    </div>
  );
}

export default Financialfilters;
