import React, { useState } from "react";
import Financialfilters from "../../components/AdminComponent/Financialfilters.jsx";
import Ledger2 from "../../components/AdminComponent/Ledger2.jsx";
import Pageno from "../../components/AdminComponent/Pageno.jsx";
import { FaLocationArrow } from "react-icons/fa6";
import TableData from "../../components/AdminComponent/TableData.jsx";
function BusinessUser() {
  const [openInvoice, setOpenInvoice] = useState(false);

  const [dropdownState, setDropdownState] = useState({
    openDropdown: null,
    selectedYear: "Financial Year",
    selectedQuarter: "Quarter",
  });
  const toggleDropdown = (dropdown) => {
    setDropdownState((prevState) => ({
      ...prevState,
      openDropdown: prevState.openDropdown === dropdown ? null : dropdown,
    }));
  };

  return (
    <div
      style={{ height: "calc(100vh - 9.5rem)" }}
      className="text-sm relative"
    >
      {/* Top Part */}
      <div className="flex flex-col gap-1 items-center justify-center">
        <h2 className="text-2xl font-extrabold">
          Ledger <span className="text-[#f7b935]">Report</span>
        </h2>
        <p className="text-center w-[45%] text-gray-600 font-medium text-[13px]">
          Ledger is a record-keeping system that tracks all financial
          transactions in organized accounts.
        </p>
      </div>

      {/* Date Filters */}
      <div className="w-full py-5 pt-8 pl-1 flex items-end justify-between">
        <div className="flex items-end gap-5">
          <Financialfilters />
          <button className="flex items-center justify-center gap-1.5 border-[1px] p-1 pr-2 rounded-full font-medium group duration-300 hover:bg-[#FFF4B5] hover:border-[#FFD61F]">
            <svg
              fill="none"
              className="w-5 h-5 fill-[#000000] duration-300"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              id="fi_18604906"
            >
              <path
                clip-rule="evenodd"
                d="m1.25 12c0-5.93706 4.81294-10.75 10.75-10.75 5.9371 0 10.75 4.81294 10.75 10.75 0 5.9371-4.8129 10.75-10.75 10.75-5.93706 0-10.75-4.8129-10.75-10.75zm11.7559-4c0-.55228-.4478-1-1-1-.5523 0-1 .44772-1 1v4.5h-.5943c-.1756-.0001-.3913-.0003-.56773.0218l-.00334.0004c-.12645.0158-.70249.0876-.97685.6532-.27496.5669.02697 1.067.09229 1.1752l.00244.004c.09221.153.22636.3239.3367.4645l.02374.0302c.29463.3759.6766.8601 1.05705 1.2511.1898.1951.4071.3963.638.5552.2052.1412.5554.3444.9861.3444s.7809-.2032.9861-.3444c.2309-.1589.4482-.3601.638-.5552.3805-.391.7624-.8752 1.0571-1.2511l.0237-.0302c.1103-.1406.2445-.3114.3367-.4645l.0024-.004c.0653-.1082.3673-.6084.0923-1.1752-.2743-.5656-.8504-.6374-.9768-.6532l-.0034-.0004c-.1764-.0221-.3921-.0219-.5677-.0218h-.5825z"
                fill-rule="evenodd"
              ></path>
            </svg>
            <span className="">PDF</span>
          </button>
        </div>
        <div className="flex gap-4 items-center px-3">
          <button
            onClick={() => setOpenInvoice(true)}
            className="duration-300 px-3 py-1.5 rounded-full text-[13px] font-semibold bg-[#FFD61F]  text-black flex items-center justify-center gap-2 w-fit disabled:opacity-50 disabled:cursor-not-allowed hover:shadow-sm"
          >
            Payment History
            <span>
              <svg
                id="fi_8270260"
                className="w-5 h-5"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  id="document"
                  d="m13.75 12a.75.75 0 0 1 -.75.75h-6a.75.75 0 0 1 0-1.5h6a.75.75 0 0 1 .75.75zm-6.75-3.25h3a.75.75 0 0 0 0-1.5h-3a.75.75 0 0 0 0 1.5zm6 6.5h-6a.75.75 0 0 0 0 1.5h6a.75.75 0 0 0 0-1.5zm8.75-3.75v7.5a2.752 2.752 0 0 1 -2.75 2.75h-13a3.383 3.383 0 0 1 -3.75-3.75v-12a3.383 3.383 0 0 1 3.75-3.75h8a3.383 3.383 0 0 1 3.75 3.75v3.25h1.75a2.253 2.253 0 0 1 2.25 2.25zm-5.2 8.75a2.723 2.723 0 0 1 -.3-1.25v-13c0-1.577-.673-2.25-2.25-2.25h-8c-1.577 0-2.25.673-2.25 2.25v12c0 1.577.673 2.25 2.25 2.25zm3.7-8.75a.751.751 0 0 0 -.75-.75h-1.75v8.25a1.25 1.25 0 0 0 2.5 0z"
                  fill="rgb(0,0,0)"
                ></path>
              </svg>
            </span>
          </button>
        </div>
      </div>

      {/* Ledger */}
      <div className="">
        <Ledger2 />
      </div>

      {/* Invoice History */}
      {openInvoice === true && (
        <div className="slider-container rounded-md absolute right-0 top-0">
          <div className="w-[90%] absolute right-0 rounded-md h-full bg-white shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] p-5 overflow-x-auto text-[13px] rightToLeft">
            <span
              onClick={() => setOpenInvoice(false)}
              className="bg-[#000000] p-1.5 rounded-lg flex items-center justify-center cursor-pointer w-fit"
            >
              <svg
                className="w-[13px] h-[13px] fill-[#ffffff]"
                id="fi_11488614"
                enable-background="new 0 0 512 512"
                viewBox="0 0 512 512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="Icon">
                  <g id="_43">
                    <path d="m235.4 256 149.7-149.7c20-20 20-52.3 0-72.3-20-20-52.3-20-72.3 0l-185.9 185.9c-20 20-20 52.3 0 72.3l185.9 185.8c20 20 52.3 20 72.3 0 20-20 20-52.3 0-72.3z"></path>
                  </g>
                </g>
              </svg>
            </span>
            <div className="flex flex-col gap-1 items-center justify-center">
              <h2 className="text-2xl font-extrabold">
                Payment <span className="text-[#f7b935]">History</span>
              </h2>
              <p className="text-center w-[45%] text-gray-600 font-medium text-[13px]">
                Payment history is a record of past payments, reflecting
                timeliness and reliability.
              </p>
            </div>
            {/* Date Filters */}
            <div className="py-6 flex justify-between items-end">
              <div className="flex items-end gap-5">
                <div className="flex flex-wrap  gap-10 items-end juestify-between">
                  {/* Choose Financial Year */}
                  <div className="relative  text-left w-full md:w-auto">
                    <p className="text-[13px] font-semibold w-full">
                      Date From <span className="text-red-600">*</span>
                    </p>

                    <input
                      type="date"
                      className="mt-1 px-2 py-1 text-gray-700 bg-white  border-gray-300 rounded-md focus:outline-none shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] transition-all font-medium min-w-[160px]"
                    />
                  </div>

                  {/* Choose Quarter */}
                  <div className="relative  text-left ">
                    <p className="text-[13px] font-semibold">
                      Date To <span className="text-red-600">*</span>
                    </p>
                    <input
                      type="date"
                      className="mt-1 px-2 py-1 text-gray-700 bg-white  border-gray-300 rounded-md focus:outline-none shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] transition-all font-medium min-w-[160px]"
                    />
                  </div>

                  <button className="px-3 py-1 text-[13px] rounded font-semibold bg-[#0EA5E9] text-white relative overflow-hidden group z-[5] hover:text-white duration-1000 flex items-center gap-1">
                    View
                    <span>
                      <FaLocationArrow />
                    </span>
                  </button>
                </div>
                <button className="flex items-center justify-center gap-1.5 border-[1px] p-1 pr-2 rounded-full font-medium group duration-300 hover:bg-[#FFF4B5] hover:border-[#FFD61F]">
                  <svg
                    fill="none"
                    className="w-5 h-5 fill-[#000000] duration-300"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    id="fi_18604906"
                  >
                    <path
                      clip-rule="evenodd"
                      d="m1.25 12c0-5.93706 4.81294-10.75 10.75-10.75 5.9371 0 10.75 4.81294 10.75 10.75 0 5.9371-4.8129 10.75-10.75 10.75-5.93706 0-10.75-4.8129-10.75-10.75zm11.7559-4c0-.55228-.4478-1-1-1-.5523 0-1 .44772-1 1v4.5h-.5943c-.1756-.0001-.3913-.0003-.56773.0218l-.00334.0004c-.12645.0158-.70249.0876-.97685.6532-.27496.5669.02697 1.067.09229 1.1752l.00244.004c.09221.153.22636.3239.3367.4645l.02374.0302c.29463.3759.6766.8601 1.05705 1.2511.1898.1951.4071.3963.638.5552.2052.1412.5554.3444.9861.3444s.7809-.2032.9861-.3444c.2309-.1589.4482-.3601.638-.5552.3805-.391.7624-.8752 1.0571-1.2511l.0237-.0302c.1103-.1406.2445-.3114.3367-.4645l.0024-.004c.0653-.1082.3673-.6084.0923-1.1752-.2743-.5656-.8504-.6374-.9768-.6532l-.0034-.0004c-.1764-.0221-.3921-.0219-.5677-.0218h-.5825z"
                      fill-rule="evenodd"
                    ></path>
                  </svg>
                  <span className="">PDF</span>
                </button>
              </div>

              <div className="flex items-center justify-between border-[1px] border-gray-400 rounded-full pl-2 pr-0.5 py-0.5 bg-white min-w-[200px] max-w-[200px]">
                <input
                  type="text"
                  // value={filterTable}
                  // onChange={(e) => setFiterTable(e.target.value)}
                  className="text-[13px] font-medium text-gray-600 placeholder-gray-400 focus:outline-none w-full bg-transparent placeholder:text-xs"
                  placeholder="Order id"
                />
                <button className="flex items-center justify-center p-1.5 bg-yellow-400 rounded-full hover:bg-yellow-500 transition-all duration-300">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    className="w-3.5 h-3.5 text-black"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M21 21l-4.35-4.35M10 18a8 8 0 100-16 8 8 0 000 16z"
                    />
                  </svg>
                </button>
              </div>
            </div>

            {/* Table Data */}
            <div className="mt-5">
              <TableData />
            </div>

            {/* Page Numbers */}
            <div className="mt-10">
              <Pageno />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default BusinessUser;
