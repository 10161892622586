import { useState, useEffect } from "react";
import secureLocalStorage from "react-secure-storage";

const transformCustomizeToProductLocal = (
  customize,
  productId,
  style,
  subCategory,
  action
) => {
  const matchingEntry = customize.find((entry) => {
    return entry?.Article_no === productId;
  });
  let productLocal = null;
  if (!matchingEntry) {
    const pattern = new RegExp(`^${subCategory}_${style}_(a\\d+)$`, "i"); // Added 'i' flag for case-insensitivity
    const matchingArticles = customize
      .map((entry) => entry?.Article_no)
      .filter((article) => {
        return pattern.test(article);
      });

    let maxNumber = 0;
    matchingArticles.forEach((article) => {
      const match = article.match(pattern);
      if (match) {
        const number = parseInt(match[1].substring(1), 10);
        maxNumber = Math.max(maxNumber, number);
      }
    });

    const nextNumber = maxNumber + 1;

    let articleNo = `${subCategory}_${style}_a${nextNumber}`;
    articleNo =
      articleNo.charAt(0).toUpperCase() + articleNo.slice(1).toLowerCase();

    productLocal = {
      Article_no: articleNo,
    };
  } else {
    if (action === "edit") {
      productLocal = {
        Article_no: matchingEntry?.Article_no || "",
        Leather: matchingEntry?.Leather || {},
        Sole: matchingEntry?.Sole || {},
        Edge: matchingEntry?.Edge || [],
        Lining: matchingEntry?.Lining || {},
        Eyelets: matchingEntry?.Eyelets || [],
        Buckle: matchingEntry?.Buckle || {},
        Shoelace: matchingEntry?.Shoelace || {},
        Edit: matchingEntry?.Edit || false,
        Altered: matchingEntry?.Altered?.Leather
          ? matchingEntry?.Altered
          : {
              ...matchingEntry?.Altered,
              Leather: matchingEntry?.Leather,
            },
      };
    } else {
      productLocal = {
        Article_no: matchingEntry?.Article_no || "",
        Leather: matchingEntry?.Leather || {},
        Sole: matchingEntry?.Sole || {},
        Edge: matchingEntry?.Edge || [],
        Lining: matchingEntry?.Lining || {},
        Eyelets: matchingEntry?.Eyelets || [],
        Buckle: matchingEntry?.Buckle || {},
        Shoelace: matchingEntry?.Shoelace || {},
        Edit: matchingEntry?.Edit || false,
      };
    }
  }
  return productLocal;
};

const LeatherSelection = ({
  onNext,
  style,
  ProductId,
  materialCost,
  subCategory,
  action,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(true);
  const [selectedLeather, setSelectedLeather] = useState("Boxcalf");
  const [fadeEffect, setFadeEffect] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [leatherOptions, setLeatherOptions] = useState([]);
  const [finishingOptions, setFinishingOptions] = useState([]);
  const storedData2 = secureLocalStorage.getItem("Customize");
  const Customize = JSON.parse(storedData2);
  const [ProductLocal, setProductLocal] = useState({});

  useEffect(() => {
    materialCost?.forEach((item) => {
      if (item.Leathers) {
        setLeatherOptions(item.Leathers);
      }
      if (item.Finishings) {
        setFinishingOptions(item.Finishings);
      }
    });
  }, []);

  useEffect(() => {
    setProductLocal(
      transformCustomizeToProductLocal(
        Customize,
        ProductId,
        style,
        subCategory,
        action
      )
    );
  }, [style, subCategory, ProductId]);

  useEffect(() => {
    const handleClickOutside = (e) => {
      const modal = document.getElementById("modal");
      if (modal && !modal.contains(e.target)) {
        setIsModalOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const colors = Object.entries(leatherOptions[selectedLeather] || {}).map(
    ([id, details]) => ({
      ["id"]: id,
      ["color"]: details.Color,
      ["img"]: details.Img,
    })
  );

  const handleLeatherSelect = (type) => {
    if (action === "edit") {
      setProductLocal((prev) => {
        const updated = { ...prev };
        if (!updated.Altered.Leather) {
          updated.Altered.Leather = updated.Leather;
        }
        // Remove empty Leather entries
        if (updated.Altered.Leather) {
          Object.keys(updated.Altered.Leather).forEach((key) => {
            if (
              key !== "Consumption" &&
              !updated.Altered.Leather[key].Colors?.length &&
              !updated.Altered.Leather[key].Finishings?.length
            ) {
              delete updated.Altered.Leather[key];
            }
          });
        } else {
          updated.Altered.Leather = {};
        }

        setSelectedLeather(type);
        setFadeEffect(true);
        setIsMenuOpen(false);

        if (!updated.Altered.Leather[type]) {
          updated.Altered.Leather[type] = { Colors: [], Finishings: [] };
        }

        return updated;
      });
    } else {
      setProductLocal((prev) => {
        const updated = { ...prev };

        // Remove empty Leather entries
        if (updated.Leather) {
          Object.keys(updated.Leather).forEach((key) => {
            if (
              key !== "Consumption" &&
              !updated.Leather[key].Colors?.length &&
              !updated.Leather[key].Finishings?.length
            ) {
              delete updated.Leather[key];
            }
          });
        } else {
          updated.Leather = {};
        }

        setSelectedLeather(type);
        setFadeEffect(true);
        setIsMenuOpen(false);

        if (!updated.Leather[type]) {
          updated.Leather[type] = { Colors: [], Finishings: [] };
        }

        return updated;
      });
    }
    setTimeout(() => setFadeEffect(false), 500);
  };

  const handleColorToggle = (color) => {
    if (action === "edit") {
      setProductLocal((prev) => {
        const updated = JSON.parse(JSON.stringify(prev));

        if (!updated.Altered.Leather) {
          updated.Altered.Leather = {};
        }

        // Remove empty Leather entries
        Object.keys(updated.Altered.Leather).forEach((key) => {
          if (
            key !== "Consumption" &&
            !updated.Altered.Leather[key].Colors?.length &&
            !updated.Altered.Leather[key].Finishings?.length
          ) {
            delete updated.Altered.Leather[key];
          }
        });

        if (!updated.Altered.Leather[selectedLeather]) {
          updated.Altered.Leather[selectedLeather] = {
            Colors: [],
            Finishings: [],
          };
        }

        const colorsArray = updated.Altered.Leather[selectedLeather].Colors;

        if (colorsArray.includes(color)) {
          updated.Altered.Leather[selectedLeather].Colors = colorsArray.filter(
            (c) => c !== color
          );
        } else {
          updated.Altered.Leather[selectedLeather].Colors.push(color);
        }

        return updated;
      });
    } else {
      setProductLocal((prev) => {
        const updated = JSON.parse(JSON.stringify(prev));

        if (!updated.Leather) {
          updated.Leather = {};
        }

        // Remove empty Leather entries
        Object.keys(updated.Leather).forEach((key) => {
          if (
            key !== "Consumption" &&
            !updated.Leather[key].Colors?.length &&
            !updated.Leather[key].Finishings?.length
          ) {
            delete updated.Leather[key];
          }
        });

        if (!updated.Leather[selectedLeather]) {
          updated.Leather[selectedLeather] = { Colors: [], Finishings: [] };
        }

        const colorsArray = updated.Leather[selectedLeather].Colors;

        if (colorsArray.includes(color)) {
          updated.Leather[selectedLeather].Colors = colorsArray.filter(
            (c) => c !== color
          );
        } else {
          updated.Leather[selectedLeather].Colors.push(color);
        }

        return updated;
      });
    }
  };

  const handleFinishingToggle = (finishing) => {
    if (action === "edit") {
      setProductLocal((prev) => {
        const updated = JSON.parse(JSON.stringify(prev));

        if (!updated.Altered.Leather) {
          updated.Altered.Leather = {};
        }

        // Remove empty Leather entries
        Object.keys(updated.Altered.Leather).forEach((key) => {
          if (
            key !== "Consumption" &&
            !updated.Altered.Leather[key].Colors?.length &&
            !updated.Altered.Leather[key].Finishings?.length
          ) {
            delete updated.Altered.Leather[key];
          }
        });

        if (!updated.Altered.Leather[selectedLeather]) {
          updated.Altered.Leather[selectedLeather] = {
            Colors: [],
            Finishings: [],
          };
        }

        const finishingsArray =
          updated.Altered.Leather[selectedLeather].Finishings;

        if (finishingsArray.includes(finishing)) {
          updated.Altered.Leather[selectedLeather].Finishings =
            finishingsArray.filter((f) => f !== finishing);
        } else {
          updated.Altered.Leather[selectedLeather].Finishings.push(finishing);
        }

        return updated;
      });
    } else {
      setProductLocal((prev) => {
        const updated = JSON.parse(JSON.stringify(prev));

        if (!updated.Leather) {
          updated.Leather = {};
        }

        // Remove empty Leather entries
        Object.keys(updated.Leather).forEach((key) => {
          if (
            key !== "Consumption" &&
            !updated.Leather[key].Colors?.length &&
            !updated.Leather[key].Finishings?.length
          ) {
            delete updated.Leather[key];
          }
        });

        if (!updated.Leather[selectedLeather]) {
          updated.Leather[selectedLeather] = { Colors: [], Finishings: [] };
        }

        const finishingsArray = updated.Leather[selectedLeather].Finishings;

        if (finishingsArray.includes(finishing)) {
          updated.Leather[selectedLeather].Finishings = finishingsArray.filter(
            (f) => f !== finishing
          );
        } else {
          updated.Leather[selectedLeather].Finishings.push(finishing);
        }

        return updated;
      });
    }
  };

  const handleSave = () => {
    if (action === "edit") {
      const leather = ProductLocal?.Altered.Leather || {};
      // Remove empty Leather entries
      const cleanedLeather = { ...leather };
      Object.keys(cleanedLeather).forEach((key) => {
        if (
          key !== "Consumption" &&
          (!cleanedLeather[key].Colors ||
            cleanedLeather[key].Colors.length === 0) &&
          (!cleanedLeather[key].Finishings ||
            cleanedLeather[key].Finishings.length === 0)
        ) {
          delete cleanedLeather[key];
        }
      });

      // Validate consumption
      const isConsumptionValid = (consumption) =>
        consumption && consumption > 0;
      if (!isConsumptionValid(ProductLocal?.Altered.Leather?.Consumption)) {
        alert("Consumption is compulsory");
        return;
      }

      // Validate leather entries
      const validateEntry = (key, value) => {
        if (
          !value ||
          typeof value !== "object" ||
          Object.keys(value).length === 0
        ) {
          return { isValid: false, reason: "empty", key };
        }

        const hasColors =
          Array.isArray(value.Colors) && value.Colors.length > 0;
        const hasFinishings =
          Array.isArray(value.Finishings) && value.Finishings.length > 0;

        if (!hasColors || !hasFinishings) {
          return {
            isValid: false,
            reason: "missing Colors or Finishings",
            key,
          };
        }

        return { isValid: true };
      };

      let hasValidEntries = false;
      const invalidEntries = [];

      for (const [key, value] of Object.entries(cleanedLeather)) {
        const { isValid, reason, key: invalidKey } = validateEntry(key, value);

        if (isValid) {
          hasValidEntries = true;
        } else if (reason === "missing Colors or Finishings") {
          invalidEntries.push(invalidKey);
        }
      }

      if (invalidEntries.length > 0) {
        alert(
          `The following entries are invalid. Please ensure they have both Colors and Finishings: ${invalidEntries.join(
            ", "
          )}.`
        );
        return;
      }

      if (!hasValidEntries) {
        alert(
          "At least one entry must have both Colors and Finishings, apart from Consumption."
        );
        return;
      }

      // Update ProductLocal with cleaned and validated Leather
      const updatedProductLocal = {
        ...ProductLocal,
        Altered: { ...ProductLocal.Altered, Leather: cleanedLeather },
      };
      const index = Customize.findIndex(
        (item) => item.Article_no === ProductLocal.Article_no
      );

      if (index !== -1) {
        Customize[index].Altered = updatedProductLocal.Altered;
      }
    } else {
      const leather = ProductLocal?.Leather || {};
      // Remove empty Leather entries
      const cleanedLeather = { ...leather };
      Object.keys(cleanedLeather).forEach((key) => {
        if (
          key !== "Consumption" &&
          (!cleanedLeather[key].Colors ||
            cleanedLeather[key].Colors.length === 0) &&
          (!cleanedLeather[key].Finishings ||
            cleanedLeather[key].Finishings.length === 0)
        ) {
          delete cleanedLeather[key];
        }
      });

      // Validate consumption
      const isConsumptionValid = (consumption) =>
        consumption && consumption > 0;
      if (!isConsumptionValid(ProductLocal?.Leather?.Consumption)) {
        alert("Consumption is compulsory");
        return;
      }

      // Validate leather entries
      const validateEntry = (key, value) => {
        if (
          !value ||
          typeof value !== "object" ||
          Object.keys(value).length === 0
        ) {
          return { isValid: false, reason: "empty", key };
        }

        const hasColors =
          Array.isArray(value.Colors) && value.Colors.length > 0;
        const hasFinishings =
          Array.isArray(value.Finishings) && value.Finishings.length > 0;

        if (!hasColors || !hasFinishings) {
          return {
            isValid: false,
            reason: "missing Colors or Finishings",
            key,
          };
        }

        return { isValid: true };
      };

      let hasValidEntries = false;
      const invalidEntries = [];

      for (const [key, value] of Object.entries(cleanedLeather)) {
        const { isValid, reason, key: invalidKey } = validateEntry(key, value);

        if (isValid) {
          hasValidEntries = true;
        } else if (reason === "missing Colors or Finishings") {
          invalidEntries.push(invalidKey);
        }
      }

      if (invalidEntries.length > 0) {
        alert(
          `The following entries are invalid. Please ensure they have both Colors and Finishings: ${invalidEntries.join(
            ", "
          )}.`
        );
        return;
      }

      if (!hasValidEntries) {
        alert(
          "At least one entry must have both Colors and Finishings, apart from Consumption."
        );
        return;
      }

      // Update ProductLocal with cleaned and validated Leather
      const updatedProductLocal = {
        ...ProductLocal,
        Leather: cleanedLeather,
      };
      const index = Customize.findIndex(
        (item) => item?.Article_no === ProductLocal.Article_no
      );

      if (index !== -1) {
        Customize[index].Leather = updatedProductLocal.Leather;
      } else {
        Customize.push({
          Article_no: ProductLocal.Article_no,
          Leather: updatedProductLocal.Leather,
          Edit: true,
        });
      }
    }
    secureLocalStorage.setItem("Customize", JSON.stringify(Customize));
    alert("Leather's specifications saved in local storage");
    onNext(style, ProductLocal.Article_no);
    setProductLocal(
      transformCustomizeToProductLocal(
        Customize,
        ProductLocal.Article_no,
        style,
        subCategory,
        action
      ) || {}
    );
  };

  return (
    <div className="bg-white max-md:p-2 md:p-4 2xl:p-6 rounded-lg ">
      <div className="lg:hidden max-lg:block">
        {/* Burger Icon for Small Screens */}
        <button
          className="lg:hidden block text-[#212121] py-2 focus:outline-none"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </button>

        {/* Sidebar for Small Screens */}
        <div
          className={`fixed top-0 left-0 h-full w-64 bg-white shadow-lg transform transition-transform duration-300 ease-in-out z-50 ${
            isMenuOpen ? "translate-x-0" : "-translate-x-full"
          } lg:translate-x-0 lg:relative lg:top-auto lg:left-auto lg:h-auto lg:shadow-none lg:bg-transparent lg:block`}
        >
          <div className="mt-4 max-w-5xl px-4">
            <label
              htmlFor="leatherType"
              className="block text-sm xl:text-base font-semibold leading-4 text-[#212121]"
            >
              Type of Leather:
            </label>
            <div className="flex flex-wrap gap-2 mt-3">
              {Object.keys(leatherOptions).map((type) => {
                const isInOriginal =
                  ProductLocal?.Leather?.[type]?.Finishings?.length > 0 ||
                  ProductLocal?.Leather?.[type]?.Colors?.length > 0;
                const isInAltered =
                  ProductLocal?.Altered?.Leather?.[type]?.Finishings?.length >
                    0 ||
                  ProductLocal?.Altered?.Leather?.[type]?.Colors?.length > 0;
                // Determine the color based on conditions
                let colorClass = "fill-gray-300"; // Default color (gray)
                if (isInOriginal && isInAltered) {
                  colorClass = "fill-yellow-500 bg-yellow-500";
                } else if (isInOriginal && !isInAltered) {
                  colorClass = "fill-red-500"; // Red: Present only in ProductLocal.Leather
                } else if (!isInOriginal && isInAltered) {
                  colorClass = "fill-green-400 bg-green-400"; // Green: Present only in ProductLocal.Altered.Leather
                }

                return (
                  <button
                    onClick={() => handleLeatherSelect(type)}
                    className={`px-3 py-2 rounded-md flex items-center gap-1.5 font-medium w-full text-left text-sm xl:text-base ${
                      selectedLeather === type ? "bg-yellow-100" : "bg-white"
                    }`}
                  >
                    {/* Leather SVG Icon */}
                    <svg
                      viewBox="0 0 16 16"
                      xmlns="http://www.w3.org/2000/svg"
                      className={`w-5 h-5 rounded-full ${
                        action === "edit"
                          ? colorClass
                          : isInOriginal
                          ? "fill-yellow-500 bg-yellow-500"
                          : "fill-gray-300"
                      }`}
                    >
                      <g>
                        <path d="m8 0c-4.41113 0-8 3.58887-8 8s3.58887 8 8 8 8-3.58887 8-8-3.58887-8-8-8zm0 14c-3.30859 0-6-2.69141-6-6s2.69141-6 6-6 6 2.69141 6 6-2.69141 6-6 6z"></path>
                      </g>
                    </svg>
                    {type}
                  </button>
                );
              })}
            </div>
          </div>
        </div>

        {/* Overlay for Small Screens */}
        {isMenuOpen && (
          <div
            className="fixed inset-0 bg-black opacity-50 z-40"
            onClick={() => setIsMenuOpen(false)}
          />
        )}
      </div>
      {/* consumption */}
      <div className="group   flex items-center gap-2 mb-5 max-xl:px-2.5 xl:px-4 ">
        <label
          htmlFor="leatherConsumption"
          className="block text-sm xl:text-base font-semibold leading-4 text-[#212121]"
        >
          Consumption:
        </label>
        <div className=" relative ">
          <input
            id="leatherConsumption"
            name="leatherConsumption"
            type="number"
            step="any"
            value={
              ProductLocal?.Altered?.Leather?.Consumption
                ? ProductLocal?.Altered?.Leather?.Consumption
                : ProductLocal?.Leather?.Consumption || ""
            }
            onChange={(e) => {
              const value = e.target.value;
              if (value === "" || !isNaN(value)) {
                const parsedValue = parseFloat(value);
                if (
                  action === "edit" &&
                  isNaN(ProductId?.split("_")?.pop()[0])
                ) {
                  setProductLocal((prev) => {
                    const updated = { ...prev };
                    if (!updated.Altered.Leather) {
                      updated.Altered.Leather = updated.Leather;
                    }
                    updated.Altered.Leather["Consumption"] = parsedValue || "";

                    return updated;
                  });
                } else {
                  setProductLocal((prev) => {
                    const updated = { ...prev };
                    if (!updated.Leather) {
                      updated.Leather = {};
                    }
                    updated.Leather["Consumption"] = parsedValue || "";

                    return updated;
                  });
                }
              }
            }}
            placeholder="185.50"
            className={`ml-1 border-b-2 placeholder:text-slate-300 block w-28 md:w-40 tracking-wider ${
              ProductLocal?.Altered?.Leather?.Consumption ===
              ProductLocal?.Leather?.Consumption
                ? " border-gray-300"
                : " border-green-300"
            } group focus:border-b-2 hover:border-[#eebd2d] focus:border-[#eebd2d] transition-colors focus:outline-none bg-inherit font-semibold`}
          />

          <div className=" absolute text-sm xl:text-base max-lg:right-3 lg:right-4 max-xl:top-1 xl:top-0 font-semibold ">
            dm
          </div>
        </div>
      </div>

      <div className="rounded-xl border-2  border-gray-300 hover:border-yellow-400 duration-300 ease-in-out group max-xl:p-2.5 xl:p-4 relative">
        {/* Leather Types */}{" "}
        <div className=" max-w-5xl lg:block max-lg:hidden">
          <label
            htmlFor="leatherType"
            className="absolute border-2 rounded-lg w-32 py-1  text-center text-sm md:text-base font-semibold -top-5 left-5 duration-300 ease-in-out border-gray-300 bg-gray-50  group-hover:border-yellow-400 group-hover:bg-yellow-50"
          >
            LEATHER
          </label>

          <div className="flex md:flex-row  items-start max-xl:gap-3 xl:gap-5 mt-3 mb-4">
            <label
              htmlFor="leatherType"
              className="min-w-32 block text-sm xl:text-base font-semibold leading-4 text-[#212121] mt-1"
            >
              Type:
            </label>
            <div className="flex flex-wrap gap-y-3 gap-x-5  ">
              {Object.keys(leatherOptions).map((type) => {
                const isInOriginal =
                  ProductLocal?.Leather?.[type]?.Finishings?.length > 0 ||
                  ProductLocal?.Leather?.[type]?.Colors?.length > 0;
                const isInAltered =
                  ProductLocal?.Altered?.Leather?.[type]?.Finishings?.length >
                    0 ||
                  ProductLocal?.Altered?.Leather?.[type]?.Colors?.length > 0;
                // Determine the color based on conditions
                let colorClass = "fill-gray-300"; // Default color (gray)
                if (isInOriginal && isInAltered) {
                  colorClass = "fill-yellow-400"; // Yellow: Present in both
                } else if (isInOriginal && !isInAltered) {
                  colorClass = "fill-red-500"; // Red: Present only in ProductLocal.Leather
                } else if (!isInOriginal && isInAltered) {
                  colorClass = "fill-green-400"; // Green: Present only in ProductLocal.Altered.Leather
                }
                return (
                  <div className="flex items-center">
                    <button
                      onClick={() => handleLeatherSelect(type)}
                      className={` py-1 flex items-center gap-2 font-medium w-40 text-[#313131] text-sm xl:text-base  ${
                        selectedLeather === type
                          ? "border-b-[3px] border-[#ffc94a]  font-semibold text-[#e2ac2f]"
                          : "underline-animation "
                      } ${isInOriginal || isInAltered ? "font-semibold" : ""}`}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        id="Layer_1"
                        data-name="Layer 1"
                        viewBox="0 0 24 24"
                        className={`w-5 h-5 ${
                          action === "edit"
                            ? colorClass
                            : isInOriginal
                            ? "fill-yellow-400"
                            : "fill-gray-300"
                        }`}
                      >
                        <path d="m17,0H7C4.243,0,2,2.243,2,5v15.996c0,1.235.742,2.329,1.889,2.786,1.149.46,2.439.173,3.317-.755l4.793-5.505,4.822,5.536c.581.613,1.367.939,2.172.939.374,0,.752-.07,1.117-.216,1.147-.457,1.889-1.551,1.889-2.786V5c0-2.757-2.243-5-5-5Zm-.297,8.467l-1.971,1.606.815,2.484c.112.336-.002.706-.282.922-.281.216-.667.231-.964.038l-2.295-1.495-2.257,1.51c-.14.094-.302.141-.464.141-.176,0-.352-.056-.5-.166-.283-.212-.401-.58-.295-.917l.784-2.513-1.981-1.612c-.267-.226-.364-.594-.243-.922.12-.328.432-.546.782-.546h2.501l.885-2.483c.121-.326.433-.542.781-.542s.66.216.781.542l.885,2.483h2.501c.35,0,.663.219.783.548.12.329.022.698-.246.923Z" />
                      </svg>
                      {type.replace(/_/g, " ")}
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {/* Colors Section */}
        <div
          className={`mb-4 flex md:flex-row max-md:flex-col items-start  max-xl:gap-3 xl:gap-5  ${
            fadeEffect ? "fade-effect" : ""
          }`}
        >
          <label
            htmlFor="leathercolor"
            className="min-w-32 block text-sm xl:text-base font-semibold leading-4 text-[#212121] mt-2 "
          >
            Colors:
          </label>
          <div className="flex flex-wrap  gap-2 ">
            {Object.values(colors).map((val) => {
              const isInOriginal = ProductLocal?.Leather?.[
                selectedLeather
              ]?.Colors?.includes(val.id);
              const isInAltered = ProductLocal?.Altered?.Leather?.[
                selectedLeather
              ]?.Colors?.includes(val.id);
              // Determine the color based on conditions
              let colorClass =
                "border-[#fff] before:border-[3px] before:text-[#fff]";
              if (isInOriginal && isInAltered) {
                colorClass =
                  "peer-checked:border-[#eebd2d] peer-checked:text-[#eebd2d] peer-checked:before:border-[#eebd2d] peer-checked:before:bg-[#eebd2d]";
              } else if (isInOriginal && !isInAltered) {
                colorClass =
                  "peer-checked:border-[#ef4444] peer-checked:text-[#ef4444] peer-checked:before:border-[#ef4444]";
              } else if (!isInOriginal && isInAltered) {
                colorClass =
                  "peer-checked:border-[#4ade80] peer-checked:text-[#4ade80] peer-checked:before:border-[#4ade80] peer-checked:before:bg-[#4ade80]";
              }
              return (
                <div className="flex  justify-center items-center   gap-2 ">
                  <div className="flex flex-col items-center justify-center">
                    <label className="text-slate-400">
                      <input
                        type="checkbox"
                        className="h-[1px] opacity-0 overflow-hidden absolute whitespace-nowrap w-[1px] peer"
                        checked={isInOriginal || isInAltered || false}
                        onChange={() => handleColorToggle(val.id)}
                      />
                      <span
                        className={`${
                          action === "edit"
                            ? colorClass
                            : isInOriginal
                            ? "peer-checked:border-[#eebd2d] peer-checked:text-[#eebd2d] peer-checked:before:border-[#eebd2d] peer-checked:before:bg-[#eebd2d]"
                            : "border-[#fff] before:border-[3px] before:text-[#fff]"
                        } peer-checked:before:opacity-100 peer-checked:before:scale-100  flex flex-col items-center justify-center w-14 h-14 xl:w-[86px] xl:h-[86px] rounded-lg  transition-all duration-200 cursor-pointer relative border-2 bg-[#fff] before:absolute before:block max-xl:before:w-1 xl:before:w-2 max-xl:before:h-1 xl:before:h-2  before:rounded-full before:top-1 before:left-1 before:opacity-0 before:transition-transform before:scale-0 before:text-xs  before:items-center before:justify-center hover:border-[#eebd2d] hover:before:scale-100 hover:before:opacity-100`}
                      >
                        {" "}
                        <img
                          className="w-10 h-10 xl:w-[70px] xl:h-[70px] rounded-full  flex items-center justify-center border-[1px]"
                          src={val.img}
                          alt=""
                          srcset=""
                        />
                      </span>
                    </label>

                    <p className="font-semibold text-xs xl:text-sm">
                      {val.color.replace(/_/g, " ")}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {/* Finishing Types */}
        <div
          className={`mb-4 flex md:flex-row max-md:flex-col items-start max-xl:gap-3 xl:gap-5 ${
            fadeEffect ? "fade-effect" : ""
          }`}
        >
          <label
            htmlFor="leatherFinishing"
            className="min-w-32 block text-sm xl:text-base font-semibold leading-4 text-[#212121] mt-2"
          >
            Finishing:
          </label>

          <div className="flex max-xl:gap-x-2 xl:gap-x-5 flex-wrap">
            {Object.keys(finishingOptions).map((finish) => {
              const isInOriginal =
                ProductLocal?.Leather?.[selectedLeather]?.Finishings?.includes(
                  finish
                );
              const isInAltered =
                ProductLocal?.Altered?.Leather?.[
                  selectedLeather
                ]?.Finishings?.includes(finish);

              return (
                <button
                  className={` rounded-md flex items-center text-sm xl:text-base gap-1.5 text-[#313131]  font-medium px-2 py-2`}
                  onClick={() => handleFinishingToggle(finish)}
                >
                  <div className="checkbox-wrapper-31">
                    <input
                      type="checkbox"
                      checked={
                        action === "edit"
                          ? (isInOriginal && isInAltered) ||
                            isInAltered ||
                            false
                          : isInOriginal || false
                      }
                      readOnly
                    />
                    <svg
                      viewBox="0 0 35.6 35.6"
                      className={`${
                        action === "edit"
                          ? isInOriginal && isInAltered
                            ? "checked-both"
                            : isInOriginal
                            ? "checked-original"
                            : isInAltered
                            ? "checked-altered"
                            : ""
                          : isInOriginal
                          ? "checked-both"
                          : ""
                      }`}
                    >
                      <circle
                        className="background"
                        cx="17.8"
                        cy="17.8"
                        r="17.8"
                      ></circle>
                      <circle
                        className="stroke"
                        cx="17.8"
                        cy="17.8"
                        r="14.37"
                      ></circle>
                      <polyline
                        className="check"
                        points="11.78 18.12 15.55 22.23 25.17 12.87"
                      ></polyline>
                    </svg>
                  </div>
                  <span className="cursor-default checkbox-wrapper-31-text">
                    {finish.replace(/_/g, " ")}
                  </span>
                  {/* Eye Icon for Image Preview */}
                  <button
                    onClick={() => {
                      setSelectedImage(finishingOptions[finish].Img);
                      setIsModalOpen(true);
                    }}
                    className="ml-3 text-blue-500"
                  >
                    <svg
                      className="w-3 md:w-5 h-3 md:h-5 fill-gray-500 hover:fill-gray-700"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 640 512"
                    >
                      <path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zM223.1 149.5C248.6 126.2 282.7 112 320 112c79.5 0 144 64.5 144 144c0 24.9-6.3 48.3-17.4 68.7L408 294.5c8.4-19.3 10.6-41.4 4.8-63.3c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3c0 10.2-2.4 19.8-6.6 28.3l-90.3-70.8zM373 389.9c-16.4 6.5-34.3 10.1-53 10.1c-79.5 0-144-64.5-144-144c0-6.9 .5-13.6 1.4-20.2L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5L373 389.9z"></path>
                    </svg>
                  </button>
                </button>
              );
            })}
          </div>
        </div>
        {/* Image Modal (Popup) */}
        {isModalOpen && (
          <div className="fixed inset-0 bg-gray-700 bg-opacity-50 flex flex-col justify-center items-center z-50 ">
            <div className="group flex flex-col items-center justify-center pop-up-animation">
              <button
                onClick={() => setIsModalOpen(false)}
                className="-translate-y-4  duration-300 ease-in-out z-40"
              >
                <svg
                  height="24pt"
                  viewBox="0 0 512 512"
                  width="24pt"
                  xmlns="http://www.w3.org/2000/svg"
                  id="fi_1828945"
                  className=" hover:fill-red-500 hover:bg-red-200 rounded-full duration-300 ease-in-out"
                >
                  <path d="m256 512c-141.164062 0-256-114.835938-256-256s114.835938-256 256-256 256 114.835938 256 256-114.835938 256-256 256zm0-480c-123.519531 0-224 100.480469-224 224s100.480469 224 224 224 224-100.480469 224-224-100.480469-224-224-224zm0 0"></path>
                  <path d="m176.8125 351.1875c-4.097656 0-8.195312-1.554688-11.308594-4.691406-6.25-6.25-6.25-16.382813 0-22.632813l158.398438-158.402343c6.253906-6.25 16.386718-6.25 22.636718 0s6.25 16.382812 0 22.636718l-158.402343 158.398438c-3.15625 3.136718-7.25 4.691406-11.324219 4.691406zm0 0"></path>
                  <path d="m335.1875 351.1875c-4.09375 0-8.191406-1.554688-11.304688-4.691406l-158.398437-158.378906c-6.253906-6.25-6.253906-16.382813 0-22.632813 6.25-6.253906 16.382813-6.253906 22.632813 0l158.398437 158.398437c6.253906 6.25 6.253906 16.382813 0 22.632813-3.132813 3.117187-7.230469 4.671875-11.328125 4.671875zm0 0"></path>
                </svg>
              </button>
              <div className=" bg-white p-2 rounded-lg z-50">
                <img
                  src={selectedImage}
                  alt="Finishing preview"
                  className="max-w-full min-h-[30vh] max-h-[30vh] object-cover"
                />
              </div>
            </div>
          </div>
        )}
      </div>
      {/* Save Button */}
      <div className="flex items-center justify-end w-full mt-3 gap-3">
        <button
          className="bg-[#fff] text-center w-16 rounded-lg h-9 lg:h-11 relative text-[#313131] text-xl font-semibold group border hover:bg-[#f3f3f3] duration-200 ease-in-out"
          type="button"
          onClick={() => {
            if (action === "edit") {
              setProductLocal((prev) => {
                const updated = { ...prev };
                if (updated.Altered.Leather) {
                  updated.Altered.Leather = updated.Leather;
                }
                secureLocalStorage.setItem(
                  "Customize",
                  JSON.stringify(Customize)
                );
                return updated;
              });
            } else {
              setProductLocal((prev) => {
                const updated = { ...prev };
                if (updated.Leather) {
                  delete updated.Leather;
                }
                Customize[Customize.length].Leather = {};
                secureLocalStorage.setItem(
                  "Customize",
                  JSON.stringify(Customize)
                );
                return updated;
              });
            }
          }}
        >
          <p className="text-xs lg:text-sm ">Reset</p>
        </button>
        <button
          className="bg-white text-center w-20 lg:w-32 rounded-lg h-9 lg:h-11 relative text-[#313131] text-xl font-semibold group border "
          type="button"
          onClick={() => {
            handleSave();
          }}
        >
          <div className="bg-yellow-400 rounded-md h-[26px] lg:h-[34px] w-1/3 flex items-center justify-center absolute left-1 top-[4px] group-hover:w-[70px] group-hover:lg:w-[118px] z-10 duration-500 ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1024 1024"
              className="rotate-180 w-4 lg:w-6 h-4 lg:h-6"
            >
              <path
                d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z"
                fill="#313131"
              ></path>
              <path
                d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z"
                fill="#313131"
              ></path>
            </svg>
          </div>
          <p className="max-lg:translate-x-4 lg:translate-x-5 text-xs lg:text-sm">
            Next
          </p>
        </button>
      </div>
    </div>
  );
};

export default LeatherSelection;
