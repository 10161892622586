import React, { useState, useRef, useEffect } from "react";

const Filter = ({
  materials,
  data,
  leather,
  sole,
  color,
  construction,
  onFilter,
}) => {
  const [leatherOptions, setLeatherOptions] = useState([]);
  const [soleOptions, setSoleOptions] = useState([]);
  const [constructionOptions, setConstructionOptions] = useState([]);
  const [selectedColors, setSelectedColors] = useState([]);
  const [selectedLeathers, setSelectedLeathers] = useState([]);
  const [selectedSoles, setSelectedSoles] = useState([]);
  const [selectedConstructions, setSelectedConstructions] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentCategory, setCurrentCategory] = useState("");
  const [sortingOptionsVisible, setSortingOptionsVisible] = useState(false);
  const [selectedSorting, setSelectedSorting] = useState("Relevance");
  const [activeDropdown, setActiveDropdown] = useState(null);
  const filterRef = useRef(null);
  const sortingRef = useRef(null);

  useEffect(() => {
    materials?.forEach((item) => {
      if (item.Leathers) {
        setLeatherOptions(item.Leathers);
      }
      if (item.Soles) {
        setSoleOptions(item.Soles);
      }
      if (item?.Constructions) {
        setConstructionOptions(item?.Constructions);
      }
    });
    setSelectedColors(color);
    setSelectedLeathers(leather);
    setSelectedSoles(sole);
    setSelectedConstructions(construction);
  }, [JSON.stringify(materials)]);

  const handleOpenModal = (category) => {
    setCurrentCategory(category);
    setIsModalOpen(true);
    setSortingOptionsVisible(false); // Close sorting options when a modal is opened
  };

  const handleSortOpen = () => {
    setSortingOptionsVisible(!sortingOptionsVisible);
    setIsModalOpen(false); // Close modal when sorting is opened
  };

  const handleCheckboxChange = (e, category) => {
    const value = e.target.value.replaceAll(" ", "_").toLocaleLowerCase();
    let updatedSelection;

    switch (category) {
      case "Colors":
        updatedSelection = e.target.checked
          ? [...selectedColors, value]
          : selectedColors.filter((item) => item !== value);
        setSelectedColors(updatedSelection);
        break;
      case "Leathers":
        updatedSelection = e.target.checked
          ? [...selectedLeathers, value]
          : selectedLeathers.filter((item) => item !== value);
        setSelectedLeathers(updatedSelection);
        break;
      case "Soles":
        updatedSelection = e.target.checked
          ? [...selectedSoles, value]
          : selectedSoles.filter((item) => item !== value);
        setSelectedSoles(updatedSelection);
        break;
      case "Constructions":
        updatedSelection = e.target.checked
          ? [...selectedConstructions, value]
          : selectedConstructions.filter((item) => item !== value);
        setSelectedConstructions(updatedSelection);
        break;
      default:
        break;
    }
    onFilter(category, updatedSelection);
  };

  const renderModalContent = () => {
    return (
      <div>
        <div className="flex justify-end items-center">
          {/* <h2 className="text-xl font-bold mb-4">{currentCategory}</h2> */}
          <button
            className=""
            onClick={() => setIsModalOpen(false)}
            aria-label="Close"
          >
            <svg
              viewBox="0 0 512 512"
              xmlns="http://www.w3.org/2000/svg"
              id="fi_1828945"
              className=" hover:fill-red-600 hover:bg-red-300 rounded-full duration-300 ease-in-out hover:scale-90  w-7 h-7 "
            >
              <path d="m256 512c-141.164062 0-256-114.835938-256-256s114.835938-256 256-256 256 114.835938 256 256-114.835938 256-256 256zm0-480c-123.519531 0-224 100.480469-224 224s100.480469 224 224 224 224-100.480469 224-224-100.480469-224-224-224zm0 0"></path>
              <path d="m176.8125 351.1875c-4.097656 0-8.195312-1.554688-11.308594-4.691406-6.25-6.25-6.25-16.382813 0-22.632813l158.398438-158.402343c6.253906-6.25 16.386718-6.25 22.636718 0s6.25 16.382812 0 22.636718l-158.402343 158.398438c-3.15625 3.136718-7.25 4.691406-11.324219 4.691406zm0 0"></path>
              <path d="m335.1875 351.1875c-4.09375 0-8.191406-1.554688-11.304688-4.691406l-158.398437-158.378906c-6.253906-6.25-6.253906-16.382813 0-22.632813 6.25-6.253906 16.382813-6.253906 22.632813 0l158.398437 158.398437c6.253906 6.25 6.253906 16.382813 0 22.632813-3.132813 3.117187-7.230469 4.671875-11.328125 4.671875zm0 0"></path>
            </svg>
          </button>
        </div>
        <div className="flex flex-wrap  justify-center gap-5">
          {options[currentCategory]?.map((option) => (
            <>
              <label
                key={option}
                className="flex items-center font-medium uppercase text-base cursor-pointer"
              >
                <div className="checkbox-wrapper-31 mr-2">
                  <input
                    type="checkbox"
                    value={option}
                    checked={(() => {
                      const array =
                        currentCategory === "Leathers"
                          ? selectedLeathers
                          : currentCategory === "Soles"
                          ? selectedSoles
                          : currentCategory === "Colors"
                          ? selectedColors
                          : currentCategory === "Constructions"
                          ? selectedConstructions
                          : [];
                      return array.some(
                        (item) => item.toLowerCase() === option.toLowerCase()
                      );
                    })()}
                    onChange={(e) => handleCheckboxChange(e, currentCategory)}
                  />
                  <svg
                    viewBox="0 0 35.6 35.6"
                    className={`${
                      (() => {
                        const array =
                          currentCategory === "Leathers"
                            ? selectedLeathers
                            : currentCategory === "Soles"
                            ? selectedSoles
                            : currentCategory === "Colors"
                            ? selectedColors
                            : currentCategory === "Constructions"
                            ? selectedConstructions
                            : [];
                        return array.some(
                          (item) => item.toLowerCase() === option.toLowerCase()
                        );
                      })()
                        ? "checked-both"
                        : ""
                    }`}
                  >
                    <circle
                      className="background"
                      cx="17.8"
                      cy="17.8"
                      r="17.8"
                    ></circle>
                    <circle
                      className="stroke"
                      cx="17.8"
                      cy="17.8"
                      r="14.37"
                    ></circle>
                    <polyline
                      className="check"
                      points="11.78 18.12 15.55 22.23 25.17 12.87"
                    ></polyline>
                  </svg>
                </div>

                {option.replaceAll("_", " ")}
              </label>
            </>
          ))}
        </div>
      </div>
    );
  };

  const sortingOptions = [
    "Relevance",
    "Name A-Z",
    "Name Z-A",
    "Price lower",
    "Price higher",
  ];
  // Close the modal and sorting options when clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setIsModalOpen(false); // Close modal when clicked outside
        setSortingOptionsVisible(false); // Close sorting options when clicked outside
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [isOpen, setIsOpen] = useState(false);

  const options = {
    Leathers: Object.keys(leatherOptions),
    Soles: Object.keys(soleOptions),
    Colors: ["Burgundy", "Blue", "Brown", "Black", "Tan", "Gray"],
    Constructions: Object.keys(constructionOptions),
  };

  const toggleDropdown = (key) => {
    setActiveDropdown(activeDropdown === key ? null : key);
    handleOpenModal(key);
  };

  return (
    // filter section
    <div
      ref={filterRef}
      className="flex max-md:justify-between md:justify-center items-center  w-full   border-t border-b relative max-md:py-3 md:py-4 max-md:px-4"
    >
      <div className="md:w-[15%] flex justify-center items-center text-[#313131] font-semibold text-xs uppercase  ">
        <p>{data?.length} items</p>
      </div>
      <div className="flex justify-center items-center text-base text-[#616161]  font-medium uppercase  w-[70%] max-md:hidden">
        <div className="px-3 border-r">
          <button
            className="underline-animation-light hover:text-[#212121] duration-500 ease-in-out px-1 w-full"
            onClick={() => {
              handleOpenModal("Leathers");
            }}
          >
            Leathers{" "}
            {selectedLeathers.length > 0 && (
              <span className="text-sm bg-yellow-400 text-black rounded-full px-2">
                {selectedLeathers.length}
              </span>
            )}
          </button>
        </div>
        <div className="px-3 border-r">
          <button
            className="underline-animation-light hover:text-[#212121] duration-500 ease-in-out px-1 w-full"
            onClick={() => {
              handleOpenModal("Soles");
            }}
          >
            Soles{" "}
            {selectedSoles.length > 0 && (
              <span className="text-sm bg-yellow-400 text-black rounded-full px-2">
                {selectedSoles.length}
              </span>
            )}
          </button>
        </div>
        <div className="px-3 border-r">
          <button
            className="underline-animation-light hover:text-[#212121] duration-500 ease-in-out px-1 w-full "
            onClick={() => {
              handleOpenModal("Colors");
            }}
          >
            Colors{" "}
            {selectedColors.length > 0 && (
              <span className="text-sm bg-yellow-400 text-black rounded-full px-2">
                {selectedColors.length}
              </span>
            )}
          </button>
        </div>

        <div className="px-3">
          <button
            className="underline-animation-light hover:text-[#212121] duration-500 ease-in-out px-1 w-full"
            onClick={() => {
              handleOpenModal("Constructions");
            }}
          >
            Constructions{" "}
            {selectedConstructions.length > 0 && (
              <span className="text-sm bg-yellow-400 text-black rounded-full px-2">
                {selectedConstructions.length}
              </span>
            )}
          </button>
        </div>

        {isModalOpen && (
          <div
            className={`bg-white   shadow-[0px_0px_0px_1px_rgba(0,0,0,0.06),0px_1px_1px_-0.5px_rgba(0,0,0,0.06),0px_3px_3px_-1.5px_rgba(0,0,0,0.06),_0px_6px_6px_-3px_rgba(0,0,0,0.06),0px_12px_12px_-6px_rgba(0,0,0,0.06),0px_24px_24px_-12px_rgba(0,0,0,0.06)]   absolute top-14 left-0 w-full z-50 ${
              isModalOpen ? "max-h-screen opac-1 p-6 " : "max-h-0 opac-0"
            }`}
          >
            {renderModalContent()}
          </div>
        )}
      </div>
      {/* Sorting Section */}
      <div className="md:w-[15%] flex justify-center items-center max-md:hidden">
        <button
          className="flex items-center text-[#313131] font-semibold text-xs "
          onClick={handleSortOpen}
        >
          {selectedSorting}
          <svg
            className="ml-1 w-4 h-4"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M19 9l-7 7-7-7"
            ></path>
          </svg>
        </button>

        {sortingOptionsVisible && (
          <div
            ref={sortingRef}
            className={`absolute top-[51px] right-2  fadein-animation overflow-hidden  bg-white   mt-2 w-48  z-50  shadow-[0px_0px_0px_1px_rgba(0,0,0,0.06),0px_1px_1px_-0.5px_rgba(0,0,0,0.06),0px_3px_3px_-1.5px_rgba(0,0,0,0.06),_0px_6px_6px_-3px_rgba(0,0,0,0.06),0px_12px_12px_-6px_rgba(0,0,0,0.06),0px_24px_24px_-12px_rgba(0,0,0,0.06)]  transition-opacity duration-500 ease-in-out  ${
              sortingOptionsVisible ? "max-h-60 opac-1 p-2 " : "max-h-0 opac-0 "
            }`}
          >
            <ul className="py-1 text-xs text-gray-600">
              {sortingOptions.map((option) => (
                <li
                  key={option}
                  onClick={() => {
                    setSelectedSorting(option);
                    onFilter("filter2", option);
                    setSortingOptionsVisible(false);
                  }}
                  className={`px-2 py-2  hover:text-[#212121] font-medium  duration-200 ease-in-out cursor-pointer ${
                    selectedSorting === option
                      ? " text-[#212121]"
                      : "text-[#616161]"
                  }`}
                >
                  {option.replaceAll("_", " ")}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      <div className=" flex justify-center items-center  md:hidden">
        <button
          className="flex items-center text-[#313131] font-semibold text-sm "
          onClick={() => setIsOpen(true)}
        >
          Filter
          <svg
            fill="none"
            viewBox="0 0 24 24"
            className="ml-1 w-4 h-4"
            xmlns="http://www.w3.org/2000/svg"
            id="fi_8017777"
          >
            <path
              clipRule="evenodd"
              d="m18 12c2.2091 0 4-1.7909 4-4 0-2.20914-1.7909-4-4-4-1.8638 0-3.4299 1.27477-3.874 3h-11.126c-.55228 0-1 .44772-1 1 0 .55229.44772 1 1 1h11.126c.4441 1.7252 2.0102 3 3.874 3zm-2-4c0 1.10457.8954 2 2 2s2-.89543 2-2-.8954-2-2-2-2 .89543-2 2zm-14 8c0-2.2091 1.79086-4 4-4 1.86384 0 3.42994 1.2748 3.87398 3h11.12602c.5523 0 1 .4477 1 1s-.4477 1-1 1h-11.12602c-.44404 1.7252-2.01014 3-3.87398 3-2.20914 0-4-1.7909-4-4zm6 0c0-1.1046-.89543-2-2-2s-2 .8954-2 2 .89543 2 2 2 2-.8954 2-2z"
              fill="rgb(0,0,0)"
              fillRule="evenodd"
            ></path>
          </svg>
        </button>
      </div>

      <div
        className={`fixed top-0 right-0 h-screen w-64 bg-white shadow-lg z-50 transform transition-transform duration-700 ease-in-out ${
          isOpen ? "translate-x-0" : "translate-x-full"
        } md:hidden`}
      >
        <div className="flex items-center justify-between p-4 border-b">
          <h2 className="text-sm font-semibold flex items-center">
            Filter
            <svg
              fill="none"
              viewBox="0 0 24 24"
              className="ml-1 w-4 h-4"
              xmlns="http://www.w3.org/2000/svg"
              id="fi_8017777"
            >
              <path
                clipRule="evenodd"
                d="m18 12c2.2091 0 4-1.7909 4-4 0-2.20914-1.7909-4-4-4-1.8638 0-3.4299 1.27477-3.874 3h-11.126c-.55228 0-1 .44772-1 1 0 .55229.44772 1 1 1h11.126c.4441 1.7252 2.0102 3 3.874 3zm-2-4c0 1.10457.8954 2 2 2s2-.89543 2-2-.8954-2-2-2-2 .89543-2 2zm-14 8c0-2.2091 1.79086-4 4-4 1.86384 0 3.42994 1.2748 3.87398 3h11.12602c.5523 0 1 .4477 1 1s-.4477 1-1 1h-11.12602c-.44404 1.7252-2.01014 3-3.87398 3-2.20914 0-4-1.7909-4-4zm6 0c0-1.1046-.89543-2-2-2s-2 .8954-2 2 .89543 2 2 2 2-.8954 2-2z"
                fill="rgb(0,0,0)"
                fillRule="evenodd"
              ></path>
            </svg>
          </h2>
          <button
            className=""
            onClick={() => setIsOpen(false)}
            aria-label="Close"
          >
            <svg
              viewBox="0 0 512 512"
              xmlns="http://www.w3.org/2000/svg"
              id="fi_1828945"
              className=" hover:fill-red-600 hover:bg-red-300 rounded-full duration-300 ease-in-out hover:scale-90  w-4 lg:w-5 h-4 lg:h-5"
            >
              <path d="m256 512c-141.164062 0-256-114.835938-256-256s114.835938-256 256-256 256 114.835938 256 256-114.835938 256-256 256zm0-480c-123.519531 0-224 100.480469-224 224s100.480469 224 224 224 224-100.480469 224-224-100.480469-224-224-224zm0 0"></path>
              <path d="m176.8125 351.1875c-4.097656 0-8.195312-1.554688-11.308594-4.691406-6.25-6.25-6.25-16.382813 0-22.632813l158.398438-158.402343c6.253906-6.25 16.386718-6.25 22.636718 0s6.25 16.382812 0 22.636718l-158.402343 158.398438c-3.15625 3.136718-7.25 4.691406-11.324219 4.691406zm0 0"></path>
              <path d="m335.1875 351.1875c-4.09375 0-8.191406-1.554688-11.304688-4.691406l-158.398437-158.378906c-6.253906-6.25-6.253906-16.382813 0-22.632813 6.25-6.253906 16.382813-6.253906 22.632813 0l158.398437 158.398437c6.253906 6.25 6.253906 16.382813 0 22.632813-3.132813 3.117187-7.230469 4.671875-11.328125 4.671875zm0 0"></path>
            </svg>
          </button>
        </div>
        <div className="p-4 flex flex-col items-start w-full ">
          {Object.keys(options).map((key) => (
            <div key={key} className="w-full border-b py-2">
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() => toggleDropdown(key)}
              >
                <button className="font-medium text-[#212121] duration-500 ease-in-out">
                  {key}
                  &nbsp;
                  {/* Dynamically determine the selected array based on the currentCategory */}
                  {(() => {
                    const array =
                      key === "Leathers"
                        ? selectedLeathers
                        : key === "Soles"
                        ? selectedSoles
                        : key === "Colors"
                        ? selectedColors
                        : key === "Constructions"
                        ? selectedConstructions
                        : [];
                    if (array.length > 0) {
                      return (
                        <span className="text-xs bg-yellow-400 text-black rounded-full px-2">
                          {array.length}
                        </span>
                      );
                    }
                  })()}
                </button>

                <svg
                  className={`w-4 h-5 transition-transform duration-700 ease-in-out ${
                    activeDropdown === key ? "rotate-180" : ""
                  }`}
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  id="fi_2722987"
                >
                  <g id="_16" data-name="16">
                    <path d="m12 16a1 1 0 0 1 -.71-.29l-6-6a1 1 0 0 1 1.42-1.42l5.29 5.3 5.29-5.29a1 1 0 0 1 1.41 1.41l-6 6a1 1 0 0 1 -.7.29z"></path>
                  </g>
                </svg>
              </div>
              {/* Dropdown Content */}
              <div
                className={`overflow-hidden transition-all duration-700 ease-in-out ${
                  activeDropdown === key ? "max-h-40" : "max-h-0"
                }`}
              >
                <div className="flex flex-col justify-center gap-2 my-3">
                  {options[currentCategory]?.map((option) => (
                    <label
                      key={option}
                      className="flex items-center font-medium uppercase text-sm cursor-pointer"
                    >
                      <div className="checkbox-wrapper-31 scale-75 mr-2">
                        <input
                          type="checkbox"
                          value={option}
                          checked={(() => {
                            const array =
                              currentCategory === "Leathers"
                                ? selectedLeathers
                                : currentCategory === "Soles"
                                ? selectedSoles
                                : currentCategory === "Colors"
                                ? selectedColors
                                : currentCategory === "Constructions"
                                ? selectedConstructions
                                : [];
                            return array.some(
                              (item) =>
                                item.toLowerCase() === option.toLowerCase()
                            );
                          })()}
                          onChange={(e) => {
                            handleCheckboxChange(e, currentCategory);
                          }}
                        />
                        <svg
                          viewBox="0 0 35.6 35.6"
                          className={`${
                            (() => {
                              const array =
                                currentCategory === "Leathers"
                                  ? selectedLeathers
                                  : currentCategory === "Soles"
                                  ? selectedSoles
                                  : currentCategory === "Colors"
                                  ? selectedColors
                                  : currentCategory === "Constructions"
                                  ? selectedConstructions
                                  : [];
                              return array.some(
                                (item) =>
                                  item.toLowerCase() === option.toLowerCase()
                              );
                            })()
                              ? "checked-both"
                              : ""
                          }`}
                        >
                          <circle
                            className="background"
                            cx="17.8"
                            cy="17.8"
                            r="17.8"
                          ></circle>
                          <circle
                            className="stroke"
                            cx="17.8"
                            cy="17.8"
                            r="14.37"
                          ></circle>
                          <polyline
                            className="check"
                            points="11.78 18.12 15.55 22.23 25.17 12.87"
                          ></polyline>
                        </svg>
                      </div>
                      {option.replaceAll("_", " ")}
                    </label>
                  ))}
                </div>
              </div>
            </div>
          ))}
          <div
            className={`w-full flex flex-col items-center overflow-hidden transition-all duration-700 ease-in-out ${
              sortingOptionsVisible ? "h-60  " : " h-10 "
            }`}
          >
            <button
              className="flex items-center justify-between w-full text-[#313131] font-semibold text-sm mt-3"
              onClick={handleSortOpen}
            >
              {selectedSorting}
              <svg
                className={`ml-1 w-4 h-4 transition-transform duration-700 ease-in-out ${
                  sortingOptionsVisible ? "rotate-180" : ""
                }`}
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                ></path>
              </svg>
            </button>
            <div
              ref={sortingRef}
              className={`  bg-white   w-full  z-50    transition-opacity duration-700 ease-in-out   ${
                sortingOptionsVisible ? "opacity-100 " : "opacity-0"
              }`}
            >
              <ul className="py-1 text-sm text-gray-600">
                {sortingOptions.map((option) => (
                  <li
                    key={option}
                    onClick={() => {
                      setSelectedSorting(option);
                      onFilter("filter2", option);
                      setSortingOptionsVisible(false);
                    }}
                    className={` py-1  hover:text-[#212121] font-medium duration-200 ease-in-out cursor-pointer ${
                      selectedSorting === option ? " hidden" : "text-[#616161]"
                    }`}
                  >
                    {option.replaceAll("_", " ")}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="absolute  top-[67px] left-0 w-full bg-white  p-6"></div>
      )}
    </div>
  );
};

export default Filter;
