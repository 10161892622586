import React, { useState } from "react";
import { IoClose } from "react-icons/io5";

export default function ImageViewer({ img, onClose}) {
  const [isClose, setIsClose] = useState(false);
  return (

    <div className="demo1-container">
        <div className="bg-white rounded-lg shadow relative dropdown-animation p-2">
          <div className="border rounded-md">

          <img src={img} className="w-[320px] h-[320px] lg:w-[350px] lg:h-[350px] rounded-lg" alt="image" />
          <span
            onClick={() => {
             onClose()
            }}
            className="bg-[#ffffff] hover:bg-red-300 border-[1px] border-zinc-400 hover:border-red-500 shadow-sm hover:shadow p-1 rounded-full text-black hover:scale-95 duration-300 cursor-pointer absolute -right-2 -top-2 z-10"
          >
            <IoClose className="text-xl" />
          </span>
          </div>
        </div>
      </div>
    
  );
}
