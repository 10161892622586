import React, { useRef, useState } from "react";
import "./adminledger.css";
import Slider from "./Slider.jsx";
import Popup from "../AdminComponent/Popup.jsx";
import Financialfilters from "../../components/AdminComponent/Financialfilters";
import Pageno from "../../components/AdminComponent/Pageno.jsx";

function DebtorandCreditor() {
  const [receiveItem, setReceiveItem] = useState(null);
  const [ledgerType, setLedgerType] = useState("Debtor");
  const [isAnimate, setIsAnimate] = useState(false);
  const [filterTable, setFiterTable] = useState("");
  const [editPopup, setEditPopup] = useState(false);

  // Payment Functions
  const paymentData = [
    {
      type: "Debtor",
      option: [
        {
          id: 0,
          date: "05-06-24",
          orderid: "BUW/26/00213",
          particulars: "ABC Pvt. Ltd.",
          utr: "UTR/1021451254",
          amount: "+₹ 50,000.00",
        },
        {
          id: 1,
          date: "06-06-24",
          orderid: "BUW/26/00214",
          particulars: "ABC Pvt. Ltd.",
          utr: "UTR/1021451255",
          amount: "+₹ 40,000.00",
        },
        {
          id: 2,
          date: "07-06-24",
          orderid: "BUW/26/00215",
          particulars: "QWERT & Co",
          utr: "UTR/1021451256",
          amount: "+₹ 1,00,000.00",
        },
      ],
    },
    {
      type: "Creditor",
      option: [
        {
          id: 0,
          date: "05-06-24",
          purchaseid: "INV/26/00216",
          particulars: "QWERT & Co",
          utr: "UTR/1021451257",
          amount: "-₹ 30,000.00",
        },
        {
          id: 1,
          date: "06-06-24",
          purchaseid: "INV/26/00217",
          particulars: "ABC Pvt. Ltd.",
          utr: "UTR/1021451258",
          amount: "-₹ 20,000.00",
        },
        {
          id: 2,
          date: "07-06-24",
          purchaseid: "INV/26/00218",
          particulars: "QWERT & Co",
          utr: "UTR/102145125445",
          amount: "-₹ 1,000.00",
        },
      ],
    },
  ];

  // Ledger ListData
  const buttonData = [
    { id: "Debtor", label: "Debtor" },
    { id: "Creditor", label: "Creditor" },
  ];
  const [selectedOne, setSelectedOne] = useState("Debtor");
  const scrollContainerRef2 = useRef(null);
  const handleButtonClick = (id, setSelectedOne, setMaterialState) => {
    setSelectedOne(id);
    setMaterialState(() => ({}));
  };
  const [materialState, setMaterialState] = useState({});
  const [dropdownState, setDropdownState] = useState({
    openDropdown: null,
    selectedYear: "Financial Year",
    selectedQuarter: "Quarter",
  });
  const toggleDropdown = (dropdown) => {
    setDropdownState((prevState) => ({
      ...prevState,
      openDropdown: prevState.openDropdown === dropdown ? null : dropdown,
    }));
  };

  //   Table Functions and Data
  const Table_API = [
    {
      type: "Debtor",
      data: [
        {
          id: 0,
          particulars: "XYZ Pvt. Ltd.",
          amount: "₹ 2,00,000.00",
        },
        { id: 1, particulars: "XYZ INC", amount: "₹ 5,00,000.00" },
        { id: 2, particulars: "PQR & Co", amount: "₹ 10,00,000.00" },
        {
          id: 3,
          particulars: "DEF Pvt.ltd.",
          amount: "₹ 6,00,000.00",
        },
        { id: 4, particulars: "XYZ Co", amount: "₹ 8,00,000.00" },
        {
          id: 5,
          particulars: "LMQ Pvt Ltd.",
          amount: "₹ 15,00,000.00",
        },
      ],
    },
    {
      type: "Creditor",
      data: [
        {
          id: 0,
          particulars: "QWERT 45/25/2024",
          amount: "₹ 2,00,000.00",
        },
        {
          id: 1,
          particulars: "BUAA Co/25/2024",
          amount: "₹ 3,00,000.00",
        },
      ],
    },
  ];

  return (
    <div
      style={{ height: "calc(100vh - 10rem)" }}
      className="flex justify-between text-[13px] relative"
    >
      {/* Left Part */}
      <div className="w-[44%]">
        <div className="w-full flex flex-col gap-6 shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] rounded-xl p-5">
          {/* Buttons */}
          <div
            className="flex justify-start gap-5 overflow-x-scroll no-scrollbar p-1 relative border rounded-lg w-fit mx-auto"
            ref={scrollContainerRef2}
          >
            {/* Animated background for the active tab */}
            <div
              className="absolute h-7 z-0 rounded-md bg-[#FFD61F] shadow-inner pointer-events-none transition-transform duration-300 ease-in-out"
              style={{
                transform: `translateX(${
                  buttonData.findIndex((btn) => btn.id === selectedOne) * 145
                }px)`,
                width: "125px",
              }}
            ></div>

            {buttonData.map((button) => (
              <button
                key={button.id}
                className={`relative z-10 rounded-md  py-1 text-[13px] font-semibold text-black text-center whitespace-nowrap min-w-[125px] ${
                  selectedOne === button.id ? "" : "hover:bg-[#fff4b5a1]"
                }  duration-200`}
                onClick={() => {
                  handleButtonClick(
                    button.id,
                    setSelectedOne,
                    setMaterialState
                  );
                  setLedgerType(button.label);
                  // onSelect(button.label);
                  setIsAnimate(true);
                }}
              >
                {button.label}
              </button>
            ))}
          </div>

          <div className="mt-3">
            <Financialfilters />
          </div>

          <div className="overflow-x-auto text-[13px] w-full mt-3">
            <div className="flex items-center justify-between flex-wrap gap-2">
              <p className="font-bold text-sm">List of Sundry Debtors</p>
              <button className="flex items-center justify-center gap-1.5 border-[1px] p-1 pr-2 rounded-full font-medium group duration-300 hover:bg-[#FFF4B5] hover:border-[#FFD61F]">
                <svg
                  fill="none"
                  className="w-5 h-5 fill-[#000000] duration-300"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  id="fi_18604906"
                >
                  <path
                    clip-rule="evenodd"
                    d="m1.25 12c0-5.93706 4.81294-10.75 10.75-10.75 5.9371 0 10.75 4.81294 10.75 10.75 0 5.9371-4.8129 10.75-10.75 10.75-5.93706 0-10.75-4.8129-10.75-10.75zm11.7559-4c0-.55228-.4478-1-1-1-.5523 0-1 .44772-1 1v4.5h-.5943c-.1756-.0001-.3913-.0003-.56773.0218l-.00334.0004c-.12645.0158-.70249.0876-.97685.6532-.27496.5669.02697 1.067.09229 1.1752l.00244.004c.09221.153.22636.3239.3367.4645l.02374.0302c.29463.3759.6766.8601 1.05705 1.2511.1898.1951.4071.3963.638.5552.2052.1412.5554.3444.9861.3444s.7809-.2032.9861-.3444c.2309-.1589.4482-.3601.638-.5552.3805-.391.7624-.8752 1.0571-1.2511l.0237-.0302c.1103-.1406.2445-.3114.3367-.4645l.0024-.004c.0653-.1082.3673-.6084.0923-1.1752-.2743-.5656-.8504-.6374-.9768-.6532l-.0034-.0004c-.1764-.0221-.3921-.0219-.5677-.0218h-.5825z"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="">PDF</span>
              </button>
            </div>
            <table className="min-w-full font-medium table-auto shadow-lg rounded-lg overflow-hidden mt-2">
              <thead className="rounded-lg">
                <tr className="border rounded-t-xl bg-[#F1F7F9]">
                  <th className="px-4 py-2 text-left w-[50px] ">S.No</th>
                  <th className="px-4 py-2 text-left ">Particulars</th>
                  <th className="px-4 py-2 text-left flex items-center gap-2 w-[165px]">
                    <span>Amount</span>{" "}
                    <span className="cursor-pointer">
                      <svg
                        className="w-5 h-5"
                        id="fi_9742189"
                        enable-background="new 0 0 32 32"
                        viewBox="0 0 32 32"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g>
                          <path d="m16 21c-.6 0-1-.4-1-1v-6c0-.6.4-1 1-1s1 .4 1 1v6c0 .6-.4 1-1 1z"></path>
                          <circle cx="16" cy="11" r="1"></circle>
                          <path d="m4.4 28.8c-.3-.2-.4-.6-.4-1l.9-5.1c-3.7-6.1-1.7-14.1 4.4-17.7s14.1-1.7 17.8 4.4 1.7 14.1-4.4 17.8c-4 2.4-9.1 2.5-13.1.1l-4.1 1.7c-.5.1-.8 0-1.1-.2zm5.2-3.6c.2 0 .4.1.5.2 5.2 3.3 12 1.7 15.2-3.4s1.7-12-3.4-15.2-11.9-1.9-15.2 3.3c-2.3 3.6-2.3 8.3 0 11.9.1.2.2.5.1.7l-.6 3.7 2.9-1.2z"></path>
                        </g>
                      </svg>
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody className="animate-slide">
                {Table_API.filter((data) => data.type === ledgerType).length ===
                0 ? (
                  // Loader
                  <div className="flex items-center justify-center h-full min-h-[100px]  w-full relative">
                    <div className="flex items-center justify-center flex-col absolute left-[280%]">
                      <div className="flex scale-75">
                        <div className="loader">
                          <svg viewBox="0 0 80 80">
                            <circle r="32" cy="40" cx="40" id="test"></circle>
                          </svg>
                        </div>

                        <div className="loader triangle">
                          <svg viewBox="0 0 86 80">
                            <polygon points="43 8 79 72 7 72"></polygon>
                          </svg>
                        </div>

                        <div className="loader">
                          <svg viewBox="0 0 80 80">
                            <rect height="64" width="64" y="8" x="8"></rect>
                          </svg>
                        </div>
                      </div>

                      <p className="font-semibold mt-2 text-black">
                        No material added.
                      </p>
                    </div>
                  </div>
                ) : (
                  <>
                    {Table_API.filter((data) => data.type === ledgerType).map(
                      (item) =>
                        Object.values(item)
                          .filter((data) => data !== ledgerType)
                          .map((value) =>
                            Object.entries(value).map(([key, val], index) => (
                              <tr
                                key={val.id}
                                onClick={() => setReceiveItem(val.particulars)}
                                className={`border-b cursor-pointer ${
                                  index % 2 === 0
                                    ? "bg-white border-b"
                                    : "bg-[#F9FAFB]"
                                } hover:bg-zinc-100 duration-30  animate-slide `}
                              >
                                <td className="px-4 py-2  w-[50px]">
                                  {val.id + 1}
                                </td>
                                <td className="px-4 py-2 ">
                                  {val.particulars}
                                </td>
                                <td
                                  className={`px-4 py-2 max-w-fit  w-[165px] ${
                                    ledgerType === "Debtor"
                                      ? "text-green-700"
                                      : "text-red-700"
                                  } `}
                                >
                                  {ledgerType === "Debtor"
                                    ? `+ ${val.amount}`
                                    : `- ${val.amount}`}
                                </td>
                              </tr>
                            ))
                          )
                    )}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Right Part */}
      <div className="w-[55%]">
        <div className="w-full flex flex-col gap-6 p-5 botder">
          {/* Heading */}
          <div className="border-t-2 border-[#000000] relative p-2 mt-3">
            <p className="bg-[#FFD61F] px-3 py-1 rounded-full w-fit mx-auto text-[13px] font-semibold absolute left-[50%] -translate-x-[50%] -top-3.5">
              Payment Details
            </p>
          </div>

          <div className="flex flex-wrap  gap-[8%] items-end juestify-between mt-5">
            {/* Choose Financial Year */}
            <div className="relative  text-left w-full md:w-auto">
              <p className="text-[13px] font-semibold w-full">
                Date From <span className="text-red-600">*</span>
              </p>

              <input
                type="date"
                className="mt-1 px-2 py-1 text-gray-700 bg-white  border-gray-300 rounded-md focus:outline-none shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] transition-all font-medium min-w-[160px]"
              />
            </div>

            {/* Choose Quarter */}
            <div className="relative  text-left ">
              <p className="text-[13px] font-semibold">
                Date To <span className="text-red-600">*</span>
              </p>
              <input
                type="date"
                className="mt-1 px-2 py-1 text-gray-700 bg-white  border-gray-300 rounded-md focus:outline-none shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] transition-all font-medium min-w-[160px]"
              />
            </div>

            {/* Search */}
            <div className="relative  text-left ">
              <p className="text-[13px] font-semibold">Search</p>

              <div className="flex items-center justify-between border rounded-full pl-2 pr-0.5 py-0.5 bg-white min-w-[170px] max-w-[170px]">
                <input
                  type="text"
                  value={filterTable}
                  onChange={(e) => setFiterTable(e.target.value)}
                  className="text-sm text-gray-500 placeholder-gray-400 focus:outline-none w-full bg-transparent placeholder:text-xs"
                  placeholder={`${
                    ledgerType === "Debtor" ? `Order id` : `Purchase id`
                  }`}
                />
                <button className="flex items-center justify-center p-1.5 bg-yellow-400 rounded-full hover:bg-yellow-500 transition-all duration-300">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    className="w-3.5 h-3.5 text-black"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M21 21l-4.35-4.35M10 18a8 8 0 100-16 8 8 0 000 16z"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>

          <div className="overflow-x-auto text-[13px] w-full mt-3">
            <div className="flex items-center justify-between flex-wrap gap-2">
              <p className="font-bold text-sm">
                Payment List of Sundry Debtors
              </p>
              <button className="flex items-center justify-center gap-1.5 border-[1px] p-1 pr-2 rounded-full font-medium group duration-300 hover:bg-[#FFF4B5] hover:border-[#FFD61F]">
                <svg
                  fill="none"
                  className="w-5 h-5 fill-[#000000] duration-300"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  id="fi_18604906"
                >
                  <path
                    clip-rule="evenodd"
                    d="m1.25 12c0-5.93706 4.81294-10.75 10.75-10.75 5.9371 0 10.75 4.81294 10.75 10.75 0 5.9371-4.8129 10.75-10.75 10.75-5.93706 0-10.75-4.8129-10.75-10.75zm11.7559-4c0-.55228-.4478-1-1-1-.5523 0-1 .44772-1 1v4.5h-.5943c-.1756-.0001-.3913-.0003-.56773.0218l-.00334.0004c-.12645.0158-.70249.0876-.97685.6532-.27496.5669.02697 1.067.09229 1.1752l.00244.004c.09221.153.22636.3239.3367.4645l.02374.0302c.29463.3759.6766.8601 1.05705 1.2511.1898.1951.4071.3963.638.5552.2052.1412.5554.3444.9861.3444s.7809-.2032.9861-.3444c.2309-.1589.4482-.3601.638-.5552.3805-.391.7624-.8752 1.0571-1.2511l.0237-.0302c.1103-.1406.2445-.3114.3367-.4645l.0024-.004c.0653-.1082.3673-.6084.0923-1.1752-.2743-.5656-.8504-.6374-.9768-.6532l-.0034-.0004c-.1764-.0221-.3921-.0219-.5677-.0218h-.5825z"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <span className="">PDF</span>
              </button>
            </div>
            <table className="min-w-full table-auto rounded-lg overflow-hidden mt-2">
              <thead className="rounded-lg">
                <tr className="border rounded-t-xl bg-[#F1F7F9] font-normal">
                  <th className="px-2 py-2 text-left">Date</th>
                  <th className="px-2 py-2 text-left">
                    {ledgerType === "Debtor" ? <>Order Id</> : <>Invoice no</>}
                  </th>
                  <th className="px-2 py-2 text-left">UTR/Party name</th>
                  <th className="px-2 py-2 text-left">Amount</th>
                  {ledgerType === "Debtor" ? (
                    ""
                  ) : (
                    <th className="px-2 py-2 text-left">Edit</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {paymentData?.filter((data) => data.type === ledgerType)
                  .length > 0 ? (
                  paymentData
                    .filter((data) => data.type === ledgerType)
                    .map((item) =>
                      item.option
                        .filter(
                          (data) =>
                            (ledgerType === "Debtor"
                              ? data.orderid
                                  .toLowerCase()
                                  .includes(filterTable.toLowerCase())
                              : data.purchaseid
                                  .toLowerCase()
                                  .includes(filterTable.toLowerCase())) ||
                            data.particulars
                              .toLowerCase()
                              .includes(filterTable.toLowerCase()) ||
                            data.utr
                              .toLowerCase()
                              .includes(filterTable.toLowerCase())
                        )
                        .map((option) => (
                          <tr
                            key={option.id}
                            className={`border-b cursor-pointer hover:bg-zinc-100 duration-30 animate-slide font-medium`}
                          >
                            <td className="px-2 py-3 whitespace-nowrap ">
                              {option.date}
                            </td>
                            <td className="px-2 py-3">
                              {ledgerType === "Debtor"
                                ? option.orderid
                                : option.purchaseid}
                            </td>
                            <td className="px-2 py-3 flex flex-col">
                              <span className="">{option.utr}</span>
                              <span className="text-xs text-gray-400">
                                {option.particulars}{" "}
                              </span>
                            </td>
                            <td
                              className={`px-2 py-3 max-w-fit ${
                                ledgerType === "Debtor"
                                  ? "text-green-700"
                                  : "text-red-700"
                              }`}
                            >
                              {option.amount}
                            </td>
                            {ledgerType === "Debtor" ? (
                              ""
                            ) : (
                              <td className="px-2 py-2 flex items-center justify-center">
                                <button
                                  onClick={() => setEditPopup(true)}
                                  className="bg-[#FFF4B5] hover:bg-[#FFD61F] duration-200 p-2 flex items-center justify-center rounded-lg cursor-pointer"
                                >
                                  <svg
                                    viewBox="0 0 492.49284 492"
                                    className="w-3 h-3"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path d="M304.14 82.47L33.16 353.47c-1.36 1.37-2.35 3.09-2.82 4.95L0.3 479c-.9 3.63.17 7.49 2.82 10.14a10.01 10.01 0 007.53 3.11c.85 0 1.73-.11 2.58-.32l120.55-30.04c1.88-.47 3.59-1.45 4.95-2.81l271-271zM476.87 45.52l-30.16-30.16c-20.16-20.16-55.3-20.14-75.43 0l-36.95 36.95 105.6 105.6 36.95-36.95c10.07-10.07 15.62-23.47 15.62-37.71s-5.55-27.65-15.62-37.72z" />
                                  </svg>
                                </button>
                              </td>
                            )}
                          </tr>
                        ))
                    )
                ) : (
                  <tr>
                    <td>
                      <div className="flex items-center justify-center h-full min-h-[100px]  w-full relative bg-red-50">
                        <div className="flex items-center justify-center flex-col ">
                          <div className="flex scale-75">
                            <div className="loader">
                              <svg viewBox="0 0 80 80">
                                <circle
                                  r="32"
                                  cy="40"
                                  cx="40"
                                  id="test"
                                ></circle>
                              </svg>
                            </div>

                            <div className="loader triangle">
                              <svg viewBox="0 0 86 80">
                                <polygon points="43 8 79 72 7 72"></polygon>
                              </svg>
                            </div>

                            <div className="loader">
                              <svg viewBox="0 0 80 80">
                                <rect height="64" width="64" y="8" x="8"></rect>
                              </svg>
                            </div>
                          </div>

                          <p className="font-semibold mt-2 text-black">
                            No material added.
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            <div className="mt-10">
              <Pageno />
            </div>
          </div>
        </div>
      </div>

      {/* Slider */}
      {receiveItem !== null && (
        <Slider
          setReceiveItem={setReceiveItem}
          receiveItem={receiveItem}
          ledgerType={ledgerType}
        />
      )}

      {/* Edit Popup */}
      {editPopup && (
        <Popup
          type={"edit"}
          onClose={() => {
            setEditPopup(false);
          }}
        />
      )}
    </div>
  );
}

export default DebtorandCreditor;
