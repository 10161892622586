import React from "react";
import CheckAuth from "../../components/ServiceFunctions/CheckAuth.jsx";
import DebtorandCreditor from "../../components/AdminComponent/DebtorandCreditor.jsx";
import BusinessLedger from "./Supplier.jsx";
import BusinessUser from "./BusinessUser.jsx";

function AdminLedger() {
  const { type } = CheckAuth();
  return (
    <div>
      <>
        {type === "admin" ? (
          <DebtorandCreditor />
        ) : type === "supplier" ? (
          <BusinessLedger />
        ) :type === "businessuser" ? (
          <BusinessUser />
        ) : <>No data found</>}
      </>
    </div>
  );
}

export default AdminLedger;
