import { useState, useEffect } from "react";
import secureLocalStorage from "react-secure-storage";

const transformCustomizeToProductLocal = (customize, productId, action) => {
  const matchingEntry = customize.find((entry) => {
    return entry.Article_no === productId;
  });
  let productLocal = null;
  if (!matchingEntry) {
    return null;
  } else {
    if (action === "edit") {
      productLocal = {
        Article_no: matchingEntry?.Article_no || "",
        Leather: matchingEntry?.Leather || {},
        Sole: matchingEntry?.Sole || {},
        Edge: matchingEntry?.Edge || [],
        Lining: matchingEntry?.Lining || {},
        Eyelets: matchingEntry?.Eyelets || [],
        Buckle: matchingEntry?.Buckle || {},
        Shoelace: matchingEntry?.Shoelace || {},
        Edit: matchingEntry?.Edit || false,
        Altered: matchingEntry?.Altered?.Edge
          ? matchingEntry?.Altered
          : {
              ...matchingEntry?.Altered,
              Edge: matchingEntry?.Edge,
              Lining: matchingEntry?.Lining,
              Eyelets: matchingEntry?.Eyelets,
              Buckle: matchingEntry?.Buckle,
              Shoelace: matchingEntry?.Shoelace,
            },
      };
    } else {
      productLocal = {
        Article_no: matchingEntry?.Article_no || "",
        Leather: matchingEntry?.Leather || {},
        Sole: matchingEntry?.Sole || {},
        Edge: matchingEntry?.Edge || [],
        Lining: matchingEntry?.Lining || {},
        Eyelets: matchingEntry?.Eyelets || [],
        Buckle: matchingEntry?.Buckle || {},
        Shoelace: matchingEntry?.Shoelace || {},
        Edit: matchingEntry?.Edit || false,
      };
    }
  }
  return productLocal;
};

const EdgeSelection = ({
  onNext,
  style,
  ProductId,
  materialCost,
  subCategory,
  action,
}) => {
  const storedData2 = secureLocalStorage.getItem("Customize");
  const Customize = JSON.parse(storedData2);
  const [openDropdown, setOpenDropdown] = useState("edge");
  const [selectedImage, setSelectedImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [edgeOptions, setEdgeOptions] = useState([]);
  const [eyeletOptions, setEyeletOptions] = useState([]);
  const [liningOptions, setLiningOptions] = useState([]);
  const [shoelaceOptions, setShoelaceOptions] = useState([]);
  const [buckleOptions, setBuckleOptions] = useState([]);
  const [selected, setSelected] = useState("");
  const [selectedLining, setSelectedLining] = useState(
    Object.keys(liningOptions)?.[0] || ""
  );
  const [selectedShoelace, setSelectedShoelace] = useState(
    Object.keys(shoelaceOptions)?.[0] || ""
  );
  const [selectedBuckle, setSelectedBuckle] = useState(
    Object.keys(buckleOptions)?.[0] || ""
  );

  const [ProductLocal, setProductLocal] = useState({});

  useEffect(() => {
    materialCost?.forEach((item) => {
      if (item.Edges) {
        setEdgeOptions(item.Edges);
      }
      if (item?.Eyelets) {
        setEyeletOptions(item?.Eyelets);
      }
      if (item.Linings) {
        setLiningOptions(item.Linings);
      }
      if (item.Shoelaces) {
        setShoelaceOptions(item.Shoelaces);
      }
      if (item.Buckles) {
        setBuckleOptions(item.Buckles);
      }
    });
  }, []);

  useEffect(() => {
    setProductLocal(
      transformCustomizeToProductLocal(Customize, ProductId, action)
    );
    setSelected(ProductLocal?.Eyelets?.length > 0 ? "shoelaces" : "buckles");
  }, [style, subCategory, ProductId]);

  const toggleDropdown = (section) => {
    setOpenDropdown((prev) => (prev === section ? null : section));
  };

  const mapOptionsToColor = (options, selectedOption) =>
    Object.entries(options?.[selectedOption]?.Color || {}).map(
      ([color, img]) => ({
        color,
        img,
      })
    );

  const liningColor = mapOptionsToColor(liningOptions, selectedLining);
  const shoelaceColor = mapOptionsToColor(shoelaceOptions, selectedShoelace);
  const buckleColor = mapOptionsToColor(buckleOptions, selectedBuckle);

  const toggleItemInArray = (array = [], item) =>
    array?.includes(item) ? array.filter((e) => e !== item) : [...array, item];

  const handleSelect = (key, value) => {
    if (action === "edit") {
      if (key === "Eyelets") {
        setProductLocal((prev) => ({
          ...prev,
          Altered: {
            ...prev.Altered,
            [key]: toggleItemInArray(prev?.Altered?.[key] || [], value),
            Buckle: {},
          },
        }));
      } else {
        setProductLocal((prev) => ({
          ...prev,
          Altered: {
            ...prev.Altered,
            [key]: toggleItemInArray(prev?.Altered?.[key] || [], value),
          },
        }));
      }
    } else {
      if (key === "Eyelets") {
        setProductLocal((prev) => ({
          ...prev,
          [key]: toggleItemInArray(prev[key], value),
          Buckle: {},
        }));
      } else {
        setProductLocal((prev) => ({
          ...prev,
          [key]: toggleItemInArray(prev[key], value),
        }));
      }
    }
  };

  const handleEdgeSelect = (edge) => handleSelect("Edge", edge);

  const handleEyeletsSelect = (eyelet) => handleSelect("Eyelets", eyelet);

  const handleLiningSelect = (type) => {
    if (action === "edit") {
      setProductLocal((prev) => {
        const updated = { ...prev };
        if (updated.Altered?.Lining) {
          Object.keys(updated.Altered?.Lining).forEach((key) => {
            if (!updated.Altered?.Lining[key]?.length) {
              delete updated.Altered?.Lining[key];
            }
          });
          Object.keys(updated.Lining).forEach((key) => {
            if (!updated.Lining[key]?.length) {
              delete updated.Lining[key];
            }
          });
        } else {
          updated.Altered.Lining = updated.Lining;
        }

        setSelectedLining(type); // Ensure this is correctly set
        if (!updated.Altered?.Lining[type]) {
          updated.Altered.Lining[type] = [];
        }

        return updated;
      });
    } else {
      setProductLocal((prev) => {
        const updated = { ...prev };
        if (updated.Lining) {
          Object.keys(updated.Lining).forEach((key) => {
            if (!updated.Lining[key]?.length) {
              delete updated.Lining[key];
            }
          });
        } else {
          updated.Lining = {};
        }

        setSelectedLining(type); // Ensure this is correctly set
        if (!updated.Lining[type]) {
          updated.Lining[type] = [];
        }

        return updated;
      });
    }
  };

  const handleLiningColorToggle = (lining) => {
    if (action === "edit") {
      setProductLocal((prev) => {
        const updated = JSON.parse(JSON.stringify(prev));

        Object.keys(updated.Lining).forEach((key) => {
          if (!updated.Lining[key]?.length) {
            delete updated.Lining[key];
          }
        });

        if (!updated.Altered?.Lining) {
          updated.Altered.Lining = updated.Lining;
        }

        if (!updated.Altered?.Lining[selectedLining]) {
          updated.Altered.Lining[selectedLining] = [];
        }

        const liningsArray = updated.Altered?.Lining[selectedLining];

        if (liningsArray?.includes(lining)) {
          updated.Altered.Lining[selectedLining] = liningsArray.filter(
            (l) => l !== lining
          );
        } else {
          updated.Altered?.Lining[selectedLining]?.push(lining);
        }

        return updated;
      });
    } else {
      setProductLocal((prev) => {
        const updated = JSON.parse(JSON.stringify(prev));

        if (!updated.Lining) {
          updated.Lining = {};
        }

        if (!updated.Lining[selectedLining]) {
          updated.Lining[selectedLining] = [];
        }

        const liningsArray = updated.Lining[selectedLining];

        if (liningsArray?.includes(lining)) {
          updated.Lining[selectedLining] = liningsArray.filter(
            (l) => l !== lining
          );
        } else {
          updated.Lining[selectedLining]?.push(lining);
        }

        return updated;
      });
    }
  };

  const handleShoelaceSelect = (type) => {
    if (action === "edit") {
      setProductLocal((prev) => {
        const updated = { ...prev };
        updated.Altered.Buckle = {};
        if (updated.Altered?.Shoelace) {
          Object.keys(updated.Altered?.Shoelace).forEach((key) => {
            if (!updated.Altered?.Shoelace[key]?.length) {
              delete updated.Altered.Shoelace[key];
            }
          });
        } else {
          updated.Altered.Shoelace = updated.Shoelace;
        }

        setSelectedShoelace(type); // Ensure this is correctly set
        if (!updated.Altered?.Shoelace[type]) {
          updated.Altered.Shoelace[type] = [];
        }
        return updated;
      });
    } else {
      setProductLocal((prev) => {
        const updated = { ...prev };
        updated.Buckle = {};
        // Remove empty Leather entries
        if (updated.Shoelace) {
          Object.keys(updated.Shoelace).forEach((key) => {
            if (!updated.Shoelace[key]?.length) {
              delete updated.Shoelace[key];
            }
          });
        } else {
          updated.Shoelace = {};
        }

        setSelectedShoelace(type); // Ensure this is correctly set
        if (!updated.Shoelace[type]) {
          updated.Shoelace[type] = [];
        }

        return updated;
      });
    }
  };

  const handleShoelaceColorToggle = (Shoelace) => {
    if (action === "edit") {
      setProductLocal((prev) => {
        const updated = JSON.parse(JSON.stringify(prev));
        updated.Altered.Buckle = {};

        if (!updated.Altered?.Shoelace) {
          updated.Altered.Shoelace = updated.Shoelace;
        }

        if (!updated.Altered?.Shoelace[selectedShoelace]) {
          updated.Altered.Shoelace[selectedShoelace] = [];
        }

        const ShoelacesArray = updated.Altered?.Shoelace[selectedShoelace];

        if (ShoelacesArray?.includes(Shoelace)) {
          updated.Altered.Shoelace[selectedShoelace] = ShoelacesArray.filter(
            (l) => l !== Shoelace
          );
        } else {
          updated.Altered?.Shoelace[selectedShoelace]?.push(Shoelace);
        }

        return updated;
      });
    } else {
      setProductLocal((prev) => {
        const updated = JSON.parse(JSON.stringify(prev));
        updated.Buckle = {};

        if (!updated.Shoelace) {
          updated.Shoelace = {};
        }

        if (!updated.Shoelace[selectedShoelace]) {
          updated.Shoelace[selectedShoelace] = [];
        }

        const ShoelacesArray = updated.Shoelace[selectedShoelace];

        if (ShoelacesArray?.includes(Shoelace)) {
          updated.Shoelace[selectedShoelace] = ShoelacesArray.filter(
            (l) => l !== Shoelace
          );
        } else {
          updated.Shoelace[selectedShoelace]?.push(Shoelace);
        }

        return updated;
      });
    }
  };

  const handleBuckleSelect = (type) => {
    if (action === "edit") {
      setProductLocal((prev) => {
        const updated = { ...prev };
        updated.Altered.Shoelace = {};
        updated.Altered.Eyelets = [];

        // Remove empty Leather entries
        if (updated.Altered?.Buckle) {
          Object.keys(updated.Altered?.Buckle).forEach((key) => {
            if (!updated.Altered?.Buckle[key]?.length) {
              delete updated.Altered?.Buckle[key];
            }
          });
        } else {
          updated.Altered.Buckle = updated.Buckle;
        }

        setSelectedBuckle(type); // Ensure this is correctly set
        if (!updated.Altered?.Buckle[type]) {
          updated.Altered.Buckle[type] = [];
        }

        return updated;
      });
    } else {
      setProductLocal((prev) => {
        const updated = { ...prev };
        updated.Shoelace = {};
        updated.Eyelets = [];

        // Remove empty Leather entries
        if (updated.Buckle) {
          Object.keys(updated.Buckle).forEach((key) => {
            if (!updated.Buckle[key]?.length) {
              delete updated.Buckle[key];
            }
          });
        } else {
          updated.Buckle = {};
        }

        setSelectedBuckle(type); // Ensure this is correctly set
        if (!updated.Buckle[type]) {
          updated.Buckle[type] = [];
        }

        return updated;
      });
    }
  };

  const handleBuckleColorToggle = (buckle) => {
    if (action === "edit") {
      setProductLocal((prev) => {
        const updated = JSON.parse(JSON.stringify(prev));
        updated.Altered.Shoelace = {};
        updated.Altered.Eyelets = [];

        if (!updated.Altered?.Buckle) {
          updated.Altered.Buckle = updated.Buckle;
        }

        if (!updated.Altered?.Buckle[selectedBuckle]) {
          updated.Altered.Buckle[selectedBuckle] = [];
        }

        const bucklesArray = updated.Altered?.Buckle[selectedBuckle];

        if (bucklesArray?.includes(buckle)) {
          updated.Altered.Buckle[selectedBuckle] = bucklesArray.filter(
            (b) => b !== buckle
          );
        } else {
          updated.Altered?.Buckle[selectedBuckle]?.push(buckle);
        }

        return updated;
      });
    } else {
      setProductLocal((prev) => {
        const updated = JSON.parse(JSON.stringify(prev));
        updated.Shoelace = {};
        updated.Eyelets = [];

        if (!updated.Buckle) {
          updated.Buckle = {};
        }

        if (!updated.Buckle[selectedBuckle]) {
          updated.Buckle[selectedBuckle] = [];
        }

        const bucklesArray = updated.Buckle[selectedBuckle];

        if (bucklesArray?.includes(buckle)) {
          updated.Buckle[selectedBuckle] = bucklesArray.filter(
            (b) => b !== buckle
          );
        } else {
          updated.Buckle[selectedBuckle]?.push(buckle);
        }

        return updated;
      });
    }
  };

  const handleNext = () => {
    if (openDropdown === "edge") setOpenDropdown("lining");
    else if (openDropdown === "lining" && selected === "")
      setOpenDropdown(null);
    else if (openDropdown === "lining" && selected === "shoelaces")
      setOpenDropdown("eyelets");
    else if (openDropdown === "lining" && selected === "buckles")
      setOpenDropdown("buckles");
    if (openDropdown === "eyelets") setOpenDropdown("shoelaces");
    if (action === "edit") {
      setProductLocal((prev) => {
        const updated = { ...prev };

        // Remove empty Lining entries
        if (updated.Altered?.Lining) {
          Object.keys(updated.Altered?.Lining).forEach((key) => {
            if (!updated.Altered?.Lining[key]?.length) {
              delete updated.Altered?.Lining[key];
            }
          });
        } else {
          updated.Altered.Lining = updated?.Lining;
        }

        // Remove empty Shoelace entries
        if (updated.Altered?.Shoelace) {
          Object.keys(updated.Altered?.Shoelace).forEach((key) => {
            if (!updated.Altered?.Shoelace[key]?.length) {
              delete updated.Altered?.Shoelace[key];
            }
          });
        } else {
          updated.Altered.Shoelace = updated.Shoelace;
        }

        // Remove empty Buckle entries
        if (updated.Altered?.Buckle) {
          Object.keys(updated.Altered?.Buckle).forEach((key) => {
            if (!updated.Altered?.Buckle[key]?.length) {
              delete updated.Altered?.Buckle[key];
            }
          });
        } else {
          updated.Altered.Buckle = updated.Buckle;
        }
        return updated;
      });
    } else {
      setProductLocal((prev) => {
        const updated = { ...prev };

        // Remove empty Lining entries
        if (updated.Lining) {
          Object.keys(updated.Lining).forEach((key) => {
            if (!updated.Lining[key]?.length) {
              delete updated.Lining[key];
            }
          });
        } else {
          updated.Lining = {};
        }

        // Remove empty Shoelace entries
        if (updated.Shoelace) {
          Object.keys(updated.Shoelace).forEach((key) => {
            if (!updated.Shoelace[key]?.length) {
              delete updated.Shoelace[key];
            }
          });
        } else {
          updated.Shoelace = {};
        }

        // Remove empty Buckle entries
        if (updated.Buckle) {
          Object.keys(updated.Buckle).forEach((key) => {
            if (!updated.Buckle[key]?.length) {
              delete updated.Buckle[key];
            }
          });
        } else {
          updated.Buckle = {};
        }
        return updated;
      });
    }
    setSelectedLining(Object.keys(liningOptions)?.[0] || "");
    setSelectedShoelace(Object.keys(shoelaceOptions)?.[0] || "");
    setSelectedBuckle(Object.keys(buckleOptions)?.[0] || "");
  };

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setIsModalOpen(true); // Open the modal
  };

  const handleSave = () => {
    let missingSpecs = [];
    let hasValidEdge = false;
    let hasValidLining = false;
    let hasValidShoelace = false;
    let hasValidEyelets = false;
    let hasValidBuckles = false;
    if (action === "edit") {
      // Validate Edge
      hasValidEdge =
        ProductLocal.Altered?.Edge && ProductLocal.Altered?.Edge?.length > 0;
      if (!hasValidEdge) {
        missingSpecs?.push("Edge must have at least one entry.");
      }

      // Validate Lining
      hasValidLining =
        ProductLocal.Altered?.Lining &&
        Object.values(ProductLocal.Altered?.Lining).some(
          (arr) => Array.isArray(arr) && arr?.length > 0
        );
      if (!hasValidLining) {
        missingSpecs?.push(
          "Lining must have at least one entry with a non-empty array."
        );
      }

      // Validate Shoelace
      hasValidShoelace =
        ProductLocal.Altered?.Shoelace &&
        Object.values(ProductLocal.Altered?.Shoelace).some(
          (arr) => Array.isArray(arr) && arr?.length > 0
        );
      if (!hasValidShoelace) {
        missingSpecs?.push(
          "Shoelace must have at least one entry with a non-empty array."
        );
      }

      // Validate Eyelets
      hasValidEyelets =
        ProductLocal.Altered?.Eyelets &&
        ProductLocal.Altered?.Eyelets?.length > 0;
      if (!hasValidEyelets) {
        missingSpecs?.push("Eyelets must have at least one entry.");
      }

      // Validate Buckles
      hasValidBuckles =
        ProductLocal.Altered?.Buckle &&
        Object.values(ProductLocal.Altered?.Buckle).some(
          (arr) => Array.isArray(arr) && arr?.length > 0
        );
      if (!hasValidBuckles) {
        missingSpecs?.push(
          "Buckles must have at least one entry with a non-empty array."
        );
      }
    } else {
      // Validate Edge
      hasValidEdge = ProductLocal.Edge && ProductLocal.Edge?.length > 0;
      if (!hasValidEdge) {
        missingSpecs?.push("Edge must have at least one entry.");
      }

      // Validate Lining
      hasValidLining =
        ProductLocal.Lining &&
        Object.values(ProductLocal.Lining).some(
          (arr) => Array.isArray(arr) && arr?.length > 0
        );
      if (!hasValidLining) {
        missingSpecs?.push(
          "Lining must have at least one entry with a non-empty array."
        );
      }

      // Validate Shoelace
      hasValidShoelace =
        ProductLocal.Shoelace &&
        Object.values(ProductLocal.Shoelace).some(
          (arr) => Array.isArray(arr) && arr?.length > 0
        );
      if (!hasValidShoelace) {
        missingSpecs?.push(
          "Shoelace must have at least one entry with a non-empty array."
        );
      }

      // Validate Eyelets
      hasValidEyelets =
        ProductLocal.Eyelets && ProductLocal.Eyelets?.length > 0;
      if (!hasValidEyelets) {
        missingSpecs?.push("Eyelets must have at least one entry.");
      }

      // Validate Buckles
      hasValidBuckles =
        ProductLocal.Buckle &&
        Object.values(ProductLocal.Buckle).some(
          (arr) => Array.isArray(arr) && arr?.length > 0
        );
      if (!hasValidBuckles) {
        missingSpecs?.push(
          "Buckles must have at least one entry with a non-empty array."
        );
      }
    }
    // Check overall conditions
    const meetsCondition1 = hasValidEdge && hasValidLining;
    const meetsCondition2 =
      (hasValidShoelace && hasValidEyelets) || hasValidBuckles;
    if (meetsCondition1 && meetsCondition2) {
      const index = Customize.findIndex(
        (item) => item.Article_no === ProductLocal.Article_no
      );

      if (index !== -1) {
        if (action !== "edit") {
          Customize[index].Edge = ProductLocal.Edge;
          Customize[index].Lining = ProductLocal.Lining;
          Customize[index].Eyelets = ProductLocal.Eyelets;
          Customize[index].Shoelace = ProductLocal.Shoelace;
          Customize[index].Buckle = ProductLocal.Buckle;
        } else {
          Customize[index].Altered = ProductLocal.Altered;
        }
      }
      secureLocalStorage.setItem("Customize", JSON.stringify(Customize));
      alert("Trim's specifications saved in local storage");
      onNext(style, ProductLocal.Article_no);
      setProductLocal(
        transformCustomizeToProductLocal(
          Customize,
          ProductLocal.Article_no,
          action
        ) || {}
      );
    } else {
      // Generate the detailed alert message
      let alertMessage =
        "The specifications do not meet the required conditions:\n\n";
      alertMessage += missingSpecs.join("\n");
      alert(alertMessage);
    }
  };

  return (
    <div className="bg-white max-md:p-2 md:p-4 2xl:p-6 rounded-lg  ">
      <div className="flex flex-col gap-y-8">
        {/* Image Modal (Popup) */}
        {isModalOpen && (
          <div className="fixed inset-0 bg-gray-700 bg-opacity-50 flex flex-col justify-center items-center z-50 ">
            <div className="group flex flex-col items-center justify-center pop-up-animation">
              <button
                onClick={() => setIsModalOpen(false)}
                className="-translate-y-4  duration-300 ease-in-out z-40"
              >
                <svg
                  height="24pt"
                  viewBox="0 0 512 512"
                  width="24pt"
                  xmlns="http://www.w3.org/2000/svg"
                  id="fi_1828945"
                  className=" hover:fill-red-500 hover:bg-red-200 rounded-full duration-300 ease-in-out"
                >
                  <path d="m256 512c-141.164062 0-256-114.835938-256-256s114.835938-256 256-256 256 114.835938 256 256-114.835938 256-256 256zm0-480c-123.519531 0-224 100.480469-224 224s100.480469 224 224 224 224-100.480469 224-224-100.480469-224-224-224zm0 0"></path>
                  <path d="m176.8125 351.1875c-4.097656 0-8.195312-1.554688-11.308594-4.691406-6.25-6.25-6.25-16.382813 0-22.632813l158.398438-158.402343c6.253906-6.25 16.386718-6.25 22.636718 0s6.25 16.382812 0 22.636718l-158.402343 158.398438c-3.15625 3.136718-7.25 4.691406-11.324219 4.691406zm0 0"></path>
                  <path d="m335.1875 351.1875c-4.09375 0-8.191406-1.554688-11.304688-4.691406l-158.398437-158.378906c-6.253906-6.25-6.253906-16.382813 0-22.632813 6.25-6.253906 16.382813-6.253906 22.632813 0l158.398437 158.398437c6.253906 6.25 6.253906 16.382813 0 22.632813-3.132813 3.117187-7.230469 4.671875-11.328125 4.671875zm0 0"></path>
                </svg>
              </button>
              <div className=" bg-white p-2 rounded-lg z-50">
                <img
                  src={selectedImage}
                  alt="Finishing preview"
                  className="max-w-full min-h-[30vh] max-h-[30vh] object-cover"
                />
              </div>
            </div>
          </div>
        )}

        {/* edges */}
        <div
          className={`border-2 rounded-lg p-4 max-w-5xl relative ${
            openDropdown === "edge" ? "border-yellow-400 " : "border-gray-300  "
          }`}
        >
          <div
            className={`absolute border-2 rounded-lg w-32 py-1  text-center text-sm md:text-base font-semibold -top-5 left-5 cursor-pointer ${
              openDropdown === "edge"
                ? "border-yellow-400 bg-yellow-50"
                : "border-gray-300 bg-gray-50 "
            }`}
            onClick={() => toggleDropdown("edge")}
          >
            <p>Edge</p>
          </div>
          {/* Dropdown Content */}
          <div
            className={`transition-max-height duration-500 ease-in overflow-hidden ${
              openDropdown === "edge" ? "max-h-screen" : "max-h-0"
            }`}
          >
            {/* edge Type Selection */}
            <div className=" flex max-md:flex-col md:flex-row max-md:items-start md:items-center justify-start">
              <label
                htmlFor="edgeType"
                className="min-w-20 block w-20 text-sm xl:text-base font-semibold leading-4 text-[#212121]"
              >
                Type:
              </label>
              <div className="flex flex-wrap gap-x-2  max-md:mt-2">
                {Object.keys(edgeOptions).map((edge) => {
                  const isInOriginal =
                    ProductLocal?.Edge && ProductLocal.Edge?.includes(edge)
                      ? true
                      : false;
                  const isInAltered =
                    ProductLocal?.Altered?.Edge &&
                    ProductLocal?.Altered?.Edge?.includes(edge)
                      ? true
                      : false;

                  return (
                    <button
                      className={` rounded-md flex items-center text-sm xl:text-base gap-1.5 text-[#313131]  font-medium px-2 py-2`}
                      onClick={() => handleEdgeSelect(edge)}
                    >
                      <div className="checkbox-wrapper-31">
                        <input
                          type="checkbox"
                          checked={
                            action === "edit"
                              ? (isInOriginal && isInAltered) ||
                                isInAltered ||
                                false
                              : isInOriginal || false
                          }
                          readOnly
                        />
                        <svg
                          viewBox="0 0 35.6 35.6"
                          className={`${
                            action === "edit"
                              ? isInOriginal && isInAltered
                                ? "checked-both"
                                : isInOriginal
                                ? "checked-original"
                                : isInAltered
                                ? "checked-altered"
                                : ""
                              : isInOriginal
                              ? "checked-both"
                              : ""
                          }`}
                        >
                          <circle
                            className="background"
                            cx="17.8"
                            cy="17.8"
                            r="17.8"
                          ></circle>
                          <circle
                            className="stroke"
                            cx="17.8"
                            cy="17.8"
                            r="14.37"
                          ></circle>
                          <polyline
                            className="check"
                            points="11.78 18.12 15.55 22.23 25.17 12.87"
                          ></polyline>
                        </svg>
                      </div>
                      <span className="cursor-default checkbox-wrapper-31-text">
                        {edge.replace(/_/g, " ")}
                      </span>
                      {/* Eye Icon for Image Preview */}
                      <button
                        onClick={
                          () => handleImageClick(edgeOptions[edge].Img) // Correct the image URL path
                        }
                        className="ml-3 text-blue-500"
                      >
                        <svg
                          className="w-3 md:w-5 h-3 md:h-5 fill-gray-500 hover:fill-gray-700"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 640 512"
                        >
                          <path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zM223.1 149.5C248.6 126.2 282.7 112 320 112c79.5 0 144 64.5 144 144c0 24.9-6.3 48.3-17.4 68.7L408 294.5c8.4-19.3 10.6-41.4 4.8-63.3c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3c0 10.2-2.4 19.8-6.6 28.3l-90.3-70.8zM373 389.9c-16.4 6.5-34.3 10.1-53 10.1c-79.5 0-144-64.5-144-144c0-6.9 .5-13.6 1.4-20.2L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5L373 389.9z"></path>
                        </svg>
                      </button>
                    </button>
                  );
                })}
              </div>
            </div>
            <div className="w-full flex justify-end items-center ">
              <button
                className="  mt-2 forward-motion rounded-full p-1 "
                onClick={handleNext}
              >
                <svg
                  id="fi_3114931"
                  className="w-8 p-1 fill-black scale-animation "
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  data-name="Layer 2"
                >
                  <path d="m22.707 11.293-7-7a1 1 0 0 0 -1.414 1.414l5.293 5.293h-17.586a1 1 0 0 0 0 2h17.586l-5.293 5.293a1 1 0 1 0 1.414 1.414l7-7a1 1 0 0 0 0-1.414z"></path>
                </svg>
              </button>
            </div>
          </div>
        </div>

        {/* linings */}
        <div
          className={`border-2 rounded-lg p-4 max-w-5xl relative ${
            openDropdown === "lining"
              ? "border-yellow-400 "
              : "border-gray-300  "
          }`}
        >
          <div
            className={`absolute border-2 rounded-lg w-32 py-1  text-center text-sm md:text-base font-semibold -top-5 left-5 cursor-pointer ${
              openDropdown === "lining"
                ? "border-yellow-400 bg-yellow-50"
                : "border-gray-300 bg-gray-50 "
            }`}
            onClick={() => toggleDropdown("lining")}
          >
            <p>Lining</p>
          </div>
          {/* Dropdown Content */}
          <div
            className={`transition-max-height duration-500 ease-in overflow-hidden ${
              openDropdown === "lining" ? "max-h-screen" : "max-h-0"
            }`}
          >
            {/* lining Type Selection */}
            <div className=" flex max-md:flex-col md:flex-row max-md:items-start md:items-center justify-start">
              <label
                htmlFor="liningType"
                className="min-w-20 block w-20 text-sm xl:text-base font-semibold leading-4 text-[#212121]"
              >
                Type:
              </label>
              <div className="flex flex-wrap gap-x-2  max-md:mt-2">
                {Object.keys(liningOptions).map((lining) => {
                  const isInOriginal =
                    ProductLocal?.Lining &&
                    Object.keys(ProductLocal?.Lining)?.includes(lining)
                      ? true
                      : false;
                  const isInAltered =
                    ProductLocal?.Altered?.Lining &&
                    Object.keys(ProductLocal?.Altered?.Lining)?.includes(lining)
                      ? true
                      : false;
                  return (
                    <>
                      <button
                        className={` rounded-md flex items-center text-sm xl:text-base gap-1.5 text-[#313131]  font-medium px-2 py-2`}
                        onClick={() => handleLiningSelect(lining)}
                      >
                        <div className="checkbox-wrapper-31">
                          <input
                            type="checkbox"
                            checked={
                              action === "edit"
                                ? (isInOriginal && isInAltered) ||
                                  isInAltered ||
                                  false
                                : isInOriginal || false
                            }
                            readOnly
                          />
                          <svg
                            viewBox="0 0 35.6 35.6"
                            className={`${
                              action === "edit"
                                ? isInOriginal && isInAltered
                                  ? "checked-both"
                                  : isInOriginal
                                  ? "checked-original"
                                  : isInAltered
                                  ? "checked-altered"
                                  : ""
                                : isInOriginal
                                ? "checked-both"
                                : ""
                            }`}
                          >
                            <circle
                              className="background"
                              cx="17.8"
                              cy="17.8"
                              r="17.8"
                            ></circle>
                            <circle
                              className="stroke"
                              cx="17.8"
                              cy="17.8"
                              r="14.37"
                            ></circle>
                            <polyline
                              className="check"
                              points="11.78 18.12 15.55 22.23 25.17 12.87"
                            ></polyline>
                          </svg>
                        </div>
                        <span className="cursor-default checkbox-wrapper-31-text">
                          {lining.replace(/_/g, " ")}
                        </span>
                      </button>
                      {/* Eye Icon for Image Preview */}
                      <button
                        onClick={
                          () => handleImageClick(liningOptions[lining].Img) // Correct the image URL path
                        }
                        className="ml-3 text-blue-500"
                      >
                        <svg
                          className="w-3 md:w-5 h-3 md:h-5 fill-gray-500 hover:fill-gray-700"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 640 512"
                        >
                          <path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zM223.1 149.5C248.6 126.2 282.7 112 320 112c79.5 0 144 64.5 144 144c0 24.9-6.3 48.3-17.4 68.7L408 294.5c8.4-19.3 10.6-41.4 4.8-63.3c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3c0 10.2-2.4 19.8-6.6 28.3l-90.3-70.8zM373 389.9c-16.4 6.5-34.3 10.1-53 10.1c-79.5 0-144-64.5-144-144c0-6.9 .5-13.6 1.4-20.2L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5L373 389.9z"></path>
                        </svg>
                      </button>
                    </>
                  );
                })}
              </div>
            </div>
            {/* Colors Section */}
            <div className="mt-3 flex md:flex-row max-md:flex-col items-start  max-xl:gap-3 xl:gap-5">
              <label
                htmlFor="liningColor"
                className="min-w-20 block  text-sm xl:text-base font-semibold leading-4 text-[#212121] mt-2 "
              >
                Color:
              </label>
              <div className="flex flex-wrap gap-2">
                {Object.values(liningColor).map((val) => {
                  const isInOriginal = ProductLocal?.Lining?.[
                    selectedLining
                  ]?.includes(val.color);
                  const isInAltered = ProductLocal?.Altered?.Lining?.[
                    selectedLining
                  ]?.includes(val.color);
                  // Determine the color based on conditions
                  let colorClass =
                    "border-[#fff] before:border-[3px] before:text-[#fff]";
                  if (isInOriginal && isInAltered) {
                    colorClass =
                      "peer-checked:border-[#eebd2d] peer-checked:text-[#eebd2d] peer-checked:before:border-[#eebd2d] peer-checked:before:bg-[#eebd2d]";
                  } else if (isInOriginal && !isInAltered) {
                    colorClass =
                      "peer-checked:border-[#ef4444] peer-checked:text-[#ef4444] peer-checked:before:border-[#ef4444]";
                  } else if (!isInOriginal && isInAltered) {
                    colorClass =
                      "peer-checked:border-[#4ade80] peer-checked:text-[#4ade80] peer-checked:before:border-[#4ade80] peer-checked:before:bg-[#4ade80]";
                  }
                  return (
                    <div className="flex  justify-center items-center   gap-2 ">
                      <div className="flex flex-col items-center justify-center">
                        <label className="text-slate-400">
                          <input
                            type="checkbox"
                            className="h-[1px] opacity-0 overflow-hidden absolute whitespace-nowrap w-[1px] peer"
                            checked={isInOriginal || isInAltered || false}
                            onChange={() => handleLiningColorToggle(val.color)}
                          />
                          <span
                            className={`${
                              action === "edit"
                                ? colorClass
                                : isInOriginal
                                ? "peer-checked:border-[#eebd2d] peer-checked:text-[#eebd2d] peer-checked:before:border-[#eebd2d] peer-checked:before:bg-[#eebd2d]"
                                : "border-[#fff] before:border-[3px] before:text-[#fff]"
                            } peer-checked:before:opacity-100 peer-checked:before:scale-100  flex flex-col items-center justify-center w-14 h-14 xl:w-[86px] xl:h-[86px] rounded-lg  transition-all duration-200 cursor-pointer relative border-2 bg-[#fff] before:absolute before:block max-xl:before:w-1 xl:before:w-2 max-xl:before:h-1 xl:before:h-2  before:rounded-full before:top-1 before:left-1 before:opacity-0 before:transition-transform before:scale-0 before:text-xs  before:items-center before:justify-center hover:border-[#eebd2d] hover:before:scale-100 hover:before:opacity-100`}
                          >
                            <img
                              className="w-10 h-10 xl:w-[70px] xl:h-[70px] rounded-full  flex items-center justify-center border-[1px]"
                              src={val.img}
                              alt=""
                              srcset=""
                            />
                          </span>
                        </label>

                        <p className="font-semibold text-xs xl:text-sm">
                          {val.color.replace(/_/g, " ")}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="w-full flex justify-end items-center ">
              <button
                className="  mt-2 forward-motion rounded-full p-1 "
                onClick={handleNext}
              >
                <svg
                  id="fi_3114931"
                  className="w-8 p-1 fill-black scale-animation "
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  data-name="Layer 2"
                >
                  <path d="m22.707 11.293-7-7a1 1 0 0 0 -1.414 1.414l5.293 5.293h-17.586a1 1 0 0 0 0 2h17.586l-5.293 5.293a1 1 0 1 0 1.414 1.414l7-7a1 1 0 0 0 0-1.414z"></path>
                </svg>
              </button>
            </div>
          </div>
        </div>

        {/* select one */}
        <div className="w-full  max-md:gap-2 py-2 text-base  font-semibold bg-white flex md:flex-row max-md:flex-col max-md:items-start max-md:justify-center md:items-center md:justify-start  rounded-lg ">
          <label className="min-w-32 block text-sm xl:text-base font-semibold leading-4 text-[#212121] ">
            Choose One:
          </label>
          <div className="flex  items-center justify-start w-full gap-2 ">
            <label
              htmlFor="shoelaces"
              name="status"
              className="font-medium w-40  relative border hover:bg-zinc-100 px-3 py-2 gap-3 rounded-lg flex items-center justify-between"
              onClick={() => {
                setSelected("shoelaces");
                setOpenDropdown("eyelets");
              }}
            >
              Shoelaces
              <div className="checkbox-wrapper-31">
                <input
                  type="checkbox"
                  checked={selected === "shoelaces"}
                  readOnly
                />
                <svg viewBox="0 0 35.6 35.6" className="checked-both">
                  <circle
                    className="background"
                    cx="17.8"
                    cy="17.8"
                    r="17.8"
                  ></circle>
                  <circle className="stroke" cx="17.8" cy="17.8" r="14.37"></circle>
                  <polyline
                    className="check"
                    points="11.78 18.12 15.55 22.23 25.17 12.87"
                  ></polyline>
                </svg>
              </div>
            </label>
            <label
              for="buckles"
              className="font-medium w-40 px-3 py-2 relative border hover:bg-zinc-100  flex items-center justify-between  gap-3 rounded-lg "
              onClick={() => {
                setSelected("buckles");
                toggleDropdown("buckles");
              }}
            >
              Buckles
              <div className="checkbox-wrapper-31">
                <input
                  type="checkbox"
                  checked={selected === "buckles"}
                  readOnly
                />
                <svg viewBox="0 0 35.6 35.6" className="checked-both">
                  <circle
                    className="background"
                    cx="17.8"
                    cy="17.8"
                    r="17.8"
                  ></circle>
                  <circle className="stroke" cx="17.8" cy="17.8" r="14.37"></circle>
                  <polyline
                    className="check"
                    points="11.78 18.12 15.55 22.23 25.17 12.87"
                  ></polyline>
                </svg>
              </div>
            </label>
          </div>{" "}
        </div>

        {/* eyelets & shoelaces */}
        {selected === "shoelaces" && (
          <>
            {/* eyelets */}
            <div
              className={`border-2 rounded-lg p-4 max-w-5xl relative ${
                openDropdown === "eyelets"
                  ? "border-yellow-400 "
                  : "border-gray-300  "
              }`}
            >
              <div
                className={`absolute border-2 rounded-lg w-32 py-1  text-center text-sm md:text-base font-semibold -top-5 left-5 cursor-pointer ${
                  openDropdown === "eyelets"
                    ? "border-yellow-400 bg-yellow-50"
                    : "border-gray-300 bg-gray-50 "
                }`}
                onClick={() => toggleDropdown("eyelets")}
              >
                <p>EYELETS</p>
              </div>
              {/* Dropdown Content */}
              <div
                className={`transition-max-height duration-500 ease-in overflow-hidden ${
                  openDropdown === "eyelets" ? "max-h-screen" : "max-h-0"
                }`}
              >
                {/* eyelets Type Selection */}
                <div className=" flex max-md:flex-col md:flex-row max-md:items-start md:items-center justify-start">
                  <label
                    htmlFor="eyeletsType"
                    className="min-w-20 block w-20 text-sm xl:text-base font-semibold leading-4 text-[#212121]"
                  >
                    Type:
                  </label>
                  <div className="flex flex-wrap gap-x-2  max-md:mt-2">
                    {Object.keys(eyeletOptions).map((eyelets) => {
                      const isInOriginal =
                        ProductLocal?.Eyelets &&
                        ProductLocal.Eyelets?.includes(eyelets)
                          ? true
                          : false;
                      const isInAltered =
                        ProductLocal?.Altered?.Eyelets &&
                        ProductLocal?.Altered?.Eyelets?.includes(eyelets)
                          ? true
                          : false;

                      return (
                        <button
                          className={` rounded-md flex items-center text-sm xl:text-base gap-1.5 text-[#313131]  font-medium px-2 py-2`}
                          onClick={() => handleEyeletsSelect(eyelets)}
                        >
                          <div className="checkbox-wrapper-31">
                            <input
                              type="checkbox"
                              checked={
                                action === "edit"
                                  ? (isInOriginal && isInAltered) ||
                                    isInAltered ||
                                    false
                                  : isInOriginal || false
                              }
                              readOnly
                            />
                            <svg
                              viewBox="0 0 35.6 35.6"
                              className={`${
                                action === "edit"
                                  ? isInOriginal && isInAltered
                                    ? "checked-both"
                                    : isInOriginal
                                    ? "checked-original"
                                    : isInAltered
                                    ? "checked-altered"
                                    : ""
                                  : isInOriginal
                                  ? "checked-both"
                                  : ""
                              }`}
                            >
                              <circle
                                className="background"
                                cx="17.8"
                                cy="17.8"
                                r="17.8"
                              ></circle>
                              <circle
                                className="stroke"
                                cx="17.8"
                                cy="17.8"
                                r="14.37"
                              ></circle>
                              <polyline
                                className="check"
                                points="11.78 18.12 15.55 22.23 25.17 12.87"
                              ></polyline>
                            </svg>
                          </div>
                          <span className="cursor-default checkbox-wrapper-31-text">
                            {eyelets.replace(/_/g, " ")}
                          </span>
                          {/* Eye Icon for Image Preview */}
                          <button
                            onClick={
                              () => handleImageClick(eyeletOptions[eyelets].Img) // Correct the image URL path
                            }
                            className="ml-3 text-blue-500"
                          >
                            <svg
                              className="w-3 md:w-5 h-3 md:h-5 fill-gray-500 hover:fill-gray-700"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 640 512"
                            >
                              <path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zM223.1 149.5C248.6 126.2 282.7 112 320 112c79.5 0 144 64.5 144 144c0 24.9-6.3 48.3-17.4 68.7L408 294.5c8.4-19.3 10.6-41.4 4.8-63.3c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3c0 10.2-2.4 19.8-6.6 28.3l-90.3-70.8zM373 389.9c-16.4 6.5-34.3 10.1-53 10.1c-79.5 0-144-64.5-144-144c0-6.9 .5-13.6 1.4-20.2L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5L373 389.9z"></path>
                            </svg>
                          </button>
                        </button>
                      );
                    })}
                  </div>
                </div>
                <div className="w-full flex justify-end items-center ">
                  <button
                    className="  mt-2 forward-motion rounded-full p-1 "
                    onClick={handleNext}
                  >
                    <svg
                      id="fi_3114931"
                      className="w-8 p-1 fill-black scale-animation "
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      data-name="Layer 2"
                    >
                      <path d="m22.707 11.293-7-7a1 1 0 0 0 -1.414 1.414l5.293 5.293h-17.586a1 1 0 0 0 0 2h17.586l-5.293 5.293a1 1 0 1 0 1.414 1.414l7-7a1 1 0 0 0 0-1.414z"></path>
                    </svg>
                  </button>
                </div>
              </div>
            </div>

            {/* shoelaces */}
            <div
              className={`border-2 rounded-lg p-4 max-w-5xl relative ${
                openDropdown === "shoelaces"
                  ? "border-yellow-400 "
                  : "border-gray-300  "
              }`}
            >
              <div
                className={`absolute border-2 rounded-lg w-32 py-1  text-center text-sm md:text-base font-semibold -top-5 left-5 cursor-pointer ${
                  openDropdown === "shoelaces"
                    ? "border-yellow-400 bg-yellow-50"
                    : "border-gray-300 bg-gray-50 "
                }`}
                onClick={() => toggleDropdown("shoelaces")}
              >
                <p>SHOELACES</p>
              </div>
              {/* Dropdown Content */}
              <div
                className={`transition-max-height duration-500 ease-in overflow-hidden ${
                  openDropdown === "shoelaces" ? "max-h-screen" : "max-h-0"
                }`}
              >
                {/* shoelaces Type Selection */}
                <div className=" flex max-md:flex-col md:flex-row max-md:items-start md:items-center justify-start">
                  <label
                    htmlFor="shoelacesType"
                    className="min-w-20 block w-20 text-sm xl:text-base font-semibold leading-4 text-[#212121]"
                  >
                    Type:
                  </label>
                  <div className="flex flex-wrap gap-x-2  max-md:mt-2">
                    {Object.keys(shoelaceOptions).map((shoelace) => {
                      const isInOriginal =
                        ProductLocal?.Shoelace &&
                        Object.keys(ProductLocal.Shoelace)?.includes(shoelace)
                          ? true
                          : false;
                      const isInAltered =
                        ProductLocal?.Altered?.Shoelace &&
                        Object.keys(ProductLocal?.Altered?.Shoelace)?.includes(
                          shoelace
                        )
                          ? true
                          : false;

                      return (
                        <button
                          className={` rounded-md flex items-center text-sm xl:text-base gap-1.5 text-[#313131]  font-medium px-2 py-2`}
                          onClick={() => handleShoelaceSelect(shoelace)}
                        >
                          <div className="checkbox-wrapper-31">
                            <input
                              type="checkbox"
                              checked={
                                action === "edit"
                                  ? (isInOriginal && isInAltered) ||
                                    isInAltered ||
                                    false
                                  : isInOriginal || false
                              }
                              readOnly
                            />
                            <svg
                              viewBox="0 0 35.6 35.6"
                              className={`${
                                action === "edit"
                                  ? isInOriginal && isInAltered
                                    ? "checked-both"
                                    : isInOriginal
                                    ? "checked-original"
                                    : isInAltered
                                    ? "checked-altered"
                                    : ""
                                  : isInOriginal
                                  ? "checked-both"
                                  : ""
                              }`}
                            >
                              <circle
                                className="background"
                                cx="17.8"
                                cy="17.8"
                                r="17.8"
                              ></circle>
                              <circle
                                className="stroke"
                                cx="17.8"
                                cy="17.8"
                                r="14.37"
                              ></circle>
                              <polyline
                                className="check"
                                points="11.78 18.12 15.55 22.23 25.17 12.87"
                              ></polyline>
                            </svg>
                          </div>
                          <span className="cursor-default checkbox-wrapper-31-text">
                            {shoelace.replace(/_/g, " ")}
                          </span>
                          {/* Eye Icon for Image Preview */}
                          <button
                            onClick={
                              () =>
                                handleImageClick(shoelaceOptions[shoelace].Img) // Correct the image URL path
                            }
                            className="ml-3 text-blue-500"
                          >
                            <svg
                              className="w-3 md:w-5 h-3 md:h-5 fill-gray-500 hover:fill-gray-700"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 640 512"
                            >
                              <path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zM223.1 149.5C248.6 126.2 282.7 112 320 112c79.5 0 144 64.5 144 144c0 24.9-6.3 48.3-17.4 68.7L408 294.5c8.4-19.3 10.6-41.4 4.8-63.3c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3c0 10.2-2.4 19.8-6.6 28.3l-90.3-70.8zM373 389.9c-16.4 6.5-34.3 10.1-53 10.1c-79.5 0-144-64.5-144-144c0-6.9 .5-13.6 1.4-20.2L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5L373 389.9z"></path>
                            </svg>
                          </button>
                        </button>
                      );
                    })}
                  </div>
                </div>
                {/* Colors Section */}
                <div className="mt-3 flex md:flex-row max-md:flex-col items-start  max-xl:gap-3 xl:gap-5">
                  <label
                    htmlFor="shoelaceColor"
                    className="min-w-20 block  text-sm xl:text-base font-semibold leading-4 text-[#212121] mt-2 "
                  >
                    Color:
                  </label>
                  <div className="flex flex-wrap gap-2">
                    {Object.values(shoelaceColor).map((val) => {
                      const isInOriginal = ProductLocal?.Shoelace?.[
                        selectedShoelace
                      ]?.includes(val.color);
                      const isInAltered = ProductLocal?.Altered?.Shoelace?.[
                        selectedShoelace
                      ]?.includes(val.color);
                      // Determine the color based on conditions
                      let colorClass =
                        "border-[#fff] before:border-[3px] before:text-[#fff]";
                      if (isInOriginal && isInAltered) {
                        colorClass =
                          "peer-checked:border-[#eebd2d] peer-checked:text-[#eebd2d] peer-checked:before:border-[#eebd2d] peer-checked:before:bg-[#eebd2d]";
                      } else if (isInOriginal && !isInAltered) {
                        colorClass =
                          "peer-checked:border-[#ef4444] peer-checked:text-[#ef4444] peer-checked:before:border-[#ef4444]";
                      } else if (!isInOriginal && isInAltered) {
                        colorClass =
                          "peer-checked:border-[#4ade80] peer-checked:text-[#4ade80] peer-checked:before:border-[#4ade80] peer-checked:before:bg-[#4ade80]";
                      }
                      return (
                        <div className="flex  justify-center items-center   gap-2 ">
                          <div className="flex flex-col items-center justify-center">
                            <label className="text-slate-400">
                              <input
                                type="checkbox"
                                className="h-[1px] opacity-0 overflow-hidden absolute whitespace-nowrap w-[1px] peer"
                                checked={isInOriginal || isInAltered || false}
                                onChange={() =>
                                  handleShoelaceColorToggle(val.color)
                                }
                              />
                              <span
                                className={`${
                                  action === "edit"
                                    ? colorClass
                                    : isInOriginal
                                    ? "peer-checked:border-[#eebd2d] peer-checked:text-[#eebd2d] peer-checked:before:border-[#eebd2d] peer-checked:before:bg-[#eebd2d]"
                                    : "border-[#fff] before:border-[3px] before:text-[#fff]"
                                } peer-checked:before:opacity-100 peer-checked:before:scale-100  flex flex-col items-center justify-center w-14 h-14 xl:w-[86px] xl:h-[86px] rounded-lg  transition-all duration-200 cursor-pointer relative border-2 bg-[#fff] before:absolute before:block max-xl:before:w-1 xl:before:w-2 max-xl:before:h-1 xl:before:h-2  before:rounded-full before:top-1 before:left-1 before:opacity-0 before:transition-transform before:scale-0 before:text-xs  before:items-center before:justify-center hover:border-[#eebd2d] hover:before:scale-100 hover:before:opacity-100`}
                              >
                                {" "}
                                <img
                                  className="w-10 h-10 xl:w-[70px] xl:h-[70px] rounded-full  flex items-center justify-center border-[1px]"
                                  src={val.img}
                                  alt=""
                                  srcset=""
                                />
                              </span>
                            </label>

                            <p className="font-semibold text-xs xl:text-sm">
                              {val.color.replace(/_/g, " ")}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {/* buckles */}
        {selected === "buckles" && (
          <div
            className={`border-2 rounded-lg p-4 max-w-5xl relative ${
              openDropdown === "buckles"
                ? "border-yellow-400 "
                : "border-gray-300  "
            }`}
          >
            <div
              className={`absolute border-2 rounded-lg w-32 py-1  text-center text-sm md:text-base font-semibold -top-5 left-5 cursor-pointer ${
                openDropdown === "buckles"
                  ? "border-yellow-400 bg-yellow-50"
                  : "border-gray-300 bg-gray-50 "
              }`}
              onClick={() => toggleDropdown("buckles")}
            >
              <p>BUCKLES</p>
            </div>
            {/* Dropdown Content */}
            <div
              className={`transition-max-height duration-500 ease-in overflow-hidden ${
                openDropdown === "buckles" ? "max-h-screen" : "max-h-0"
              }`}
            >
              {/* buckles Type Selection */}
              <div className=" flex max-md:flex-col md:flex-row max-md:items-start md:items-center justify-start">
                <label
                  htmlFor="bucklesType"
                  className="min-w-20 block w-20 text-sm xl:text-base font-semibold leading-4 text-[#212121]"
                >
                  Type:
                </label>
                <div className="flex flex-wrap gap-x-2  max-md:mt-2">
                  {Object.keys(buckleOptions).map((buckle) => {
                    const isInOriginal =
                      ProductLocal?.Buckle &&
                      Object.keys(ProductLocal.Buckle).includes(buckle)
                        ? true
                        : false;
                    const isInAltered =
                      ProductLocal?.Altered?.Buckle &&
                      Object.keys(ProductLocal?.Altered?.Buckle)?.includes(
                        buckle
                      )
                        ? true
                        : false;

                    return (
                      <button
                        className={` rounded-md flex items-center text-sm xl:text-base gap-1.5 text-[#313131]  font-medium px-2 py-2`}
                        onClick={() => handleBuckleSelect(buckle)}
                      >
                        <div className="checkbox-wrapper-31">
                          <input
                            type="checkbox"
                            checked={
                              action === "edit"
                                ? (isInOriginal && isInAltered) ||
                                  isInAltered ||
                                  false
                                : isInOriginal || false
                            }
                            readOnly
                          />
                          <svg
                            viewBox="0 0 35.6 35.6"
                            className={`${
                              action === "edit"
                                ? isInOriginal && isInAltered
                                  ? "checked-both"
                                  : isInOriginal
                                  ? "checked-original"
                                  : isInAltered
                                  ? "checked-altered"
                                  : ""
                                : isInOriginal
                                ? "checked-both"
                                : ""
                            }`}
                          >
                            <circle
                              className="background"
                              cx="17.8"
                              cy="17.8"
                              r="17.8"
                            ></circle>
                            <circle
                              className="stroke"
                              cx="17.8"
                              cy="17.8"
                              r="14.37"
                            ></circle>
                            <polyline
                              className="check"
                              points="11.78 18.12 15.55 22.23 25.17 12.87"
                            ></polyline>
                          </svg>
                        </div>
                        <span className="cursor-default checkbox-wrapper-31-text">
                          {buckle.replace(/_/g, " ")}
                        </span>
                        {/* Eye Icon for Image Preview */}
                        <button
                          onClick={
                            () => handleImageClick(buckleOptions[buckle].Img) // Correct the image URL path
                          }
                          className="ml-3 text-blue-500"
                        >
                          <svg
                            className="w-3 md:w-5 h-3 md:h-5 fill-gray-500 hover:fill-gray-700"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 640 512"
                          >
                            <path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zM223.1 149.5C248.6 126.2 282.7 112 320 112c79.5 0 144 64.5 144 144c0 24.9-6.3 48.3-17.4 68.7L408 294.5c8.4-19.3 10.6-41.4 4.8-63.3c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3c0 10.2-2.4 19.8-6.6 28.3l-90.3-70.8zM373 389.9c-16.4 6.5-34.3 10.1-53 10.1c-79.5 0-144-64.5-144-144c0-6.9 .5-13.6 1.4-20.2L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5L373 389.9z"></path>
                          </svg>
                        </button>
                      </button>
                    );
                  })}
                </div>
              </div>
              {/* Colors Section */}
              <div className="mt-3 flex md:flex-row max-md:flex-col items-start  max-xl:gap-3 xl:gap-5">
                <label
                  htmlFor="buckleColor"
                  className="min-w-20 block  text-sm xl:text-base font-semibold leading-4 text-[#212121] mt-2 "
                >
                  Color:
                </label>
                <div className="flex flex-wrap gap-2">
                  {Object.values(buckleColor).map((val) => {
                    const isInOriginal = ProductLocal?.Buckle?.[
                      selectedBuckle
                    ]?.includes(val.color);
                    const isInAltered = ProductLocal?.Altered?.Buckle?.[
                      selectedBuckle
                    ]?.includes(val.color);
                    // Determine the color based on conditions
                    let colorClass =
                      "border-[#fff] before:border-[3px] before:text-[#fff]";
                    if (isInOriginal && isInAltered) {
                      colorClass =
                        "peer-checked:border-[#eebd2d] peer-checked:text-[#eebd2d] peer-checked:before:border-[#eebd2d] peer-checked:before:bg-[#eebd2d]";
                    } else if (isInOriginal && !isInAltered) {
                      colorClass =
                        "peer-checked:border-[#ef4444] peer-checked:text-[#ef4444] peer-checked:before:border-[#ef4444]";
                    } else if (!isInOriginal && isInAltered) {
                      colorClass =
                        "peer-checked:border-[#4ade80] peer-checked:text-[#4ade80] peer-checked:before:border-[#4ade80] peer-checked:before:bg-[#4ade80]";
                    }
                    return (
                      <div className="flex  justify-center items-center   gap-2 ">
                        <div className="flex flex-col items-center justify-center">
                          <label className="text-slate-400">
                            <input
                              type="checkbox"
                              className="h-[1px] opacity-0 overflow-hidden absolute whitespace-nowrap w-[1px] peer"
                              checked={isInOriginal || isInAltered || false}
                              onChange={() =>
                                handleBuckleColorToggle(val.color)
                              }
                            />
                            <span
                              className={`${
                                action === "edit"
                                  ? colorClass
                                  : isInOriginal
                                  ? "peer-checked:border-[#eebd2d] peer-checked:text-[#eebd2d] peer-checked:before:border-[#eebd2d] peer-checked:before:bg-[#eebd2d]"
                                  : "border-[#fff] before:border-[3px] before:text-[#fff]"
                              } peer-checked:before:opacity-100 peer-checked:before:scale-100  flex flex-col items-center justify-center w-14 h-14 xl:w-[86px] xl:h-[86px] rounded-lg  transition-all duration-200 cursor-pointer relative border-2 bg-[#fff] before:absolute before:block max-xl:before:w-1 xl:before:w-2 max-xl:before:h-1 xl:before:h-2  before:rounded-full before:top-1 before:left-1 before:opacity-0 before:transition-transform before:scale-0 before:text-xs  before:items-center before:justify-center hover:border-[#eebd2d] hover:before:scale-100 hover:before:opacity-100`}
                            >
                              {" "}
                              <img
                                className="w-10 h-10 xl:w-[70px] xl:h-[70px] rounded-full  flex items-center justify-center border-[1px]"
                                src={val.img}
                                alt=""
                                srcset=""
                              />
                            </span>
                          </label>

                          <p className="font-semibold text-xs xl:text-sm">
                            {val.color.replace(/_/g, " ")}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Save Button */}
      <div className="flex items-center justify-end w-full mt-3 gap-3">
        <button
          className="bg-white text-center w-16 rounded-lg h-9 lg:h-11 relative text-[#313131] text-xl font-semibold group border "
          type="button"
          onClick={() => {
            setProductLocal((prev) => {
              const updated = { ...prev };
              const keysToRemove = [
                "Buckle",
                "Edge",
                "Eyelets",
                "Lining",
                "Shoelace",
              ];
              keysToRemove.forEach((key) => {
                if (updated[key]) {
                  delete updated[key];
                }
              });

              secureLocalStorage.setItem("Product", JSON.stringify(updated));
              return updated;
            });
          }}
        >
          <p className="text-xs lg:text-sm">Reset</p>
        </button>
        {/* Save Button */}
        <button
          className="bg-white text-center w-20 lg:w-32 rounded-lg h-9 lg:h-11 relative text-[#313131] text-xl font-semibold group border"
          type="button"
          onClick={() => handleSave()}
        >
          <div className="bg-yellow-400 rounded-md h-[26px] lg:h-[34px] w-1/3 flex items-center justify-center absolute left-1 top-[4px] group-hover:w-[70px] group-hover:lg:w-[118px] z-10 duration-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1024 1024"
              className="rotate-180 w-4 lg:w-6 h-4 lg:h-6"
            >
              <path
                d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z"
                fill="#313131"
              ></path>
              <path
                d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z"
                fill="#313131"
              ></path>
            </svg>
          </div>
          <p className="max-lg:translate-x-4 lg:translate-x-5 text-xs lg:text-sm">
            Next
          </p>
        </button>
      </div>
    </div>
  );
};

export default EdgeSelection;
