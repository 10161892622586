import React, { useState } from "react";
import { FaFacebookF, FaGoogle, FaLinkedin } from "react-icons/fa";
import { IoEyeSharp } from "react-icons/io5";
import { HiMiniEyeSlash } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";
import Loader2 from "../../components/Loaders/Loader2";
import PhoneInput from "react-phone-input-2";

// Validation rules for different countries
const phoneValidationRules = {
  IN: { length: 12, message: "Phone number must be a 12-digit number." },
  AU: { length: 11, message: "Phone number must be a 11-digit number." },
  US: { length: 11, message: "Phone number must be a 11-digit number." },
  GB: { length: 12, message: "Phone number must be a 12-digit number." },
  FR: { length: 11, message: "Phone number must be a 11-digit number." },
  DE: { length: 12, message: "Phone number must be a 12-digit number." },
  JP: { length: 12, message: "Phone number must be a 12-digit number." },
  TW: { length: 15, message: "Phone number must be a 15-digit number." },
  AT: { length: 15, message: "Phone number must be a 15-digit number." },
  BE: { length: 11, message: "Phone number must be a 11-digit number." },
  CH: { length: 11, message: "Phone number must be a 11-digit number." },
  NO: { length: 10, message: "Phone number must be a 10-digit number." },
  DK: { length: 10, message: "Phone number must be a 10-digit number." },
  FI: { length: 12, message: "Phone number must be a 12-digit number." },
  IL: { length: 13, message: "Phone number must be a 13-digit number." },
  SA: { length: 15, message: "Phone number must be a 15-digit number." },
  KW: { length: 15, message: "Phone number must be a 15-digit number." },
  BH: { length: 15, message: "Phone number must be a 15-digit number." },
  QA: { length: 15, message: "Phone number must be a 15-digit number." },
  IR: { length: 12, message: "Phone number must be a 12-digit number." },
  IQ: { length: 15, message: "Phone number must be a 15-digit number." },
  AE: { length: 15, message: "Phone number must be a 15-digit number." },
};

function ForgetPassword() {
  const [email, setEmail] = useState("");
  const [emailOtp, setEmailOtp] = useState("");
  const [phoneOtp, setPhoneOtp] = useState("");
  const [otp, setOtp] = useState();
  const [phone, setPhone] = useState("");
  const [generatedEmailOtp, setGeneratedEmailOtp] = useState("");
  const [emailVerified, setEmailVerified] = useState(0);
  const [phoneVerified, setPhoneVerified] = useState(0);
  const [generatedPhoneOtp, setGeneratedPhoneOtp] = useState("");
  const [sendOTP23, setSendOTP23] = useState(false);
  const [loader, setLoader] = useState(false);
  const [resendTimer, setResendTimer] = useState(90);
  const [resendPhoneTimer, setResendPhoneTimer] = useState(90);
  const [isFocused, setIsFocused] = useState(false);
  const [countryCode, setCountryCode] = useState("in");
  const [isPhoneValid, setIsPhoneValid] = useState(false);
  const [pass, setPass] = useState("");
  const [passwordStrength, setPasswordStrength] = useState({
    label: "",
    color: "",
    width: "0%",
  });
  const [confirmpass, setConfirmpass] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [passwordMatchError, setPasswordMatchError] = useState("");
  const navigate = useNavigate();

  const handleResendTimer = () => {
    setGeneratedEmailOtp("");
    setResendTimer(90);

    const timerInterval = setInterval(() => {
      setResendTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    setTimeout(() => {
      clearInterval(timerInterval);
    }, 90000);
  };

  const handleResendPhoneTimer = () => {
    setGeneratedPhoneOtp("");
    setResendPhoneTimer(90);

    const timerInterval = setInterval(() => {
      setResendPhoneTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    setTimeout(() => {
      clearInterval(timerInterval);
    }, 90000);
  };

  // Email validation function
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email validation regex
    return emailRegex.test(email);
  };

  const verifyEmailOtp = (e) => {
    e.preventDefault();
    if (generatedEmailOtp === emailOtp) {
      setEmailVerified(1); // Mark email as verified
    } else {
      alert("Email is not verified. Please enter the correct OTP!");
      setEmailVerified(2); // Mark email verification as failed
    }
  };

  const sendEmailOtp = (e) => {
    e.preventDefault();

    // Validate email before sending OTP
    if (!isValidEmail(email)) {
      alert("Please enter a valid email address!");
      return; // Exit if the email is invalid
    }

    // Send the email OTP request to the backend
    fetch(`https://buwchlifestyles.com/api/send-email-forget-pass-otp/`, {
      method: "POST",
      body: JSON.stringify({
        email: email,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to send OTP");
        }
        return response.json();
      })
      .then((response) => {
        handleResendTimer(); // Start the resend timer
        setGeneratedEmailOtp(response.data); // Store the generated OTP
        setEmailOtp(""); // Clear OTP input field
      })
      .catch((error) => {
        console.error("Error: ", error);
      });
  };

  const verifyPhoneOtp = (e) => {
    e.preventDefault();
    if (phoneOtp === otp) {
      alert("Phone is verified.");
      setPhoneVerified(1);
    } else {
      alert("Phone is not verified. Please enter correct otp!");
      setPhoneVerified(2);
    }
  };

  const handlephone = (value, countryData) => {
    setPhone(value);
    setCountryCode(countryData?.countryCode);

    // Check if the phone number matches the length of the selected country's rule
    const currentCountryCode = countryData?.countryCode?.toUpperCase() || "IN";
    const validationRule = phoneValidationRules[currentCountryCode];

    // Update the isPhoneValid state based on the length of the phone number
    if (validationRule && value.length === validationRule.length) {
      setIsPhoneValid(true); // Phone number is valid
    } else {
      setIsPhoneValid(false); // Phone number is invalid
    }
  };

  const sendPhoneOtp = (e) => {
    e.preventDefault();

    const currentCountryCode = countryCode?.toUpperCase() || "IN";
    const validationRule = phoneValidationRules[currentCountryCode];

    if (validationRule && phone.length !== validationRule.length) {
      setSendOTP23(false); // Keep the button available if validation fails
      alert(validationRule.message); // Show alert if validation fails
      return; // Exit the function if phone number is invalid
    }
    // Proceed with sending OTP if validation passes
    fetch(`https://buwchlifestyles.com/api/register/send-phone-otp/`, {
      method: "POST",
      body: JSON.stringify({
        phone: phone,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to send OTP");
        }
        return response.json();
      })
      .then((response) => {
        var dataObject = JSON.parse(response.msg91_response);
        setOtp(response.otp);
        handleResendPhoneTimer();
        setGeneratedPhoneOtp(dataObject.status);
        setSendOTP23(true); // Set OTP sent state
      })
      .catch((error) => {
        console.error("Error: ", error);
      });
  };

  // Function to validate password strength
  const validatePassword = (password) => {
    setPass(password);

    let strength = { label: "Weak", color: "bg-red-500", width: "33%" }; // Default is weak

    // Define regex for different password strengths
    const strongPassword = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    );
    const mediumPassword = new RegExp(
      "^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})"
    );

    // Determine password strength
    if (strongPassword.test(password)) {
      strength = { label: "Strong", color: "bg-green-500", width: "100%" };
    } else if (mediumPassword.test(password)) {
      strength = { label: "Medium", color: "bg-yellow-500", width: "66%" };
    }

    setPasswordStrength(strength); // Update password strength state
    validatePasswordMatch(password, confirmpass); // Check match with confirm password
  };

  // Function to validate if passwords match
  const validatePasswordMatch = (newPass, confirmPass) => {
    if (newPass !== confirmPass) {
      setPasswordMatchError("Passwords do not match.");
    } else {
      setPasswordMatchError("");
    }
  };

  const handleConfirmPasswordChange = (e) => {
    const confirmPassword = e.target.value;
    setConfirmpass(confirmPassword);
    validatePasswordMatch(pass, confirmPassword); // Validate match on confirm password change
  };

  const showResendCountdown =
    isPhoneValid && sendOTP23 && resendPhoneTimer > 0 && resendPhoneTimer < 90;

  const handleRegister = (e) => {
    setLoader(true);
    e.preventDefault();
    if (pass !== confirmpass) {
      alert("Passwords do not match, please re-renter your password.");
      setLoader(false);
      } else if (emailVerified !== 1) {
        alert("Your email is not verified, Please verify your email.");
        setLoader(false);
      } else if (phoneVerified !== 1) {
        alert("Your phone is not verified, Please verify your phone number.");
        setLoader(false);
    } else {
      fetch(`https://buwchlifestyles.com/api/forget-password/`, {
        method: "POST",
        body: JSON.stringify({
          email: email,
          password: pass,
          phone: phone,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to login");
          }
          return response.json();
        })
        .then((response) => {
          alert(response.data);
          if (
            response.data ===
            "Successfully Reset the password, please login to continue"
          ) {
            setLoader(false);
            navigate("/", { state: "login" });
          }
        })
        .catch((error) => {
          // console.error("Error: ", error);
        });
    }
  };

  return (
    <>
      {loader && <Loader2 />}
      <div className="bg-fixed flex min-h-screen flex-1 flex-col justify-center px-6 py-12 lg:px-8 md:mt-4">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <a href="/">
            <img
              className="mx-auto h-16 w-auto"
              src="/images/BuwchLogo.svg"
              alt="Your Company"
            />
          </a>
          <h2 className=" text-center text-2xl font-bold leading-9 tracking-tight text-[#212121]">
            Recover Account
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm ">
          <form className="space-y-6" onSubmit={handleRegister}>
            <div className=" justify-center">
              {/* email Input and OTP  */}
              <div className="mt-3 group">
                <div className="flex justify-between">
                  <label
                    htmlFor="email"
                    className="block text-sm font-semibold leading-4 text-[#212121]"
                  >
                    Email ID
                  </label>
                </div>
                <div className="flex items-center mt-2 relative">
                  <div className="input-group m-0 p-0 w-full flex">
                    <input
                      value={email}
                      className="px-2 block w-full py-1.5 text-[#212121] ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 group-hover:ring-2 group-hover:ring-[#eebd2d] focus:ring-2 focus:ring-[#eebd2d] focus:outline-none focus:ring-inset text-sm sm:leading-6"
                      onChange={(e) => setEmail(e.target.value)}
                      type="email"
                      placeholder="name@email.com"
                      id="email"
                      name="email"
                      required
                      disabled={emailVerified === 1} // Disable input if email is verified
                    />
                  </div>

                  {/* Show Send OTP button or Resend Timer */}
                  <div className="text-sm absolute inset-y-2 right-2">
                    {emailVerified !== 1 && (
                      <>
                        {0 < resendTimer && resendTimer < 90 ? (
                          <div className="font-semibold text-[#FFC94A] hover:text-[#e2a731] duration-300 ease-in-out">
                            Resend in {resendTimer}s
                          </div>
                        ) : (
                          <button
                            className="focus:animate-pulse font-semibold text-[#FFC94A] hover:text-[#e2a731] duration-300 ease-in-out"
                            onClick={sendEmailOtp}
                          >
                            Send OTP
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>

                {/* Show OTP input field only if the OTP is generated */}
                {generatedEmailOtp && (
                  <div className="flex justify-between items-center mt-2">
                    <div className="col-10">
                      <input
                        value={emailOtp}
                        onChange={(e) => setEmailOtp(e.target.value)}
                        type="text"
                        className={`px-2 block w-full py-1.5 text-[#212121] ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-[#eebd2d] text-sm sm:leading-6 ${
                          emailVerified === 2
                            ? "border-danger border-2"
                            : "border-dark"
                        }`}
                        placeholder="Enter OTP"
                        id="emailOtp"
                        name="emailOtp"
                        required
                        disabled={emailVerified === 1} // Disable OTP input if email is verified
                      />
                    </div>
                    <div>
                      {emailVerified !== 1 && (
                        <button
                          className="text-sm font-semibold text-[#FFC94A] hover:text-[#e2a731] duration-300 ease-in-out"
                          onClick={verifyEmailOtp}
                        >
                          Verify
                        </button>
                      )}
                      {emailVerified === 1 && (
                        <img src="/images/verified.gif" alt="" />
                      )}
                    </div>
                  </div>
                )}
              </div>

              {/* phone Input and otp */}
              <div className="mt-3 group">
                <div className="flex justify-between">
                  <label
                    htmlFor="email"
                    className="block text-sm font-semibold leading-4 text-[#212121]"
                  >
                    Phone Number
                  </label>
                </div>
                <div className="flex items-center mt-2">
                  <div className="input-group m-0 p-0 row w-full relative">
                    <div style={{ padding: "0", margin: "0" }}>
                      <div
                        className={`relative border-none  ease-in-out group-hover:ring-2 group-hover:ring-[#eebd2d] ${
                          isFocused
                            ? "ring-[#ffcc32] ring-2"
                            : "ring-[#9ca3af] ring-1"
                        }`}
                        style={{ borderRadius: "0px" }}
                        onFocus={() => setIsFocused(true)}
                        onBlur={() => setIsFocused(false)}
                      >
                        <PhoneInput
                          country={countryCode}
                          value={phone}
                          onChange={handlephone}
                          inputProps={{
                            name: "phone",
                            required: true,
                            autoFocus: false,
                            disabled: phoneVerified === 1,
                          }}
                          inputStyle={{
                            border: "none",
                            borderRadius: "0px",
                            boxShadow: "none",
                          }}
                          containerStyle={{
                            border: "none",
                          }}
                          onFocus={() => setIsFocused(true)}
                          onBlur={() => setIsFocused(false)}
                          containerclassName="phone-input-container"
                        />
                      </div>
                      <div className="text-sm absolute inset-y-2  right-2">
                        {phoneVerified !== 1 && (
                          <>
                            {showResendCountdown ? (
                              <div className="font-semibold text-[#FFC94A] hover:text-[#e2a731]">
                                Resend in {resendPhoneTimer}s
                              </div>
                            ) : (
                              <button
                                className="focus:animate-pulse font-semibold text-[#FFC94A] hover:text-[#e2a731]"
                                onClick={sendPhoneOtp}
                              >
                                Send OTP
                              </button>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {/* Conditionally render OTP input field based on phone validation */}
                {isPhoneValid && generatedPhoneOtp === "success" && (
                  <>
                    <div className="flex justify-between items-center mt-2">
                      <div className="col-10">
                        <input
                          value={phoneOtp}
                          onChange={(e) => setPhoneOtp(e.target.value)}
                          type="text"
                          className={`px-2 block w-full py-1.5 text-[#212121] ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-[#DBEDF9] focus:ring-inset text-sm sm:leading-6 ${
                            phoneVerified === 2
                              ? "border-danger border-2"
                              : "border-dark"
                          }`}
                          placeholder="Enter OTP"
                          id="phoneOtp"
                          name="phoneOtp"
                          required
                        />
                      </div>
                      <div>
                        {phoneVerified === 1 ? (
                          <img src="/images/verified.gif" alt="" />
                        ) : (
                          <button
                            className="text-sm font-semibold text-[#FFC94A] hover:text-[#e2a731]"
                            onClick={verifyPhoneOtp}
                          >
                            Verify
                          </button>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>

              {/* new password Input */}
              <div className="mt-3 group">
                <div className="flex justify-between items-end">
                  <label className="newpassword block text-sm font-semibold leading-4 text-[#212121] pt-1">
                    New Password
                  </label>
                  {/* Password strength indicator */}
                  <div className="flex flex-col-reverse items-end">
                    <div className="w-32 h-[3px] bg-gray-200 rounded">
                      <div
                        className={`${passwordStrength.color} h-full rounded transition-all duration-500`}
                        style={{ width: passwordStrength.width }}
                      ></div>
                    </div>
                    {pass && (
                      <div className="text-[10px] font-semibold text-gray-700">
                        {passwordStrength.label === "Strong" && (
                          <span className="text-green-600">
                            Strong Password
                          </span>
                        )}
                        {passwordStrength.label === "Medium" && (
                          <span className="text-yellow-500">
                            Medium Password
                          </span>
                        )}
                        {passwordStrength.label === "Weak" && (
                          <span className="text-red-500">Weak Password</span>
                        )}
                      </div>
                    )}
                  </div>
                </div>

                <div className="mt-2 ">
                  <div className="relative">
                    <input
                      value={pass}
                      onChange={(e) => validatePassword(e.target.value)}
                      type={`${showPassword === false ? "password" : "text"}`}
                      placeholder="New Password"
                      id="password"
                      name="password"
                      className="px-2 block w-full border-0 py-1.5 text-[#212121] ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 group-hover:ring-2 group-hover:ring-[#eebd2d] group-hover:outline-none group-hover:ring-inset focus:ring-2 focus:ring-inset focus:ring-[#eebd2d] focus:outline-none text-sm sm:leading-6 pr-10"
                      required
                    />
                    <div
                      className="absolute inset-y-0 right-0 flex items-center px-2 cursor-pointer duration-300 ease-in-out"
                      id="basic-addon1"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword === false ? (
                        <HiMiniEyeSlash size={22} />
                      ) : (
                        <IoEyeSharp size={22} color="#FFC94A" />
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* confirm password input */}
              <div className="mt-3 group">
                <div className="flex justify-between items-end">
                  <label className="confirmpassword block text-sm font-semibold leading-4 text-[#212121]">
                    Confirm Password
                  </label>
                  {/* Show password match error message only if both fields are filled */}
                  {pass && confirmpass && passwordMatchError && (
                    <div className=" text-red-500 text-xs">
                      {passwordMatchError}
                    </div>
                  )}
                </div>

                <div className="mt-2">
                  <div className="relative">
                    <input
                      type={showPassword1 ? "text" : "password"}
                      value={confirmpass}
                      onChange={handleConfirmPasswordChange}
                      placeholder="Confirm password"
                      id="confirmpassword"
                      name="confirmpassword"
                      className="px-2 block w-full border-0 py-1.5 text-[#212121] ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 group-hover:ring-2 group-hover:ring-[#eebd2d] group-hover:outline-none group-hover:ring-inset focus:ring-2 focus:ring-inset focus:ring-[#eebd2d] focus:outline-none text-sm sm:leading-6 pr-10"
                      required
                    />
                    <div
                      className="absolute inset-y-0 right-0 flex items-center px-2 cursor-pointer duration-300 ease-in-out"
                      id="basic-addon1"
                      onClick={() => setShowPassword1(!showPassword1)}
                    >
                      {showPassword1 === false ? (
                        <HiMiniEyeSlash size={22} />
                      ) : (
                        <IoEyeSharp size={22} color="#FFC94A" />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mb-4 d-flex justify-content-center">
              <button
                className="flex w-full justify-center  bg-[#FFC94A] px-3 py-2 duration-300 ease-in-out text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[#e2a731] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#dc9119]"
                type="submit"
              >
                Recover Account
              </button>
            </div>
          </form>

          <p className="mt-10 text-center text-sm text-gray-500">
            Already have an account?{" "}
            <a
              href="/login"
              className="font-semibold leading-6 text-[#FFC94A] hover:text-[#e2a731]"
            >
              Login Here
            </a>
          </p>
          <p className="mt-1  text-center text-sm font-semibold text-gray-500 flex-column">
            Or Sign-up with:{" "}
            <div className="signup-icons mt-2">
              <button
                data-mdb-ripple-init
                type="button"
                className="btn btn-secondary btn-floating mx-1"
              >
                <FaFacebookF />
              </button>

              <button
                data-mdb-ripple-init
                type="button"
                className="btn btn-secondary btn-floating mx-1"
              >
                <FaGoogle />
              </button>

              <button
                data-mdb-ripple-init
                type="button"
                className="btn btn-secondary btn-floating mx-1"
              >
                <FaLinkedin />
              </button>
            </div>
          </p>
        </div>
      </div>
    </>
  );
}

export default ForgetPassword;
