import React, { useState, useRef, useEffect } from "react";
import { Canvas } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { OrbitControls, Environment } from "@react-three/drei";
import * as THREE from "three";
import CheckAuth from "../../components/ServiceFunctions/CheckAuth";
import secureLocalStorage from "react-secure-storage";

const transformCustomizeToProductLocal = (customize, productId, action) => {
  const matchingEntry = customize.find((entry) => {
    return entry.Article_no === productId;
  });
  let productLocal = null;
  if (!matchingEntry) {
    return null;
  } else {
    if (action === "edit") {
      productLocal = {
        Article_no: matchingEntry?.Article_no || "",
        Leather: matchingEntry?.Leather || {},
        Sole: matchingEntry?.Sole || {},
        Edge: matchingEntry?.Edge || [],
        Lining: matchingEntry?.Lining || {},
        Eyelets: matchingEntry?.Eyelets || [],
        Buckle: matchingEntry?.Buckle || {},
        Shoelace: matchingEntry?.Shoelace || {},
        Edit: matchingEntry?.Edit || false,
        Altered: matchingEntry?.Altered
          ? matchingEntry?.Altered
          : {
              Leather: matchingEntry?.Leather || {},
              Sole: matchingEntry?.Sole || {},
              Edge: matchingEntry?.Edge || [],
              Lining: matchingEntry?.Lining || {},
              Eyelets: matchingEntry?.Eyelets || [],
              Buckle: matchingEntry?.Buckle || {},
              Shoelace: matchingEntry?.Shoelace || {},
            },
      };
    } else {
      productLocal = {
        Article_no: matchingEntry?.Article_no || "",
        Leather: matchingEntry?.Leather || {},
        Sole: matchingEntry?.Sole || {},
        Edge: matchingEntry?.Edge || [],
        Lining: matchingEntry?.Lining || {},
        Eyelets: matchingEntry?.Eyelets || [],
        Buckle: matchingEntry?.Buckle || {},
        Shoelace: matchingEntry?.Shoelace || {},
        Edit: matchingEntry?.Edit || false,
      };
    }
  }
  return productLocal;
};

const UploadGLBFile = ({ onNext, style, ProductId, subCategory, action }) => {
  const { email, type, phone } = CheckAuth();
  let Customize = JSON.parse(secureLocalStorage.getItem("Customize"));
  const [ProductLocal, setProductLocal] = useState({});
  const [isUploaded, setIsUploaded] = useState(false);
  const [fileName, setFileName] = useState("");
  const [glbFile, setGlbFile] = useState(null);
  const [bgColor, setBgColor] = useState("#191919");
  const [ambientIntensity, setAmbientIntensity] = useState(0.3);
  const [ambientColor, setAmbientColor] = useState("#ffffff");
  const [directIntensity, setDirectIntensity] = useState(2.5);
  const [directColor, setDirectColor] = useState("#ffffff");
  const [autoRotate, setAutoRotate] = useState(false);
  const [showGrid, setShowGrid] = useState(false);
  const fileInputRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [reason, setReason] = useState("");
  const [resendPhoneTimer, setResendPhoneTimer] = useState(90);
  const [otp, setOtp] = useState();
  const [userOtp, setUserOtp] = useState();
  const [showPopup, setShowPopup] = useState(false);
  const statusRef = useRef(null);
  const [isOpenStatus, setIsOpenStatus] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);

  const statuses = [
    { status: "Pending", color: "#FFA500" }, // Orange
    { status: "Approved", color: "#008000" }, // Green
    { status: "Rejected", color: "#FF0000" }, // Red
    { status: "Unlisted", color: "#808080" }, // Gray
  ];

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (statusRef.current && !statusRef.current.contains(event.target)) {
        setIsOpenStatus(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setProductLocal(
      transformCustomizeToProductLocal(Customize, ProductId, action)
    );
  }, [style, subCategory, ProductId]);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file && (file.name.endsWith(".glb") || file.name.endsWith(".gltf"))) {
      setFileName(file.name);
      setIsUploaded(true);

      const reader = new FileReader();
      reader.onload = (e) => {
        const arrayBuffer = e.target.result;
        const loader = new GLTFLoader();
        loader.parse(arrayBuffer, "", (gltf) => {
          const scene = gltf.scene;
          adjustModelToFit(scene);
          setGlbFile(scene);
        });
      };
      reader.readAsArrayBuffer(file);
    } else {
      alert("Please upload a valid GLB/GLTF file.");
    }
  };

  const adjustModelToFit = (model) => {
    const box = new THREE.Box3().setFromObject(model);
    const center = box.getCenter(new THREE.Vector3());
    const size = box.getSize(new THREE.Vector3());
    const maxDim = Math.max(size.x, size.y, size.z);
    const scale = 4 / maxDim;
    model.scale.set(scale, scale, scale);
    model.position.set(-center.x * scale, -center.y * scale, -center.z * scale);
  };

  const handleResendPhoneTimer = () => {
    setResendPhoneTimer(90);
    const timerInterval = setInterval(() => {
      setResendPhoneTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    setTimeout(() => {
      clearInterval(timerInterval);
    }, 90000);
  };

  const showResendCountdown = resendPhoneTimer > 0 && resendPhoneTimer < 90;

  const sendPhoneOtp = () => {
    fetch(`https://buwchlifestyles.com/api/register/send-phone-otp/`, {
      method: "POST",
      body: JSON.stringify({
        phone: phone,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to send OTP");
        }
        return response.json();
      })
      .then((response) => {
        setOtp(response.otp);
        handleResendPhoneTimer();
        setProductLocal((prev) => {
          const updated = { ...prev };
          updated.Edit = false;
          return updated;
        });
        setShowPopup(true);
      })
      .catch((error) => {
        console.error("Error: ", error);
      });
  };

  const handleContinue = () => {
    if (
      (ProductLocal.Reason && ProductLocal.Status) ||
      ProductLocal.Status === "Approved" ||
      type !== "admin"
    ) {
      if (otp === userOtp) {
        fetch(`https://buwchlifestyles.com/api/list-GLBproduct/`, {
          method: "POST",
          body: JSON.stringify({
            customize_data: ProductLocal,
            article: ProductLocal.Article_no,
            email: email,
          }),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("Failed to fetch data");
            }
            return response.json();
          })
          .then((data) => {
            alert(data.message);
            let existingItemIndex = Customize.findIndex((customItem) => {
              return customItem.Article_no === ProductLocal.Article_no;
            });
            if (existingItemIndex !== -1) {
              Customize[existingItemIndex] = data.customize;
            }
            secureLocalStorage.setItem("Customize", JSON.stringify(Customize));
            Customize = JSON.parse(secureLocalStorage.getItem("Customize"));
            onNext(style, data.customize.Article_no);
          })
          .catch((error) => {
            console.error("Error: ", error);
          });
      } else {
        alert("Otp is incorrect.");
      }
    } else {
      alert("please enter the status and reason.");
    }
  };

  return (
    <div className="p-6 min-h-screen">
      <div className="rounded-lg p-6 bg-white">
        <div className="flex items-center justify-center mb-4">
          <p className="text-2xl font-bold text-gray-800 text-center">
            <div>
              {" "}
              {isUploaded ? "File Uploaded!" : "Upload GLB/GLTF File "}
            </div>
            {/* <div className="text-sm text-gray-600 font-semibold">
              {" "} 
              {isUploaded
                ? "The 3D preview will be available once integrated with the rendering library."
                : " "}
            </div> */}
          </p>
        </div>

        <div
          className={` rounded-lg overflow-hidden w-full ${glbFile ? "" : ""}`}
        >
          <div className="md:flex">
            <div className="w-full flex items-start justify-center gap-x-5 p-2">
              <div
                className={`${
                  glbFile ? "w-1/4 flex flex-col gap-y-4" : "w-full"
                }`}
              >
                <div
                  className={`relative  rounded-lg group border-2  hover:border-yellow-400 flex justify-center items-center transition-all duration-300 ease-in-out ${
                    glbFile
                      ? "h-24 w-full border-yellow-400 "
                      : "h-56 w-full border-gray-800 "
                  }`}
                >
                  <div className="absolute flex flex-col items-center">
                    <svg
                      className={` group-hover:fill-yellow-400 transition-all duration-300 ease-in-out ${
                        glbFile
                          ? "fill-yellow-400 w-10 h-10"
                          : "fill-slate-700 w-20 h-20"
                      }`}
                      xmlns="http://www.w3.org/2000/svg"
                      data-name="Layer 1"
                      id="fi_14422701"
                      viewBox="0 0 64 64"
                    >
                      <title></title>
                      <path d="M31,28.019v7.2l8.234,4.755,8.235-4.755V25.709l-8.235-4.754L33,24.554v-7.2L24.766,12.6l-8.235,4.754v9.509l8.235,4.755Zm2,0,5.232,3.02v6.048L33,34.063Zm7.232,9.071v-6.04l5.237-3.029v6.045Zm4.237-10.8-5.227,3.023L34,26.285l5.232-3.021ZM31,25.709h0l-5.237,3.023V22.692L31,19.664v6.045Zm-1-7.777-5.227,3.022-5.24-3.024,5.233-3.02ZM18.531,19.661l5.232,3.02v6.048l-5.232-3.02Z"></path>
                      <path d="M39.566,53.589H38.048v2.232H39.59a1.447,1.447,0,0,0,.929-.255,1.083,1.083,0,0,0,.313-.884,1.046,1.046,0,0,0-.3-.831A1.515,1.515,0,0,0,39.566,53.589Z"></path>
                      <path d="M39.968,51.905a1.022,1.022,0,0,0,.336-.158.833.833,0,0,0,.234-.263.755.755,0,0,0,.09-.38.867.867,0,0,0-.276-.7,1.212,1.212,0,0,0-.828-.246H38.048v1.8h1.524A1.5,1.5,0,0,0,39.968,51.905Z"></path>
                      <path d="M54,43.67V17.376l-.293-.293L39.417,2.793,39.124,2.5H14a4,4,0,0,0-4,4V40.242a1.991,1.991,0,0,0-1,1.722V56.5a5.006,5.006,0,0,0,5,5H50a5.006,5.006,0,0,0,5-5V45.393A1.991,1.991,0,0,0,54,43.67ZM28.064,57.454H26.588v-.693a2.735,2.735,0,0,1-.96.633,3.311,3.311,0,0,1-1.14.179,3.48,3.48,0,0,1-1.656-.365,3.192,3.192,0,0,1-1.116-.979,4.144,4.144,0,0,1-.63-1.41,6.877,6.877,0,0,1-.2-1.662,8.069,8.069,0,0,1,.2-1.806,4.386,4.386,0,0,1,.644-1.512A3.244,3.244,0,0,1,22.912,48.8a3.827,3.827,0,0,1,1.8-.39,3.626,3.626,0,0,1,1.884.521,2.653,2.653,0,0,1,.768.721,4.369,4.369,0,0,1,.564,1.17l-1.776.468a2.757,2.757,0,0,0-.63-.93,1.531,1.531,0,0,0-1.05-.318,1.211,1.211,0,0,0-.882.321,2.165,2.165,0,0,0-.51.792,3.918,3.918,0,0,0-.234,1,9.231,9.231,0,0,0-.054.926,7.049,7.049,0,0,0,.066.913,3.592,3.592,0,0,0,.254.938,1.928,1.928,0,0,0,.532.726,1.326,1.326,0,0,0,.888.29,1.586,1.586,0,0,0,1.2-.475,1.613,1.613,0,0,0,.456-1H24.548V52.833h3.516Zm7.08,0H29.468V48.525h1.908v7.3h3.768Zm6.721-.612a4.173,4.173,0,0,1-2.479.612H36.14V48.525h3.33a6.078,6.078,0,0,1,1.144.108,2.88,2.88,0,0,1,.988.378,2.01,2.01,0,0,1,.689.726,2.3,2.3,0,0,1,.257,1.14,2.154,2.154,0,0,1-.287,1.1,1.96,1.96,0,0,1-.839.769,2.073,2.073,0,0,1,1.031.839,2.787,2.787,0,0,1,.287,1.32A2.193,2.193,0,0,1,41.865,56.841ZM52,43.393H30.086l-2.4-2.746a2,2,0,0,0-1.505-.683H12V6.5a2,2,0,0,1,2-2H37.71V14.79a4,4,0,0,0,4,4H52Z"></path>
                    </svg>
                    {isUploaded && (
                      <div className="overflow-hidden  mt-2 inline-flex items-center justify-center w-48 text-gray-500 text-xs text-center break-words ">
                        {fileName}
                      </div>
                    )}
                    <span
                      className={`block font-semibold text-center mt-3 ${
                        isUploaded ? "text-gray-800 hidden" : "text-gray-500"
                      }`}
                    >
                      {isUploaded ? "" : "Drag & drop your files here"}
                    </span>
                    <span
                      className={`block text-sm font-semibold text-center mt-1 px-3 ${
                        isUploaded ? "text-gray-500 hidden" : "text-gray-300"
                      }`}
                    >
                      {isUploaded ? " " : "or click to upload"}
                    </span>
                  </div>

                  <input
                    ref={fileInputRef}
                    className="h-full w-full opacity-0 cursor-pointer"
                    type="file"
                    id="glbUpload"
                    accept=".glb,.gltf"
                    onChange={handleFileUpload}
                  />
                </div>
                {glbFile && (
                  <div className="rounded-lg w-full border-2   flex flex-col justify-center items-center transition-all duration-300 ease-in-out px-3">
                    <h2 className="text-lg font-semibold my-2">
                      Display Settings
                    </h2>
                    <div className="flex items-start justify-center gap-x-3 w-full">
                      <div className=" w-1/2">
                        <label className="block  w-full text-xs font-medium">
                          Background
                        </label>
                        <input
                          type="color"
                          value={bgColor}
                          onChange={(e) => setBgColor(e.target.value)}
                          className="w-full mt-1"
                        />
                      </div>

                      <div className="w-1/2">
                        <label className="block w-full text-xs font-medium">
                          Ambient Light
                        </label>
                        <input
                          type="color"
                          value={ambientColor}
                          onChange={(e) => setAmbientColor(e.target.value)}
                          className="w-full mt-1"
                        />
                      </div>
                    </div>
                    <div className="mb-2 w-full">
                      <label className="block text-xs font-medium">
                        Ambient Light Intensity
                      </label>
                      <input
                        type="range"
                        min="0"
                        max="1"
                        step="0.1"
                        value={ambientIntensity}
                        onChange={(e) =>
                          setAmbientIntensity(parseFloat(e.target.value))
                        }
                        className="w-full"
                      />
                    </div>
                    <div className="mb-2 w-full">
                      <label className="block text-xs font-medium">
                        Directional Light Intensity
                      </label>
                      <input
                        type="range"
                        min="0"
                        max="5"
                        step="0.1"
                        value={directIntensity}
                        onChange={(e) =>
                          setDirectIntensity(parseFloat(e.target.value))
                        }
                        className="w-full"
                      />
                    </div>
                    <div className="mb-2 w-full">
                      <label className="block text-xs font-medium">
                        Directional Light Color
                      </label>
                      <input
                        type="color"
                        value={directColor}
                        onChange={(e) => setDirectColor(e.target.value)}
                        className="w-full mt-1"
                      />
                    </div>
                    <div className="mb-2">
                      <button
                        className={` rounded-md flex items-center text-sm xl:text-base gap-1.5 text-[#313131]  font-medium px-2 py-2`}
                      >
                        <div className="checkbox-wrapper-31">
                          <input
                            type="checkbox"
                            id="auto-rotate"
                            checked={autoRotate}
                            onChange={(e) => setAutoRotate(e.target.checked)}
                            readOnly
                          />
                          <svg viewBox="0 0 35.6 35.6">
                            <circle
                              className="background"
                              cx="17.8"
                              cy="17.8"
                              r="17.8"
                            ></circle>
                            <circle
                              className="stroke"
                              cx="17.8"
                              cy="17.8"
                              r="14.37"
                            ></circle>
                            <polyline
                              className="check"
                              points="11.78 18.12 15.55 22.23 25.17 12.87"
                            ></polyline>
                          </svg>
                        </div>
                        <label
                          for="auto-rotate"
                          className="cursor-default checkbox-wrapper-31-text"
                        >
                          Auto-Rotate
                        </label>
                      </button>
                      {/* <div className="flex items-center mt-2">
                        <input
                          type="checkbox"
                          checked={showGrid}
                          onChange={(e) => setShowGrid(e.target.checked)}
                          className="mr-2"
                        />
                        <span>Show Grid</span>
                      </div> */}
                    </div>
                  </div>
                )}
              </div>
              {/* Render 3D model using three.js */}
              {glbFile && (
                <div
                  className="border rounded-lg w-3/4 h-[500px]"
                  style={{ backgroundColor: bgColor }}
                >
                  <Canvas camera={{ position: [0, 0, 5], fov: 50 }}>
                    {/* Hemisphere Light for Soft Overall Lighting */}
                    <hemisphereLight
                      skyColor="#ffffff"
                      groundColor="#ffffff"
                      intensity={1.5}
                    />

                    {/* Directional Light for Sharper Shadows and Highlights */}
                    <directionalLight
                      position={[5, 5, 5]}
                      intensity={0.8}
                      color="#ffffff"
                    />
                    <directionalLight
                      position={[-5, -5, 5]}
                      intensity={0.8}
                      color="#ffffff"
                    />

                    {/* 3D Model */}
                    <primitive object={glbFile} />
                    <OrbitControls autoRotate={autoRotate} />
                    {showGrid && <gridHelper args={[10, 10]} />}
                    <Environment preset="studio" />
                  </Canvas>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="flex items-center justify-end w-full mt-3 gap-3">
          <button
            className="bg-[#fff] text-center w-16 rounded-lg h-9 lg:h-11 relative text-[#313131] text-xl font-semibold group border hover:bg-[#f3f3f3] duration-200 ease-in-out"
            type="button"
            onClick={() => {
              setIsUploaded(false);
              setFileName("");
              setGlbFile(null);
            }}
          >
            <p className="text-xs lg:text-sm">Reset</p>
          </button>
          <button
            className="bg-white text-center w-20 lg:w-32 rounded-lg h-9 lg:h-11 relative text-[#313131] text-xl font-semibold group border cursor-pointer"
            type="button"
            onClick={() => {
              sendPhoneOtp();
            }}
            // disabled={!isUploaded}
          >
            <div className="bg-yellow-400 rounded-md h-[26px] lg:h-[34px] w-1/3 flex items-center justify-center absolute left-1 top-[4px] group-hover:w-[70px] group-hover:lg:w-[118px] z-10 duration-500">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1024 1024"
                className="rotate-180 w-4 lg:w-6 h-4 lg:h-6"
              >
                <path
                  d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z"
                  fill="#313131"
                ></path>
                <path
                  d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z"
                  fill="#313131"
                ></path>
              </svg>
            </div>
            <p className="max-lg:translate-x-4 lg:translate-x-5 text-xs lg:text-sm">
              Next
            </p>
          </button>
        </div>
      </div>
      {/* Pop-up */}
      {showPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className=" w-[350px] sm:w-[450px] lg:w-[800px] bg-white flex rounded-xl h-max items-center justify-between ">
            <div className="h-full w-5/12 lg:w-2/5  rounded-l-xl overflow-hidden  flex items-center justify-center">
              <div className="bg-white h-full flex justify-center items-center  rounded-l-lg  p-2">
                <img
                  className="object-cover "
                  src="/images/boot-s.jpg "
                  alt=""
                />
              </div>
            </div>

            <div className="max-lg:p-2 lg:p-7 bg-white w-7/12 lg:w-3/5 rounded-r-xl relative border-l-4 border-yellow-400">
              <button
                className="float-right"
                onClick={() => setShowPopup(false)}
                aria-label="Close"
              >
                <svg
                  viewBox="0 0 512 512"
                  xmlns="http://www.w3.org/2000/svg"
                  id="fi_1828945"
                  className=" hover:fill-red-600 hover:bg-red-300 rounded-full duration-300 ease-in-out hover:scale-90  w-4 lg:w-5 h-4 lg:h-5"
                >
                  <path d="m256 512c-141.164062 0-256-114.835938-256-256s114.835938-256 256-256 256 114.835938 256 256-114.835938 256-256 256zm0-480c-123.519531 0-224 100.480469-224 224s100.480469 224 224 224 224-100.480469 224-224-100.480469-224-224-224zm0 0"></path>
                  <path d="m176.8125 351.1875c-4.097656 0-8.195312-1.554688-11.308594-4.691406-6.25-6.25-6.25-16.382813 0-22.632813l158.398438-158.402343c6.253906-6.25 16.386718-6.25 22.636718 0s6.25 16.382812 0 22.636718l-158.402343 158.398438c-3.15625 3.136718-7.25 4.691406-11.324219 4.691406zm0 0"></path>
                  <path d="m335.1875 351.1875c-4.09375 0-8.191406-1.554688-11.304688-4.691406l-158.398437-158.378906c-6.253906-6.25-6.253906-16.382813 0-22.632813 6.25-6.253906 16.382813-6.253906 22.632813 0l158.398437 158.398437c6.253906 6.25 6.253906 16.382813 0 22.632813-3.132813 3.117187-7.230469 4.671875-11.328125 4.671875zm0 0"></path>
                </svg>
              </button>
              <div className=" mb-3 mt-4">
                <div className="flex flex-col items-start justify-center gap-3">
                  {/* Status Dropdown */}
                  <div
                    className="w-full flex justify-start items-center max-md:hidden relative text-sm font-semibold gap-2"
                    ref={statusRef}
                  >
                    <p className="w-16">Status:</p>
                    <button
                      className={`w-40 flex items-center justify-between text-[#313131] font-semibold text-sm border-[1px] bg-gray-100 px-2 py-1 ${
                        isOpenStatus ? "rounded-t-md " : "rounded-md"
                      }`}
                      onClick={() => {
                        setIsOpenStatus(!isOpenStatus);
                      }}
                    >
                      <div className="flex items-center justify-start gap-2">
                        <div
                          className="inline-block w-3 h-3 rounded-full "
                          style={{
                            backgroundColor:
                              statuses.find((s) => s.status === selectedStatus)
                                ?.color || "#fff",
                          }}
                        ></div>
                        {selectedStatus || "Status"}
                      </div>
                      <svg
                        className="ml-1 w-4 h-4"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M19 9l-7 7-7-7"
                        ></path>
                      </svg>
                    </button>
                    {isOpenStatus && (
                      <div
                        className={`absolute left-[72px] top-[20px] fadein-animation overflow-hidden rounded-b-lg shadow-[0px_1px_1px_0px_#00000024] mt-2 w-40 z-50 border-[1px] border-t-0 transition-opacity duration-500 ease-in-out bg-gray-100`}
                      >
                        <ul className="py-1 text-sm text-gray-600">
                          {statuses.map(({ status, color }, index) => (
                            <li
                              key={status}
                              onClick={() => {
                                setSelectedStatus(status);
                                setProductLocal((prev) => {
                                  const updated = { ...prev };
                                  updated.Status = status;
                                  return updated;
                                });
                                setIsOpenStatus(false);
                              }}
                              className={`px-2 py-1 hover:text-[#212121] hover:bg-white font-medium duration-200 ease-in-out cursor-pointer ${
                                selectedStatus === status
                                  ? "text-[#212121] font-semibold"
                                  : "text-[#616161]"
                              }`}
                            >
                              <div
                                className={`inline-block w-2 h-2 rounded-full  mr-2`}
                                style={{ backgroundColor: color }}
                              ></div>
                              {status}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                  {(selectedStatus === "Pending" ||
                    selectedStatus === "Rejected") && (
                    <div className="w-full flex justify-start items-start max-md:hidden relative text-sm font-semibold gap-2">
                      <p className="w-16">Reason:</p>
                      <textarea
                        className="px-2 block w-full  py-1.5 text-[#212121] ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 hover:ring-2 hover:ring-[#eebd2d] hover:outline-none hover:ring-inset focus:ring-2 focus:ring-[#eebd2d] focus:outline-none focus:ring-inset text-sm sm:leading-6"
                        name=""
                        id=""
                        style={{ resize: "none" }}
                        onChange={(e) => {
                          setProductLocal((prev) => {
                            const updated = { ...prev };
                            updated.Reason = e.target.value;
                            return updated;
                          });
                          setReason(e.target.value);
                        }}
                      ></textarea>
                    </div>
                  )}
                  {/* OTP Section */}
                  <div className="w-full flex justify-start items-center gap-2 text-sm font-semibold">
                    <p className="w-16">OTP:</p>
                    <input
                      type="text"
                      maxLength="5"
                      placeholder="Enter OTP"
                      onChange={(e) => setUserOtp(e.target.value)}
                      className="px-2 py-1 block w-40 text-[#212121] ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 hover:ring-2 hover:ring-[#eebd2d] hover:outline-none hover:ring-inset focus:ring-2 focus:ring-[#eebd2d] focus:outline-none focus:ring-inset text-sm"
                    />
                    <button
                      onClick={() => {
                        sendPhoneOtp(showPopup);
                      }}
                      disabled={showResendCountdown}
                      className={` text-sm font-medium    ease-in-out duration-300 ${
                        showResendCountdown
                          ? ""
                          : "cursor-pointer underline hover:text-[#e2a731]"
                      }`}
                    >
                      {showResendCountdown ? (
                        <>{resendPhoneTimer}s</>
                      ) : (
                        <>Resend</>
                      )}
                    </button>
                  </div>
                </div>
              </div>
              <div className="flex items-end justify-end gap-4  text-[#313131]">
                <button
                  className="relative max-lg:px-4 max-md:py-1 md:py-1.5 lg:px-8 lg:py-1.5 text-[10px] md:text-xs lg:text-sm rounded-md bg-white isolation-auto z-10 border-2 border-[#FFC94A] font-semibold before:absolute before:w-full before:transition-all before:duration-500 before:hover:w-full before:-left-full before:hover:left-0 before:rounded-full  before:bg-[#FFC94A] before:-z-10  before:aspect-square before:hover:scale-[170%] overflow-hidden before:hover:duration-500"
                  onClick={handleContinue}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UploadGLBFile;
