import React, { useState } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import "./ImageUpload.css";

const ImageUpload = ({ getImg }) => {
  const [image, setImage] = useState(null);
  const [isResizing, setIsResizing] = useState(false);
  const [thumbnail, setThumbnail] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) {
      alert("No file selected. Please choose an image file.");
      return;
    }
    if (file.type.indexOf("image") === -1) {
      alert("Sorry, the file you uploaded doesn't appear to be an image.");
      return;
    }
    const reader = new FileReader();
    reader.onload = function (event) {
      setImage(event.target.result);
      setIsResizing(true);
    };
    reader.readAsDataURL(file);
  };

  const handleCrop = () => {
    if (!image) return;

    const canvas = document
      .getElementById("cropper")
      .cropper.getCroppedCanvas();
    const circularCanvas = cropToCircle(canvas, 100);
    const circularImage = circularCanvas.toDataURL();

    setThumbnail(circularImage);
    setIsResizing(false);
    getImg(circularImage);
  };

  const cropToCircle = (canvas) => {
    const diameter = Math.min(canvas.width, canvas.height);
    const circleCanvas = document.createElement("canvas");
    const ctx = circleCanvas.getContext("2d");

    circleCanvas.width = diameter;
    circleCanvas.height = diameter;

    ctx.beginPath();
    ctx.arc(diameter / 2, diameter / 2, diameter / 2, 0, Math.PI * 2);
    ctx.closePath();
    ctx.clip();

    ctx.drawImage(
      canvas,
      (canvas.width - diameter) / 2,
      (canvas.height - diameter) / 2,
      diameter,
      diameter,
      0,
      0,
      diameter,
      diameter
    );

    return circleCanvas;
  };

  return (
    <div>
      {/* Upload Image */}
      <section id="sectionDragAndDrop">
        <div
          className={`drop ${
            image ? "hidden" : ""
          } relative h-[100px] w-full rounded-lg border-[0.1rem] hover:border-[#FFD61F] hover:shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] flex justify-center items-center transition-shadow duration-300 ease-in-out`}
          onClick={() => document.getElementById("fileUpload").click()}
        >
          <div className="absolute flex flex-col items-center cursor-pointer">
            <span>
              <svg
                viewBox="0 -64 512 512"
                className="w-14 h-14"
                xmlns="http://www.w3.org/2000/svg"
                id="fi_1829586"
              >
                <path
                  d="m512 58.667969v266.664062c0 32.429688-26.238281 58.667969-58.667969 58.667969h-394.664062c-5.335938 0-10.453125-.640625-15.148438-2.132812-25.171875-6.613282-43.519531-29.441407-43.519531-56.535157v-266.664062c0-32.429688 26.238281-58.667969 58.667969-58.667969h394.664062c32.429688 0 58.667969 26.238281 58.667969 58.667969zm0 0"
                  fill="#eceff1"
                ></path>
                <path
                  d="m149.332031 106.667969c0 23.5625-19.101562 42.664062-42.664062 42.664062-23.566407 0-42.667969-19.101562-42.667969-42.664062 0-23.566407 19.101562-42.667969 42.667969-42.667969 23.5625 0 42.664062 19.101562 42.664062 42.667969zm0 0"
                  fill="#ffc107"
                ></path>
                <path
                  d="m512 276.054688v49.277343c0 32.429688-26.238281 58.667969-58.667969 58.667969h-394.664062c-5.335938 0-10.453125-.640625-15.148438-2.132812l260.695313-260.695313c14.503906-14.503906 38.398437-14.503906 52.90625 0zm0 0"
                  fill="#388e3c"
                ></path>
                <path
                  d="m363.945312 384h-305.277343c-5.335938 0-10.453125-.640625-15.148438-2.132812-25.171875-6.613282-43.519531-29.441407-43.519531-56.535157v-6.613281l122.878906-122.878906c14.507813-14.507813 38.402344-14.507813 52.90625 0zm0 0"
                  fill="#4caf50"
                ></path>
              </svg>
            </span>
            <span className="block text-gray-400 font-normal mt-1 text-sm">
              Click to upload
            </span>
          </div>
        </div>
        <input
          className="file-upload hidden"
          id="fileUpload"
          type="file"
          onChange={handleFileChange}
        />
      </section>

      {/* Resize Image */}
      {isResizing && (
        <section id="sectionResize">
          <div
            className="image-resize"
            id="imageResize"
            style={{ position: "relative" }}
          >
            <Cropper
              id="cropper"
              src={image}
              cropShape="round"
              style={{
                height: 400,
                width: "100%",
              }}
              aspectRatio={1}
              guides={false}
              cropBoxMovable={true}
              viewMode={2}
              background={false} // Remove background grid
              autoCropArea={1} // Set auto-crop to cover the entire image
            />
          </div>

          <button
            className="flex items-center gap-1 border rounded-full px-2 py-0.5 my-2 mx-auto text-[13px] hover:bg-[#000000] hover:text-[#ffffff] duration-300 group"
            id="crop"
            onClick={() => {
              handleCrop();
            }}
          >
            <span className="">
              <svg
                id="fi_3342152"
                enableBackground="new 0 0 24 24"
                className="w-[15px] h-[15px] group-hover:fill-[#ffffff] duration-300"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g>
                  <circle cx="9" cy="9" r="2"></circle>
                </g>
                <path d="m23 19h-2v-13c0-1.654-1.346-3-3-3h-13v-2c0-.552-.447-1-1-1s-1 .448-1 1v2h-2c-.553 0-1 .448-1 1s.447 1 1 1h2v13c0 1.654 1.346 3 3 3h13v2c0 .552.447 1 1 1s1-.448 1-1v-2h2c.553 0 1-.448 1-1s-.447-1-1-1zm-5-14c.552 0 1 .449 1 1v6.94l-2.26-2.26c-.66-.661-1.82-.661-2.48 0l-4.76 4.76-1.26-1.26c-.66-.661-1.82-.661-2.48 0l-.76.759v-9.939z"></path>
              </svg>
            </span>{" "}
            Crop Image
          </button>
        </section>
      )}

      {/* Insert Thumbnail */}
      {thumbnail && (
        <section
          id="sectionThumbnail"
          className={`   ${
            isResizing
              ? ""
              : "hover:shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] border-[1px] hover:border-[#FFD61F] duration-200 rounded"
          }  flex items-center justify-center cursor-pointer `}
          onClick={() => {
            document.getElementById("fileUpload").click();
            setIsResizing(true);
          }}
        >
          {isResizing === true ? (
            <></>
          ) : (
            <div className="thumbnail" id="thumbnail">
              <img src={thumbnail} alt="Thumbnail" className="w-[200px]" />
            </div>
          )}
        </section>
      )}
    </div>
  );
};

export default ImageUpload;
