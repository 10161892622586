import React, { useEffect, useRef, useState } from "react";
import { IoClose } from "react-icons/io5";
import CheckAuth from "../../components/ServiceFunctions/CheckAuth.jsx";
import secureLocalStorage from "react-secure-storage";

function AIPage2() {
  const containerRef = useRef(null);
  const [isOpen1, setIsOpen1] = useState();
  const [height, setHeight] = useState("h-[calc(100vh-120px)]");
  const [width, setIsWidth] = useState("w-[420px]");
  const { type, isAuthenticated } = CheckAuth();
  const storedData = secureLocalStorage.getItem("kycData");
  const kycData = JSON.parse(storedData);
  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        const width = containerRef.current.offsetWidth;
        setHeight(width < 450 ? "h-[620px]" : "h-[calc(100vh-120px)]");
        setIsWidth(width < 450 ? "w-[400px]" : "w-[420px]");
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const Popup_API = [
    {
      usertype: "default",
      option: [
        {
          id: 0,
          icon: 0,
          message: "Registration",
        },
        {
          id: 1,
          icon: 0,
          message: "Catalog/Accessories",
        },
        {
          id: 2,
          icon: 0,
          message: "Affiliates",
        },
        {
          id: 3,
          icon: 0,
          message: "AI Studio",
        },
        {
          id: 4,
          icon: 0,
          message: "Payment & Checkout",
        },
        {
          id: 5,
          icon: 0,
          message: "Shipping Address",
        },
        {
          id: 6,
          icon: 0,
          message: "Something else",
        },
      ],
    },
    {
      usertype: "supplier",
      option: [
        {
          id: 0,
          icon: 0,
          message: "Product Listing",
        },
        {
          id: 1,
          icon: 0,
          message: "Production Stages",
        },
        {
          id: 2,
          icon: 0,
          message: "Purchase Order",
        },
        {
          id: 3,
          icon: 0,
          message: "Order History",
        },
        {
          id: 4,
          icon: 0,
          message: "Account Settings",
        },
        {
          id: 5,
          icon: 0,
          message: "Catalog/Accessories",
        },
        {
          id: 6,
          icon: 0,
          message: "Something else",
        },
      ],
    },
    {
      usertype: "individual",
      option: [
        {
          id: 0,
          icon: 0,
          message: "Production Stages",
        },
        {
          id: 1,
          icon: 0,
          message: "Order History",
        },
        {
          id: 2,
          icon: 0,
          message: "Account Settings",
        },
        {
          id: 3,
          icon: 0,
          message: "Customize 3D",
        },
        {
          id: 4,
          icon: 0,
          message: "Payment & Checkout",
        },
        {
          id: 5,
          icon: 0,
          message: "Something else",
        },
      ],
    },
    {
      usertype: "businessuser",
      option: [
        {
          id: 0,
          icon: 0,
          message: "eCommerce API",
        },
        {
          id: 1,
          icon: 0,
          message: "Production Stages",
        },
        {
          id: 2,
          icon: 0,
          message: "Order History",
        },
        {
          id: 3,
          icon: 0,
          message: "Photoshoot",
        },
        {
          id: 4,
          icon: 0,
          message: "Shipping",
        },
        {
          id: 5,
          icon: 0,
          message: "Tech Pack",
        },
        {
          id: 6,
          icon: 0,
          message: "Account Settings",
        },
        {
          id: 7,
          icon: 0,
          message: "Something else",
        },
      ],
    },
  ];
  const userType = Popup_API.find(
    (data) =>
      data.usertype.toLowerCase() ===
      (type.toLowerCase() !== "" ? type.toLowerCase() : "default")
  );
  return (
    <div
      ref={containerRef}
      className={`shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] rounded-xl p-2 bg-white ${height}`}
    >
      {/* <div className="text-black bg-[#F9F9F9] rounded-t-lg">
        <h2 className="text-center font-bold text-xl border rounded-t-lg py-2">Enquire Details</h2>
      </div> */}
      <div
        className={`py-1 px-3 bg-white h-full flex flex-col justify-between rounded-b-xl`}
      >
        <img
          src="./images/enquire.png"
          className="w-[70%] mt-3 mx-auto"
          alt=""
        />
        <div className="mt-5 flex flex-col gap-3">
          <div className="py-2.5 px-2.5  backdrop-blur-md border-[2px] hover:border-[#fec23d] hover:bg-[#FFF4B5] duration-300 border-zinc-300 rounded-t-xl rounded-l-xl  flex items-center gap-2 cursor-pointer group">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-8 h-8 xl:w-9 xl:h-9"
                viewBox="0 0 32 32"
                id="fi_3059561"
              >
                <g id="Layer_3" data-name="Layer 3">
                  <path d="M16,1A15,15,0,1,0,31,16,15,15,0,0,0,16,1Zm.643,9.214a5.149,5.149,0,0,1,5.143,5.143H20.5A3.861,3.861,0,0,0,16.643,11.5ZM20.808,25c-.039,0-3.9-.066-8.865-4.943C7.066,15.094,7,11.231,7,11.192,7,10.711,10,7,10.663,7c.23,0,.409.156.605.39.472.562,2.559,3.856,2.463,4.405-.046.258-.265.454-.893.981a7.416,7.416,0,0,0-.866.8,6.906,6.906,0,0,0,.566,1.526A9.435,9.435,0,0,0,16.9,19.462a6.906,6.906,0,0,0,1.526.566,7.416,7.416,0,0,0,.8-.866c.527-.628.723-.847.981-.893.549-.1,3.843,1.991,4.405,2.463.234.2.39.375.39.605C25,22,21.289,25,20.808,25Zm2.906-9.643a7.079,7.079,0,0,0-7.071-7.071V7A8.367,8.367,0,0,1,25,15.357Z"></path>
                </g>
              </svg>
            </span>

            <h2 className="text-sm font-bold text-black">
              <a
                href="tel:1203180111"
                className="text-center text-black no-underline text-sm xl:text-base"
              >
                (+91)-1203180111
              </a>
            </h2>
          </div>

          <div className="py-2.5 px-2.5  backdrop-blur-md border-[2px] hover:border-[#fec23d] hover:bg-[#FFF4B5] duration-300 border-zinc-300 rounded-t-xl rounded-l-xl  flex items-center gap-2 cursor-pointer group">
            <span>
              <svg
                id="fi_6244710"
                className="w-8 h-8 xl:w-9 xl:h-9"
                viewBox="0 0 512 512"
                xmlns="http://www.w3.org/2000/svg"
                data-name="Layer 1"
              >
                <path
                  d="m256 0c141.385 0 256 114.615 256 256s-114.615 256-256 256-256-114.615-256-256 114.615-256 256-256zm162.5 355.241v-190.263l-95.137 95.131zm-304.676 3.876h284.358l-86.908-86.908-33.123 33.118a8.563 8.563 0 0 1 -6.05 2.5h-32.2a8.553 8.553 0 0 1 -6.051-2.5l-33.122-33.118-86.908 86.908zm-20.324-194.145v190.274l95.137-95.137zm312.906-12.089h-300.806l137.844 137.844h25.117z"
                  fillRule="evenodd"
                ></path>
              </svg>
            </span>

            <h2 className="text-sm font-bold text-black">
              <a
                href="mailto:support@example.com"
                className="text-center text-black no-underline text-sm xl:text-base"
              >
                support@buwch.com
              </a>
            </h2>
          </div>

          <div
            onClick={() => {
              setIsOpen1(true);
            }}
            className="py-2.5 px-2.5 backdrop-blur-md border-[2px] hover:border-[#fec23d] hover:bg-[#FFF4B5] duration-300 border-zinc-300 rounded-t-xl rounded-l-xl flex items-start gap-2 cursor-pointer group"
          >
            <span>
              <svg
                className="w-8 h-8 xl:w-9 xl:h-9"
                viewBox="0 0 176 176"
                xmlns="http://www.w3.org/2000/svg"
                id="fi_3670051"
              >
                <g id="Layer_2" data-name="Layer 2">
                  <g id="_09.whatsapp" data-name="09.whatsapp">
                    <circle
                      id="background"
                      cx="88"
                      cy="88"
                      fill="#29a71a"
                      r="88"
                    ></circle>
                    <g id="icon" fill="#fff">
                      <path d="m126.8 49.2a54.57 54.57 0 0 0 -87.42 63.13l-5.79 28.11a2.08 2.08 0 0 0 .33 1.63 2.11 2.11 0 0 0 2.24.87l27.55-6.53a54.56 54.56 0 0 0 63.09-87.21zm-8.59 68.56a42.74 42.74 0 0 1 -49.22 8l-3.84-1.9-16.89 4 .05-.21 3.5-17-1.88-3.71a42.72 42.72 0 0 1 7.86-49.59 42.73 42.73 0 0 1 60.42 0 2.28 2.28 0 0 0 .22.22 42.72 42.72 0 0 1 -.22 60.19z"></path>
                      <path d="m116.71 105.29c-2.07 3.26-5.34 7.25-9.45 8.24-7.2 1.74-18.25.06-32-12.76l-.17-.15c-12.09-11.21-15.23-20.54-14.47-27.94.42-4.2 3.92-8 6.87-10.48a3.93 3.93 0 0 1 6.15 1.41l4.45 10a3.91 3.91 0 0 1 -.49 4l-2.25 2.92a3.87 3.87 0 0 0 -.35 4.32c1.26 2.21 4.28 5.46 7.63 8.47 3.76 3.4 7.93 6.51 10.57 7.57a3.82 3.82 0 0 0 4.19-.88l2.61-2.63a4 4 0 0 1 3.9-1l10.57 3a4 4 0 0 1 2.24 5.91z"></path>
                    </g>
                  </g>
                </g>
              </svg>
            </span>
            <div className="flex flex-col">
              <h2 className="text-sm font-bold text-black">
                <a
                  href="tel:9599393130"
                  className=" text-black no-underline text-sm xl:text-base"
                >
                  (+91)-9599393130
                </a>
              </h2>
              <p className="text-xs text-black">Chat on WhatsApp</p>
            </div>
          </div>
        </div>
      </div>

      {/* OTP Section */}
      {isOpen1 && (
        <div className="demo1-container-popup flex items-center justify-center">
          <div className={`${width} dropdown-animation`}>
            <div className="flex flex-col relative rounded-xl p-4 bg-white shadow-md">
              <span
                onClick={() => {
                  setIsOpen1(false);
                }}
                className="bg-[#ffffff] hover:bg-red-300 border-[1px] border-zinc-300 hover:border-red-500 shadow-sm hover:shadow p-1 rounded-full text-black hover:scale-95 duration-300 cursor-pointer absolute -right-2 -top-2 z-10"
              >
                <IoClose className="text-xl" />
              </span>
              <h6 className="text-sm text-black pb-1 border-b flex items-center gap-2">
                <span>
                  <svg
                    className="w-8 h-8"
                    version="1.1"
                    id="fi_1067566"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsHlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 512 512"
                    xmlSpace="preserve"
                  >
                    <g>
                      <g>
                        <path d="M366,396c-5.52,0-10,4.48-10,10c0,5.52,4.48,10,10,10c5.52,0,10-4.48,10-10C376,400.48,371.52,396,366,396z"></path>
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          d="M390.622,363.663l-47.53-15.84l-17.063-34.127c15.372-15.646,26.045-36.348,29.644-57.941L357.801,243H376
			c16.542,0,30-13.458,30-30v-63C406,67.29,338.71,0,256,0c-82.922,0-150,67.097-150,150v63c0,13.036,8.361,24.152,20,28.28V253
			c0,16.542,13.458,30,30,30h8.782c4.335,9.417,9.946,18.139,16.774,25.974c1.416,1.628,2.893,3.206,4.406,4.741l-17.054,34.108
			l-47.531,15.841C66.112,382.092,26,440.271,26,502c0,5.523,4.477,10,10,10h440c5.522,0,10-4.477,10-10
			C486,440.271,445.889,382.092,390.622,363.663z M386,213c0,5.514-4.486,10-10,10h-15.262c2.542-19.69,4.236-40.643,4.917-61.28
			c0.02-0.582,0.036-1.148,0.054-1.72H386V213z M136,223c-5.514,0-10-4.486-10-10v-53h20.298c0.033,1.043,0.068,2.091,0.107,3.146
			c0.001,0.036,0.003,0.071,0.004,0.107c0,0.003,0,0.006,0,0.009c0.7,20.072,2.372,40.481,4.856,59.737H136V223z M156,263
			c-5.514,0-10-4.486-10-10v-10h8.198l2.128,12.759c0.406,2.425,0.905,4.841,1.482,7.241H156z M146.017,140H126.38
			C131.445,72.979,187.377,20,256,20c68.318,0,124.496,52.972,129.619,120h-19.635c-0.72-55.227-45.693-100-101.033-100h-17.9
			C191.712,40,146.736,84.773,146.017,140z M247.05,60h17.9c44.809,0,81.076,36.651,81.05,81.41c0,3.147-0.025,5.887-0.078,8.38
			c0,0.032-0.001,0.065-0.001,0.098l-12.508-1.787c-33.98-4.852-66.064-20.894-90.342-45.172C241.195,101.054,238.652,100,236,100
			c-26.856,0-52.564,12.236-69.558,32.908C170.63,92.189,205.053,60,247.05,60z M178.54,263c-5.006-16.653-10.734-65.653-12-97.053
			l13.459-17.946c12.361-16.476,31.592-26.713,52.049-27.888c26.917,25.616,61.739,42.532,98.537,47.786l14.722,2.104
			c-0.984,20.885-2.995,41.843-5.876,61.118c-0.001,0.006-0.002,0.013-0.003,0.02c-0.916,6.197-1.638,10.185-3.482,21.324
			c-5.296,31.765-28.998,60.49-60.287,68.313c-12.877,3.215-26.443,3.214-39.313,0c-19.537-4.884-37.451-18.402-49.012-37.778
			h20.386c4.128,11.639,15.243,20,28.28,20h20c16.575,0,30-13.424,30-30c0-16.542-13.458-30-30-30h-20
			c-13.327,0-24.278,8.608-28.297,20H178.54z M235.159,341.016c6.859,1.445,13.852,2.184,20.841,2.184
			c5.471,0,10.943-0.458,16.353-1.346l-17.67,18.687L235.159,341.016z M240.935,375.079l-31.718,33.542
			c-8.732-16.714-16.235-34.109-22.389-51.917l11.911-23.822L240.935,375.079z M311.566,329.494l13.604,27.209
			c-6.164,17.838-13.669,35.239-22.392,51.933l-33.948-33.948L311.566,329.494z M226,273c0-5.521,4.478-10,10-10h20
			c5.514,0,10,4.486,10,10c0,5.522-4.479,10-10,10h-20C230.486,283,226,278.514,226,273z M46.4,492
			c3.963-49.539,36.932-94.567,81.302-109.363l42.094-14.028c7.712,21.325,17.266,42.052,28.463,61.74
			c0.019,0.034,0.037,0.068,0.056,0.101c0,0.001,0.001,0.001,0.001,0.002c8.181,14.389,17.389,28.45,27.372,41.799L237.99,492H46.4z
			 M256,483.086l-13.562-21.773c-0.152-0.244-0.314-0.481-0.486-0.711c-8.098-10.802-15.652-22.099-22.532-33.662l35.663-37.714
			l37.578,37.578c-6.926,11.647-14.506,22.991-22.611,33.796C269.56,461.253,270.255,460.224,256,483.086z M274.01,492
			l12.301-19.748c10.027-13.4,19.301-27.574,27.564-42.132c0.05-0.088,0.097-0.178,0.147-0.266c0.006-0.011,0.012-0.021,0.018-0.032
			c11.055-19.5,20.509-40.047,28.164-61.213l42.093,14.028c44.371,14.796,77.34,59.824,81.303,109.363H274.01z"
                        ></path>
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          d="M435.546,451.531c-6.683-13.377-16.472-25.261-28.309-34.367c-4.378-3.369-10.656-2.55-14.023,1.828
			c-3.368,4.378-2.549,10.656,1.828,14.024c9.454,7.273,17.272,16.766,22.611,27.453c2.473,4.949,8.483,6.941,13.415,4.477
			C436.008,462.478,438.013,456.472,435.546,451.531z"
                        ></path>
                      </g>
                    </g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                  </svg>
                </span>
                <span>
                  <span className="font-semibold text-[15px] flex flex-col">
                    To help us narrow down your query,
                  </span>{" "}
                  <span className="">Please select the Subject.</span>
                </span>
              </h6>

              <div className="mt-2 borde flex flex-col gap-2">
                {Object.values(userType?.option).map((item) => (
                  <a
                    href={`https://wa.me/9953140287?text= ${
                      !isAuthenticated
                        ? `Greetings BUWCH. I need more info on ${item.message}`
                        : `Greetings. This is ${
                            kycData?.username || ""
                          }. I need help with ${item.message}`
                    }`}
                    key={item.id}
                    className="flex items-center gap-2 hover:shadow-sm rounded-lg p-2.5 cursor-pointer hover:bg-[#FFF4B5] border-[1px] hover:border-[#FFD61F] duration-300"
                  >
                    <span>
                      <svg
                        className="w-[18px] h-[18px]"
                        viewBox="0 0 512 512"
                        xmlns="http://www.w3.org/2000/svg"
                        id="fi_13983851"
                      >
                        <g id="Layer_2" data-name="Layer 2">
                          <g id="Layer_1_copy_2" data-name="Layer 1 copy 2">
                            <g id="_1" data-name="1">
                              <path d="m256 0c-141.38 0-256 114.62-256 256s114.62 256 256 256 256-114.62 256-256-114.62-256-256-256zm128.88 215-133.2 133.2c-.71.85-1.46 1.68-2.26 2.48a38.23 38.23 0 0 1 -53.91 0c-.8-.8-1.55-1.63-2.26-2.48l-66.13-66.13a38 38 0 0 1 0-53.7 38 38 0 0 1 53.7 0l41.65 41.63 108.71-108.69a38 38 0 0 1 53.7 0 38 38 0 0 1 0 53.69z"></path>
                            </g>
                          </g>
                        </g>
                      </svg>
                    </span>
                    <span className="text-sm font-semibold text-black">
                      {item.message}
                    </span>
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AIPage2;
