import React, { useState, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import Header from "../../components/Header/Header";
import "./Products.css";
import Filter from "../../components/Filter/Filter";
import CheckAuth from "../../components/ServiceFunctions/CheckAuth";
import secureLocalStorage from "react-secure-storage";

function ProductPage() {
  const { isAuthenticated } = CheckAuth();
  let MaterialCost = JSON.parse(secureLocalStorage.getItem("Material_cost"));
  let Customize = JSON.parse(secureLocalStorage.getItem("Customize"));
  let Catalogue = JSON.parse(secureLocalStorage.getItem("Catalogue"));
  const [searchParams, setSearchParams] = useSearchParams();
  const [material, setMaterial] = useState([]);
  const [customize, setCustomize] = useState([]);
  const [catalogue, setCatalogue] = useState([]);
  const containerRef = useRef(null);
  const [filteredShoes, setFilteredShoes] = useState([]);
  // States to manage filters
  const [selectedCategory, setSelectedCategory] = useState(
    searchParams.get("category") || ""
  );
  const [filter, setFilter] = useState("Relevance");
  const [currency, setCurrency] = useState("INR");
  const [leather, setLeather] = useState(
    searchParams.get("leather")?.split(" ") || []
  );
  const [sole, setSole] = useState(searchParams.get("sole")?.split(" ") || []);
  const [color, setColor] = useState(
    searchParams.get("color")?.split(" ") || []
  );
  const [construction, setConstruction] = useState(
    searchParams.get("construction")?.split(" ") || []
  );

  // Update URL query parameters when filter values change
  useEffect(() => {
    const params = {};
    if (selectedCategory) params.category = selectedCategory;
    if (leather.length > 0) params.leather = leather.join(" ");
    if (sole.length > 0) params.sole = sole.join(" ");
    if (color.length > 0) params.color = color.join(" ");
    if (construction.length > 0) params.construction = construction.join(" ");

    setSearchParams(params);
  }, [selectedCategory, leather, sole, color, construction]);

  useEffect(() => {
    if (searchParams.get("category")) {
      setSelectedCategory(searchParams.get("category"));
    }
    if (searchParams.get("leather")) {
      setLeather(searchParams.get("leather").split(" "));
    }
    if (searchParams.get("sole")) {
      setSole(searchParams.get("sole").split(" "));
    }
    if (searchParams.get("color")) {
      setColor(searchParams.get("color").split(" "));
    }
    if (searchParams.get("construction")) {
      setConstruction(searchParams.get("construction").split(" "));
    }
  }, [searchParams]);

  useEffect(() => {
    if (
      !isAuthenticated &&
      Catalogue === null &&
      Customize === null &&
      MaterialCost === null
    ) {
      fetch(`http://127.0.0.1:8000/api/get-all-products-data/`, {
        method: "POST",
        body: JSON.stringify({}),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to fetch data");
          }
          return response.json();
        })
        .then((data) => {
          setCatalogue(data.catalogue);
          setCustomize(data.customize);
          setMaterial(data.materials);
        })
        .catch((error) => {
          console.error("Error: ", error);
        });
    } else {
      setMaterial(JSON.parse(secureLocalStorage.getItem("Material_cost")));
      setCustomize(JSON.parse(secureLocalStorage.getItem("Customize")));
      setCatalogue(JSON.parse(secureLocalStorage.getItem("Catalogue")));
    }
  }, [isAuthenticated]);

  const calculatePrice = (item) => {
    let price = 0;
    let consumption = 0;
    const index = customize?.findIndex(
      (data) => data?.Article_no === item?.Article_no
    );
    if (index !== -1) {
      consumption = customize[index].Leather.Consumption;
    }
    material.forEach((mat) => {
      if (
        mat.item === "Leathers" &&
        mat.Leathers[item.Leather?.replaceAll(" ", "_")]
      ) {
        const leather = mat.Leathers[item.Leather?.replaceAll(" ", "_")];
        const leatherKey = Object.keys(leather).find(
          (key) =>
            leather[key].Color === item.color_leather?.replaceAll(" ", "_")
        );
        if (leatherKey) {
          price += leather[leatherKey].Price_per_dm * consumption;
        }
      }

      if (mat.item === "Soles" && mat.Soles[item.Sole?.replaceAll(" ", "_")]) {
        // Calculate sole price
        price += mat.Soles[item.Sole?.replaceAll(" ", "_")].Price || 0;
      }

      if (
        mat.item === "Finishings" &&
        mat.Finishings[item.Finish?.replaceAll(" ", "_")]
      ) {
        // Calculate finishing price
        price += mat.Finishings[item.Finish?.replaceAll(" ", "_")].Price || 0;
      }

      if (
        mat.item === "Constructions" &&
        mat.Constructions[item.Construction?.replaceAll(" ", "_")]
      ) {
        // Calculate construction price
        price +=
          mat.Constructions[item.Construction?.replaceAll(" ", "_")].Price || 0;
      }

      if (mat.item === "Welts" && mat.Welts[item.Welt?.replaceAll(" ", "_")]) {
        // Calculate welt price
        price += mat.Welts[item.Welt?.replaceAll(" ", "_")].Price || 0;
      }

      if (mat.item === "Edges" && mat.Edges[item.Edge?.replaceAll(" ", "_")]) {
        // Calculate edge price
        price += mat.Edges[item.Edge?.replaceAll(" ", "_")].Price || 0;
      }

      if (
        mat.item === "Thickness" &&
        mat.Thickness[item.Sole_thickness?.replaceAll(" ", "_")]
      ) {
        // Calculate thickness price
        price +=
          mat.Thickness[item.Sole_thickness?.replaceAll(" ", "_")].Price || 0;
      }
    });

    return price;
  };

  const filteredData = catalogue?.filter((item) => {
    let matches = true;
    if (
      color.length > 0 &&
      !color.some((c) =>
        item.color_sorting
          ?.replaceAll(" ", "_")
          .toLowerCase()
          .includes(c?.replaceAll(" ", "_").toLowerCase())
      )
    ) {
      matches = false;
    }
    if (
      leather.length > 0 &&
      !leather.some((l) =>
        item.Leather?.replaceAll(" ", "_")
          .toLowerCase()
          .includes(l?.replaceAll(" ", "_").toLowerCase())
      )
    ) {
      matches = false;
    }
    if (
      sole.length > 0 &&
      !sole.some((s) =>
        item.Sole?.replaceAll(" ", "_")
          .toLowerCase()
          .includes(s?.replaceAll(" ", "_").toLowerCase())
      )
    ) {
      matches = false;
    }
    if (
      construction.length > 0 &&
      !construction.some((c) =>
        item.Construction?.replaceAll(" ", "_")
          .toLowerCase()
          .includes(c?.replaceAll(" ", "_").toLowerCase())
      )
    ) {
      matches = false;
    }
    if (
      selectedCategory &&
      !item.Article_no.toLowerCase().includes(selectedCategory.toLowerCase())
    ) {
      matches = false;
    }
    return matches;
  });

  const cardData = filteredData?.map((item) => ({
    color: item.color_leather,
    article: item.Article_no,
    title: `${item.color_leather} ${item.Article_no.split("_")
      .slice(0, -1)
      .join(" ")
      .replaceAll("_", " ")}`,
    image: item.images.Main,
    image2: item.images.Side1,
    price: calculatePrice(item),
    keyPoints: [item.Leather, item.Finish, item.Sole, item.Construction],
  }));

  function sortCardData(data, option) {
    if (!Array.isArray(data)) {
      // console.error("Data is not an array:", data); // Debugging log
      return [];
    }

    const sortedData = [...data]; // Ensure we are not mutating the original array

    switch (option) {
      case "Name A-Z":
        sortedData.sort((a, b) => a.title.localeCompare(b.title));
        break;

      case "Name Z-A":
        sortedData.sort((a, b) => b.title.localeCompare(a.title));
        break;

      case "Price lower":
        sortedData.sort((a, b) => a.price - b.price);
        break;

      case "Price higher":
        sortedData.sort((a, b) => b.price - a.price);
        break;

      default:
        break;
      // console.error("Invalid sorting option:", option);
    }

    return sortedData;
  }

  const finalCardData = sortCardData(cardData, filter || "Name A-Z");

  const handleFilterChange = (category, filter) => {
    if (category === "Colors") setColor(filter);
    if (category === "Leathers") setLeather(filter);
    if (category === "Soles") setSole(filter);
    if (category === "Constructions") setConstruction(filter);
    if (category === "filter2") setFilter(filter);
  };

  const scrollLeft = () => {
    containerRef.current.scrollBy({
      top: 0,
      left: -200,
      behavior: "smooth",
    });
  };

  const scrollRight = () => {
    containerRef.current.scrollBy({
      top: 0,
      left: 200,
      behavior: "smooth",
    });
  };

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  const images = [
    "/images/test-shoes1.png",
    "/images/test-shoes1.png",
    "/images/test-shoes1.png",
  ];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const [direction, setDirection] = useState("enter"); // Track whether the image is entering or exiting

  useEffect(() => {
    const interval = setInterval(() => {
      setIsAnimating(true);
      setDirection("exit"); // First, exit the current image

      setTimeout(() => {
        setCurrentImageIndex((prevIndex) =>
          prevIndex === images.length - 1 ? 0 : prevIndex + 1
        );
        setDirection("enter"); // Then, enter the new image
        setTimeout(() => {
          setIsAnimating(false);
        }, 1000); // Complete animation duration
      }, 1000); // Animation timing for the exit
    }, 7000); // Change the image every 3 seconds

    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, [images.length]);

  const categories = [
    { name: "Boots", image: "/images/b2.jpg" },
    { name: "Loafers", image: "/images/b3.jpg" },
    { name: "Oxfords", image: "/images/b4.jpg" },
    { name: "Derby", image: "/images/b5.jpg" },
    { name: "Monk Strap", image: "/images/b6.jpg" },
    { name: "Sneakers", image: "/images/b9.jpg" },
    { name: "Exotic", image: "/images/b10.jpg" },
  ];

  const [isOpen, setIsOpen] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null); // To track which card is hovered
  const [showDetails, setShowDetails] = useState(false); // To control key points visibility
  const [showImage, setShowImage] = useState(false); // To control image visibility
  return (
    <>
      {/* <div className="w-full flex justify-center">
        <div className="w-full flex justify-center items-center  overflow-hidden">
          <div className="w-full flex flex-row-reverse justify-center h-full items-center overflow-hidden ">
            <div className="w-3/5 flex items-center justify-end relative mt-5">
              <div className="carousel-container">
                <div
                  className={`carousel-image-wrapper flex items-center ${
                    isAnimating ? direction : ""
                  }`}
                >
                  <img
                    className="carousel-image drop-shadow-[0_10px_10px_rgba(0,0,0,0.4)]"
                    src={images?.[currentImageIndex]}
                    alt={`Slide ${currentImageIndex + 1} of ${images.length}`}
                  />
                </div>
              </div>
              <div className="absolute left-40 -top-6">
                {" "}
                <button
                  className="rolling-button"
                  onClick={() => scrollToSection("products")}
                >
                  <p className="button__text absolute inset-0">
                    <span style={{ transform: `rotate(${23 * 0}deg)` }}>E</span>
                    <span style={{ transform: `rotate(${23 * 1}deg)` }}>X</span>
                    <span style={{ transform: `rotate(${23 * 2}deg)` }}>P</span>
                    <span style={{ transform: `rotate(${23 * 3}deg)` }}>L</span>
                    <span style={{ transform: `rotate(${23 * 4}deg)` }}>O</span>
                    <span style={{ transform: `rotate(${23 * 5}deg)` }}>R</span>
                    <span style={{ transform: `rotate(${23 * 6}deg)` }}>E</span>
                    <span style={{ transform: `rotate(${20 * 7}deg)` }}> </span>
                    <span style={{ transform: `rotate(${20 * 8}deg)` }}> </span>
                    <span style={{ transform: `rotate(${25 * 9}deg)` }}>N</span>
                    <span style={{ transform: `rotate(${25 * 10}deg)` }}>
                      O
                    </span>
                    <span style={{ transform: `rotate(${25 * 11}deg)` }}>
                      W
                    </span>
                    <span style={{ transform: `rotate(${20 * 12}deg)` }}>
                      {" "}
                    </span>
                    <span style={{ transform: `rotate(${20 * 13}deg)` }}>
                      {" "}
                    </span>
                  </p>

                  <div className="button__circle">
                    <svg
                      viewBox="0 0 14 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="button__icon"
                      width="14"
                    >
                      <path
                        d="M13.376 11.552l-.264-10.44-10.44-.24.024 2.28 6.96-.048L.2 12.56l1.488 1.488 9.432-9.432-.048 6.912 2.304.024z"
                        fill="currentColor"
                      ></path>
                    </svg>

                    <svg
                      viewBox="0 0 14 15"
                      fill="none"
                      width="14"
                      xmlns="http://www.w3.org/2000/svg"
                      className="button__icon button__icon--copy"
                    >
                      <path
                        d="M13.376 11.552l-.264-10.44-10.44-.24.024 2.28 6.96-.048L.2 12.56l1.488 1.488 9.432-9.432-.048 6.912 2.304.024z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </div>
                </button>
              </div>
            </div>
            <div className="flex flex-col items-start justify-center text-[#313131] w-2/5  h-full  rounded-md overflow-hidden bg-white pl-5">
              <div className="w-full flex flex-col items-start justify-center mt-5">
                <div className="text-5xl font-bold text-start uppercase ">
                  <p className="text-transparent text-7xl bg-clip-text bg-gradient-to-r from-[#241912] to-[#68431c] ">
                    MEN'S COLLECTION
                  </p>
                </div>
                <p className="pt-10  text-start text-base font-semibold ml-1.5">
                  Discover a unique collection of shoes for men, handcrafted by
                  our veteran leather artisans and meticulously stitched using
                  our four-generation technique. Crafted from premium-quality
                  calf leather and exotic skins, our versatile range includes
                  classic oxfords, timeless loafers, sophisticated brogues,
                  comfortable sneakers, durable derby shoes, and many more!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <Header
        curr={(e) => {
          setCurrency(e);
        }}
      />
      {/* category wise shoes */}
      <div
        className="after-header w-full flex justify-center  overflow-hidden "
        id="category-shoes"
      >
        <div className="w-full h-full flex flex-col  items-center gap-y-6 ">
          <div
            className={` w-full  ${
              isOpen ? "border-b " : "border-b rounded-lg "
            }`}
          >
            {/* Header */}
            <div
              className=" flex justify-center max-lg:gap-3 lg:gap-5 items-center py-2 rounded-lg transition-all duration-700 ease-in-out cursor-pointer"
              onClick={() => setIsOpen(!isOpen)}
            >
              <p className="text-base lg:text-lg font-semibold text-gray-700">
                Explore by Category
              </p>
              <svg
                className="w-5 h-5 transition-all duration-700 ease-in-out max-md:-rotate-90 md:hidden"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                id="fi_2722987"
              >
                <g id="_16" data-name="16">
                  <path d="m12 16a1 1 0 0 1 -.71-.29l-6-6a1 1 0 0 1 1.42-1.42l5.29 5.3 5.29-5.29a1 1 0 0 1 1.41 1.41l-6 6a1 1 0 0 1 -.7.29z"></path>
                </g>
              </svg>
            </div>

            {/* Categories with Animation */}
            <div className="overflow-hidden transition-all duration-700 ease-in-out max-h-screen max-md:hidden">
              <div className="flex items-center justify-center gap-8 mb-4">
                {categories.map((category, index) => (
                  <button
                    key={index}
                    onClick={() =>
                      setSelectedCategory(
                        category.name
                          .split(" ")[0]
                          ?.replaceAll(" ", "_")
                          .toLocaleLowerCase()
                      )
                    }
                    className={`flex flex-col items-center text-center group cursor-pointer ${
                      category.name
                        ?.replaceAll(" ", "_")
                        .toLocaleLowerCase() === selectedCategory
                        ? "underline-light"
                        : ""
                    } underline-animation-light`}
                  >
                    <img
                      src={category.image}
                      alt={category.name}
                      className="w-24 h-24 object-contain transition-transform group-hover:scale-105 text-xs"
                    />
                    <span className="text-sm text-gray-600 font-medium group-hover:text-black ">
                      {category.name}
                    </span>
                  </button>
                ))}
              </div>
            </div>

            <div
              className={`fixed top-0 right-0 h-screen w-64 bg-white shadow-lg z-50 transform transition-transform duration-700 ease-in-out ${
                isOpen ? "translate-x-0" : "translate-x-full"
              } md:hidden`}
            >
              <div className="flex items-center justify-between p-4 border-b">
                <h2 className="text-sm font-semibold">Explore by Category</h2>
                <button
                  className=""
                  onClick={() => setIsOpen(false)}
                  aria-label="Close"
                >
                  <svg
                    viewBox="0 0 512 512"
                    xmlns="http://www.w3.org/2000/svg"
                    id="fi_1828945"
                    className=" hover:fill-red-600 hover:bg-red-300 rounded-full duration-300 ease-in-out hover:scale-90  w-4 lg:w-5 h-4 lg:h-5"
                  >
                    <path d="m256 512c-141.164062 0-256-114.835938-256-256s114.835938-256 256-256 256 114.835938 256 256-114.835938 256-256 256zm0-480c-123.519531 0-224 100.480469-224 224s100.480469 224 224 224 224-100.480469 224-224-100.480469-224-224-224zm0 0"></path>
                    <path d="m176.8125 351.1875c-4.097656 0-8.195312-1.554688-11.308594-4.691406-6.25-6.25-6.25-16.382813 0-22.632813l158.398438-158.402343c6.253906-6.25 16.386718-6.25 22.636718 0s6.25 16.382812 0 22.636718l-158.402343 158.398438c-3.15625 3.136718-7.25 4.691406-11.324219 4.691406zm0 0"></path>
                    <path d="m335.1875 351.1875c-4.09375 0-8.191406-1.554688-11.304688-4.691406l-158.398437-158.378906c-6.253906-6.25-6.253906-16.382813 0-22.632813 6.25-6.253906 16.382813-6.253906 22.632813 0l158.398437 158.398437c6.253906 6.25 6.253906 16.382813 0 22.632813-3.132813 3.117187-7.230469 4.671875-11.328125 4.671875zm0 0"></path>
                  </svg>
                </button>
              </div>
              <div className="p-4">
                <div className="flex flex-col ">
                  {categories.map((category, index) => (
                    <div
                      key={index}
                      className="flex items-center py-1 gap-4 cursor-pointer group border-b"
                    >
                      <img
                        src={category.image}
                        alt={category.name}
                        className="w-16 h-16 object-contain transition-transform "
                      />
                      <span className="text-sm text-[#313131] font-semibold ">
                        {category.name}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="flex gap-2 justify-start " id="products">
              <div className="  w-full ">
                {/* filter  */}
                <div className="flex justify-between items-center text-sm font-normal w-full ">
                  <Filter
                    materials={material}
                    data={cardData}
                    leather={leather}
                    sole={sole}
                    color={color}
                    construction={construction}
                    onFilter={handleFilterChange}
                    className=" sticky top-96 left-0 z-50"
                  />
                </div>
              </div>
            </div>

            <div
              className="min-h-[90vh] flex gap-2 justify-start"
              id="products"
            >
              <div className=" flex flex-wrap justify-start   h-max w-full max-lg:mt-3 lg:mt-10 ">
                {/* products */}

                {finalCardData.length > 0 ? (
                  finalCardData?.map((card, index) => (
                    <div
                      key={index}
                      className="w-[50%] md:w-[33%] xl:w-[25%] h-max my-2 flex flex-col items-center justify-end overflow-hidden max-sm:p-1 sm:p-2 lg:p-3 pt-0 "
                    >
                      <a
                        className="w-full flex flex-col items-center justify-end"
                        href={`/catalogue/${card.article.toLowerCase()}?color=${card.color.toLowerCase()}`}
                      >
                        <article
                          key={index}
                          className="relative   isolate overflow-hidden"
                          onMouseEnter={() => {
                            setHoveredIndex(index); // Set hovered index
                            setShowImage(true); // Show image section
                          }}
                          onMouseLeave={() => {
                            setHoveredIndex(null); // Reset hovered index
                            setShowImage(false); // Hide image section
                          }}
                        >
                          <button
                            onMouseEnter={() => {
                              setShowDetails(true);
                              setShowImage(false);
                            }}
                            onMouseLeave={() => setShowDetails(false)}
                            className="absolute top-2 right-2 z-50  "
                            aria-label="Toggle details"
                          >
                            <svg
                              id="fi_18488814"
                              enableBackground="new 0 0 100 100"
                              className="w-6 h-6 fill-[#616161] hover:fill-yellow-400 hover:scale-[110%] duration-300 ease-in-out"
                              viewBox="0 0 100 100"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clipRule="evenodd" fillRule="evenodd">
                                <path d="m17.6919785 28.0415211c-.1316013-5.3589725-4.5253763-9.6649876-9.9157562-9.6649876h-.0125104c-1.0906396-.0062733-1.9743905-.8900623-1.9743905-1.9869385 0-1.090601.8900251-1.9806643 1.9869003-1.9806643 5.4781013 0 9.9220295-4.4500875 9.9220295-9.9282665.0000005-1.0906009.8900247-1.9806642 1.9869009-1.9806642 1.090601 0 1.9806652.8900633 1.9806652 1.9806647 0 5.4781785 4.4501629 9.9282665 9.9282646 9.9282665 1.0906029 0 1.9806633.8900633 1.9806633 1.9806643 0 1.0968761-.8900604 1.9869385-1.9806633 1.9869385-5.396616 0-9.79039 4.306015-9.9219913 9.6649876l-.0062733.2570038c0 1.0968761-.8900642 1.9869385-1.9806652 1.9869385-1.0968761 0-1.9869003-.8900623-1.9869003-1.9869385z"></path>
                                <path d="m94.2106781 55.8747787c0 1.9169502-1.5555191 3.4723358-3.4615021 3.4723358-19.147583 0-34.6913185 15.5438004-34.6913185 34.6804199 0 1.9169464-1.5554504 3.4724655-3.4724007 3.4724655-1.9059868 0-3.4615021-1.5555191-3.4615021-3.4724655l-.0109634-.4491577c-.2299919-18.9393845-15.6860771-34.2312622-34.6803513-34.2312622h-.0219297c-1.9059858-.0109673-3.4504709-1.566349-3.4504709-3.4723358 0-1.9169502 1.5554495-3.4724693 3.4724007-3.4724693h.1095133c19.0928879-.0546875 34.5818024-15.576561 34.5818024-34.6804199 0-1.9169512 1.5555153-3.4723339 3.4615021-3.4723339 1.9169502 0 3.4724007 1.5553827 3.4724007 3.4723339v.1096478c.0657234 19.0927544 15.5765648 34.5707703 34.6913185 34.5707703 1.9059819.0000018 3.461501 1.5555209 3.461501 3.4724711z"></path>
                                <path d="m88.8960648 29.2111721c0 .9458561-.7620773 1.7079639-1.7025528 1.7133732h-.010788c-2.8321609 0-5.1346512 2.3024902-5.1346512 5.1346474 0 .945858-.7675171 1.7133102-1.707962 1.7133102-.9458542 0-1.7133408-.7674522-1.7133408-1.7133102v-.1297112c-.0702667-2.7727127-2.3457413-5.0049362-5.1346512-5.0049362-.9458542 0-1.7133408-.7675171-1.7133408-1.7133732 0-.9404469.7674866-1.7079639 1.7133408-1.7079639 2.8321609 0 5.1346512-2.3024902 5.1346512-5.1346474 0-.9458561.7674866-1.7133083 1.7133408-1.7133083l.6648254.1351223c.4107437.1729927.735054.5026169.9080124.9079838l.5404892 2.6646404c.7783051 1.8430882 2.6051636 3.1402092 4.7292862 3.1402092.9458542 0 1.7133408.7675171 1.7133408 1.7079639z"></path>
                              </g>
                            </svg>
                          </button>
                          <div className="max-md:p-4  md:p-2 lg:p-4 w-[90%] relative">
                            {/* Image Section */}
                            <div
                              className={`  
                      }`}
                            >
                              <img
                                src={card.image}
                                alt={card.title}
                                className="object-cover"
                              />
                            </div>
                            {/* Image Section */}
                            <div
                              className={`absolute top-0 max-lg:p-2 lg:p-4 w-full left-0 transition-opacity duration-500 ${
                                showImage && hoveredIndex === index
                                  ? "opac-1 "
                                  : "opac-0"
                              }`}
                            >
                              <img
                                src={card.image2}
                                alt={card.title}
                                className="object-cover"
                              />
                            </div>

                            {/* Key Points Section */}
                            <div
                              className={`absolute inset-0 z-30 flex items-center justify-start bg-[#ffffffdc] transition-transform duration-500  ${
                                showDetails && hoveredIndex === index
                                  ? "translate-x-[40%]"
                                  : "translate-x-[110%]"
                              }`}
                            >
                              <ul className="max-md:p-1 md:p-4 space-y-2  text-xs lg:text-sm text-gray-800">
                                {(card.keyPoints || []).map(
                                  (point, pointIndex) => (
                                    <li
                                      key={pointIndex}
                                      className="flex items-center space-x-2"
                                    >
                                      <span className="w-1 h-1 md:h-2 md:w-2 font-medium bg-gray-800 rounded-full"></span>
                                      <span className="font-semibold tracking-normal">
                                        {point}
                                      </span>
                                    </li>
                                  )
                                )}
                              </ul>
                            </div>
                          </div>

                          <div className="z-50">
                            <div className="z-50 text-xs md:text-sm h-max lg:text-base font-medium uppercase text-center text-[#616161]">
                              {card.title}
                            </div>
                            <div className="z-50 overflow-hidden  text-xs lg:text-sm text-center text-[#313131] font-semibold">
                              {currency === "INR"
                                ? "₹"
                                : currency === "USD"
                                ? "$"
                                : currency === "EUR"
                                ? "€"
                                : currency === "GBP"
                                ? "£"
                                : currency === "JPY"
                                ? "JP¥"
                                : "CN¥"}
                              {card.price.toLocaleString("en-In")}
                            </div>
                          </div>
                        </article>
                      </a>
                    </div>
                  ))
                ) : (
                  <span className="text-xl text-gray-500">
                    No Catalogue to show
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductPage;
