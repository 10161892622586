import React, { useState, useEffect } from "react";
import Sourcing from "./Sourcing.jsx";
import Branding from "./Branding.jsx";
import UpperStitching from "./UpperStitching.jsx";
import SolePasting from "./SolePasting.jsx";
import Finishing from "./Finishing.jsx";
import Packaging from "./Packaging.jsx";
import ReadytoShip from "./ReadytoShip.jsx";

const steps = [
  {
    id: 1,
    label: "Sourcing",
    component: <Sourcing />,
    icon: "/images/sourcing.svg",
    activeIcon: "/images/sourcing-active.svg",
  },
  {
    id: 2,
    label: "Branding",
    component: <Branding />,
    icon: "/images/branding.svg",
    activeIcon: "/images/branding-active.svg",
  },
  {
    id: 3,
    label: "Upper Stitching",
    component: <UpperStitching />,
    icon: "/images/leather.svg",
    activeIcon: "/images/leather-active.svg",
  },
  {
    id: 4,
    label: "Sole Pasting",
    component: <SolePasting />,
    icon: "/images/soles.svg",
    activeIcon: "/images/soles-active.svg",
  },
  {
    id: 5,
    label: "Finishing",
    component: <Finishing />,
    icon: "/images/finishing.svg",
    activeIcon: "/images/finishing-active.svg",
  },
  {
    id: 6,
    label: "Packaging",
    component: <Packaging />,
    icon: "/images/package.svg",
    activeIcon: "/images/package-active.svg",
  },
  {
    id: 7,
    label: "Ready to Ship",
    component: <ReadytoShip />,
    icon: "/images/delivery.svg",
    activeIcon: "/images/delivery-active.svg",
  },
];

const ProductionStepper = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [completedSteps, setCompletedSteps] = useState([]);
  const [lastCompletedStep, setLastCompletedStep] = useState(null);
  const [isAnimating, setIsAnimating] = useState(false);

  const handleNextStep = () => {
    if (currentStep < steps.length) {
      setIsAnimating(true); // Trigger animation
      setTimeout(() => {
        setCurrentStep((prev) => prev + 1);
        setIsAnimating(false); // Reset animation state after timeout 
      }, 300); // Match animation duration
      setCompletedSteps((prev) => [...new Set([...prev, currentStep])]);
      setLastCompletedStep(currentStep); // Set the last completed step
    }
  };

  const handleGoToStep = (step) => {
    if (step <= currentStep || completedSteps.includes(step - 1)) {
      setCurrentStep(step);
    }
  };

  useEffect(() => {
    // Reset animation state when step changes
    setIsAnimating(false);
  }, [currentStep]);

  

  return (
    <div className="p-6  min-h-screen">
      {/* Stepper UI */}
      <div className="flex items-center justify-center mb-20">
        {steps.map((step, index) => (
          <div
            key={step.id}
            className="flex items-center justify-center cursor-pointer "
            onClick={() => handleGoToStep(step.id)}
            role="button"
            aria-label={`Go to ${step.label}`}
          >
            {/* Step Circle */}
            <div
              className={`flex items-center justify-center w-24 px-2 rounded-full relative ${
                completedSteps.includes(step.id)
                  ? "  text-[#ffc300] font-semibold"
                  : currentStep === step.id
                  ? "  text-[#343434] font-semibold"
                  : "text-[#343434]  "
              }`}
            >
              <div
                className={`w-10 h-10 ${
                  lastCompletedStep === step.id ? "blink-animation" : ""
                }`}
              >
                {completedSteps.includes(step.id) ? (
                  <img src={step.activeIcon} alt={`${step.label} active`} />
                ) : (
                  <img src={step.icon} alt={`${step.label} inactive`} />
                )}
              </div>
              {/* Step Label */}
              <div
                className={`absolute top-12 w-32 text-center text-xs leading-3  ${
                  lastCompletedStep === step.id ? "blink-animation" : ""
                }`}
              >
                {step.label}
              </div>
            </div>

            {/* Connector */}
            {index < steps.length - 1 && (
              <div
                className={`h-[2px] relative w-24  border-dashed border-[1px] border-gray-400 ${
                  completedSteps.includes(step.id) ? "border-none " : ""
                }`}
              >
                <div
                  className={`absolute h-[3px]  w-1  transition-all ease-in-out duration-1000 rounded-full ${
                    completedSteps.includes(step.id) ? " w-24 bg-[#ffc300]" : ""
                  }`}
                ></div>
              </div>
            )}
          </div>
        ))}
      </div>

      {/* Step Content */}
      <div
        className={`p-4  bg-white rounded-lg border  transition-transform duration-1000 ${
          isAnimating ? "fade-section" : ""
        }`}
      >
        {steps[currentStep - 1]?.component || (
          <div className="text-center text-gray-500">Step not found</div>
        )}
      </div>

      {/* Next Button */}
      {currentStep < steps.length && (
        <div className="mt-6 text-center">
          <button
            className="px-6 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            onClick={handleNextStep}
          >
            Next
          </button>
        </div>
      )}
    </div>
  );
};

export default ProductionStepper;
